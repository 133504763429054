import React from "react";

import { Field } from "../types";

import InputBlockLabel from "./InputBlockLabel";

interface Props {
  field: Field;
}

// TODO(geo): [COL-1535] Should we remove this custom control and just use the default <FormLabel>?
const InputFieldLabel: React.FC<Props> = ({ field }) => {
  return <InputBlockLabel label={field.label} subLabel={field.subLabel} />;
};

export default InputFieldLabel;
