import React from "react";

function GreenFilledCheck() {
  return (
    <div
      style={{
        display: "inline-block",
        boxSizing: "border-box",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24" // Keep viewBox as is for proper scaling
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="10" fill="#1AB092" />
        <path
          d="M7 12.5L10 15.5L17 8.5"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    </div>
  );
}

export default GreenFilledCheck;
