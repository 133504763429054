import React, { useContext, useEffect, useMemo, useState } from "react";

import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Hide,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { sendEventButtonClick } from "../api";
import {
  Action,
  ActionType,
  BannerBlock,
  BlockType,
  ButtonClickEventNames,
  EstimatorBlock as EstimatorBlockType,
  EstimatorWaitBlock as EstimatorWaitBlockType,
  HandleSubmitType,
  MenuItem as MenuItemType,
  MenuSubItem,
  NavigationChoiceType,
  OpenHelpTypes,
  PreviousBlock,
  Resource,
  ResourceType,
  Screen,
} from "../types";

import BackButton from "../components/BackButton";
import BannerRenderer from "../components/BannerRenderer";
import { CloseModal } from "../components/CloseButton";

import { FeatureFlagContext } from "../../App";
import { getUserTokenIfExists } from "../../utils/params-utils";
import { apiUrl, isInternalUse, isSandbox } from "../../utils/utils";
import { NavigationMenuContext } from "../helpers/navigationMenuContext";
import FallbackBannerIcon from "../icons/ExpertAssist/FallbackBannerIcon";
import Monitor from "../icons/Monitor";
import MoreIcon from "../icons/MoreIcon";
import { useNavManager } from "../navigationContext";
import { useUserContext } from "../userContext";
import DesktopHandoffModalBody from "./DesktopHandoffModalBody";
import EstimatorBlock from "./EstimatorBlock";
import Modal from "./Modal";
import ResourceIcon from "./ResourceIcon";
import SupportHeader from "./SupportHeader";
import EstimatorWaitBlock from "./blocks/EstimatorWaitBlock";

const activeColor = "background.light";

const Loader = () => (
  <Box w={5} h={5} display="flex" justifyContent="center" alignItems="center">
    <Spinner size="xs" />
  </Box>
);

const getMenuItems = (
  screen: Screen,
  onSubmit: (action: Action) => Promise<void>,
  paramsString: string,
) => {
  const menuItems = [];

  const userDetailContext = useUserContext();
  const featureFlags = useContext(FeatureFlagContext);

  screen.customMenuItems.forEach((customMenuItem) => {
    let buttonName = ButtonClickEventNames.UNTRACKED;
    let key = "untracked";
    if (customMenuItem.label === "Edit Questionnaire") {
      buttonName = ButtonClickEventNames.NAV_HEADER_EDIT_QUESTIONNAIRE;
      key = "edit-questionnaire";
    } else if (customMenuItem.label === "Edit Bank Info") {
      buttonName = ButtonClickEventNames.NAV_HEADER_EDIT_BANKING_INFO;
      key = "edit-bank-info";
    }

    menuItems.push(
      <MenuItem
        key={key}
        onClick={() => {
          sendEventButtonClick({
            buttonName: buttonName,
            screenId: customMenuItem.screenId,
            callback: () => {
              onSubmit({
                type: ActionType.NAVIGATE,
                navigationChoice: customMenuItem.screenId,
                resourceType: ResourceType.Target,
              });
            },
          });
        }}
      >
        {customMenuItem.label}
      </MenuItem>,
    );
  });

  // On internal and sandbox, we'll optionally show an overflow menu
  // that links to fed xml, state xml, and the user review page.
  // We'll never want to show these on production.
  if (
    (isInternalUse() || isSandbox()) &&
    featureFlags?.showInternalOverflowMenu
  ) {
    menuItems.push(
      <MenuItem
        key="federal-xml-key"
        onClick={() => {
          window.open(
            `${apiUrl()}/internal/diy/tax_return_xml?${paramsString}`,
          );
        }}
      >
        Show Federal XML
      </MenuItem>,
    );
    menuItems.push(
      <MenuItem
        key="state-xml-key"
        onClick={() => {
          window.open(
            `${apiUrl()}/internal/diy/state_tax_return_xml?${paramsString}`,
          );
        }}
      >
        Show State XML (if relevant)
      </MenuItem>,
    );
    menuItems.push(
      <MenuItem
        key="pdf-key"
        onClick={() => {
          window.open(
            `${apiUrl()}/internal/diy/tax_return_pdf?${paramsString}`,
          );
        }}
      >
        Show PDF
      </MenuItem>,
    );
    menuItems.push(
      <MenuItem
        key="user-review"
        onClick={() => {
          window.open(
            `user-review/${
              userDetailContext?.userDetails.userId
            }?apiUrl=${encodeURIComponent(apiUrl())}`,
          );
        }}
      >
        Open User Review
      </MenuItem>,
    );
  }

  return menuItems;
};

function CloseMenu({
  screen,
  onSubmit,
  screenId,
}: {
  screen: Screen;
  onSubmit: (action: Action) => Promise<void>;
  screenId: string;
}) {
  // NOTE: userToken will be empty if the user is using cookies
  const userToken = getUserTokenIfExists();
  let paramsString: string;
  if (userToken) {
    const urlToken = btoa(JSON.stringify({ token: userToken }));
    paramsString = `params=${urlToken}`;
  } else {
    paramsString = "";
  }

  const menuItems = getMenuItems(screen, onSubmit, paramsString);

  if (menuItems.length === 0) {
    return <div />;
  }

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<MoreIcon />}
          variant="unstyled"
          data-testid="top-menu"
          data-cypress="top-menu"
          display="flex"
          justifyContent="center"
          alignItems="center"
          w={12}
          h={12}
          onClick={() =>
            sendEventButtonClick({
              buttonName: ButtonClickEventNames.NAV_HEADER_MORE_BUTTON,
              screenId,
            })
          }
        ></MenuButton>
        <MenuList>{menuItems}</MenuList>
      </Menu>
    </>
  );
}

type NavMenuItemProps = {
  item: MenuItemType;
  screenId: string;
  screenSectionName?: string;
  handleClick: (props: HandleClickProps) => void;
  navigationDisabled?: boolean;
  loadingId?: string | undefined;
  changeDisplay: (arg0: string) => void;
  isStateItem?: boolean;
};

const NavMenuItem = ({
  item,
  screenId,
  screenSectionName,
  handleClick,
  navigationDisabled,
  loadingId,
  changeDisplay,
}: NavMenuItemProps) => {
  if (!item) return null;
  const otherScreens = item.otherScreens;
  const hasMatchingScreens =
    otherScreens && otherScreens.some((e: string) => e === screenId);

  const active =
    (item.link && item.link === screenId) ||
    hasMatchingScreens ||
    (item.subItems &&
      item.subItems.some(
        (e: MenuSubItem) =>
          e.link == screenId || e.sectionName == screenSectionName,
      )) ||
    (item.isStateItem && screenSectionName === "state");

  const enabled = !navigationDisabled && item.enabled;
  const loading = item.link && item.link === loadingId;

  function onClick() {
    if (!item.link && !item.sectionName) return;
    if (active) return;

    if (enabled && handleClick) {
      changeDisplay("none");
      handleClick({
        screenId: item.link,
        sectionName: item.sectionName,
        navigationChoiceType: NavigationChoiceType.Section,
      });
    }
  }

  return (
    <>
      <Box
        pl={6}
        py={3}
        borderLeft="solid 4px"
        data-cypress={item.dataCypress}
        backgroundColor={active ? activeColor : undefined}
        _hover={{
          backgroundColor: enabled && !active ? activeColor : undefined,
        }}
        borderColor={active ? "brand.medium" : "transparent"}
        onClick={onClick}
        cursor={enabled && !active ? "pointer" : "initial"}
        opacity={enabled ? 1 : 0.5}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box>
            {loading ? (
              <Loader />
            ) : enabled ? (
              <ResourceIcon iconId={item.icon} isEnabled={item.enabled} />
            ) : (
              <ResourceIcon iconId={"LOCK_ICON"} />
            )}
          </Box>
          <Flex flexDirection="row" alignItems="center" ml={2}>
            <Text size="md" fontWeight="semibold">
              {item.label}
            </Text>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

type NavMenuProps = {
  screen: Screen;
  onSubmit: HandleSubmitType | undefined;
};

type HandleClickProps =
  | {
      screenId: string;
      navigationChoiceType: NavigationChoiceType.Screen;
      resource?: Resource;
    }
  | {
      screenId: string;
      navigationChoiceType: NavigationChoiceType.Section;
      sectionName?: string;
      resource?: Resource;
    };

const MobileNavMenu: React.FC<NavMenuProps> = (props: NavMenuProps) => {
  // navHeader is the hamburger menu items that opens from the header
  const { nav, userDetails, setNavHeaderVisible } = useContext(
    NavigationMenuContext,
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const closeMenuDisclosure = useDisclosure();
  const { onOpen: onOpenCloseMenu } = closeMenuDisclosure;
  const { navigationDisabled, enableNavigation, disableNavigation } =
    useNavManager();
  const [loadingId, setLoadingId] = useState<string | undefined>(undefined);

  const { screen, onSubmit } = props;

  useEffect(() => {
    setLoadingId(undefined);
    enableNavigation();
    onClose();
  }, [screen]);

  // Handle visibility state on menu open
  const handleOpen = () => {
    setNavHeaderVisible(true);
    onOpen(); // Open the menu
  };

  const handleClose = () => {
    setNavHeaderVisible(false);
    onClose(); // Close the menu
  };

  const handleClick = (props: HandleClickProps) => {
    disableNavigation();
    setLoadingId(props.screenId);

    if (!onSubmit) {
      return;
    }

    if (props.navigationChoiceType === NavigationChoiceType.Screen) {
      const { screenId, resource } = props;

      onSubmit({
        navigationChoice: screenId,
        navigationChoiceType: NavigationChoiceType.Screen,
        type: ActionType.NAVIGATE,
        resource,
        resourceType: ResourceType.Target,
      });
    } else if (props.navigationChoiceType === NavigationChoiceType.Section) {
      const { sectionName, resource } = props;

      onSubmit({
        navigationChoice: sectionName,
        navigationChoiceType: NavigationChoiceType.Section,
        type: ActionType.NAVIGATE,
        resource,
        resourceType: ResourceType.Target,
      });
    }
  };

  const showNavItems =
    !nav?.navigationMenuDisabledScreens?.includes(screen.id) &&
    !nav?.navigationDisabled;

  return (
    <>
      <IconButton
        data-testid="top-menu"
        data-cypress="top-menu"
        aria-label="Open Menu"
        w={12}
        h={12}
        icon={<HamburgerIcon h={6} w={6} />}
        onClick={handleOpen}
        variant={"unstyled"}
      />
      <Drawer isOpen={isOpen} placement="top" onClose={handleClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <Flex
              w="100vw"
              pos="fixed"
              top="0"
              left="0"
              overflowY="auto"
              flexDir="column"
            >
              <Flex
                justify="flex-end"
                px={{ base: 5, xs: 6, sm: 8 }}
                // Not sure why this little hack is needed but without it the background bleeds through and then disappears
                // We increase the background of the top header to prevent the issue
                mb={-1}
                pb={1}
                backgroundColor="white"
              >
                <IconButton
                  w={12}
                  h={12}
                  mt={3}
                  aria-label="Open Menu"
                  size="lg"
                  icon={<CloseIcon h={4} w={4} />}
                  onClick={handleClose}
                  variant={"unstyled"}
                />
              </Flex>
              <Flex
                flexDir="column"
                h="100vh"
                maxH="100vh"
                align="center"
                onClick={handleClose}
              >
                <Box maxH="100vh" w="full">
                  <Flex
                    flexDirection="column"
                    maxH="100vh"
                    h="auto"
                    justifyContent="space-between"
                    borderBottomRadius="card"
                    backgroundColor="white"
                  >
                    {showNavItems && (
                      <Box flexDirection="column" mt={4}>
                        {nav?.navItems?.map(
                          (item: MenuItemType, index: number) => (
                            <NavMenuItem
                              key={"menuItem" + index}
                              item={item}
                              screenId={screen.id}
                              screenSectionName={screen.sectionName}
                              handleClick={handleClick}
                              navigationDisabled={navigationDisabled}
                              loadingId={loadingId}
                              changeDisplay={handleClose}
                            />
                          ),
                        )}
                      </Box>
                    )}
                    <Box
                      h="auto"
                      mx={0}
                      mt={2}
                      pt={2}
                      pb={4}
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      borderTop="1px dashed"
                      borderTopColor="border.medium"
                    >
                      {onSubmit &&
                        screen.customMenuItems?.map((customMenuItem) => {
                          // TODO(marcia): These look like they should be wrapped around sendEventButtonClick
                          // but are not... add.
                          let key = "untracked";
                          if (customMenuItem.label === "Edit Questionnaire") {
                            key = "edit-questionnaire";
                          } else if (
                            customMenuItem.label === "Edit Bank Info"
                          ) {
                            key = "edit-bank-info";
                          }

                          return (
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              cursor="pointer"
                              data-cypress={key}
                              mx={"30px"}
                              py={3}
                              _hover={{
                                backgroundColor: activeColor,
                              }}
                              onClick={() =>
                                handleClick({
                                  screenId: customMenuItem.screenId,
                                  navigationChoiceType:
                                    NavigationChoiceType.Screen,
                                })
                              }
                            >
                              <Box>
                                <ResourceIcon iconId={customMenuItem.iconId} />
                              </Box>
                              <Flex
                                flexDirection="row"
                                alignItems="center"
                                ml={2}
                              >
                                <Text size="md" fontWeight="semibold">
                                  {customMenuItem.label}
                                </Text>
                              </Flex>
                            </Box>
                          );
                        })}
                      <Box>
                        {nav?.returnItems?.map(
                          (item: MenuItemType, index: number) => (
                            <NavMenuItem
                              key={"menuItem" + index}
                              item={item}
                              screenId={screen.id}
                              screenSectionName={screen.sectionName}
                              handleClick={handleClick}
                              navigationDisabled={navigationDisabled}
                              loadingId={loadingId}
                              changeDisplay={onClose}
                            />
                          ),
                        )}
                      </Box>
                      <Box
                        data-testid="close-button-mobile-nav"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        cursor="pointer"
                        mx={"30px"}
                        py={3}
                        _hover={{
                          backgroundColor: activeColor,
                        }}
                        onClick={onOpenCloseMenu}
                      >
                        <Box>
                          <ResourceIcon iconId={"EXIT_ARROW"} />
                        </Box>
                        <Flex flexDirection="row" alignItems="center" ml={2}>
                          <Text size="md" fontWeight="semibold">
                            Back to {userDetails?.bankNavTitle}
                          </Text>
                        </Flex>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <CloseModal small disclosureProps={closeMenuDisclosure} />
    </>
  );
};

function NavHeader({
  onSubmit,
  renderBack,
  onBack,
  bannerItems,
  tempScreenId = "",
  previousBlock,
  estimatorBlock,
  supportEnabled,
  showDesktopTest = false,
  screen,
}: {
  onSubmit?: (action: Action, requestScreen?: Screen) => Promise<void>;
  renderBack: boolean;
  onBack?: () => Promise<void>;
  bannerItems: BannerBlock[] | undefined;
  tempScreenId: string;
  screen: Screen;
  previousBlock?: PreviousBlock;
  estimatorBlock?: EstimatorBlockType | EstimatorWaitBlockType;
  supportEnabled: boolean;
  showDesktopTest?: boolean;
}) {
  const { nav } = useContext(NavigationMenuContext);
  const [showScreenId, setShowScreenId] = useState(false);

  const openEventProperties = {
    openKey: "desktop_icon",
    openType: OpenHelpTypes.BLOCK,
    screenId: tempScreenId,
  };

  const desktopTestButton = (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      h={12}
      w={12}
      cursor="pointer"
    >
      <Icon as={Monitor} boxSize={6}></Icon>
    </Box>
  );

  const showExpertAssistFallbackBanner = useMemo(() => {
    // STOPLAUNCH(veronica): This should come from the BE, since it will change
    // with each tax year.
    const fallbackScreenId = "Screens::FallbackLoadingScreen";

    return (
      (screen.accessToExpertAssistLiveHelp ||
        screen.accessToExpertAssistExpertReview) &&
      tempScreenId.includes(fallbackScreenId)
    );
  }, [
    screen.accessToExpertAssistLiveHelp,
    screen.accessToExpertAssistExpertReview,
    tempScreenId,
  ]);

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        zIndex={1}
        backgroundColor={{ base: "background.light", md: "white" }}
      >
        <Box
          boxShadow={{ md: "card" }}
          display="flex"
          justifyContent="center"
          w="full"
          py={3}
          zIndex={1000}
          px={{ base: 5, xs: 6, sm: 8 }}
        >
          <Box
            display="flex"
            maxW={nav ? "full" : "lg"}
            minHeight={12}
            w="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            {estimatorBlock && estimatorBlock.type === BlockType.ESTIMATOR && (
              <EstimatorBlock
                fedAmount={estimatorBlock.amount}
                fedStatus={estimatorBlock.status}
                stateAmount={estimatorBlock.stateAmount}
              />
            )}
            {estimatorBlock &&
              estimatorBlock.type === BlockType.ESTIMATOR_WAIT && (
                <EstimatorWaitBlock
                  timeoutMilliseconds={estimatorBlock.timeoutMilliseconds}
                />
              )}
            {onBack && !showExpertAssistFallbackBanner && (
              <BackButton
                onBack={onBack}
                previousBlock={previousBlock}
                disabled={!renderBack}
              />
            )}
            {!showExpertAssistFallbackBanner && (
              <Spacer
                onClick={
                  isInternalUse() ? () => setShowScreenId(true) : undefined
                }
              />
            )}
            {showScreenId && (
              <>
                <Box onClick={() => setShowScreenId(false)}>{screen.id}</Box>
                <Spacer />
              </>
            )}
            {showExpertAssistFallbackBanner && (
              <Flex alignItems="center" justifyContent="center" gap={1}>
                <FallbackBannerIcon />
                <Text fontWeight={600} fontSize={16}>
                  You're enrolled in Expert Assist
                </Text>
              </Flex>
            )}
            {showDesktopTest && !showExpertAssistFallbackBanner && (
              <Box
                display={
                  estimatorBlock
                    ? { base: "none", sm: "initial", md: "none" }
                    : { base: "initial", md: "none" }
                }
              >
                <Modal
                  noMargins
                  openEventProperties={openEventProperties}
                  icon={desktopTestButton}
                  body={
                    <DesktopHandoffModalBody
                      screenId={tempScreenId}
                      userEmail={screen.userEmail}
                    />
                  }
                />
              </Box>
            )}
            <Flex>
              {supportEnabled && (
                <SupportHeader
                  screen={screen}
                  screenId={tempScreenId}
                  onSubmit={onSubmit}
                  resource={screen.resource}
                  accessToExpertAssistLiveHelp={
                    !!screen.accessToExpertAssistLiveHelp
                  }
                  accessToExpertAssistExpertReview={
                    !!screen.accessToExpertAssistExpertReview
                  }
                  shouldColumnTaxOfferExpertAssistOnSupportScreen={
                    !!screen.shouldColumnTaxOfferExpertAssistOnSupportScreen
                  }
                  expertAssistScreenId={screen.expertAssistScreenId}
                />
              )}
              {/*
              we show the overflow (...) menu:
              - always in internal use
              - for external use -- we only show the (...) menu on specific desktop screens
                  (HomeScreen, DirectDepositPrefillScreen) where we want to
                  allow but not promote a certain navigation path
            */}
              {onSubmit && (
                <Show above="navmenu">
                  <CloseMenu
                    screen={screen}
                    onSubmit={onSubmit}
                    screenId={tempScreenId}
                  />
                </Show>
              )}
            </Flex>
            <Hide above="navmenu">
              {screen.showMobileNavMenu !== false && (
                <MobileNavMenu onSubmit={onSubmit} screen={screen} />
              )}
            </Hide>
          </Box>
        </Box>
      </Flex>

      {bannerItems && <BannerRenderer bannerItems={bannerItems} />}
    </>
  );
}

export default NavHeader;
