import { SDKEvent } from "../types";
import { onExit } from "./api";

// On very rare occasion -- as when we conditionally embed our fallback
// in an iframe of our own, we will listen for the close event
// and forward it up to the partner frame.
export function forwardCloseMessage(event: MessageEvent) {
  // This is heavily borrowed from our column-tax.js sdk, see
  // ColumnTax.receiveMessage
  if (
    event.origin !== "https://app-sandbox.columnapi.com" &&
    event.origin !== "https://app.columnapi.com" &&
    event.origin !== "http://localhost:3001" &&
    event.origin !== "http://127.0.0.1:3001" &&
    event.origin !== "https://classic-columntax-com-qa.test.env.bz" &&
    event.origin !== "https://classic.columnapi.com"
  ) {
    return;
  }
  const data = JSON.parse(event.data);
  if (data.name === SDKEvent.COLUMN_ON_CLOSE) {
    onExit();
  }
}
