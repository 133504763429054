import React, { ReactNode } from "react";

import { Box, Flex } from "@chakra-ui/react";

import { useAppHeight } from "../utils/utils";

const AppContainer = ({
  screenId,
  children,
}: {
  screenId: string;
  children: ReactNode;
}) => {
  const appHeight = useAppHeight();
  return (
    <Box height="100%" borderRadius="md" data-testid="tax-filing">
      <Box
        minHeight={appHeight}
        margin="auto"
        data-cypress-screen-id={screenId}
        backgroundColor={{ md: "background.light" }}
      >
        <Flex flexDirection="row" flexWrap="nowrap">
          {children}
        </Flex>
      </Box>
    </Box>
  );
};

export default AppContainer;
