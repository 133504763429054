import React from "react";

import { Link } from "@chakra-ui/react";

import { Currency } from "../types";

import { centsToUsdNoCents, dollarsToUsdNoCents } from "./CurrencyFormatter";

const URL_REGEX =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

export function textWithLinks(content: string) {
  const words: string[] = content.split(" ");
  return (
    <>
      {words.map((word: string) => {
        return word.match(URL_REGEX) ? (
          <>
            <Link
              href={word}
              rel="noreferrer"
              target="_blank"
              color="brand.medium"
              fontWeight="medium"
              cursor="pointer"
            >
              {word}
            </Link>{" "}
          </>
        ) : (
          word + " "
        );
      })}
    </>
  );
}

function createMarkup(code: string): { __html: string } {
  return { __html: code };
}

export function renderWithHtml(code: string) {
  return <span dangerouslySetInnerHTML={createMarkup(code)} />;
}

export function textFromArray(text: string | string[]) {
  if (typeof text == "string") return text;
  return text.map((paragraph: string, index: number) => {
    return <p key={index}>{paragraph}</p>;
  });
}

export function detailsValuetoString(value: Currency | string | number) {
  switch (typeof value) {
    case "string":
      return value;
    case "number":
      return dollarsToUsdNoCents(value);
    case "object":
      if (value?.cents || value?.cents == 0) {
        return centsToUsdNoCents(value.cents);
      }
      return null;
    default:
      return null;
  }
}
