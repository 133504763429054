import React from "react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Collapse, Flex, Text, useDisclosure } from "@chakra-ui/react";

import { CollapsibleSectionBlock as CollapsibleSectionBlockType } from "../types";

import ContentListRenderer from "./ContentListRenderer";

interface Props {
  blockProps: CollapsibleSectionBlockType;
}

const outerBorderRadius = "md";
const borderColor = "border.medium";
const borderWidth = "1px";

const CollapsibleSectionBlock: React.FC<Props> = ({ blockProps }) => {
  const { label, collapseContentBlocks, open } = blockProps;
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: open });

  return (
    <>
      <Box
        borderWidth={borderWidth}
        borderColor={borderColor}
        borderRadius={outerBorderRadius}
        background="background.dark"
      >
        <Flex
          tabIndex={0}
          _hover={{ cursor: "pointer" }}
          align="center"
          p="3"
          justify="space-between"
          onClick={onToggle}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              onToggle();
            }
          }}
        >
          <Text fontWeight="semibold" fontSize="md">
            {label}
          </Text>
          <Box>
            <ChevronDownIcon
              boxSize="6"
              fontWeight="bold"
              transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"} // Rotate the icon based on isOpen
            />
          </Box>
        </Flex>
        <Collapse in={isOpen} animateOpacity>
          <Box
            background="white"
            borderColor={borderColor}
            borderTopWidth={borderWidth}
            borderBottomRadius={outerBorderRadius}
            p="4"
            h="full"
          >
            <ContentListRenderer contentList={collapseContentBlocks} />
          </Box>
        </Collapse>
      </Box>
    </>
  );
};

export default CollapsibleSectionBlock;
