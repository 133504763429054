import React, { useEffect } from "react";

import { Center, Spinner, Text } from "@chakra-ui/react";

import { BASE_HEADERS } from "./utils/headers";
import { apiUrl } from "./utils/utils";

const StripeRedirectPage = () => {
  const downloadStripeAndRedirect = async () => {
    const response = await fetch(
      `${apiUrl()}/internal/diy/drake/download_stripe_info${
        window.location.search
      }`,
      {
        headers: {
          ...BASE_HEADERS,
        },
      },
    );

    const json = await response.json();

    if (response.ok) {
      window.location.href = json.redirectUrl;
    }
  };

  useEffect(() => {
    downloadStripeAndRedirect();
  }, []);

  return (
    <Center minHeight="400px">
      <Spinner mr="4" color="brand.medium" />
      <Text>Processing your payment...</Text>
    </Center>
  );
};

export default StripeRedirectPage;
