export class ApiError extends Error {
  backtrace: string[] | undefined;

  constructor(message: string, backtrace?: string[] | undefined) {
    super(message);
    this.message = message;
    this.backtrace = backtrace;
  }
}

export class LoggedOutError extends Error {}

export class BackendOfflineError extends Error {}
