import React from "react";

function GetStartedIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.81818C7.48131 3.81818 3.81818 7.48131 3.81818 12C3.81818 16.5187 7.48131 20.1818 12 20.1818C16.5187 20.1818 20.1818 16.5187 20.1818 12C20.1818 7.48131 16.5187 3.81818 12 3.81818ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
        fill="#293056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3581 7.72086C11.7131 7.36583 12.2887 7.36583 12.6437 7.72086L16.2801 11.3572C16.6351 11.7122 16.6351 12.2878 16.2801 12.6429L12.6437 16.2792C12.2887 16.6343 11.7131 16.6343 11.3581 16.2792C11.003 15.9242 11.003 15.3486 11.3581 14.9936L14.3516 12L11.3581 9.00651C11.003 8.65148 11.003 8.07588 11.3581 7.72086Z"
        fill="#293056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.45508 11.9999C7.45508 11.4978 7.86209 11.0908 8.36417 11.0908H15.6369C16.139 11.0908 16.546 11.4978 16.546 11.9999C16.546 12.502 16.139 12.909 15.6369 12.909H8.36417C7.86209 12.909 7.45508 12.502 7.45508 11.9999Z"
        fill="#293056"
      />
    </svg>
  );
}

export default GetStartedIcon;
