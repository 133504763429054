import React, { useEffect } from "react";

import { Box, Flex, Heading, Text } from "@chakra-ui/react";

import { ActionType, InterludeIcons, InterludeScreenProps } from "../types";

import NavHeader from "../components/NavHeader";

import AnimatedCTLogo from "../icons/AnimatedCTLogo";
import ArrowsIcon from "../icons/ArrowsRight";
import BankIcon from "../icons/Bank";
import UserIcon from "../icons/User";
import HandWithPhone from "../icons/hand_with_phone.svg";
import WomanWithLaptop from "../icons/woman_with_laptop.svg";

const InterludeScreen: React.FC<InterludeScreenProps> = (
  props: InterludeScreenProps,
) => {
  const { screen, onSubmit, supportEnabled } = props;

  useEffect(() => {
    const timeout = setTimeout(() => {
      onSubmit({
        navigationChoice: screen.nextScreen,
        type: ActionType.NAVIGATE,
        resource: screen.resource,
      });
    }, screen.delay);

    return () => clearTimeout(timeout);
  }, []);

  let Icon;
  switch (screen.icon) {
    case InterludeIcons.WOMAN_WITH_LAPTOP:
      Icon = <img src={WomanWithLaptop} style={{ margin: "auto" }} />;
      break;
    case InterludeIcons.ANIMATED_CT_ICON:
      Icon = (
        <Flex direction="row" alignItems="center">
          <Box px={2}>
            <UserIcon />
          </Box>
          <Box px={2}>
            <ArrowsIcon />
          </Box>
          <Box px={2}>
            <AnimatedCTLogo />
          </Box>
          <Box px={2}>
            <ArrowsIcon />
          </Box>
          <Box px={2}>
            <BankIcon />
          </Box>
        </Flex>
      );
      break;
    case InterludeIcons.HAND_WITH_PHONE:
    default:
      Icon = <img src={HandWithPhone} style={{ margin: "auto" }} />;
  }

  return (
    <Box w="full" flexDirection="column" zIndex={1}>
      <NavHeader
        bannerItems={screen.bannerItems}
        renderBack={false}
        tempScreenId={screen.id}
        supportEnabled={supportEnabled}
        showDesktopTest={screen.desktopIcon}
        screen={screen}
        onSubmit={onSubmit}
      />
      <Flex
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
        alignItems="center"
        flex={1}
        minHeight={{ base: "appHeight", md: "appHeight" }}
      >
        <Box>{Icon}</Box>
        <Box
          // (Tomasz) Arbitrary max width from #design
          maxW="324px"
        >
          {screen.subTitle && (
            <Text mt={12} fontWeight="600">
              {screen.subTitle}
            </Text>
          )}
          <Heading size="xl" mt={4} fontWeight="extrabold">
            {screen.title || screen.id}
          </Heading>
        </Box>
      </Flex>
    </Box>
  );
};

export default InterludeScreen;
