export enum ProductIdentifier {
  DIY,
  NAV,
  DIY_UPLOAD_BINARY_ATTACHMENT,
  DIY_DELETE_BINARY_ATTACHMENT,
  DIY_GET_BINARY_ATTACHMENT,
  DIY_GET_BINARY_ATTACHMENT_STATUS,
  DIY_UPLOAD_W2,
  TRU,
  EVENTS,
  SUPPORT,
  VERIFY_PHONE_CODE,
  SEND_PHONE_VERIFICATION_CODE,
  AUTHENTICATE_VIA_DEVICE_ID,
  ACTIVE_TIME,
  ZENDESK,
  EMAIL_DIY_MAGIC_LINK,
  REQUEST_EXPERT_REVIEW,
  OPT_IN_TO_EXPERT_ASSIST,
  SEND_CALLBACK_DETAILS,
  LOGOUT,
  DEVICE_ID_REPORTER,
  ESTIMATOR,
}
