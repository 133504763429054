import React, { ReactNode, createContext, useContext } from "react";

import { UserDetails } from "./types";

type userContextType = {
  userDetails: UserDetails;
};

const userContext = createContext<null | userContextType>(null);

export const UserContextProvider = ({
  children,
  userDetails,
}: {
  children: ReactNode;
  userDetails: UserDetails;
}) => {
  return (
    <userContext.Provider value={{ userDetails }}>
      {children}
    </userContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(userContext);
};
