import React from "react";
import { Calendar, Edit, LogOut, MessageSquare, Phone } from "react-feather";

import { IconId } from "../types";

import Archive from "../icons/ArchiveIcon";
import CheckIcon from "../icons/CheckIcon";
import CoinsIcon from "../icons/CoinsIcon";
import CreditCardCircle from "../icons/CreditCardCircle";
import Disqualifying from "../icons/Disqualifying";
import DollarSign from "../icons/DollarSign";
import GetStartedIcon from "../icons/GetStartedIcon";
import Home from "../icons/Home";
import LocationCircle from "../icons/LocationCircle";
import LockIcon from "../icons/LockIcon";
import PersonCircle from "../icons/PersonCircle";
import PersonPlusCircle from "../icons/PersonPlusCircle";
import Review from "../icons/Review";
import ReviewFileIcon from "../icons/ReviewFileIcon";
import Splash from "../icons/Splash";
import WarningIcon from "../icons/Warning";

interface Props {
  iconId: string;
  isEnabled?: boolean;
}

// NOTE(nihar): we now have react-feather, which has many of our icons. some of these can likely be changed out for
// react-feather icons. To do that, remove the SVG, and then import the react icon:
// `import { Mail } from "react-feather";`
const ResourceIcon: React.FC<Props> = (props: Props) => {
  const { iconId, isEnabled } = props;

  switch (iconId) {
    case IconId.PERSON:
      return <PersonCircle />;
    case IconId.CALENDAR:
      return <Calendar />;
    case IconId.DOLLAR_SIGN:
      return <DollarSign />;
    case IconId.LOCATION:
      return <LocationCircle />;
    case IconId.CREDIT_CARD:
      return <CreditCardCircle isEnabled={isEnabled} />;
    case IconId.HOME:
      return <Home />;
    case IconId.MESSAGE_SQUARE:
      return <MessageSquare />;
    case IconId.PERSON_PLUS:
      return <PersonPlusCircle isEnabled={isEnabled} />;
    case IconId.PHONE:
      return <Phone />;
    case IconId.COINS:
      return <CoinsIcon />;
    case IconId.REVIEW_AND_FILE:
      return <ReviewFileIcon />;
    case IconId.DISQUALIFYING:
      return <Disqualifying />;
    case IconId.SPLASH:
      return <Splash />;
    case IconId.REVIEW:
      return <Review />;
    case IconId.LOCK_ICON:
      return <LockIcon />;
    case IconId.WARNING:
      return <WarningIcon />;
    case IconId.CHECK:
      return <CheckIcon />;
    case IconId.EXIT_ARROW:
      return <LogOut size={20} />;
    case IconId.GET_STARTED:
      return <GetStartedIcon />;
    case IconId.EDIT:
      return <Edit size={20} />;
    case IconId.ARCHIVE:
      return <Archive />;

    default:
      return <PersonCircle />;
  }
};

export default ResourceIcon;
