import React from "react";
import { Control, Controller } from "react-hook-form";

import { Box, FormControl } from "@chakra-ui/react";

import { ReactHookFormError, SelectField } from "../types";

import { ErrorMessages, isInvalid, resolveVariant } from "../helpers/Errors";
import InputFieldLabel from "./InputFieldLabel";
import Radios from "./Radios";

export interface SelectFieldProps {
  field: SelectField;
  error: ReactHookFormError;
  control: Control;
  isLoading: boolean;
}

const SelectFieldRadio: React.FC<SelectFieldProps> = ({
  field,
  error,
  control,
  isLoading,
}: SelectFieldProps) => {
  return (
    <Box>
      <FormControl isInvalid={isInvalid(error)} isDisabled={isLoading}>
        <InputFieldLabel field={field} />

        <Controller
          render={({ field: { onChange, value } }) => {
            return (
              <Radios
                name={field.id}
                variant={resolveVariant(error)}
                value={value}
                onChange={onChange}
                options={field.options}
                showCheckboxes
              />
            );
          }}
          defaultValue={field.answer}
          name={field.id}
          control={control}
        />
        <ErrorMessages error={error} />
      </FormControl>
    </Box>
  );
};

export default SelectFieldRadio;
