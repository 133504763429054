import React, { useContext } from "react";

import { Box, Flex, Show, Text } from "@chakra-ui/react";

import {
  Action,
  ActionType,
  MenuItem,
  MenuSubItem,
  Resource,
  ResourceType,
  Screen,
  ScreenId,
} from "../types";

import { NavigationMenuContext } from "../helpers/navigationMenuContext";
import ChevronRight from "../icons/ChevronRight";
import { useUserContext } from "../userContext";

const REVIEW_SECTION_NAME = "review";
const REVIEW_SECTION_LABEL = "Review and file";
const HOME_SECTION_NAME = "home";

type HandleSubmitType = (
  action: Action,
  requestScreen?: Screen,
) => Promise<void>;

type BreadcrumbsProps = {
  screen: Screen;
  onSubmit: HandleSubmitType;
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = (
  props: BreadcrumbsProps,
) => {
  const { screen, onSubmit } = props;
  const { id, sectionName } = screen;

  const { nav } = useContext(NavigationMenuContext);
  const userDetailContext = useUserContext();

  if (!nav || !userDetailContext) return null;

  const handleClick = (screenId: ScreenId | undefined, resource?: Resource) => {
    if (screenId) {
      onSubmit({
        navigationChoice: screenId,
        type: ActionType.NAVIGATE,
        resource,
        resourceType: ResourceType.Target,
      });
    }
  };

  let sectionString: string | undefined;
  let sectionLink: ScreenId | undefined;
  let subSectionString: string | undefined;
  let resource: Resource;

  if (screen.sectionName === HOME_SECTION_NAME) return null;

  const homeMenuItem = nav.navItems.find((item) => {
    return item.link.endsWith("::HomeScreen");
  });

  nav.navItems.map((item: MenuItem) => {
    if (!item) return;
    const label = item.label;
    const link = item.link;
    const subItems = item.subItems;

    const active = link && link === id;

    if (
      active ||
      (subItems &&
        subItems.some((e: MenuSubItem) => e.sectionName == sectionName))
    ) {
      sectionString = label;
      sectionLink = link;
    }

    subItems &&
      subItems.map((subItem: MenuSubItem) => {
        if (subItem.link === id || subItem.sectionName == sectionName) {
          subSectionString = subItem.label;
          resource = subItem.resource;
        }
      });

    if (screen.sectionName === REVIEW_SECTION_NAME) {
      subSectionString = REVIEW_SECTION_LABEL;
      sectionString =
        nav.subTitle || userDetailContext.userDetails.bankNavSubTitle;

      // TODO - ScreenId is an outdated type. It seems like we need to consolidate this FE concept of
      // screen constants
      sectionLink = homeMenuItem?.link;
    }
  });

  if (subSectionString)
    return (
      <Box w="full">
        <Flex
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Show above="md">
            <Box
              cursor={
                sectionLink && !nav.navigationDisabled ? "pointer" : "initial"
              }
              onClick={
                !nav.navigationDisabled && sectionLink && handleClick
                  ? () => handleClick(sectionLink, resource)
                  : undefined
              }
            >
              {/* Instead of bolding, we're using a workaround shadow. Bolding changes the character width resulting in snappy hover.*/}
              <Text
                _hover={
                  nav.navigationDisabled
                    ? undefined
                    : {
                        cursor: "pointer",
                        textDecoration: "underline",
                        textShadow:
                          "-0.03ex 0 0 currentColor, 0.03ex 0 0 currentColor",
                      }
                }
                size="caption"
              >
                {sectionString}
              </Text>
            </Box>
            <Box mx={2}>
              <ChevronRight />
            </Box>
          </Show>
          <Box>
            <Text size="caption" fontWeight="semibold">
              {subSectionString}{" "}
            </Text>
          </Box>
        </Flex>
      </Box>
    );
  return null;
};

export default Breadcrumbs;
