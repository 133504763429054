import React from "react";

import { Circle } from "@chakra-ui/react";

import TickIconSVG from "./tick_icon.svg";

interface Props {
  pixelSize?: number;
  mr?: string;
}

function TickIcon({ pixelSize = 30, mr = "2" }: Props) {
  return (
    <Circle size={`${pixelSize}px`} bg="brand.medium" mr={mr}>
      <img src={TickIconSVG} />
    </Circle>
  );
}

export default TickIcon;
