import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import NumberFormat from "react-number-format";

import { Box, FormControl } from "@chakra-ui/react";

import { CurrencyField, ReactHookFormError } from "../types";

import { ErrorMessages, isInvalid, resolveVariant } from "../helpers/Errors";
import InputFieldLabel from "./InputFieldLabel";
import StyledInput from "./StyledInput";

export interface CurrencyFieldProps {
  field: CurrencyField;
  register: UseFormRegister<FieldValues>;
  error: ReactHookFormError;
  control: Control;
  isLoading: boolean;
}

const CurrencyFieldInput: React.FC<CurrencyFieldProps> = ({
  field,
  error,
  control,
  isLoading,
}) => {
  return (
    <Box>
      <FormControl isInvalid={isInvalid(error)} isDisabled={isLoading}>
        <InputFieldLabel field={field} />

        <Controller
          render={({ field: { onChange, value, onBlur } }) => (
            <NumberFormat
              name={field.id}
              inputMode={"decimal"}
              variant={resolveVariant(error)}
              allowNegative={field.allowNegative || false}
              decimalScale={field.allowCents ? 2 : 0}
              thousandSeparator={true}
              fixedDecimalScale={false}
              onValueChange={(v) => onChange(v.value)}
              prefix="$"
              value={value}
              placeholder={field.placeholder}
              customInput={StyledInput}
              isAllowed={(values) => {
                const { floatValue } = values;

                // Exit early and allow the input if either is undefined
                if (floatValue === undefined || field === undefined) {
                  return true;
                }

                // Otherwise, proceed to check min and max value boundaries
                let passes = true;
                if (field.minCents && floatValue < field.minCents / 100) {
                  passes = false;
                }

                if (field.maxCents && floatValue > field.maxCents / 100) {
                  passes = false;
                }

                return passes;
              }}
              onBlur={() => {
                // We don't want to modify the value if the field is disabled
                if (value && !field.disabled) {
                  value = Math.round(Number(value));
                  onChange(value);
                }
                onBlur();
              }}
              isReadOnly={field.disabled}
              disabled={field.disabled}
            />
          )}
          name={field.id}
          control={control}
          defaultValue={field.answer}
        />
        <ErrorMessages error={error} />
      </FormControl>
    </Box>
  );
};

export default CurrencyFieldInput;
