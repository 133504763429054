import React from "react";

interface LiveIconProps {
  width?: number;
  height?: number;
}

const LiveIcon: React.FC<LiveIconProps> = ({ width = 48, height = 48 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="48.2598"
        width="47"
        height="47"
        rx="23.5"
        transform="rotate(-90 0.5 48.2598)"
        fill="white"
      />
      <rect
        x="0.5"
        y="48.2598"
        width="47"
        height="47"
        rx="23.5"
        transform="rotate(-90 0.5 48.2598)"
        stroke="#F2F4F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2929 30.0527C27.6834 29.6621 28.3166 29.6621 28.7071 30.0527L33.0571 34.4027C33.4476 34.7932 33.4476 35.4263 33.0571 35.8169C32.6666 36.2074 32.0334 36.2074 31.6429 35.8169L27.2929 31.4669C26.9024 31.0763 26.9024 30.4432 27.2929 30.0527Z"
        fill="#1E263A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2547 19.7598C18.5197 17.9463 20.6213 16.7598 23 16.7598C25.3788 16.7598 27.4804 17.9463 28.7453 19.7598H31.0645C29.5918 16.7963 26.5337 14.7598 23 14.7598C19.4663 14.7598 16.4082 16.7963 14.9355 19.7598H17.2547ZM19.3924 29.7598H16.2917C17.9397 31.601 20.3345 32.7598 23 32.7598C25.6655 32.7598 28.0604 31.601 29.7083 29.7598H26.6076C25.5541 30.3946 24.3197 30.7598 23 30.7598C21.6804 30.7598 20.446 30.3946 19.3924 29.7598Z"
        fill="#1E263A"
      />
      <path
        d="M16 23.7598L16.8179 25.4021C17.439 26.6498 18.3727 27.6768 19.5069 28.3601L21 29.2598L19.5069 30.1595C18.3727 30.8427 17.439 31.8697 16.8179 33.1174L16 34.7598L15.1821 33.1174C14.561 31.8697 13.6273 30.8427 12.4931 30.1595L11 29.2598L12.4931 28.3601C13.6273 27.6768 14.561 26.6498 15.1821 25.4021L16 23.7598Z"
        fill="#D4AF37"
      />
      <path
        d="M33.9998 28.5986H28.9185V21.418H33.9998V22.7637H30.4472V24.2708H33.9244V25.6165H30.4472V27.2529H33.9998V28.5986Z"
        fill="#1E263A"
      />
      <path
        d="M25.4887 28.5986H23.5724L20.8057 21.418H22.5389L24.5305 26.9299L26.5222 21.418H28.2554L25.4887 28.5986Z"
        fill="#1E263A"
      />
      <path
        d="M20.1342 28.5986H18.6055V21.418H20.1342V28.5986Z"
        fill="#1E263A"
      />
      <path
        d="M17.5754 28.5986H13V21.418H14.5395V27.2529H17.5754V28.5986Z"
        fill="#1E263A"
      />
    </svg>
  );
};

export default LiveIcon;
