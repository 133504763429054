import React from "react";

interface Props {
  isEnabled?: boolean;
}

function CreditCardCircle({ isEnabled }: Props) {
  const color = isEnabled ? "#0070FF" : "#C4C4C4";

  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="19" r="18.5" fill="white" stroke="#E0E0E1" />
      <rect
        x="10"
        y="13"
        width="18"
        height="12"
        rx="3"
        stroke={color}
        strokeWidth="2"
      />
      <rect x="10" y="16" width="18" height="2" fill={color} />
      <rect x="12" y="20" width="5" height="2" rx="1" fill={color} />
    </svg>
  );
}

export default CreditCardCircle;
