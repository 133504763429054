import React from "react";

import { VStack } from "@chakra-ui/react";

import SaveAsPdfBlock from "./SaveAsPdfBlock";

interface Props {
  url: string;
  authenticate: boolean;
}

const PdfBlock: React.FC<Props> = ({ url, authenticate }) => {
  return (
    <VStack>
      <SaveAsPdfBlock
        label="Save as PDF"
        url={url}
        authenticate={authenticate}
      />
    </VStack>
  );
};

export default PdfBlock;
