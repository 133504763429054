import React, { lazy } from "react";

import LinkModal from "../../components/LinkModal";

import { CustomerSupportLinkModal } from "./CustomerSupportModal";

const PrivacyPolicy = lazy(() => import("../../components/PrivacyPolicy"));
const TermsOfService = lazy(() => import("../../components/TermsOfService"));

interface Props {
  openText: string;
  screenId: string;
  bodyComponent?: string;
}

const TemporaryLinkModalBlock: React.FC<Props> = ({
  openText,
  screenId,
  bodyComponent,
}) => {
  let modal = <></>;
  if (bodyComponent === "PrivacyPolicy") {
    modal = <PrivacyPolicy />;
  } else if (bodyComponent === "TermsOfService") {
    modal = <TermsOfService />;
  } else if (bodyComponent === "CustomerSupport") {
    // The CustomerSupportLink follows a bit of a different pattern
    return <CustomerSupportLinkModal screenId={screenId} openText={openText} />;
  }
  return <LinkModal openText={openText} body={modal}></LinkModal>;
};

export default TemporaryLinkModalBlock;
