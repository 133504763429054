import React from "react";

import { Box, Input } from "@chakra-ui/react";

import InputBlockLabel from "./InputBlockLabel";

interface Props {
  label: string;
  subLabel: string;
}

const NotApplicableBlock: React.FC<Props> = ({ label, subLabel }) => {
  return (
    <Box mt="5">
      <InputBlockLabel label={label} subLabel={subLabel} />
      <Input
        fontSize="md"
        fontWeight="normal"
        borderRadius="lg"
        isDisabled={true}
        value="N/A"
        bgColor="background.dark"
        border="1px"
        borderColor="border.medium"
      />
    </Box>
  );
};

export default NotApplicableBlock;
