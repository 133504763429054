import React from "react";
import { MultipleFieldErrors } from "react-hook-form";

import { FormErrorMessage } from "@chakra-ui/react";

import { AlertTypes, ReactHookFormError } from "../types";

export function isInvalid(error?: ReactHookFormError): boolean {
  // invalid types can either be ErrorTypes.ERROR or ErrorTypes.Warning
  return (error && error.type != "") || false;
}

export const resolveColor = (error: ReactHookFormError) => {
  if (error?.type == AlertTypes.WARNING) {
    return "warning.dark";
  } else if (error?.type == AlertTypes.ERROR) {
    return "error.medium";
  } else return "border.medium";
};

export function resolveVariant(error: ReactHookFormError): string {
  if (error?.type == AlertTypes.WARNING) {
    return "outline-warning";
  } else if (error?.type == AlertTypes.ERROR) {
    return "outline-error";
  } else return "outline";
}

export function ErrorMessages({
  error,
}: {
  error?: ReactHookFormError;
}): JSX.Element {
  if (error?.type == AlertTypes.ERROR) {
    return (
      <>
        {((error?.types as MultipleFieldErrors)?.custom as string[])?.map(
          (message, index) => {
            return (
              <FormErrorMessage color="error.dark" key={index}>
                {message}
              </FormErrorMessage>
            );
          },
        )}
      </>
    );
  } else if (error?.type == AlertTypes.WARNING) {
    return (
      <>
        <FormErrorMessage color="warning.dark">
          See warning below
        </FormErrorMessage>
      </>
    );
  }
  return <></>;
}
