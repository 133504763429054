import React, { useState } from "react";

import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";

import { AnimatePresence, motion } from "framer-motion";

import {
  ModalStep as ModalStepType,
  NavigationModalBlock as NavigationModalBlockType,
} from "../../types";

import BackIcon from "../../icons/BackIcon";
import BlockRenderer from "../BlockRenderer";

const pageVariants = {
  initial: (direction: number) => ({
    x: direction * 50, // Slide in from right or left based on direction
    opacity: 0,
  }),
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    x: direction * -50, // Slide out in opposite direction
    opacity: 0,
  }),
};

const NavigationModalBlock: React.FC<NavigationModalBlockType> = ({
  steps,
  isOpen,
  onClose,
  screen,
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  // Add direction state to track navigation direction for framer-motion
  const [direction, setDirection] = useState(1); // 1 for forward, -1 for backward
  // State to track to skip animation if the modal is opened for the first time
  const [isInitialRender, setIsInitialRender] = useState(true);

  const handleClose = () => {
    setCurrentStepIndex(0); // Reset index when closing
    setIsInitialRender(true);

    const currentStep = steps[currentStepIndex];

    if (currentStep.onClose) {
      currentStep.onClose();
    }

    onClose();
  };

  const handleNext = () => {
    const currentStep = steps[currentStepIndex];
    setDirection(1); // Set direction to forward
    setIsInitialRender(false);

    if (currentStep.onStepComplete) {
      const canProceed = currentStep.onStepComplete();
      if (!canProceed) return;
    }

    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      handleClose?.();
    }
  };
  const handleBack = () => {
    if (currentStepIndex > 0) {
      setDirection(-1); // Set direction to backward
      setIsInitialRender(false);
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const renderStep = (step: ModalStepType) => {
    return (
      <motion.div
        key={currentStepIndex}
        custom={direction}
        initial={isInitialRender ? false : "initial"} // Skip initial animation if it's first render
        variants={pageVariants}
        animate="animate"
        exit="exit"
        transition={{
          type: "tween",
          duration: 0.3,
        }}
        style={{ width: "100%", height: "100%", gap: 8 }}
      >
        <Flex
          flexDirection="column"
          gap={8}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <ModalBody
            px={{ base: 5, md: 0 }}
            pt={0}
            pb={0}
            gap={5}
            display="flex"
            flexDirection="column"
            maxWidth="472px"
            width="100%"
            flex="1"
            overflow="auto"
            alignItems="center"
          >
            <BlockRenderer blocks={step.blocks} screen={screen} />
          </ModalBody>
          <ModalFooter
            width="100%"
            maxWidth="472px"
            display="flex"
            justifyContent="center"
            pt={0}
            px={{ base: 5, md: 0 }}
            pb={0}
            mt="auto"
          >
            <Button
              variant="primary"
              onClick={handleNext}
              width={{ base: "100%", md: "335px" }}
            >
              {steps[currentStepIndex].buttonLabel}
            </Button>
          </ModalFooter>
        </Flex>
      </motion.div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      scrollBehavior="inside"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        pt={{ base: 0, md: 0 }}
        pb={{ base: 12, md: 12 }}
        px={{ base: 0, md: 0 }}
        maxHeight="full"
        // There is a fixed width and height here so that the modal does not change size when the user navigates
        // This may need to be adjusted if we have a list of blocks that are taller than the modal
        height={{ base: "80%", md: "718px" }}
        width={{ base: "100%", md: "718px" }}
        borderRadius="0"
        bottom="0px"
        borderTopRadius="card"
        borderBottomRadius={{ base: 0, md: "card" }}
        // Use safe-area-inset-top to account for iphone notch
        mt="max(20px, env(safe-area-inset-top))"
        containerProps={{
          height: "full",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: { base: "flex-end", md: "center" },
          borderTopRadius: "card",
        }}
      >
        <Flex justifyContent="flex-start" px={5} pt={2} minHeight={14}>
          {/* Show back button only if we're not on the first step AND hideBackButton is false */}
          {currentStepIndex > 0 && !steps[currentStepIndex].hideBackButton && (
            <IconButton
              aria-label="Back"
              icon={<BackIcon height={28} width={28} />}
              variant="ghost"
              onClick={handleBack}
              height={10}
              width={10}
            />
          )}
          <ModalCloseButton size="lg" />
        </Flex>
        <AnimatePresence mode="wait" custom={currentStepIndex}>
          {/* Subtract header height */}
          <Flex direction="column" height="calc(100% - 56px)">
            {renderStep(steps[currentStepIndex])}
          </Flex>
        </AnimatePresence>
      </ModalContent>
    </Modal>
  );
};

export default NavigationModalBlock;
