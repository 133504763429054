// Expects a number 1023.99 to represent $1023.99. Returns
// comma separated "1,023.99".
export function dollarsToUsd(amountDollars: number): string {
  const usd = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return usd.format(amountDollars);
}

export function centsToUsd(cents: number): string {
  const usd = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return usd.format(cents / 100);
}

// Expects a number 1023.99 and returns comma separated value as
// above, but will return a dollars only rounded amount, ie "1,024".
export function dollarsToUsdNoCents(amountDollars: number): string {
  const usd = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    // we need to specify both minimum and maximum fraction digits to support old browsers
    // for 'currency', minimumFractionDigits is defaulted to 2 and the constructor in old
    // browsers had an invariant that maximumFractionDigits >= minimumFractionDigits
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return usd.format(amountDollars);
}

export function centsToUsdNoCents(cents: number): string {
  return dollarsToUsdNoCents(cents / 100);
}
