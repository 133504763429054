import React, { useEffect } from "react";

import { Box, Divider, Text } from "@chakra-ui/react";

import { Action, SectionStatusResponse, Source, TManageScreen } from "../types";

import ResourceItem from "./ResourceItem";

interface Props {
  screen: TManageScreen;
  deleteButtonEnabled: boolean;
  onSubmit: (action: Action) => void;
  clearLoaders?: () => void;
  currentTimer?: null | NodeJS.Timeout;
  navigationDisabled?: boolean;
  navigationDisableSetter?: (state: boolean) => void;
}

const ResourceList: React.FC<Props> = (props: Props) => {
  const {
    onSubmit,
    screen,
    screen: { resources },
    clearLoaders,
    currentTimer,
    navigationDisabled,
    navigationDisableSetter,
  } = props;

  useEffect(() => {
    currentTimer && clearTimeout(currentTimer);
    clearLoaders && clearLoaders();
    return;
  }, [resources]);

  if (resources.length === 0) {
    return null;
  } else {
    const Resources = resources.map((obj) => {
      const {
        resource,
        resourceStatus,
        heading,
        subHeading,
        iconId,
        resourceSource,
        details,
      } = obj;

      return (
        <ResourceItem
          key={resource}
          heading={heading}
          subHeading={subHeading}
          iconId={iconId}
          onSubmit={onSubmit}
          resource={resource}
          deleteButtonEnabled={props.deleteButtonEnabled}
          resourceName={screen.labels.delete ?? screen.labels.addAnother}
          resourceStatus={resourceStatus as unknown as SectionStatusResponse}
          resourceSource={resourceSource as Source}
          details={details}
          navigationDisabled={navigationDisabled}
          navigationDisableSetter={navigationDisableSetter}
          hostBankName={screen.hostBankName}
        />
      );
    });

    const viewEdit =
      !screen.labels.viewEdit || screen.labels.viewEdit.length === 0 ? null : (
        <Box display="flex" flexDirection="column" py={{ base: 2, md: 0 }}>
          <Text
            fontSize="md"
            fontWeight="bold"
            textAlign="left"
            color="text.secondary"
            textTransform="uppercase"
          >
            VIEW/EDIT {screen.labels.viewEdit}
          </Text>
          <Divider color="text.secondary" />
        </Box>
      );

    return (
      <Box display="flex" flexDirection="column" gridRowGap={6}>
        {viewEdit}
        {Resources}
      </Box>
    );
  }
};

export default ResourceList;
