import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";

import { Box, FormControl } from "@chakra-ui/react";

import { BooleanField, FieldAnswer, ReactHookFormError } from "../types";

import { ErrorMessages, isInvalid, resolveVariant } from "../helpers/Errors";
import InputFieldLabel from "./InputFieldLabel";
import Radios from "./Radios";

export interface BooleanFieldProps {
  field: BooleanField;
  register: UseFormRegister<FieldValues>;
  error: ReactHookFormError;
  control: Control;
  isLoading: boolean;
}
const BooleanFieldRadio: React.FC<BooleanFieldProps> = (
  props: BooleanFieldProps,
) => {
  const parseBoolean = (value: FieldAnswer) => {
    if (value === undefined || value === null) {
      return undefined;
    }
    // Cast the value from boolean to string true/false to properly
    // set the user's previous answer
    return value ? "true" : "false";
  };
  const options = [
    {
      label: props.field.trueLabel || "Yes",
      value: "true",
    },
    {
      label: props.field.falseLabel || "No",
      value: "false",
    },
  ];

  return (
    <Box>
      <FormControl
        isInvalid={isInvalid(props.error)}
        isDisabled={props.isLoading}
      >
        <InputFieldLabel field={props.field} />
        <Controller
          render={({ field: { onChange, value } }) => (
            <Radios
              horizontal={props.field.horizontal}
              name={props.field.id}
              value={parseBoolean(value) as string}
              onChange={(v) => {
                onChange(v === "true");
              }}
              options={options}
              variant={resolveVariant(props.error)}
              showCheckboxes={!props.field.horizontal}
            ></Radios>
          )}
          name={props.field.id}
          control={props.control}
        />
        <ErrorMessages error={props.error} />
      </FormControl>
    </Box>
  );
};

export default BooleanFieldRadio;
