import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import NumberFormat from "react-number-format";

import { Box, FormControl } from "@chakra-ui/react";

import { DecimalField, ReactHookFormError } from "../types";

import { ErrorMessages, isInvalid, resolveVariant } from "../helpers/Errors";
import InputFieldLabel from "./InputFieldLabel";
import StyledInput from "./StyledInput";

interface Props {
  field: DecimalField;
  register: UseFormRegister<FieldValues>;
  error: ReactHookFormError;
  control: Control;
  isLoading: boolean;
}

const DecimalFieldInput: React.FC<Props> = ({
  field,
  error,
  control,
  isLoading,
}) => {
  const checkMinMax =
    field.min !== undefined &&
    field.min !== null &&
    field.max !== undefined &&
    field.max !== null;

  return (
    <Box>
      <FormControl isInvalid={isInvalid(error)} isDisabled={isLoading}>
        <InputFieldLabel field={field} />

        <Controller
          render={({ field: { onChange, value, onBlur } }) => (
            <NumberFormat
              name={field.id}
              variant={resolveVariant(error)}
              allowNegative={false}
              decimalScale={2}
              thousandSeparator={true}
              fixedDecimalScale={true}
              onValueChange={(v) => onChange(v.value)}
              value={value}
              placeholder={field.placeholder}
              customInput={StyledInput}
              onBlur={onBlur}
              isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                return (
                  formattedValue === "" ||
                  (floatValue != null &&
                    checkMinMax &&
                    floatValue >= (field.min || 0) &&
                    floatValue <= (field.max || 0)) ||
                  !checkMinMax
                );
              }}
            />
          )}
          name={field.id}
          control={control}
          defaultValue={field.answer}
        />
        <ErrorMessages error={error} />
      </FormControl>
    </Box>
  );
};

export default DecimalFieldInput;
