import React, { ReactElement } from "react";

/**
 * Note there is a very similarly implemented legacy CloseButton
 * that is used in TRU.
 * TODO(marcia): Switch TRU CloseButton over to this one, when we
 * want to re-visit and test it...
 */
import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
  useDisclosure,
} from "@chakra-ui/react";

import { exitDiy } from "../api";

import QuestionText from "../../components/QuestionText";

import ExitIcon from "../icons/ExitIcon";

// CTA block widths forNormal and Small
const LARGE_MODAL_CTA_WIDTH = "600px";
const SMALL_MODAL_CTA_WIDTH = "375px";

export function CloseModal({
  disclosureProps,
  small,
}: {
  disclosureProps: UseDisclosureReturn;
  small?: boolean;
}) {
  const { isOpen: isOpenModal, onClose: onCloseModal } = disclosureProps;

  const CancelButton = () => (
    <Button
      display={{ base: "none", md: "flex" }}
      onClick={onCloseModal}
      variant="gray"
      width={{ base: "full", md: "auto" }}
      px={{ md: 6 }}
    >
      Cancel
    </Button>
  );

  return (
    <Modal
      isOpen={isOpenModal}
      onClose={onCloseModal}
      scrollBehavior="inside"
      motionPreset="slideInBottom"
      variant={small ? "small" : "base"}
    >
      <ModalOverlay />
      <ModalContent
        height="auto"
        maxHeight="full"
        margin={0}
        borderRadius={0}
        bottom={0}
        borderTopRadius="card"
        borderBottomRadius={{ base: 0, md: "card" }}
        // Use safe-area-inset-top to account for iphone notch
        mt="max(20px, env(safe-area-inset-top))"
        containerProps={{
          height: "full",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: { base: "flex-end", md: "center" },
          borderTopRadius: "card",
        }}
      >
        <ModalCloseButton size="lg" />
        <ModalBody
          px={{ base: 8, md: 0 }}
          pt={{ base: 14, md: 0 }}
          pb={{ base: 12, md: 0 }}
          display="flex"
          flexDirection="column"
        >
          <Flex direction="column" height="auto">
            <QuestionText text="Are you sure you want to exit?" />
            <Text mt={6}>
              We'll save your place, so everything will be just as you left it
              when you're ready to continue filing your taxes.
            </Text>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={{ md: "center" }}
              alignItems={{ md: "center" }}
              mt={{ base: undefined, md: 6 }}
              gridGap={4}
              maxW={
                small
                  ? { base: "full", md: SMALL_MODAL_CTA_WIDTH }
                  : { base: "full", md: LARGE_MODAL_CTA_WIDTH }
              }
            >
              <CancelButton />
              <Button
                onClick={exitDiy}
                width={{ base: "full", md: "auto" }}
                mt={{ base: 6, md: 0 }}
                px={{ md: 10 }}
              >
                Exit Column Tax
              </Button>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function CloseButton({
  component,
  renderCloseConfirmationModal,
  small,
  includeTopPadding,
}: {
  renderCloseConfirmationModal: boolean;
  component?: ReactElement;
  small?: boolean;
  includeTopPadding?: boolean;
}) {
  // TODO(marcia): Unify with ModalBlock after season, I just copied things over
  // to the (unreleased/unused) ModalBlock to de-risk development
  const disclosureProps = useDisclosure();
  const { onOpen: onOpenModal } = disclosureProps;

  const modalOpener = component ? (
    <Box
      flexGrow={1}
      onClick={renderCloseConfirmationModal ? onOpenModal : exitDiy}
      minW={{ base: "full", md: "calc(50% - 6px)" }}
      pt={{ base: 0, md: includeTopPadding ? 8 : undefined }}
    >
      {component}
    </Box>
  ) : (
    <IconButton
      w={12}
      h={12}
      background="none"
      data-testid="close-button-diy"
      color="text.primary"
      _hover={{ backgroundColor: "white" }}
      _active={{ backgroundColor: "white", color: "text.primary" }}
      variant="customIconButton"
      aria-label="Close"
      fontSize="lg"
      onClick={renderCloseConfirmationModal ? onOpenModal : exitDiy}
      icon={<ExitIcon />}
    />
  );

  return (
    <>
      {/* Close icon or custom component in the upper right of most screens.
       In the common case, clicking the close icon will show a confirmation modal.
       Sometimes, eg on the error screen where they cannot do anything anyway (for now?),
       we will skip the confirmation modal and immediately exit the SDK.
      */}
      {modalOpener}

      {/* Modal that asks the user to confirm they want to exit */}
      <CloseModal disclosureProps={disclosureProps} small={small} />
    </>
  );
}

export default CloseButton;
