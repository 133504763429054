import React from "react";

function Bank() {
  return (
    <svg
      width="36"
      height="26"
      viewBox="0 0 36 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6.80035"
        y="6.04468"
        width="22.2792"
        height="1.59137"
        rx="0.795684"
        fill="#162436"
      />
      <rect
        x="6.80035"
        y="23.55"
        width="22.2792"
        height="1.59137"
        rx="0.795684"
        fill="#162436"
      />
      <rect
        x="13.124"
        y="21.8958"
        width="12"
        height="1.94733"
        rx="0.973666"
        transform="rotate(-90 13.124 21.8958)"
        fill="#162436"
      />
      <rect
        x="17.0187"
        y="21.8958"
        width="12.9822"
        height="1.94733"
        rx="0.973666"
        transform="rotate(-90 17.0187 21.8958)"
        fill="#162436"
      />
      <rect
        x="9.22922"
        y="21.8958"
        width="11"
        height="1.94733"
        rx="0.973666"
        transform="rotate(-90 9.22922 21.8958)"
        fill="#162436"
      />
      <rect
        x="24.8079"
        y="21.8958"
        width="11"
        height="1.94733"
        rx="0.973666"
        transform="rotate(-90 24.8079 21.8958)"
        fill="#162436"
      />
      <rect
        x="20.9132"
        y="21.8958"
        width="12"
        height="1.94733"
        rx="0.973666"
        transform="rotate(-90 20.9132 21.8958)"
        fill="#162436"
      />
      <path
        d="M17.8113 0.892949C17.8956 0.87047 17.9844 0.87047 18.0688 0.892948L29.0777 3.82648C29.643 3.97713 29.534 4.80963 28.9489 4.80963H6.93111C6.34606 4.80963 6.23704 3.97713 6.80237 3.82648L17.8113 0.892949Z"
        fill="#162436"
      />
    </svg>
  );
}

export default Bank;
