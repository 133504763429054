import React from "react";

function CoinsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.89749 5.52506C4.89746 5.52506 4.8976 5.52446 4.89799 5.52326L4.89749 5.52506ZM4.90896 5.5001C4.93129 5.46078 4.99431 5.37583 5.16347 5.25085C5.45592 5.03477 5.93664 4.80228 6.61157 4.59136C7.95071 4.17288 9.85597 3.9001 12 3.9001C14.144 3.9001 16.0492 4.17288 17.3884 4.59136C18.0633 4.80228 18.544 5.03477 18.8365 5.25085C19.0056 5.37583 19.0687 5.46078 19.091 5.5001C19.0687 5.53941 19.0056 5.62437 18.8365 5.74935C18.544 5.96543 18.0633 6.19792 17.3884 6.40883C16.0492 6.82731 14.144 7.1001 12 7.1001C9.85597 7.1001 7.95071 6.82731 6.61157 6.40883C5.93664 6.19792 5.45592 5.96543 5.16347 5.74935C4.99431 5.62437 4.93129 5.53941 4.90896 5.5001ZM19.1025 5.52506C19.1024 5.52507 19.1022 5.52447 19.102 5.52326L19.1025 5.52506ZM19.102 5.47693C19.1022 5.47573 19.1024 5.47513 19.1025 5.47513L19.102 5.47693ZM4.89799 5.47694C4.8976 5.47574 4.89746 5.47513 4.89749 5.47513L4.89799 5.47694ZM6.07467 2.8733C7.63097 2.38696 9.7257 2.1001 12 2.1001C14.2742 2.1001 16.369 2.38696 17.9253 2.8733C18.6981 3.11479 19.3889 3.42101 19.9061 3.80312C20.4057 4.17223 20.9 4.7353 20.9 5.5001C20.9 6.26489 20.4057 6.82797 19.9061 7.19707C19.3889 7.57919 18.6981 7.8854 17.9253 8.1269C16.369 8.61324 14.2742 8.9001 12 8.9001C9.7257 8.9001 7.63097 8.61324 6.07467 8.1269C5.30189 7.8854 4.61103 7.57919 4.09384 7.19707C3.59427 6.82797 3.09998 6.26489 3.09998 5.5001C3.09998 4.7353 3.59427 4.17223 4.09384 3.80312C4.61103 3.42101 5.30189 3.11479 6.07467 2.8733Z"
        fill="#293056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99998 10.3C4.49703 10.3 4.89998 10.703 4.89998 11.2C4.89998 11.2681 4.93285 11.4379 5.22387 11.6973C5.5146 11.9565 5.98705 12.2311 6.64975 12.48C7.96864 12.9754 9.8563 13.3 12 13.3C14.1437 13.3 16.0313 12.9754 17.3502 12.48C18.0129 12.2311 18.4853 11.9565 18.7761 11.6973C19.0671 11.4379 19.1 11.2681 19.1 11.2C19.1 10.703 19.5029 10.3 20 10.3C20.497 10.3 20.9 10.703 20.9 11.2C20.9 11.962 20.4884 12.5822 19.9739 13.0409C19.459 13.4999 18.7648 13.8715 17.9831 14.1651C16.4131 14.7547 14.3007 15.1 12 15.1C9.69921 15.1 7.58687 14.7547 6.01687 14.1651C5.23512 13.8715 4.5409 13.4999 4.02608 13.0409C3.51154 12.5822 3.09998 11.962 3.09998 11.2C3.09998 10.703 3.50292 10.3 3.99998 10.3Z"
        fill="#293056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99998 5.1001C4.49703 5.1001 4.89998 5.50304 4.89998 6.0001V18.353L4.90011 18.3544C4.90026 18.3553 4.9007 18.3578 4.90199 18.3621C4.90453 18.3704 4.91128 18.3885 4.92843 18.4164C4.96448 18.4752 5.03926 18.5654 5.1814 18.6772C5.47103 18.905 5.94663 19.1496 6.61642 19.3715C7.94654 19.8123 9.84629 20.1001 12 20.1001C14.1537 20.1001 16.0534 19.8123 17.3835 19.3715C18.0533 19.1496 18.5289 18.905 18.8186 18.6772C18.9607 18.5654 19.0355 18.4752 19.0715 18.4164C19.0887 18.3885 19.0954 18.3704 19.098 18.3621C19.0993 18.3578 19.0997 18.3553 19.0998 18.3544L19.1 18.353V6.0001C19.1 5.50304 19.5029 5.1001 20 5.1001C20.497 5.1001 20.9 5.50304 20.9 6.0001V18.353C20.9 19.113 20.4376 19.6938 19.9314 20.092C19.4155 20.4978 18.7244 20.8234 17.9498 21.0801C16.391 21.5967 14.2907 21.9001 12 21.9001C9.70921 21.9001 7.60897 21.5967 6.0502 21.0801C5.27554 20.8234 4.58448 20.4978 4.06855 20.092C3.56234 19.6938 3.09998 19.113 3.09998 18.353V6.0001C3.09998 5.50304 3.50292 5.1001 3.99998 5.1001Z"
        fill="#293056"
      />
    </svg>
  );
}

export default CoinsIcon;
