import React from "react";

import { Text } from "@chakra-ui/react";

import { renderWithHtml } from "../helpers/TextFormatters";

const SubTitle: React.FC<{ text: string; color?: string }> = ({
  text,
  color,
}) => {
  const formattedSubTitle = text.split("\n").map((line, index) => {
    return (
      <Text key={index} mt={0} mb={0} _last={{ marginBottom: 0 }} color={color}>
        {renderWithHtml(line)}
      </Text>
    );
  });

  return <>{formattedSubTitle}</>;
};

export default SubTitle;
