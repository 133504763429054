import React, { useState } from "react";

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";

import { submitSupportRequest } from "../api";

import QuestionText from "../../components/QuestionText";

import CustomerSupport from "../icons/CustomerSupport";

const initialState = {
  submitted: false,
  loading: false,
};

const submittingState = {
  submitted: false,
  loading: true,
};

const submittedState = {
  submitted: true,
  loading: false,
};

interface SupportModalProps {
  isOpen: boolean;
  onClose: () => void;
  screenId: string;
}

export function CustomerSupportLinkModal({
  screenId,
  openText,
}: {
  screenId: string;
  openText: string;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Text as="span" textDecoration="underline" onClick={onOpen}>
        {openText}
      </Text>
      <CustomerSupportModal
        isOpen={isOpen}
        screenId={screenId}
        onClose={onClose}
      />
    </>
  );
}

const CustomerSupportModal = ({
  isOpen,
  onClose,
  screenId,
}: SupportModalProps) => {
  const [message, setMessage] = useState("");
  const [modalState, setModalState] = useState(initialState);

  // Reset the state when the user closes the modal
  const close = () => {
    setModalState(initialState);
    setMessage("");
    onClose();
  };

  const sendSupportRequest = () => {
    setModalState(submittingState);
    submitSupportRequest(message, screenId).then(() => {
      setModalState(submittedState);
    });
  };

  const showAfterSubmit = () => {
    return modalState.submitted && !modalState.loading;
  };

  // The modal content to collect the user's message
  const form = (
    <>
      <Box>
        <Text fontWeight="bold">Description of your problem:</Text>
        <Textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Write your question or comment here."
          size="md"
          rows={20}
        />
      </Box>
      <Box>
        {/* TODO(sean): Send back email via API to display*/}
        <Text>
          Someone from our Customer Support team will respond to you at your
          email
        </Text>
      </Box>
      <Spacer />

      <Button
        isDisabled={message.length === 0}
        onClick={sendSupportRequest}
        isLoading={modalState.loading}
        width="full"
      >
        Send
      </Button>
    </>
  );

  // The modal content after the message has been submitted
  const afterSubmit = (
    <>
      <CustomerSupport />
      <Box>
        <Text>
          Thank you for contacting Customer Support. We will be in touch
          shortly.
        </Text>
      </Box>
      <Spacer />

      <Button onClick={close} width="full">
        Continue
      </Button>
    </>
  );

  return (
    <Modal isOpen={isOpen} onClose={close} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent height="full" maxHeight="full" margin="0" borderRadius="0">
        <ModalHeader paddingTop="max(20px, env(safe-area-inset-top))">
          <QuestionText text="Contact support" />
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Flex direction="column" height="full" py="10">
            {showAfterSubmit() ? afterSubmit : form}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomerSupportModal;
