import React from "react";

import { Box, Flex } from "@chakra-ui/react";

import {
  BlockType,
  IconBlock as IconBlockType,
  LinkBlock as LinkBlockType,
  NewTextBlock as NewTextBlockType,
  TemporaryLinkModalBlock as TemporaryLinkModalBlockType,
} from "../types";

import rollbar from "../../rollbar-utils";
import IconBlock from "./IconBlock";
import LinkBlock from "./LinkBlock";
import NewTextBlock from "./NewTextBlock";
import TemporaryLinkModalBlock from "./TemporaryLinkModalBlock";

interface Props {
  justifyContent?: string;
  content: Array<
    | NewTextBlockType
    | LinkBlockType
    | TemporaryLinkModalBlockType
    | IconBlockType
  >;
}

const TextCompositionBlock: React.FC<Props> = ({ content, justifyContent }) => {
  return (
    <Flex justifyContent={justifyContent || "flex-start"}>
      <Box>
        {content.map((item, index) => {
          switch (item.type) {
            case BlockType.ICON:
              return <IconBlock {...item} key={index} />;
            case BlockType.LINK:
              return <LinkBlock {...item} key={index} />;
            case BlockType.NEW_TEXT_BLOCK:
              return (
                <NewTextBlock
                  key={index}
                  {...item}
                  marginBottom="0"
                  display="inline"
                />
              );
            case BlockType.TEMPORARY_LINK_MODAL:
              return <TemporaryLinkModalBlock {...item} key={index} />;
            default:
              rollbar.error("Unexpected block type for TextCompositionBlock");
          }
        })}
      </Box>
    </Flex>
  );
};

export default TextCompositionBlock;
