import { useEffect } from "react";

import BrowserInteractionTime from "browser-interaction-time";

import { updateTotalActiveTime } from "./diy/api";

function useActiveTimeTracker() {
  useEffect(() => {
    // We record active time in 15 second intervals
    // This was chosen somewhat arbitrarily since to reduce load
    // and still get a fairly decent estimate of the user's active time
    const trackerIntervalMilleseconds = 15 * 1000;

    // Callback fires after *active* trackerIntervalMillseconds
    // Once the user has been idle for idleTimeMs, the interval pauses
    const trackerCallback = {
      // Multiplier is used to increase the amount of time between
      // active time tracking. We don't need to do this, so the
      // identity function here is a no-op
      multiplier: (time: number) => time + trackerIntervalMilleseconds,

      timeInMilliseconds: trackerIntervalMilleseconds,
      // Update the BE with the amount of active time elapsed
      callback: () =>
        updateTotalActiveTime(trackerIntervalMilleseconds).catch(() => {
          // Silently ignore active time tracking failure
        }),
    };

    const browserInteractionTime = new BrowserInteractionTime({
      timeIntervalEllapsedCallbacks: [trackerCallback],
      absoluteTimeEllapsedCallbacks: [],
      browserTabInactiveCallbacks: [],
      browserTabActiveCallbacks: [],
      // Consider inactive after this amount of time
      idleTimeoutMs: 10 * 1000,
      checkCallbacksIntervalMs: 1000,
    });

    browserInteractionTime.startTimer();

    return () => browserInteractionTime.destroy();
  }, []);
}

export default useActiveTimeTracker;
