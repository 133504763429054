import React from "react";

/**
 * Note that public/fonts/ProximaNova contains many more files, but
 * we only have four rules here to minimize initial load time.
 */
const FontFace = () => (
  <style>
    {`
      @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-display: block;
        src: url("${
          import.meta.env.BASE_URL
        }fonts/ProximaNova/ProximaNova-Regular.otf") format("opentype");
      }

      @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 500;
        font-display: block;
        src: url("${
          import.meta.env.BASE_URL
        }fonts/ProximaNova/ProximaNova-Medium.otf") format("opentype");
      }

      @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
        font-display: block;
        src: url("${
          import.meta.env.BASE_URL
        }fonts/ProximaNova/ProximaNova-Semibold.otf") format("opentype");
      }

      @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-display: block;
        src: url("${
          import.meta.env.BASE_URL
        }fonts/ProximaNova/ProximaNova-Bold.otf") format("opentype");
      }

      @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 800;
        font-display: block;
        src: url("${
          import.meta.env.BASE_URL
        }fonts/ProximaNova/ProximaNova-Extrabold.otf") format("opentype");
      }
    `}
  </style>
);

export default FontFace;
