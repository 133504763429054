import { createContext } from "react";

import { NavObject, UserDetails } from "../types";

export enum NavigationMenuState {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  ERROR = "ERROR",
  READY = "READY",
}

interface Props {
  nav: NavObject | null;
  navigationMenuState: NavigationMenuState;
  userDetails: null | UserDetails;

  // navMenu is the left hand nav
  navMenuVisible: boolean;
  setNavMenuVisible: (visible: boolean) => void;

  // navHeader is the hamburger menu items that opens from the header
  navHeaderVisible: boolean;
  setNavHeaderVisible: (visible: boolean) => void;
}

export const NavigationMenuContext = createContext<Props>({
  nav: null,
  navigationMenuState: NavigationMenuState.INITIAL,
  userDetails: null,
  navMenuVisible: false,
  setNavMenuVisible: () => {
    // Intentionally empty - will be overridden by context provider
  },
  navHeaderVisible: false,
  setNavHeaderVisible: () => {
    // Intentionally empty - will be overridden by context provider
  },
});
