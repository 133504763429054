import Rollbar from "rollbar";

// FIX ME EVE SHUM - after create-react-app is upgraded, remove the dev environment check
// with upgraded create-react-app, we can set the jest testing env variables via
// setupFiles in package.json
const rollbar = new Rollbar({
  accessToken:
    import.meta.env.REACT_APP_ENVIRONMENT === "development"
      ? import.meta.env.REACT_APP_ROLLBAR_TEST_TOKEN
      : import.meta.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  scrubTelemetryInputs: true,
  payload: {
    environment: import.meta.env.REACT_APP_ENVIRONMENT,
    client: {
      javascript: {
        // Configured according to:
        // https://docs.rollbar.com/docs/source-maps#2-configure-the-rollbarjs-sdk-to-support-source-maps
        source_map_enabled: true,
        code_version: import.meta.env.REACT_APP_BUILD_COMMIT_SHA,
        guess_uncaught_frames: true,
      },
    },
  },
});

export function configurePerson(id: string) {
  // Configure rollbar with whatever available user information that we have,
  // whether just the url param string or the user's valid token
  // See https://docs.rollbar.com/docs/person-tracking#javascript
  rollbar.configure({
    payload: {
      person: {
        id,
      },
    },
  });
}

export default rollbar;
