/* Props from NumberFormat - We could not use the NumberFormatProps directly
 * due to a type mismatch */
import React from "react";
import { NumberFormatValues } from "react-number-format";

import { Input } from "@chakra-ui/react";

interface StyledInputProps {
  placeholder?: string;
  allowNegative: false;
  decimalScale: number;
  thousandSeparator: true;
  fixedDecimalScale: true;
  onValueChange: (v: NumberFormatValues) => void;
  prefix: string;
  value: string;
  customInput: (props: StyledInputProps) => Element;
  isReadOnly?: boolean;
  variant?: string;
}

/* Input wrapper to pass to NumberFormat. Passing the Chakra input directly
 * would leave the input field unstyled.  */
const StyledInput = (props: StyledInputProps) => {
  return (
    <Input
      variant="outline"
      // I'm not sure why you need to explicitly pass the props
      // but this does not work unless you do
      {...props}
    />
  );
};

export default StyledInput;
