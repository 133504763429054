import React from "react";

function ArrowsRight() {
  return (
    <svg
      width="48"
      height="14"
      viewBox="0 0 48 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66553 2.625L9.78669 7L5.66553 11.375"
        stroke="#E5E5E5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6212 2.625L26.7423 7L22.6212 11.375"
        stroke="#E5E5E5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.5768 2.625L43.6979 7L39.5768 11.375"
        stroke="#E5E5E5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowsRight;
