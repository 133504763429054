import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import NumberFormat from "react-number-format";

import { Box, FormControl } from "@chakra-ui/react";

import { EINField, ReactHookFormError } from "../types";

import { ErrorMessages, isInvalid, resolveVariant } from "../helpers/Errors";
import InputFieldLabel from "./InputFieldLabel";
import StyledInput from "./StyledInput";

interface Props {
  field: EINField;
  register: UseFormRegister<FieldValues>;
  error: ReactHookFormError;
  control: Control;
  isLoading: boolean;
}

// EINs are 9 digit numbers that are usually formatted
// with a dash after the first two numbers, like 12-1234567
const EINFieldInput: React.FC<Props> = ({
  field,
  error,
  control,
  isLoading,
}) => {
  const formatInput = (value: string) => {
    let formattedValue = "";
    for (let i = 0; i < value.length; i++) {
      if (i > 8) {
        break;
      }

      // Add the "-" right before the 2-index digit
      if (i === 2) {
        formattedValue += "-";
      }

      formattedValue += value[i];
    }
    return formattedValue;
  };

  return (
    <Box>
      <FormControl isInvalid={isInvalid(error)} isDisabled={isLoading}>
        <InputFieldLabel field={field} />

        <Controller
          render={({ field: { onChange, value, onBlur } }) => (
            <NumberFormat
              name={field.id}
              variant={resolveVariant(error)}
              onValueChange={(v) => onChange(v.value)}
              value={value}
              placeholder={field.placeholder}
              customInput={StyledInput}
              format={formatInput}
              onBlur={onBlur}
            />
          )}
          name={field.id}
          control={control}
          defaultValue={field.answer}
        />
        <ErrorMessages error={error} />
      </FormControl>
    </Box>
  );
};

export default EINFieldInput;
