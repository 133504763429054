import React from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";

import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { ScreenId } from "../types";

interface Props {
  routingNumber: string;
  accountNumber: string;
  accountType: string;
  editScreenId?: ScreenId;
  onSubmit: (screenId: ScreenId) => void;
}

const BankingInformationBlock: React.FC<Props> = ({
  routingNumber,
  accountNumber,
  accountType,
  editScreenId,
  onSubmit,
}) => {
  const headerRow = editScreenId ? (
    <Tr>
      <Th>Banking info</Th>
      <Th textAlign="right">
        <Menu flip={true}>
          <MenuButton
            as={IconButton}
            data-manage-menu
            aria-label="Edit bank information"
            icon={
              <Icon
                as={BiDotsHorizontalRounded}
                w={6}
                h={6}
                background="background.dark"
              />
            }
            variant="outline"
            minWidth="0"
            maxHeight="6"
            color="black"
            border={"none"}
          />
          <MenuList>
            <MenuItem
              data-manage-edit
              onClick={() => {
                onSubmit(editScreenId);
              }}
            >
              Edit
            </MenuItem>
          </MenuList>
        </Menu>
      </Th>
    </Tr>
  ) : (
    <Tr>
      <Th colSpan={2}>Banking info</Th>
    </Tr>
  );

  return (
    <TableContainer>
      <Table variant="banking">
        <Thead>{headerRow}</Thead>
        <Tbody>
          <Tr>
            <Td>Account type</Td>
            <Td textTransform="capitalize">{accountType}</Td>
          </Tr>
        </Tbody>
        <Tbody>
          <Tr>
            <Td>Account number</Td>
            <Td data-private>{accountNumber}</Td>
          </Tr>
        </Tbody>
        <Tbody>
          <Tr>
            <Td>Routing number</Td>
            <Td data-private>{routingNumber}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default BankingInformationBlock;
