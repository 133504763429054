import React, { useState } from "react";

import { Button, Center, Flex } from "@chakra-ui/react";

import { Block, Screen, ScreenId } from "../types";

import BlockRenderer from "./BlockRenderer";

interface Props {
  label: string;
  contents: Block[];
  screen: Screen;
  onSubmit: (screenId: ScreenId) => void;
}

const ExpandBlock: React.FC<Props> = ({
  label,
  contents,
  onSubmit,
  screen,
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <Flex
      flexDirection="column"
      mt={visible ? 6 : 4}
      mb={visible ? 0 : 4}
      gap={6}
    >
      {!visible && (
        <Center>
          <Button
            onClick={() => {
              setVisible(!visible);
            }}
            variant="link"
            fontSize="16px"
            color="brand.medium"
          >
            {label || "Show everything"}
          </Button>
        </Center>
      )}

      {visible && (
        <BlockRenderer blocks={contents} onSubmit={onSubmit} screen={screen} />
      )}
    </Flex>
  );
};

export default ExpandBlock;
