import React from "react";

interface Props {
  isEnabled?: boolean;
}

function FileTextIcon({ isEnabled }: Props) {
  const color = isEnabled ? "#173049" : "#C4C4C4";
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1666 2.4267H5.49992C5.05789 2.4267 4.63397 2.60229 4.32141 2.91485C4.00885 3.22741 3.83325 3.65134 3.83325 4.09336V17.4267C3.83325 17.8687 4.00885 18.2926 4.32141 18.6052C4.63397 18.9178 5.05789 19.0934 5.49992 19.0934H15.4999C15.9419 19.0934 16.3659 18.9178 16.6784 18.6052C16.991 18.2926 17.1666 17.8687 17.1666 17.4267V7.4267L12.1666 2.4267Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1667 2.4267V7.4267H17.1667"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8334 11.5933H7.16675"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8334 14.9267H7.16675"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.83341 8.26001H8.00008H7.16675"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FileTextIcon;
