import React from "react";

import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Flex, Text } from "@chakra-ui/react";

import ErrorIcon from "../icons/ErrorIcon";
import FileTextIcon from "../icons/FileTextIcon";

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  hasErrors: boolean;
  isState: boolean;
  onResubmitPage: boolean;
  stateAbbreviation: string;
}
const PromptUserRefile: React.FC<Props> = ({
  onResubmitPage,
  hasErrors,
  onClick,
  isState,
  stateAbbreviation,
}) => {
  if (onResubmitPage) {
    return (
      <Flex flexDirection="column">
        <Box
          borderColor="success.dark"
          borderWidth="1px"
          bgColor="success.light"
          borderTopRadius="12px"
          borderBottom="0px"
          p={4}
        >
          <Flex direction="row" alignItems="flex-start">
            <Box>
              <CheckCircleIcon w={6} h={6} color="success.dark" />
            </Box>
            <Box flexDirection="column" ml={3}>
              <Text fontWeight="medium">Let’s resubmit your return</Text>
            </Box>
          </Flex>
        </Box>
        <Box
          borderColor="success.dark"
          borderWidth="1px"
          borderTop="0px"
          borderBottomRadius="12px"
          p={4}
          backgroundColor="white"
        >
          <Text mb={10}>
            You’re ready to file! Let's give your return one last check before
            resubmitting it.
          </Text>
          <Box textAlign="center">
            <Button width="full" onClick={onClick}>
              Resubmit Return
            </Button>
          </Box>
        </Box>
      </Flex>
    );
  }

  if (hasErrors) {
    return (
      <Flex flexDirection="column">
        <Box
          borderColor="error.medium"
          borderWidth="1px"
          bgColor="error.light"
          borderTopRadius="12px"
          borderBottom="0px"
          p={4}
        >
          <Flex direction="row" alignItems="flex-start">
            <Box>
              <ErrorIcon />
            </Box>
            <Box flexDirection="column" ml={3}>
              {isState ? (
                <Text fontWeight="medium">
                  Your {stateAbbreviation} state return was rejected
                </Text>
              ) : (
                <Text fontWeight="medium">
                  Your federal return was rejected
                </Text>
              )}
            </Box>
          </Flex>
        </Box>
        <Box
          borderColor="error.medium"
          borderWidth="1px"
          borderTop="0px"
          borderBottomRadius="12px"
          p={4}
          pb={0}
          backgroundColor="white"
        >
          <Text mb={3}>
            You’ll be able to resubmit your return after reviewing and
            addressing any errors.
          </Text>
          <Divider
            orientation="horizontal"
            borderColor="border.medium"
            variant="dashed"
            mb={0}
          />
          <Box textAlign="center">
            <Button
              onClick={onClick}
              leftIcon={<FileTextIcon isEnabled />}
              variant="link"
              color="text.mdark"
              fontWeight="semibold"
              width="full"
              p={4}
              fontSize="md"
            >
              View Return Status
            </Button>
          </Box>
        </Box>
      </Flex>
    );
  }

  return (
    <Button
      onClick={onClick}
      leftIcon={<FileTextIcon isEnabled />}
      color="text.primary"
      fontWeight="semibold"
      variant="outline"
      width="full"
      borderRadius="12px"
      borderColor="border.medium"
      mb={6}
      fontSize="md"
      _hover={{ borderColor: "border.medium" }}
    >
      View Return Status
    </Button>
  );
};

export default PromptUserRefile;
