import React from "react";

import { Circle, HStack, Icon, Show, Text } from "@chakra-ui/react";

import { OpenHelpTypes } from "../types";

import Monitor from "../icons/Monitor";
import DesktopHandoffModalBody from "./DesktopHandoffModalBody";
import Modal from "./Modal";

type Props = {
  screenId: string;
  userEmail: string;
};

const DesktopHandoffBlock: React.FC<Props> = ({ screenId, userEmail }) => {
  const openContent = (
    <HStack
      flexGrow={1}
      justifyContent="center"
      borderTopWidth="1px"
      borderTopStyle="dashed"
      borderTopColor="border.medium"
      pt={8}
      spacing={4}
    >
      <Circle size={12} bg="background.dark" color="text.primary">
        <Icon as={Monitor} boxSize={6}></Icon>
      </Circle>
      <Text>Looking for a desktop version?</Text>
    </HStack>
  );

  const openEventProperties = {
    openKey: "desktop_handoff",
    openType: OpenHelpTypes.BLOCK,
    screenId: screenId,
  };
  return (
    <Show below="md">
      <Modal
        openEventProperties={openEventProperties}
        openText={openContent}
        body={
          <DesktopHandoffModalBody screenId={screenId} userEmail={userEmail} />
        }
      />
    </Show>
  );
};

export default DesktopHandoffBlock;
