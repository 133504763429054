import React, { ReactElement, useMemo } from "react";
import { Edit } from "react-feather";

import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, useTheme } from "@chakra-ui/react";

import { Source } from "../types";

import SparkleFillIcon from "../icons/SparkleFillIcon";

type Variation =
  | undefined
  | {
      color?: string;
      outlineColor: string;
      backgroundColor: string;
      icon: ReactElement;
      label: string;
    };
function ChoiceOutline({
  readyToRefile,
  incomplete,
  prefilled,
  source,
  hostBankName,
  children,
}: {
  incomplete?: boolean;
  readyToRefile?: boolean;
  prefilled?: boolean;
  source?: Source;
  hostBankName?: string;
  children: ReactElement;
}) {
  let variation: undefined | Variation;
  const theme = useTheme();
  const prefillMediumColor = theme.colors.prefill.medium;

  const prefilledLabel = useMemo(() => {
    let sourceLabel = "";
    switch (source) {
      case Source.PARTNER_BANK:
      case Source.IMPORTED:
        // When this ChoiceOutline is used for a resource, we
        // pass in a hostBankName.
        // TODO(marcia): Fix up the types / props so that this is
        // reflected in the types and we can remove this if check
        if (hostBankName) {
          sourceLabel = hostBankName;
        }
        break;
      case Source.UPLOADED:
        sourceLabel = "your uploaded document";
        break;
      case Source.PRIOR_YEAR:
        sourceLabel = "last year";
        break;
    }
    return `Review info we brought in from ${sourceLabel}`;
  }, [source, hostBankName]);

  /**
   *  The order here is important because we only want to show the prefilled status (i.e. ready to review) if
   *  the user hasn't started editing the section. If the user has started editing the section, we want to show
   *  the incomplete status.
   * */
  if (readyToRefile) {
    variation = {
      outlineColor: "success.dark",
      backgroundColor: "success.light",
      icon: <CheckCircleIcon w={6} h={6} color="success.dark" />,
      label: "Let’s resubmit your return",
    };
  } else if (incomplete) {
    variation = {
      outlineColor: "warning.dark",
      backgroundColor: "warning.light",
      icon: <Edit size={20} />,
      label: "Finish this section to continue filing",
    };
  } else if (prefilled) {
    variation = {
      color: "text.primary",
      outlineColor: "prefill.medium",
      backgroundColor: "prefill.light",
      icon: <SparkleFillIcon color={prefillMediumColor} />,
      label: prefilledLabel,
    };
  }

  if (variation) {
    return (
      <Box
        borderColor={variation.outlineColor}
        borderWidth="1px"
        rounded="card"
        width="full"
        backgroundColor="white"
      >
        <Flex
          direction="row"
          alignItems="flex-start"
          bgColor={variation.backgroundColor}
          p={4}
          roundedTop="card"
          display="flex"
          gap={3}
        >
          <Box color={variation.outlineColor}>{variation.icon}</Box>
          <Box flexDirection="column">
            <Text fontSize="md" fontWeight="medium" color={variation.color}>
              {variation.label}
            </Text>
          </Box>
        </Flex>
        {children}
      </Box>
    );
  } else {
    return children;
  }
}

export default ChoiceOutline;
