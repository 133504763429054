import React from "react";

import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Flex,
  useTheme,
} from "@chakra-ui/react";

import { AlertTypes } from "../types";

import { renderWithHtml } from "../helpers/TextFormatters";
import ErrorIcon from "../icons/ErrorIcon";
import HelpIcon from "../icons/HelpIcon";
import InfoIcon from "../icons/InfoIcon";
import SparkleFillIcon from "../icons/SparkleFillIcon";
import WarningIcon from "../icons/WarningIcon";

interface Props {
  title?: string;
  text: string;
  status: AlertTypes;
}

const AlertBlock: React.FC<Props> = ({ title, text, status }) => {
  let borderColor = "brand";
  let backgroundColor = "brand";
  let statusIcon;

  const theme = useTheme();

  switch (status) {
    case AlertTypes.WARNING:
      borderColor = "warning.dark";
      backgroundColor = "warning.light";
      statusIcon = <WarningIcon />;
      break;
    case AlertTypes.ERROR:
      borderColor = "error.dark";
      backgroundColor = "error.light";
      statusIcon = <ErrorIcon />;
      break;
    case AlertTypes.INFO:
      borderColor = "brand.medium";
      backgroundColor = "brand.light";
      statusIcon = <InfoIcon color={theme.colors.brand.medium} />;
      break;
    case AlertTypes.PREFILL:
      borderColor = "prefill.medium";
      backgroundColor = "prefill.light";
      statusIcon = <SparkleFillIcon color={theme.colors.prefill.medium} />;
      break;
    case AlertTypes.HELP:
      borderColor = "brand.medium";
      backgroundColor = "brand.light";
      statusIcon = <HelpIcon color={theme.colors.brand.medium} />;
      break;
    case AlertTypes.SUBTLE_INFO:
    default:
      borderColor = "border.medium";
      backgroundColor = "background.dark";
      statusIcon = <InfoIcon color="var(--chakra-colors-text-secondary)" />;
      break;
  }

  return (
    <Alert
      borderColor={borderColor}
      bgColor={backgroundColor}
      borderWidth="1px"
      borderRadius="12px"
      px={3}
      py={4}
    >
      <Flex direction="row" alignItems="flex-start">
        <Box>{statusIcon}</Box>
        <Box flexDirection="column" ml={3}>
          {title && <AlertTitle>{title}</AlertTitle>}
          <AlertDescription fontSize="md" whiteSpace="pre-line">
            {renderWithHtml(text)}
          </AlertDescription>
        </Box>
      </Flex>
    </Alert>
  );
};

export default AlertBlock;
