import { Box, Button, Flex, Text } from "@chakra-ui/react";

import { NavigationChoiceBlock } from "../types";

interface Props {
  blockTitle: string;
  continueFilingButton: NavigationChoiceBlock;
  onSubmit: (screenId: string) => string;
}

const ReturnToFilingBlock: React.FC<Props> = ({
  blockTitle,
  continueFilingButton,
  onSubmit,
}) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box
        rounded="card"
        boxShadow="card"
        backgroundColor="white"
        margin-top={{ base: 8, md: 8 }}
        margin-bottom={{ base: 8, md: 6 }}
      >
        <Flex w="full" justifyContent="center" alignItems="center">
          <Flex
            grow={1}
            flexDirection={{ base: "column", md: "row" }}
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems="center"
            pb={8}
            pt={8}
            px={{ base: 0, md: 8 }}
          >
            <Text pb={{ base: 4, md: 0 }} pr={{ base: 5, md: 0 }} align="left">
              {blockTitle}
            </Text>
            <Button
              w={{ base: "full", md: "auto" }}
              px={8}
              form={continueFilingButton.id}
              variant="primary"
              onClick={() => onSubmit(continueFilingButton.screenId)}
              isLoading={false}
              loadingText={continueFilingButton.label}
              maxWidth={{ md: "300px", base: "80%" }}
            >
              Continue Filing
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default ReturnToFilingBlock;
