import React from "react";

import { Box, Text } from "@chakra-ui/react";

interface Props {
  label?: string;
}

const GroupSeparatorBlock: React.FC<Props> = ({ label }) => {
  return (
    <Box mb={-6}>
      {label && (
        <Text
          fontSize="lg"
          fontWeight="bold"
          textAlign="left"
          color="text.primary"
        >
          {label}
        </Text>
      )}
    </Box>
  );
};

export default GroupSeparatorBlock;
