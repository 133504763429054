import { Box, Heading, Text } from "@chakra-ui/react";

interface Props {
  blockTitle: string;
  pillInfo?: string;
}

const OffSeasonInfoBlockHeader = ({ blockTitle, pillInfo }: Props) => {
  return (
    <Box
      display="flex"
      justifyContent={{ base: "space-between", md: "flex-start" }}
      gap={{ base: "none", md: "2" }}
      width="full"
    >
      <Box display="flex">
        <Heading
          data-cypress="blockTitle"
          color="text.primary"
          size={{ base: "h4", md: "lg" }}
        >
          {blockTitle}
        </Heading>
      </Box>
      {pillInfo && (
        <Box
          display="flex"
          backgroundColor="background.dark"
          rounded="card"
          pl={2}
          pr={2}
          alignItems="center"
        >
          <Text size="caption" fontWeight="semibold">
            {pillInfo}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default OffSeasonInfoBlockHeader;
