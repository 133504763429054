import React from "react";

function User() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3.41663C9.05044 3.41663 3.41669 9.05038 3.41669 16C3.41669 22.9495 9.05044 28.5833 16 28.5833C22.9496 28.5833 28.5834 22.9495 28.5834 16C28.5834 9.05038 22.9496 3.41663 16 3.41663ZM1.91669 16C1.91669 8.22195 8.22201 1.91663 16 1.91663C23.778 1.91663 30.0834 8.22195 30.0834 16C30.0834 23.778 23.778 30.0833 16 30.0833C8.22201 30.0833 1.91669 23.778 1.91669 16ZM14.2222 18.0833C12.6728 18.0833 11.4167 19.3394 11.4167 20.8888C11.4167 21.4565 11.8768 21.9166 12.4445 21.9166H19.5556C20.1232 21.9166 20.5834 21.4565 20.5834 20.8888C20.5834 19.3394 19.3273 18.0833 17.7778 18.0833H14.2222ZM22.0834 20.8888C22.0834 22.2849 20.9516 23.4166 19.5556 23.4166H12.4445C11.0484 23.4166 9.91669 22.2849 9.91669 20.8888C9.91669 18.511 11.8443 16.5833 14.2222 16.5833H17.7778C20.1557 16.5833 22.0834 18.511 22.0834 20.8888ZM14.0834 10.6666C14.0834 9.60808 14.9415 8.74996 16 8.74996C17.0586 8.74996 17.9167 9.60808 17.9167 10.6666C17.9167 11.7252 17.0586 12.5833 16 12.5833C14.9415 12.5833 14.0834 11.7252 14.0834 10.6666ZM16 7.24996C14.113 7.24996 12.5834 8.77965 12.5834 10.6666C12.5834 12.5536 14.113 14.0833 16 14.0833C17.887 14.0833 19.4167 12.5536 19.4167 10.6666C19.4167 8.77965 17.887 7.24996 16 7.24996Z"
        fill="#06031F"
      />
    </svg>
  );
}

export default User;
