import React from "react";

import styles from "./AnimatedCTLogo.module.css";

function AnimatedCTLogo() {
  return (
    <svg
      width="46"
      height="42"
      viewBox="0 0 46 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14_362)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.157 1.75L46.157 36.75C46.157 39.6495 43.9429 42 41.2116 42H34.6177C33.7073 42 32.9693 41.2165 32.9693 40.25L32.9693 5.25001C32.9693 2.35051 35.1834 0 37.9147 0L44.5085 0C45.4189 0 46.157 0.783504 46.157 1.75Z"
          fill="#3B20F1"
          className={styles.bar1}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1877 1.75L13.1877 36.75C13.1877 39.6495 10.9736 42 8.24233 42H1.64846C0.738041 42 9.53674e-07 41.2165 9.53674e-07 40.25L9.53674e-07 5.25C9.53674e-07 2.35051 2.21413 0 4.94539 0L11.5393 0C12.4497 0 13.1877 0.783502 13.1877 1.75Z"
          fill="#0070FF"
          className={styles.bar2}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.6723 1.75L29.6723 36.75C29.6723 39.6495 27.4582 42 24.7269 42H18.1331C17.2226 42 16.4846 41.2165 16.4846 40.25L16.4846 5.25001C16.4846 2.35051 18.6987 0 21.43 0L28.0239 0C28.9343 0 29.6723 0.783504 29.6723 1.75Z"
          fill="#00CDFF"
          className={styles.bar3}
        />
      </g>
      <defs>
        <clipPath id="clip0_14_362">
          <rect width="46" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AnimatedCTLogo;
