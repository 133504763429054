import React from "react";

import { Box, Flex, Text, useTheme } from "@chakra-ui/react";

import { PrefillNoticeItemBlockType, Source } from "../types";

import { renderWithHtml } from "../helpers/TextFormatters";
import SparkleFillIcon from "../icons/SparkleFillIcon";
import IconChip from "./Chip";

// TODO: we can consolidate some code from this component, the W2OverviewBlock, and the ScheduleCOverviewBlock
function PrefillNoticeItemBlock({
  title,
  descriptionList,
  importedSourceLabel,
  source,
}: PrefillNoticeItemBlockType) {
  const theme = useTheme();
  const brandDarkColor = theme.colors.brand.dark;
  const brandLightColor = theme.colors.brand.light;
  const prefillDarkColor = theme.colors.prefill.dark;
  const prefillLightColor = theme.colors.prefill.light;

  const getColorScheme = (source: Source) => {
    switch (source) {
      case Source.PARTNER_BANK:
        return { darkColor: brandDarkColor, lightColor: brandLightColor };
      case Source.PRIOR_YEAR:
        return { darkColor: prefillDarkColor, lightColor: prefillLightColor };
      default:
        return { darkColor: prefillDarkColor, lightColor: prefillLightColor };
    }
  };

  const { darkColor, lightColor } = getColorScheme(source);

  return (
    <Box
      rounded="card"
      boxShadow="card"
      backgroundColor="white"
      padding={{ base: 5, sm: 6, md: 8 }}
      gap={{ base: 3, sm: 4 }}
      display="flex"
      flexDirection="column"
    >
      {/* 'row-reverse' is utilized for larger screens to ensure proper horizontal content placement. 
      When designing or debugging, consider the placement logic from a 'reversed' perspective. */}
      <Flex
        flexDirection={{ base: "column", sm: "row-reverse" }}
        justifyContent={{ base: "flex-start", sm: "space-between" }}
        alignItems={{ base: "flex-start", sm: "center" }}
        gap={{ base: 2, sm: 4 }}
      >
        <IconChip
          icon={<SparkleFillIcon color={darkColor} />}
          label={importedSourceLabel}
          backgroundColor={lightColor}
          contentColor={darkColor}
        />
        <Box flex="1" pr={6}>
          <Text fontSize="xl" fontWeight="bold">
            {title}
          </Text>
        </Box>
      </Flex>
      {/* renders text as html so we can use formatting */}
      {descriptionList.map((descriptionItem, index) => (
        <Flex key={index}>
          <Text>{renderWithHtml(descriptionItem)}</Text>
        </Flex>
      ))}
    </Box>
  );
}

export default PrefillNoticeItemBlock;
