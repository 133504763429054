import React from "react";

function PersonCircle() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6364 9.27284C15.6364 10.2373 15.2533 11.1622 14.5713 11.8441C13.8894 12.5261 12.9644 12.9092 12 12.9092C11.0356 12.9092 10.1107 12.5261 9.42871 11.8441C8.74676 11.1622 8.36365 10.2373 8.36365 9.27284C8.36365 8.30841 8.74676 7.38349 9.42871 6.70154C10.1107 6.01959 11.0356 5.63647 12 5.63647C12.9644 5.63647 13.8894 6.01959 14.5713 6.70154C15.2533 7.38349 15.6364 8.30841 15.6364 9.27284ZM13.8182 9.27284C13.8182 9.75505 13.6266 10.2175 13.2857 10.5585C12.9447 10.8995 12.4822 11.091 12 11.091C11.5178 11.091 11.0553 10.8995 10.7144 10.5585C10.3734 10.2175 10.1818 9.75505 10.1818 9.27284C10.1818 8.79063 10.3734 8.32816 10.7144 7.98719C11.0553 7.64621 11.5178 7.45466 12 7.45466C12.4822 7.45466 12.9447 7.64621 13.2857 7.98719C13.6266 8.32816 13.8182 8.79063 13.8182 9.27284Z"
        fill="#293056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47727 2 2 6.47727 2 12C2 17.5227 6.47727 22 12 22C17.5227 22 22 17.5227 22 12C22 6.47727 17.5227 2 12 2ZM3.81818 12C3.81818 13.9 4.46636 15.6491 5.55273 17.0382C6.31566 16.0363 7.29991 15.2243 8.42858 14.6657C9.55724 14.1072 10.7998 13.8171 12.0591 13.8182C13.3021 13.817 14.529 14.0996 15.6463 14.6443C16.7636 15.1891 17.7418 15.9817 18.5064 16.9618C19.294 15.9287 19.8244 14.7229 20.0535 13.4442C20.2827 12.1654 20.204 10.8505 19.8241 9.60817C19.4441 8.36585 18.7738 7.23186 17.8686 6.30003C16.9634 5.36821 15.8493 4.66533 14.6185 4.24955C13.3877 3.83378 12.0756 3.71707 10.7908 3.90907C9.50593 4.10107 8.28526 4.59627 7.22979 5.35369C6.17432 6.11111 5.31437 7.10897 4.72112 8.26472C4.12786 9.42047 3.81835 10.7009 3.81818 12ZM12 20.1818C10.1218 20.1846 8.30024 19.5385 6.84364 18.3527C7.42994 17.5134 8.21031 16.8281 9.11836 16.3552C10.0264 15.8822 11.0353 15.6356 12.0591 15.6364C13.0701 15.6356 14.0668 15.876 14.9663 16.3377C15.8658 16.7993 16.6422 17.469 17.2309 18.2909C15.763 19.5152 13.9114 20.1845 12 20.1818Z"
        fill="#293056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.1C16.4735 20.1 20.1 16.4735 20.1 12C20.1 7.52649 16.4735 3.9 12 3.9C7.52649 3.9 3.9 7.52649 3.9 12C3.9 16.4735 7.52649 20.1 12 20.1ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#293056"
      />
    </svg>
  );
}

export default PersonCircle;
