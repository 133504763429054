import React from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";

import { Box, FormControl, Select } from "@chakra-ui/react";

import { DropdownField, ReactHookFormError } from "../types";

import {
  ErrorMessages,
  isInvalid,
  resolveColor,
  resolveVariant,
} from "../helpers/Errors";
import InputFieldLabel from "./InputFieldLabel";
import SelectOption from "./SelectOption";

export interface DropdownFieldProps {
  field: DropdownField;
  register: UseFormRegister<FieldValues>;
  error: ReactHookFormError;
  isLoading: boolean;
}

const SelectFieldDropdown: React.FC<DropdownFieldProps> = (
  props: DropdownFieldProps,
) => {
  const options = props.field.options.map((option, index) => (
    <SelectOption key={index} label={option.label} value={option.value} />
  ));

  return (
    // TODO(sean): Can the placeholder text color be gray and selected
    // field be black?
    <Box>
      <FormControl
        isInvalid={isInvalid(props.error)}
        isDisabled={props.isLoading}
      >
        <InputFieldLabel field={props.field} />

        <Select
          variant={resolveVariant(props.error)}
          // Keep height the same as the radio buttons
          // TODO(marcia): Use some some constant.
          height="12"
          fontSize="lg"
          borderColor="border.medium"
          backgroundColor="white"
          borderRadius="lg"
          fontWeight="normal"
          placeholder={props.field.placeholder}
          sx={{
            "&": { borderColor: resolveColor(props.error) },
            // Possibly the only way to style a <Select /> placeholder in Chakra
            "& option[value='']": { color: "text.secondary" },
            "& :not(option[value=''])": { color: "text.primary" },
          }}
          {...props.register(props.field.id)}
        >
          {options}
        </Select>
        <ErrorMessages error={props.error} />
      </FormControl>
    </Box>
  );
};

export default SelectFieldDropdown;
