import React from "react";

import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { dollarsToUsd } from "../helpers/CurrencyFormatter";

interface Props {
  totalIncome: number;
  adjustments: number;
  deductions: number;
  taxableIncome: number;
  effectiveTaxRate: number;
  totalTax: number;
  otherTaxes: number;
  credits: number;
  payments: number;
  netRefundAmount: number;
}

const ReturnSummaryBlock: React.FC<Props> = ({
  totalIncome,
  adjustments,
  deductions,
  taxableIncome,
  effectiveTaxRate,
  totalTax,
  otherTaxes,
  credits,
  payments,
  netRefundAmount,
}) => {
  const isRefund = netRefundAmount > 0;
  return (
    <Box mt={8} mb={3}>
      <TableContainer>
        <Table variant="summary">
          <Thead>
            <Tr>
              <Th colSpan={2}>Federal return</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Total income</Td>
              <Td>{dollarsToUsd(totalIncome)}</Td>
            </Tr>
            <Tr>
              <Td>Adjustments</Td>
              <Td>({dollarsToUsd(adjustments)})</Td>
            </Tr>
            <Tr>
              <Td>Deductions</Td>
              <Td>({dollarsToUsd(deductions)})</Td>
            </Tr>
          </Tbody>
          <Tbody>
            <Tr>
              <Td>Taxable income</Td>
              <Td>{dollarsToUsd(taxableIncome)}</Td>
            </Tr>
            <Tr>
              <Td>Effective tax rate</Td>
              <Td>{effectiveTaxRate}%</Td>
            </Tr>
          </Tbody>
          <Tbody>
            <Tr>
              <Td>Total federal tax</Td>
              <Td>{dollarsToUsd(totalTax)}</Td>
            </Tr>
            <Tr>
              <Td>Other taxes</Td>
              <Td>+&nbsp;{dollarsToUsd(otherTaxes)}</Td>
            </Tr>
            <Tr>
              <Td>Credits</Td>
              <Td>{dollarsToUsd(credits)}</Td>
            </Tr>
            <Tr>
              <Td>Payments</Td>
              <Td>{dollarsToUsd(payments)}</Td>
            </Tr>
          </Tbody>
          <Tbody>
            <Tr>
              <Td>{isRefund ? "Refund amount" : "Tax due"}</Td>
              <Td>{dollarsToUsd(Math.abs(netRefundAmount))}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ReturnSummaryBlock;
