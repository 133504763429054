import React from "react";

interface Props {
  label: string;
  value: string;
}

const SelectOption: React.FC<Props> = ({ label, value }) => {
  return <option value={value}>{label}</option>;
};

export default SelectOption;
