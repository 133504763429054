import React from "react";

function Review() {
  return (
    <svg
      width="152"
      height="151"
      viewBox="0 0 152 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="78.5" cy="73.5" r="73.5" fill="#EDF5FF" />
      <rect
        x="2.5"
        y="26.5"
        width="114"
        height="96"
        rx="3.5"
        fill="white"
        stroke="#0070FF"
        strokeWidth="5"
      />
      <rect
        x="46.1768"
        y="81.728"
        width="5"
        height="18"
        rx="2.5"
        transform="rotate(-45 46.1768 81.728)"
        fill="#0070FF"
      />
      <rect
        x="77.2894"
        y="69"
        width="5"
        height="31"
        rx="2.5"
        transform="rotate(45 77.2894 69)"
        fill="#0070FF"
      />
      <path
        d="M79.3236 81.827C79.8513 86.3847 78.8068 90.9859 76.3629 94.869C73.9191 98.7521 70.2225 101.684 65.8851 103.18C61.5477 104.676 56.8295 104.646 52.5116 103.095C48.1936 101.543 44.5348 98.5642 42.1409 94.6502C39.7469 90.7362 38.7613 86.122 39.3472 81.5714C39.9331 77.0209 42.0554 72.8068 45.3627 69.6268C48.6701 66.4469 52.9642 64.4916 57.5342 64.0848C62.1043 63.678 66.6762 64.844 70.4933 67.3897"
        stroke="#B3D4FF"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <rect x="5" y="42" width="109" height="5" fill="#0070FF" />
      <circle cx="11.5" cy="35.5" r="2.5" fill="#B3D4FF" />
      <circle cx="18.5" cy="35.5" r="2.5" fill="#B3D4FF" />
      <circle cx="25.5" cy="35.5" r="2.5" fill="#B3D4FF" />
      <circle
        cx="114"
        cy="120"
        r="28.5"
        fill="white"
        stroke="white"
        strokeWidth="5"
      />
      <circle
        cx="114"
        cy="120"
        r="23.5"
        fill="white"
        stroke="#0070FF"
        strokeWidth="5"
      />
      <rect x="111.5" y="114" width="5" height="19" rx="2.5" fill="#0070FF" />
      <rect x="111.5" y="107" width="5" height="5" rx="2.5" fill="#0070FF" />
    </svg>
  );
}

export default Review;
