import React, { useState } from "react";
import { Mail } from "react-feather";

import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Circle,
  HStack,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

import { emailMagicLink, sendEventButtonClick } from "../api";
import { ButtonClickEventNames } from "../types";

import DesktopWindowWithCursor from "../icons/desktop_window_with_cursor.svg";

type Props = {
  screenId: string;
  userEmail: string;
};

export const DesktopHandoffModalBody: React.FC<Props> = ({
  screenId,
  userEmail,
}) => {
  const [requestedLink, setRequestedLink] = useState(false);

  return (
    <Box pt={14}>
      <Image src={DesktopWindowWithCursor} margin="auto" />
      <Heading size="lg" textAlign="center" mb={5} mt={8}>
        Want to file your taxes
        <br /> from your computer?
      </Heading>

      <div>
        <Text size="md" mb="5">
          We'll send a link to your email address — open it from a desktop
          computer to continue filing your taxes. This link will expire in four
          hours.
        </Text>
        <Text mb="5">
          <b>Your email:</b> {userEmail}
        </Text>
        {requestedLink && (
          <Center mb={8}>
            <HStack spacing={2}>
              <Circle size={6} bg="success.medium" color="white">
                <CheckIcon boxSize={3} />
              </Circle>
              <Text color="success.dark">Link sent!</Text>
            </HStack>
          </Center>
        )}
        {!requestedLink && (
          <Button
            onClick={() => {
              setRequestedLink(true);
              emailMagicLink();
              sendEventButtonClick({
                buttonName:
                  ButtonClickEventNames.SEND_MOBILE_DESKTOP_HANDOFF_MAGIC_LINK,
                screenId: screenId,
              });
            }}
            width="full"
            variant="outline"
            mb="6"
            leftIcon={<Mail />}
          >
            Get a Link
          </Button>
        )}
      </div>
    </Box>
  );
};

export default DesktopHandoffModalBody;
