import React from "react";

import { Box, Divider, HStack, Text, VStack } from "@chakra-ui/react";

import { dollarsToUsd } from "../helpers/CurrencyFormatter";

function SummaryAmountBlock({
  text,
  amount,
}: {
  text: string;
  amount: number;
}) {
  const formattedAmount = amount ? dollarsToUsd(amount) : dollarsToUsd(0);
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <HStack spacing={3}>
          <VStack alignItems="left" spacing={0}>
            <Text alignItems="left" width="100%" fontSize="xl">
              {text}
            </Text>
          </VStack>
        </HStack>

        <HStack>
          <Text alignItems="left" width="100%" fontSize="xl">
            {formattedAmount}
          </Text>
        </HStack>
      </Box>
      <Divider color="border.medium" />
    </>
  );
}

export default SummaryAmountBlock;
