import React from "react";

function TrashCan() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 5H4.16667H17.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8334 4.99984V16.6665C15.8334 17.1085 15.6578 17.5325 15.3453 17.845C15.0327 18.1576 14.6088 18.3332 14.1667 18.3332H5.83341C5.39139 18.3332 4.96746 18.1576 4.6549 17.845C4.34234 17.5325 4.16675 17.1085 4.16675 16.6665V4.99984M6.66675 4.99984V3.33317C6.66675 2.89114 6.84234 2.46722 7.1549 2.15466C7.46746 1.8421 7.89139 1.6665 8.33341 1.6665H11.6667C12.1088 1.6665 12.5327 1.8421 12.8453 2.15466C13.1578 2.46722 13.3334 2.89114 13.3334 3.33317V4.99984"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33325 9.1665V14.1665"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 9.1665V14.1665"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TrashCan;
