import React from "react";

import { Button, HStack } from "@chakra-ui/react";

interface Props {
  onClick: () => void;
  label: string;
}

const HelpButton = ({ onClick, label }: Props) => {
  return (
    <HStack my="2">
      <Button width="full" onClick={onClick} variant="outline">
        {label}
      </Button>
    </HStack>
  );
};

export default HelpButton;
