import React from "react";

function Monitor() {
  // from https://feathericons.com/?query=monitor
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6667 4.00977H4.33332C3.13671 4.00977 2.16666 4.97982 2.16666 6.17643V17.0098C2.16666 18.2064 3.13671 19.1764 4.33332 19.1764H21.6667C22.8633 19.1764 23.8333 18.2064 23.8333 17.0098V6.17643C23.8333 4.97982 22.8633 4.00977 21.6667 4.00977Z"
        stroke="#293056"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66666 23.5098H17.3333"
        stroke="#293056"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 19.1763V23.5096"
        stroke="#293056"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Monitor;
