import React from "react";

function NavSubmenuArrowClosed() {
  return (
    <svg
      width="4"
      height="6"
      viewBox="0 0 4 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.08557 3.22299L0.500689 5.54938C0.307633 5.72313 0 5.58612 0 5.32639L0 0.673609C0 0.413878 0.307633 0.27687 0.500689 0.450621L3.08557 2.77701C3.21798 2.89618 3.21798 3.10382 3.08557 3.22299Z"
        fill="#293056"
      />
    </svg>
  );
}

export default NavSubmenuArrowClosed;
