import {
  InitialScreenRequest,
  URLParamError,
  UnvalidatedUserParams,
  UserTokenCheckAndPayload,
  ValidUserParams,
} from "../types";
import { getApiUrlByProduct, getCookieValue } from "./utils";

import { ProductIdentifier } from "../shared/types";

import { Screen } from "../diy/types";
import { BASE_HEADERS } from "./headers";

let userParams: ValidUserParams | undefined;

export function getUserTokenIfExists(): string | null {
  // Given our current architecture, we will only ask for the
  // token if we've already confirmed that it's a valid user token
  if (userParams) {
    return (userParams as ValidUserParams).token;
  } else {
    return null;
  }
}

// TODO (sumit) remove when no longer needed see https://github.com/column-tax/column-be/pull/17934
// for more context
export function parseJwtForHostBankIdentifier(token: string | null) {
  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
    if (base64) {
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );

      return JSON.parse(jsonPayload)["host_bank_user_identifier"];
    }
  }
}

function getTargetParams(): string {
  return new URLSearchParams(window.location.search).get("target") || "";
}

export function parseUserParams(params: string): UnvalidatedUserParams {
  if (!params) {
    throw new Error(URLParamError.MISSING_PARAMS);
  }

  let decodedParams: string;
  try {
    decodedParams = atob(params);
  } catch (e) {
    throw new Error(URLParamError.FAILED_TO_BASE64_DECODE);
  }

  if (!decodedParams) {
    throw new Error(URLParamError.FAILED_TO_BASE64_DECODE);
  }

  let token: string;
  try {
    const parsedParams = JSON.parse(decodedParams);

    token = parsedParams.token;
    if (!token) {
      throw new Error(URLParamError.MISSING_TOKEN);
    }

    return {
      token,
      screen: parsedParams.screen,
    };
  } catch (error) {
    if (error instanceof SyntaxError) {
      // This catches any error from JSON.parse
      throw new Error(URLParamError.INVALID_JSON);
    } else {
      // This will re-throw the URLParamError.MISSING_TOKEN error above
      throw error;
    }
  }
}

async function isTokenValidAndGetInitialPayload(
  productIdentifier: ProductIdentifier,
  token?: string | null,
) {
  const url = new URL(getApiUrlByProduct(productIdentifier));

  const targetParams = getTargetParams();
  if (targetParams) {
    url.searchParams.set("target", targetParams);
  }

  // Define headers - only include Authorization if a token is provided
  const headers: Record<string, string> = {
    ...BASE_HEADERS,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const authResponsePromise = fetch(url.toString(), {
    headers: headers,
    // include cookies (if they're there)
    credentials: "include",
  });

  const response = await authResponsePromise;

  if (response.ok) {
    if (token) {
      saveUserParams({ isValid: true, token });
    }

    return {
      isValid: response.ok,
      payload: await response.json(),
    };
  } else {
    throw new Error(URLParamError.INVALID_USER_TOKEN);
  }
}

async function checkUrlParams(
  product: ProductIdentifier,
  paramString: string,
): Promise<UserTokenCheckAndPayload> {
  try {
    // Parse user params from url parameter, and throw if malformed
    const parsedParams = parseUserParams(paramString);

    // Check with BE whether user token is valid
    return await isTokenValidAndGetInitialPayload(product, parsedParams.token);
  } catch (error) {
    return {
      isValid: false,
      errorMessage: (error as Error).message,
    };
  }
}

function saveUserParams(params: ValidUserParams) {
  userParams = params;
}

function getParamString() {
  return new URLSearchParams(window.location.search).get("params") || "";
}

function decode(params: string): InitialScreenRequest | null {
  try {
    return JSON.parse(atob(params));
  } catch (e) {
    return null;
  }
}

function encode(obj: Record<string, unknown>): string {
  return btoa(JSON.stringify(obj));
}

export function clearScreenFromUrlParams() {
  // At this point, the user was on a final Submission Pending Screen
  // and clicked the browser back button. The URL now points to something
  // like the Submit Return screen, and we replaceState it with a tax
  // filing URL that encodes no screen information.
  const url = new URL(window.location.href);
  url.searchParams.delete("target");
  window.history.replaceState({}, "", url.toString());
}

export function saveScreenToUrlParams(newScreen: Screen) {
  // First get the original screen from our params, which
  // represents our previous screen
  const previousParsedTargetParams: InitialScreenRequest | null =
    decode(getTargetParams());

  const previousScreenId = previousParsedTargetParams?.id;

  // If the previous screen matches the new screen (as when the taxpayer is
  // stuck on a screen with a blocking error), return early. We do not need to
  // update the URL.
  if (previousScreenId === newScreen.id) {
    return;
  }

  // Construct our new URL that represents the new screen
  const newTargetParams: InitialScreenRequest = {
    id: newScreen.id,
    payload: {},
  };

  if (newScreen.resource) {
    newTargetParams.resource = newScreen.resource;
  }

  // If we've transitioned from a previous screen to a new screen
  const url = new URL(window.location.href);
  url.searchParams.set("target", encode(newTargetParams));

  if (previousScreenId) {
    window.history.pushState({}, "", url.toString());
  } else {
    // There are additional edge cases where we will not want to let the user
    // navigate backward. 7216, credit customization screen, income
    // documents screen. For those, we'll probably want to replaceState,
    // but we will need to figure out how best to indicate that.
    window.history.replaceState({}, "", url.toString());
  }
}

/**
 * Checks if the application is using cookie authentication by checking for the 'is using cookie auth' cookie
 */
function isUsingCookieAuth(): boolean {
  const IS_USING_COOKIE_AUTH_COOKIE_NAME = "columntax_is_using_cookie_auth";
  try {
    return !!getCookieValue(IS_USING_COOKIE_AUTH_COOKIE_NAME);
  } catch (error) {
    return false;
  }
}

/**
 * This function will retrieve the user_id from the cookie and use it
 * to validate and get the user data from the backend. Note: This assumes
 * that your backend can handle the user_id as a token, and that the logic
 * to do so is correctly implemented in isTokenValidAndGetInitialPayload.
 *
 * @param {ProductIdentifier} product - The product identifier
 * @returns {Promise<UserTokenCheckAndPayload>} The payload and validity of the user token
 */
async function getInitialPayloadViaCookieAuth(
  product: ProductIdentifier,
): Promise<UserTokenCheckAndPayload> {
  try {
    // Get payload and validate token
    // Cookies are included by default in the fetch, so we don't have to do anything special
    return await isTokenValidAndGetInitialPayload(
      product,
      null, // token is null for cookies
    );
  } catch (error) {
    return {
      isValid: false,
      errorMessage: (error as Error).message,
    };
  }
}

// When using URL parameters, we don't actually look at the URL on every request
// Instead, we have an in-memory variable: `userParams` that we send along with
// our requests to authenticate with the backend. On certains screens, such as the
// AuthScreen, we may need to update the params we're using to authenticate (for
// example, we may be issued new params upon completing MFA). This function allows
// us to update the in-memory variable from the URL.
export function updateUserParamsFromUrl() {
  const paramString = getParamString();
  const parsedParams = parseUserParams(paramString);
  const { token } = parsedParams;
  saveUserParams({
    isValid: true,
    token,
  });
}

async function getInitialPayload(
  product: ProductIdentifier,
): Promise<UserTokenCheckAndPayload> {
  const paramString = getParamString();

  // first, prioritize url params
  // this is because for internal users, we often use params to log in, and the cookies might not be cleared
  // cookies will get cleared after the HTTP request with params
  if (paramString) {
    return checkUrlParams(product, paramString);
  }
  // then, use cookies (if present)
  else if (isUsingCookieAuth()) {
    return getInitialPayloadViaCookieAuth(product);
  }
  // lastly, return error
  else {
    return {
      isValid: false,
      errorMessage: URLParamError.MISSING_PARAMS,
    };
  }
}

export async function getInitialPayloadForTRU(): Promise<UserTokenCheckAndPayload> {
  return getInitialPayload(ProductIdentifier.TRU);
}

export async function getInitialPayloadForDIY(): Promise<UserTokenCheckAndPayload> {
  return getInitialPayload(ProductIdentifier.DIY);
}

export async function loadScreenFromUrl(): Promise<UserTokenCheckAndPayload> {
  return getInitialPayloadForDIY();
}
