import React, { useState } from "react";
import { Mail } from "react-feather";

import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Circle,
  HStack,
  Heading,
  Text,
} from "@chakra-ui/react";

import { emailMagicLink, sendEventButtonClick } from "../api";
import { ButtonClickEventNames } from "../types";

type Props = {
  screenId: string;
  userEmail: string;
  title: string;
};

export const DesktopHandoffForPdfModalBody: React.FC<Props> = ({
  screenId,
  userEmail,
  title,
}) => {
  const [requestedLink, setRequestedLink] = useState(false);

  return (
    <Box pt={4}>
      {/* TODO(marcia): Use variant modalBodyHeader for padding */}
      <Heading size="lg" pr={8}>
        {title}
      </Heading>

      <div>
        {/* TODO(marcia): Use variant modalBodyText */}
        <Text size="md" mb="5" mt="5">
          No worries! We’ll email you a link that you can use to file from a
          desktop computer instead. This link will expire 4 hours after it
          arrives.
        </Text>
        <Text mb="5">
          <b>Your email:</b> {userEmail}
        </Text>
        {requestedLink && (
          <Center mb={8}>
            <HStack spacing={2}>
              <Circle size={6} bg="success.medium" color="white">
                <CheckIcon boxSize={3} />
              </Circle>
              <Text color="success.dark">Link Sent!</Text>
            </HStack>
          </Center>
        )}
        {!requestedLink && (
          <Button
            onClick={() => {
              setRequestedLink(true);
              emailMagicLink();
              sendEventButtonClick({
                buttonName:
                  ButtonClickEventNames.SEND_MOBILE_DESKTOP_HANDOFF_MAGIC_LINK,
                screenId: screenId,
              });
            }}
            width="full"
            variant="outline"
            mb="6"
            leftIcon={<Mail />}
          >
            Get a Link
          </Button>
        )}
      </div>
    </Box>
  );
};

export default DesktopHandoffForPdfModalBody;
