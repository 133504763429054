import React, { useEffect, useState } from "react";

import { getEstimator } from "../../api";
import {
  BlockType,
  EstimatorBlock as EstimatorBlockType,
  EstimatorStatus,
} from "../../types";

import EstimatorBlock from "../EstimatorBlock";

interface Props {
  timeoutMilliseconds: number;
}

function EstimatorWaitBlock({ timeoutMilliseconds }: Props) {
  const [resolvedEstimator, setResolvedEstimator] =
    useState<EstimatorBlockType | null>(null);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const timeoutTimeout = setTimeout(() => {
      if (!signal.aborted) {
        setResolvedEstimator({
          id: "placeholder-estimator",
          type: BlockType.ESTIMATOR,
          status: EstimatorStatus.CALCULATING,
          amount: 0,
        });
        abortController.abort();
      }
    }, timeoutMilliseconds);

    async function pollForEstimator() {
      try {
        const response = await getEstimator();

        if (signal.aborted) return;

        if (response?.type === BlockType.ESTIMATOR) {
          setResolvedEstimator(response);
          abortController.abort();
        } else {
          setTimeout(() => {
            if (!signal.aborted) {
              pollForEstimator();
            }
          }, 3 * 1000);
        }
      } catch (err) {
        if (!signal.aborted) console.error(err);
      }
    }

    // In strict mode, this will result in two requests being made the first time the component is mounted, but
    // because we cancel the abort controller in the cleanup function, the duplicate request won't have any
    // effect and we won't do any subsequent duplicative polling.
    pollForEstimator();

    return () => {
      clearTimeout(timeoutTimeout);
      abortController.abort();
    };
  }, []);

  if (resolvedEstimator) {
    return (
      <EstimatorBlock
        fedAmount={resolvedEstimator.amount}
        fedStatus={resolvedEstimator.status}
        stateAmount={resolvedEstimator.stateAmount}
      />
    );
  }

  return <EstimatorBlock fedAmount={0} fedStatus={EstimatorStatus.LOADING} />;
}

export default EstimatorWaitBlock;
