import React from "react";

import { Box, Flex, Text, useTheme } from "@chakra-ui/react";

import { W2OverviewBlock as W2BlockType } from "../types";

import { centsToUsdNoCents } from "../helpers/CurrencyFormatter";
import SparkleFillIcon from "../icons/SparkleFillIcon";
import IconChip from "./Chip";

function W2OverviewBlock({ w2s, itemHeader }: W2BlockType) {
  const theme = useTheme();
  const brandDarkColor = theme.colors.brand.dark;

  return (
    <>
      {w2s.map((w2) => (
        <Box
          rounded="card"
          boxShadow="card"
          backgroundColor="white"
          key={w2.id}
          padding={{ base: 5, sm: 6, md: 8 }}
          gap={{ base: 3, sm: 4 }}
          display="flex"
          flexDirection="column"
        >
          {/* 'row-reverse' is utilized for larger screens to ensure proper horizontal content placement. 
      When designing or debugging, consider the placement logic from a 'reversed' perspective. */}
          <Flex
            flexDirection={{ base: "column", sm: "row-reverse" }}
            justifyContent={{ base: "flex-start", sm: "space-between" }}
            alignItems={{ base: "flex-start", sm: "center" }}
            gap={{ base: 2, sm: 4 }}
          >
            <IconChip
              icon={<SparkleFillIcon color={brandDarkColor} />}
              label={w2.importedSourceLabel}
              backgroundColor="brand.light"
              contentColor={brandDarkColor}
            />
            <Box>
              <Text fontSize="xl" fontWeight="bold">
                {itemHeader}
              </Text>
            </Box>
          </Flex>
          <Flex>
            <Text>Employer:&nbsp;</Text>
            <Text fontWeight="semibold">{w2.employerName}</Text>
          </Flex>
          {w2.totalWages && (
            <Flex>
              <Text>Total Wages:&nbsp;</Text>
              <Text fontWeight="semibold">
                {centsToUsdNoCents(w2.totalWages)}
              </Text>
            </Flex>
          )}
          {w2.totalIncomeTax && (
            <Flex>
              <Text>Total income tax withheld:&nbsp;</Text>
              <Text fontWeight="semibold">
                {centsToUsdNoCents(w2.totalIncomeTax)}
              </Text>
            </Flex>
          )}
        </Box>
      ))}
    </>
  );
}

export default W2OverviewBlock;
