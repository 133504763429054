import { FieldValues, UseFormSetError, UseFormSetValue } from "react-hook-form";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

import { AlertTypes, Block, BlockType, FieldType } from "../types";

import { categorizeActivity } from "../helpers/BusinessCategories";

// TODO(marcia): Where does this extension go?
dayjs.extend(utc);
dayjs.extend(customParseFormat);

export function initializeBlockAnswers(
  block: Block,
  setValue: UseFormSetValue<FieldValues>,
  setError: UseFormSetError<FieldValues>,
) {
  if (block.type === BlockType.PROGRESSIVE_DISCLOSURE_BLOCK) {
    block.progressiveBlocks.forEach((progBlock) => {
      initializeBlockAnswers(progBlock, setValue, setError);
    });
  }

  if (block.type !== BlockType.FIELD) {
    return;
  }

  if (block.fieldType === FieldType.DATE) {
    let date;
    if (block.useCurrentDate) {
      // When getting the current date, we want to make sure to factor in the user's timezone
      date = dayjs();
    } else {
      date = dayjs(block.answer as string);
    }

    // Set the value for a date to be MMDDYYYY and then
    // rely on the formatInput to add the forward slashes
    // to turn it to MM/DD/YYYY
    setValue(block.id, date.format("MMDDYYYY"));
  } else if (block.fieldType === FieldType.CHECKBOX) {
    if (block.inverted) {
      setValue(block.id, block.answer === false);
    } else {
      setValue(block.id, block.answer);
    }
  } else if (block.fieldType === FieldType.BUSINESS) {
    if (typeof block.answer == "string") {
      const activityCategories = categorizeActivity(block);
      if (activityCategories != undefined) {
        setValue(block.id + "_category", activityCategories.category);
        setValue(block.id + "_subcategory", activityCategories.subcategory);
      }
    }

    setValue(block.id, block.answer);
  } else {
    setValue(block.id, block.answer);
  }

  if (!block.errors) {
    return;
  }

  let errors: string[];
  let warnings: string[];

  if (block.errors.length > 0 && typeof block.errors[0] == "string") {
    // for backwards compatibility when errors were just strings
    errors = block.errors;
    warnings = [];
  } else {
    errors = [];
    warnings = [];
    (
      block.errors as unknown as { message: string; dismissible: boolean }[]
    ).forEach((e: { dismissible: boolean; message: string }) => {
      if (e.dismissible) {
        warnings.push(e.message);
      } else {
        errors.push(e.message);
      }
    });
  }

  let type = "";
  let finalErrors: string[] = [];

  if (errors.length > 0) {
    type = AlertTypes.ERROR;
    finalErrors = errors;
  } else if (warnings.length > 0) {
    // only show dismissible warnings if there are no errors
    type = AlertTypes.WARNING;
    finalErrors = warnings;
  }

  setError(block.id, { type: type, types: { custom: finalErrors } });
}

export const scrollToTop = (ref: React.RefObject<HTMLDivElement>) => {
  window && window.scrollTo(0, 0);
  ref?.current?.scrollIntoView();
};

export const resetScreen = (
  timer: React.MutableRefObject<NodeJS.Timeout | null>,
  setIsDisabled: (value: boolean) => void,
  setLoadingButton: React.Dispatch<React.SetStateAction<string>>,
  ref: React.RefObject<HTMLDivElement>,
) => {
  clearLoaders(timer, setIsDisabled, setLoadingButton);
  scrollToTop(ref);
};

const clearLoaders = (
  timer: React.MutableRefObject<NodeJS.Timeout | null>,
  setIsDisabled: (value: boolean) => void,
  setLoadingButton: React.Dispatch<React.SetStateAction<string>>,
) => {
  timer.current && clearTimeout(timer.current);
  setIsDisabled(false);
  setLoadingButton("");
};

export const indefiniteArticle = (name: string) => {
  if (name?.slice(0, 3) === "SSA") return "an";
  if (["A", "E", "I", "O", "U"].includes(name?.slice(0, 1))) return "an";
  return "a";
};

export function setBlockAnswer(
  block: Block,
  formValues: FieldValues,
  dirtyFields: Partial<
    Readonly<{
      [x: string]: any;
    }>
  >,
) {
  if (block.type === BlockType.PROGRESSIVE_DISCLOSURE_BLOCK) {
    block.progressiveBlocks.forEach((progBlock) => {
      // Clear out any of the progressive disclosure blocks before sending the the backend
      // if the values don't match.
      // TODO(sean/eve): How should we make this comparison work across string, boolean, etc.
      if (block.fieldValue !== (formValues[block.fieldName] || "").toString()) {
        formValues[progBlock.id] = null;
      }
      return setBlockAnswer(progBlock, formValues, dirtyFields);
    });
  }
  if (block.type !== BlockType.FIELD) {
    return;
  }

  let answer;

  switch (block.fieldType) {
    case FieldType.CHECKBOX:
      if (block.inverted) {
        answer = formValues[block.id] !== true;
      } else {
        answer = formValues[block.id] === true;
      }
      break;

    case FieldType.CURRENCY:
      answer = parseFloat(formValues[block.id]);
      break;

    case FieldType.DATE:
      /* At this point, the date value should be in the form MMDDYYYY.
       *
       * Unfortunately, dayjs will do it's best to guess the date in
       * all scenarios, so we add guards to ensure we correct capture
       * the user input. We specifically handle the following edge cases:
       * * Less than 8 characters
       *   * If the user types in 0-5 or 7 characters, the date
       *   is basically nonsensical.
       *   * If 6 characters, dayjs decides it is in the 1900s if 69-99,
       *   while it is in the 2000s if 00-68.
       * * If the user enters an *invalid* date, eg the 13th month or
       *   32nd of any given month.
       *
       * The safest quickest fix at this point is to clear out an
       * ambiguous value. If the date field is a required value,
       * then the user wil be prompted to re-enter.
       */
      answer = "";
      if (formValues[block.id]?.length && formValues[block.id].length == 8) {
        const parsedValue = dayjs.utc(formValues[block.id], "MMDDYYYY");

        // This specifically handles the 13th month / 32nd day scenario, but
        // is a generally a good check.
        if (parsedValue.format("MMDDYYYY") == formValues[block.id]) {
          answer = parsedValue;
        }
      }
      break;

    case FieldType.SELECT:
    case FieldType.DROPDOWN:
    default:
      answer = formValues[block.id];
  }

  if (typeof answer === "string") {
    // If we have a string-type answer, then trim out leading, trailing, and repeating whitespace
    // e.g. will turn ' a  bc' into 'a bc'
    answer = answer.trim().replace(/  +/g, " ");

    if (answer === "") {
      // Furthermore, turn any empty strings to null when sending to the BE
      answer = null;
    }
  }

  block.answer = answer;

  if (block.fieldType === FieldType.SSN && block.readOnly) {
    // If the block was originally readOnly (so, the user has already entered
    // a valid ssn and now the BE is sending back the masked last 4 digits), we
    // check if the user interacted with the field (by clicking the edit/pencil)
    // icon. If the user did interact with it, then the BE will try to validate it
    block.readOnly = !(block.id in dirtyFields);
  }
}
