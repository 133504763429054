import React from "react";

import { Box, Button, useDisclosure } from "@chakra-ui/react";

import CustomerSupportModal from "./CustomerSupportModal";

interface CustomerSupportModalProps {
  screenId: string;
}

const CustomerSupportBlock = ({ screenId }: CustomerSupportModalProps) => {
  // Used for opening the Customer Support Modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box mt="5">
        <Button variant="outline" width="full" onClick={onOpen}>
          Contact Support
        </Button>
      </Box>

      <CustomerSupportModal
        isOpen={isOpen}
        onClose={onClose}
        screenId={screenId}
      />
    </>
  );
};

export default CustomerSupportBlock;
