import React from "react";

import { Box, Flex, ListItem, Text } from "@chakra-ui/react";

import { IconId } from "../types";

import TickIcon from "../../diy/icons/TickIcon";
import theme from "../../theme";
import ExpertIcon from "../icons/ExpertAssist/ExpertIcon";
import LiveIcon from "../icons/ExpertAssist/LiveIcon";
import ReviewIcon from "../icons/ExpertAssist/ReviewIcon";

interface Props {
  label: React.ReactNode | string;
  subLabel?: string;
  index?: number;
  iconType?: IconId;
  flexDir?: "row" | "column";
  variant?: "icon" | "bullet";
}

const listTheme = theme.components.CheckmarkListItem.sizes.md;

const CheckmarkListItem: React.FC<Props> = ({
  label,
  subLabel,
  index,
  iconType = "TICK",
  flexDir = "row",
  variant = "icon",
}) => {
  const Icon = () => {
    switch (iconType) {
      case IconId.EA_LIVE:
        return <LiveIcon />;
      case IconId.EA_REVIEW:
        return <ReviewIcon />;
      case IconId.EA_EXPERT:
        return <ExpertIcon />;
      default:
        return <TickIcon pixelSize={24} mr="0" />;
    }
  };

  if (variant === "bullet") {
    return (
      <ListItem fontSize="md" lineHeight="tall">
        {label}
      </ListItem>
    );
  }

  return (
    <Box mx="auto" w="full" data-cypress={`checkmark-list-item-${index}`}>
      <Flex alignItems="center" gap="16px">
        <Flex justifyContent="center" alignItems="center">
          <Icon />
        </Flex>
        <Box
          flexDirection={flexDir}
          gap={2}
          alignItems={flexDir === "column" ? "flex-start" : "center"}
          flexWrap="wrap"
          display={flexDir === "column" ? "flex" : "block"}
        >
          <Text as="span" fontWeight={listTheme.fontWeight}>
            {label}
          </Text>
          {subLabel && <Text as="span"> {subLabel}</Text>}
        </Box>
      </Flex>
    </Box>
  );
};

export default CheckmarkListItem;
