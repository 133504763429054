import { ProductIdentifier } from "../shared/types";

import { unwrapOrElse } from "./general";

// iphone 12 pro height
export const MAX_HEIGHT = "926px";

// Height of the top navigation bar containing back button, help button, exit/menu.
// value in px
export const NAV_HEADER_HEIGHT = 72;

// Minimum card height in pixels - recommended by Katherine
export const MIN_CARD_HEIGHT = "650px";
export const MIN_CARD_INNER_HEIGHT = "554px";

export const DEFAULT_TAX_YEAR_CONFIG = "TY24";

export const DEFAULT_API_URL = unwrapOrElse(
  import.meta.env.REACT_APP_API_URL,
  () => {
    throw new Error(`Must have REACT_APP_API_URL defined in environment`);
  },
);

export const BOOLEAN_QUESTION_OPTIONS = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const API_PATHS_BY_PRODUCT: { [key in ProductIdentifier]: string } = {
  [ProductIdentifier.DIY_UPLOAD_BINARY_ATTACHMENT]:
    "/internal/diy/upload_binary_attachment",
  [ProductIdentifier.DIY_DELETE_BINARY_ATTACHMENT]:
    "/internal/diy/delete_binary_attachment",
  [ProductIdentifier.DIY_GET_BINARY_ATTACHMENT]:
    "/internal/diy/view_binary_attachment",
  [ProductIdentifier.DIY_GET_BINARY_ATTACHMENT_STATUS]:
    "/internal/diy/get_binary_attachment_status",
  [ProductIdentifier.DIY_UPLOAD_W2]: "/internal/diy/upload_w2",
  [ProductIdentifier.DIY]: "/internal/diy/screen",
  [ProductIdentifier.NAV]: "/internal/diy/nav",
  [ProductIdentifier.TRU]: "/internal/steps",
  [ProductIdentifier.EVENTS]: "/internal/events",
  [ProductIdentifier.SUPPORT]: "/internal/support_requests",
  [ProductIdentifier.ACTIVE_TIME]: "/internal/diy/active_time",
  [ProductIdentifier.VERIFY_PHONE_CODE]: "/internal/diy/verify_phone_code",
  [ProductIdentifier.SEND_PHONE_VERIFICATION_CODE]:
    "/internal/diy/send_phone_verification_code",
  [ProductIdentifier.ZENDESK]: "/internal/zendesk_jwt",
  [ProductIdentifier.EMAIL_DIY_MAGIC_LINK]:
    "/internal/diy/send_email_with_magic_link",
  [ProductIdentifier.SEND_CALLBACK_DETAILS]:
    "/internal/diy/send_callback_details",
  [ProductIdentifier.REQUEST_EXPERT_REVIEW]:
    "/internal/diy/request_expert_review",
  [ProductIdentifier.OPT_IN_TO_EXPERT_ASSIST]:
    "/internal/diy/opt_in_to_expert_assist",
  [ProductIdentifier.LOGOUT]: "/internal/diy/logout",
  [ProductIdentifier.DEVICE_ID_REPORTER]: "/internal/diy/set_device_id",
  [ProductIdentifier.AUTHENTICATE_VIA_DEVICE_ID]:
    "/internal/diy/auth/authenticate_via_device_id_match",
  [ProductIdentifier.ESTIMATOR]: "/internal/diy/estimator",
};

// We redact certain fields from our cobrowse vendor Fullview.
// See FieldModel.to_field for where we determine whether a field should be
// redacted. If so, field.redact will be true. This class needs to be applied to
// the input element to mask the value. If the class is applied to a higher
// containing element, then fullview will mask the *label*, which is not what we
// want!
// See https://support.fullview.io/en/articles/7320064-redacting-pii-and-sensitive-user-data
export const classNameToRedactInput = "fvMask";
