import { useMemo } from "react";

import { optInToExpertAssist, sendEventButtonClick } from "../../api";
import {
  Block,
  BlockType,
  ButtonClickEventNames,
  ImageId,
  ModalStep,
  TextBlockTypes,
} from "../../types";

interface Props {
  screenId: string;
  expertAssistFee: number;
  taxFilingFee?: number;
}

// This step view maps to our Expert Assist Confirm Screen
export const ExpertAssistConfirmStep = ({
  screenId,
  expertAssistFee,
  taxFilingFee,
}: Props): ModalStep => {
  const rows = useMemo(
    () =>
      !taxFilingFee
        ? [
            {
              label: "Total due at filing",
              value: expertAssistFee,
            },
          ]
        : [
            {
              label: "Tax filing",
              value: taxFilingFee,
            },
            {
              label: "Expert Assist",
              value: expertAssistFee,
            },
            {
              label: "Total due at filing",
              value: taxFilingFee
                ? expertAssistFee + taxFilingFee
                : expertAssistFee,
            },
          ],
    [expertAssistFee, taxFilingFee],
  );

  const blocks: Block[] = [
    {
      id: "image",
      type: BlockType.IMAGE,
      imageId: ImageId.EXPERT_ASSIST_CONFIRM,
    },
    {
      id: "title",
      type: BlockType.NEW_TEXT_BLOCK,
      text: "Confirm your purchase of Expert Assist",
      variant: TextBlockTypes.H4,
      fontWeight: "bold",
      fontAlign: "center",
    },
    {
      id: "table",
      type: BlockType.TABLE,
      header: "Expert Assist",
      rows,
      formatAsDollar: true,
      width: { base: "100%", md: "335px" },
    },
  ];

  return {
    blocks,
    buttonLabel: "Confirm Purchase",
    onStepComplete: async () => {
      sendEventButtonClick({
        buttonName: ButtonClickEventNames.EXPERT_ASSIST_CONFIRM_VIA_OPT_IN,
        screenId: screenId,
      });

      await optInToExpertAssist();
      return true;
    },
  };
};
