import React, { useEffect, useRef, useState } from "react";

import { Box, Button, Flex, Show } from "@chakra-ui/react";

import { redirect } from "../api";
import {
  OpenHelpTypes,
  Resource,
  Screen,
  ScreenId,
  SplashScreenContinueBlock,
} from "../types";

import BlockRenderer from "./BlockRenderer";
import DesktopHandoffModalBody from "./DesktopHandoffModalBody";
import Modal from "./Modal";
import TextCompositionBlock from "./TextCompositionBlock";

interface Props {
  block: SplashScreenContinueBlock;
  screen: Screen;
  onSubmit: (screenId: ScreenId, resource?: Resource) => void;
  navigationDisabled?: boolean;
  userEmail: string;
}

// TODO(Billy): consider consolidating this with the existing "ContinueButtonBlock"
const SplashScreenContinueButtonBlock: React.FC<Props> = ({
  block,
  onSubmit,
  screen,
  navigationDisabled,
  userEmail,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [tick, setTick] = useState(0);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearLoaders = () => {
    timer.current && clearTimeout(timer.current);
    setIsDisabled(false);
    setIsLoading(false);
  };

  const mutationObserver = new MutationObserver(() => {
    mutationObserver.disconnect();
    setTick(tick + 1);
  });

  useEffect(() => {
    mutationObserver.observe(document.body, { subtree: true, childList: true });
  }, []);

  useEffect(() => {
    clearLoaders();
    return;
  }, [onSubmit]);

  const handleClick = () => {
    mutationObserver.disconnect();
    // Open the url in a new tab if one is provided
    if (block.url) {
      redirect(block.url);
    } else {
      setIsDisabled(true);
      timer.current = setTimeout(() => {
        setIsLoading(true);
      }, 250);
      onSubmit(block.screenId);
    }
  };

  const openEventProperties = {
    openKey: "desktop_handoff",
    openType: OpenHelpTypes.BLOCK,
    screenId: screen.id,
  };

  return (
    <Box
      key={block.id}
      // A button should take up half container width minus half of row grid gap
      minW={{ base: "full", md: "calc(50% - 6px)" }}
      height="fit-content"
      display="flex"
      flexGrow={1}
      width="full"
    >
      <Flex
        direction="column"
        justifyContent={"flex-end"}
        flexGrow={1}
        height="fit-content"
      >
        <Box
          display="flex"
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          width="full"
        >
          <Button
            key={block.id}
            width="full"
            size="md"
            fontWeight="bold"
            // the Chakra Button component uses the negative style name "isDisabled"
            // so we have to be careful of the logic here
            isLoading={isLoading}
            loadingText={block.label}
            isDisabled={!block.isEnabled || isDisabled || navigationDisabled}
            onClick={handleClick}
            variant="primary"
            maxWidth={{ md: "300px" }}
          >
            {block.label}
          </Button>
        </Box>
        <Show below="md">
          {block.renderDesktopButton && (
            // Note we also load the same modal on the NavHeader
            // We can consider consolidating these together
            <Modal
              openEventProperties={openEventProperties}
              openText={
                <Button
                  w="full"
                  size="md"
                  px={8}
                  variant="outline"
                  maxWidth={{ md: "300px" }}
                >
                  Continue on Desktop
                </Button>
              }
              body={
                <DesktopHandoffModalBody
                  screenId={screen.id}
                  userEmail={userEmail}
                />
              }
            />
          )}
        </Show>
        {block.filingFeeContent.length > 0 && (
          <Box mt={4}>
            <BlockRenderer
              blocks={block.filingFeeContent}
              onSubmit={onSubmit}
              screen={screen}
            />
          </Box>
        )}
        {block.legalNoteContent.length > 0 && (
          <Box mt={8}>
            <TextCompositionBlock content={block.legalNoteContent} />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default SplashScreenContinueButtonBlock;
