import React from "react";

import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";

import { dollarsToUsd } from "../helpers/CurrencyFormatter";
import DollarSignCircle from "../icons/DollarSignCircle";

const OverallRefund = ({ amount }: { amount: number }) => {
  const isRefund = amount > 0;
  const bg = isRefund ? "success.light" : "background.dark";
  const color = isRefund ? "success.dark" : "text.primary";
  const label = isRefund
    ? "Federal refund"
    : amount == 0
      ? "You and the IRS are square"
      : "Federal tax due";

  return (
    <Box>
      <Flex direction={"column"} align="center" mb={-5}>
        <DollarSignCircle
          boxSize={10}
          stroke="#FFFFFF"
          fill="currentColor"
          color={isRefund ? "success.dark" : "text.primary"}
          strokeWidth={1.2}
        ></DollarSignCircle>
      </Flex>
      <Box bg={bg} pb={6} pt={8} borderRadius="xl">
        <VStack spacing={2.5} align="center">
          <Text
            fontSize="38px"
            lineHeight="38px"
            fontWeight="700"
            color={color}
          >
            {dollarsToUsd(Math.abs(amount))}
          </Text>
          <Text color="text.primary">{label}</Text>
        </VStack>
      </Box>
    </Box>
  );
};

const FedAndStateSummary = ({
  federal,
  state,
  stateCode = "State",
}: {
  federal: number;
  state: number;
  stateCode?: string;
}) => {
  const fedColor = federal > 0 ? "success.dark" : "text.primary";
  const fedLabel = federal > 0 ? "Federal refund" : "Federal tax due";

  const stateColor = state > 0 ? "success.dark" : "text.primary";
  const stateLabel = state > 0 ? `${stateCode} refund` : `${stateCode} tax due`;

  const labelColor = "text.primary";

  return (
    <Box mt={7}>
      <Flex justify="center">
        <HStack>
          <Flex flexDirection="column" align="center">
            <Text color={fedColor} fontSize="xl" fontWeight="bold">
              {dollarsToUsd(Math.abs(federal))}
            </Text>
            <Text color={labelColor} fontSize="md">
              {fedLabel}
            </Text>
          </Flex>
          <Box w={16} />
          <Flex align="center" flexDirection="column">
            <Text color={stateColor} fontSize="xl" fontWeight="bold">
              {dollarsToUsd(Math.abs(state))}
            </Text>
            <Text color={labelColor} fontSize="md">
              {stateLabel}
            </Text>
          </Flex>
        </HStack>
      </Flex>
    </Box>
  );
};

type Props = {
  amount: number;
  federal?: number;
  state?: number;
  stateCode?: string;
  showOverallAmount?: boolean;
};

const SummaryHeaderBlock = ({
  amount,
  federal,
  state,
  stateCode,
  showOverallAmount = true,
}: Props) => {
  return (
    <Box>
      {showOverallAmount && <OverallRefund amount={amount} />}
      {federal !== undefined && state !== undefined && (
        <FedAndStateSummary
          federal={federal}
          state={state}
          stateCode={stateCode}
        />
      )}
    </Box>
  );
};

export default SummaryHeaderBlock;
