import React from "react";

import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from "@chakra-ui/react";

import {
  LinkBlock,
  NewTextBlock,
  TextCompositionBlock,
  UnorderedListBlock,
} from "../types";

import { dollarsToUsd } from "../helpers/CurrencyFormatter";
import { renderWithHtml } from "../helpers/TextFormatters";
import HelpContentBlock from "./HelpContentBlock";
import ResourceIcon from "./ResourceIcon";

function CreditSummaryEligibleBlock({
  screenId,
  creditName,
  content,
  amountLabel,
  amount,
  amounts,
  helpLabel,
  helpContentBlocks,
}: {
  screenId: string;
  creditName: string;
  content: Array<string>;
  amountLabel: string;
  amount?: number;
  amounts?: Array<[string, number]>;
  helpLabel: string;
  helpContentBlocks?: Array<
    NewTextBlock | LinkBlock | TextCompositionBlock | UnorderedListBlock
  >;
}) {
  let amountElement = <></>;
  let helpContentElement = <></>;

  if (amount !== null) {
    amountElement = (
      <Table variant="credit_summary">
        <Td>{amountLabel}</Td>
        <Td fontWeight="bold">{dollarsToUsd(amount || 0)}</Td>
      </Table>
    );
  } else if (Array.isArray(amounts) && amounts.length > 0) {
    const mappedContent = amounts.map((amount) => amount[0]);
    const mappedAmounts = amounts.map((amount) => amount[1]);
    amountElement = (
      <Table variant="credit_summary">
        <Tbody>
          {mappedContent.map((element, index) => {
            return (
              <Tr key={index}>
                <Td>{element}</Td>
                <Td fontWeight="bold">
                  {dollarsToUsd((mappedAmounts && mappedAmounts[index]) || 0)}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    );
  }

  if (helpLabel && helpContentBlocks) {
    helpContentElement = (
      <HelpContentBlock
        screenId={screenId}
        label={helpLabel}
        helpContentBlocks={helpContentBlocks || []}
      />
    );
  }

  return (
    <>
      <Box mb={2}>
        <Flex direction={"column"} align="center" mb={-5}>
          <ResourceIcon iconId="CHECK" />
        </Flex>
        <Box bg="success.light" pb={6} pt={8} borderRadius="xl">
          <VStack spacing={2.5} align="center">
            <Text fontSize="md" lineHeight="38px" fontWeight="700">
              {creditName}
            </Text>
          </VStack>
        </Box>
      </Box>
      {content.map((element, index) => (
        <Text key={index}>{renderWithHtml(element)}</Text>
      ))}
      {amountElement}
      {helpContentElement}
    </>
  );
}

export default CreditSummaryEligibleBlock;
