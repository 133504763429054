import { useState } from "react";
import NumberFormat from "react-number-format";

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Textarea,
  useTheme,
} from "@chakra-ui/react";

import {
  ButtonClickCompletedAction,
  ButtonClickCompletedResult,
  requestCallbackFromExpert,
  sendEventButtonClick,
  sendEventButtonClickCompleted,
} from "../../api";

import {
  ButtonClickEventNames,
  CallbackFormBlock as CallbackFormBlockType,
} from "../../../diy/types";
import GreenFilledCheck from "../../../icons/GreenFilledCheck";
import { formatPhoneNumber } from "../../../utils/formatter";
import Alert from "../../icons/AlertCircleRed";
import StyledInput from "../StyledInput";

const CallbackFormBlock: React.FC<CallbackFormBlockType> = ({
  name,
  phone,
  screenId,
}) => {
  const theme = useTheme();
  const [fullName, setFullName] = useState(name);
  const [fullNameError, setFullNameError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(formatPhoneNumber(phone));
  const [phoneErrorType, setPhoneErrorType] = useState<
    "empty" | "invalid" | null
  >(null);
  const [reason, setReason] = useState("");
  const [callbackStatus, setCallbackStatus] = useState<
    "loading" | "success" | "error" | undefined
  >(undefined);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFullName(value);
    setFullNameError(!value.trim()); // Show error if empty or only whitespace
  };

  const handlePhoneValueChange = ({ value }: { value: string }) => {
    setPhoneNumber(value);
    if (!value) {
      setPhoneErrorType("empty");
    } else if (value.length < 10) {
      setPhoneErrorType("invalid");
    } else {
      setPhoneErrorType(null);
    }
  };

  const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
  };

  const onConfirmCallbackClick = async () => {
    if (fullNameError || phoneErrorType !== null) return;
    sendEventButtonClick({
      buttonName: ButtonClickEventNames.CONFIRM_ZENDESK_CALLBACK_REQUEST,
      screenId,
    });
    setCallbackStatus("loading");

    try {
      const response = await requestCallbackFromExpert({
        name: fullName,
        phone: phoneNumber,
        callbackReason: reason,
      });

      if (response.success) {
        setCallbackStatus("success");
        sendEventButtonClickCompleted({
          buttonName: ButtonClickEventNames.CONFIRM_ZENDESK_CALLBACK_REQUEST,
          result: ButtonClickCompletedResult.SUCCESS,
          action: ButtonClickCompletedAction.SEND_ZENDESK_CALLBACK_REQUEST,
          screenId,
        });
      } else {
        setCallbackStatus("error");
        sendEventButtonClickCompleted({
          buttonName: ButtonClickEventNames.CONFIRM_ZENDESK_CALLBACK_REQUEST,
          result: ButtonClickCompletedResult.FAILURE,
          action: ButtonClickCompletedAction.SEND_ZENDESK_CALLBACK_REQUEST,
          screenId,
        });
      }
    } catch {
      setCallbackStatus("error");
      sendEventButtonClickCompleted({
        buttonName: ButtonClickEventNames.CONFIRM_ZENDESK_CALLBACK_REQUEST,
        result: ButtonClickCompletedResult.FAILURE,
        action: ButtonClickCompletedAction.SEND_ZENDESK_CALLBACK_REQUEST,
        screenId,
      });
    }
  };

  return (
    <Flex flexDirection="column" gap={8}>
      <Flex gap={4} display="flex" flexDirection="column">
        <FormControl isRequired isInvalid={fullNameError}>
          <FormLabel fontWeight={600}>Your name</FormLabel>
          <Input value={fullName} onChange={handleNameChange} />
          {fullNameError && (
            <FormErrorMessage>Your name is required</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isRequired isInvalid={phoneErrorType !== null}>
          <FormLabel fontWeight={600}>Your phone number</FormLabel>
          <NumberFormat
            customInput={StyledInput}
            value={phoneNumber}
            format={formatPhoneNumber}
            onValueChange={handlePhoneValueChange}
            allowNegative={false}
          />
          {phoneErrorType && (
            <FormErrorMessage>
              {phoneErrorType === "empty"
                ? "Your phone number is required"
                : "Phone numbers must contain 10 digits"}
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl>
          <FormLabel fontWeight={600}>What do you need help with?</FormLabel>
          <Textarea
            placeholder="Optional"
            value={reason}
            onChange={handleReasonChange}
          />
        </FormControl>
      </Flex>
      {callbackStatus === "error" ? (
        <Flex gap={2} justifyContent="center">
          <Alert />
          <Text variant="body" color={theme.colors.error.dark}>
            We couldn’t complete your callback request. Please try again in a
            few minutes.
          </Text>
        </Flex>
      ) : (
        <Flex gap={4} flexDir="column">
          {callbackStatus === "success" ? (
            <Flex gap={2} justifyContent="center">
              <GreenFilledCheck />
              <Text variant="body" color={theme.colors.success.medium}>
                Callback request sent
              </Text>
            </Flex>
          ) : (
            <Button
              variant="primary"
              onClick={onConfirmCallbackClick}
              isLoading={callbackStatus === "loading"}
            >
              Confirm Callback Request
            </Button>
          )}
          <Text variant="body" color={theme.colors.primary}>
            An expert can typically call back within <strong>30 minutes</strong>{" "}
            during open hours: Monday – Friday, 10am – 5pm ET.
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default CallbackFormBlock;
