import React, { useState } from "react";

import { NavigationChoiceBlock, Status } from "../types";

import ChoiceOutline from "./ChoiceOutline";
import DefaultNavigationChoiceItem from "./DefaultNavigationChoiceItem";
import NavigationChoiceHOC from "./NavigationChoiceHOC";

function NavigationChoice(props: {
  choice: NavigationChoiceBlock;
  color?: string;
  fontWeight?: string;
  navigationDisabled?: boolean;
  navigationDisableSetter?: (state: boolean) => void;
  onClick: () => void;

  // TODO(marcia): Rename this to a better loading/disabled/progress style name
  showLoadingSpinner?: boolean;
}) {
  const { choice, showLoadingSpinner } = props;
  const { isEnabled } = choice;
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  // TODO(marcia): Understand what these two different statuses mean. It seems
  // the orange in progress is used on the home screen, and then started was
  // used on a schedule c from casual meandering around the app.
  const incomplete =
    choice.isEnabled &&
    (choice.status.status === Status.ORANGE_IN_PROGRESS ||
      choice.status.status === Status.STARTED);

  let loadingCallback = setIsLoading;
  let disabledCallback = setIsDisabled;
  if (showLoadingSpinner === false) {
    // By default, callers do not pass this prop in, and we do want to show the
    // loading spinner. If and only if showLoadingSpinner is specified to false,
    // then don't show the loading spinner.
    // TODO(marcia): Instead of managing disabled and loading state separately, we
    // should manage them together and then on delay show the loading spinner.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    loadingCallback = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    disabledCallback = () => {};
  }

  return (
    <NavigationChoiceHOC
      setIsLoading={loadingCallback}
      setIsDisabled={disabledCallback}
      isEnabled={isEnabled}
      {...props}
      item={
        <ChoiceOutline incomplete={incomplete}>
          <DefaultNavigationChoiceItem
            isLoading={isLoading}
            isDisabled={isDisabled}
            {...props}
          />
        </ChoiceOutline>
      }
    />
  );
}

export default NavigationChoice;
