import React, { useEffect, useRef, useState } from "react";

import { Box, Button, Flex, Text } from "@chakra-ui/react";

import { redirect, sendEventButtonClick } from "../api";
import { ContinueBlock, Resource, Screen, ScreenId } from "../types";

interface Props {
  block: ContinueBlock;
  screen: Screen;
  onSubmit: (screenId: ScreenId, resource?: Resource) => void;
  onBack?: () => Promise<void>;
  navigationDisabled?: boolean;
  secondaryLinkBlock?: {
    label: string;
    screenId: ScreenId;
    resource?: Resource;
  };
}

const ContinueButtonBlock: React.FC<Props> = ({
  screen,
  block,
  onSubmit,
  onBack,
  navigationDisabled,
  secondaryLinkBlock,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [tick, setTick] = useState(0);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearLoaders = () => {
    timer.current && clearTimeout(timer.current);
    setIsDisabled(false);
    setIsLoading(false);
  };

  const mutationObserver = new MutationObserver(() => {
    mutationObserver.disconnect();
    setTick(tick + 1);
  });

  useEffect(() => {
    mutationObserver.observe(document.body, { subtree: true, childList: true });
  }, []);

  useEffect(() => {
    clearLoaders();
    return;
  }, [onSubmit]);

  const handleClick = () => {
    mutationObserver.disconnect();
    sendEventButtonClick({
      buttonName: "ContinueButtonBlock",
      screenId: screen.id,
    });
    // Open the url in a new tab if one is provided
    if (block.url) {
      redirect(block.url);
    } else {
      setIsDisabled(true);
      timer.current = setTimeout(() => {
        setIsLoading(true);
      }, 250);
      onSubmit(block.screenId, block.resource);
    }
  };

  const handleSecondaryLinkClick = () => {
    sendEventButtonClick({
      buttonName: "ContinueButtonBlockSecondaryLink",
      screenId: screen.id,
    });
    if (secondaryLinkBlock) {
      setIsDisabled(true);
      onSubmit(secondaryLinkBlock.screenId, secondaryLinkBlock.resource);
    }
  };

  return (
    <Box
      key={block.id}
      // A button should take up half container width minus half of row grid gap
      minW={{ base: "full", md: "calc(50% - 33px)" }}
      height="fit-content"
      display="flex"
      flexGrow={1}
    >
      <Flex
        direction="column"
        justifyContent={"flex-end"}
        flexGrow={1}
        height="fit-content"
        gap="12px"
        alignItems="center"
      >
        <Box display="flex" alignItems="end" width="full" flexGrow={1}>
          {block.renderBackCta && onBack && (
            <Button key="back-button" onClick={onBack} variant="gray" mr={4}>
              Back
            </Button>
          )}
          <Box
            display="flex"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
            width={block.renderBackCta && onBack ? undefined : "full"}
          >
            <Button
              key={block.id}
              width="full"
              size="md"
              fontWeight="bold"
              // the Chakra Button component uses the negative style name "isDisabled"
              // so we have to be careful of the logic here
              isLoading={isLoading}
              loadingText={block.label}
              isDisabled={!block.isEnabled || isDisabled || navigationDisabled}
              onClick={handleClick}
              variant={block.style}
              maxWidth={{ md: "300px" }}
            >
              {block.label}
            </Button>
          </Box>
        </Box>
        {secondaryLinkBlock && (
          <Box padding={4}>
            <Text
              onClick={handleSecondaryLinkClick}
              style={{
                cursor: "pointer",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "24px",
              }}
              color="brand.medium"
            >
              {secondaryLinkBlock.label}
            </Text>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default ContinueButtonBlock;
