import React from "react";

import { ContentListType } from "../types";
import { BlockType } from "../types";

import rollbar from "../../rollbar-utils";
import ImageBlock from "./ImageBlock";
import LinkBlock from "./LinkBlock";
import NewTextBlock from "./NewTextBlock";
import TextCompositionBlock from "./TextCompositionBlock";
import UnorderedListBlock from "./UnorderedListBlock";

interface Props {
  contentList: ContentListType;
}

const ContentListRenderer = ({ contentList }: Props) => {
  return (
    <>
      {contentList.map((item, index) => {
        switch (item.type) {
          case BlockType.TEXT_COMPOSITION_BLOCK:
            return <TextCompositionBlock {...item} key={index} />;
          case BlockType.LINK:
            return <LinkBlock {...item} key={index} />;
          case BlockType.NEW_TEXT_BLOCK:
            return (
              <NewTextBlock
                key={index}
                {...item}
                marginBottom="0"
                display="inline"
              />
            );
          case BlockType.UNORDERED_LIST:
            return <UnorderedListBlock {...item} key={index} />;
          case BlockType.IMAGE:
            return <ImageBlock {...item} key={index} />;
          default:
            rollbar.error("Unexpected block type for ContentListRenderer");
        }
      })}
    </>
  );
};

export default ContentListRenderer;
