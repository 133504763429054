import React from "react";

import { ArrowBackIcon } from "@chakra-ui/icons";

import { StepType } from "../types";

interface Props {
  onBack: (targetStep: {
    target_step_id: string;
    target_question_id?: string;
  }) => Promise<void>;
  previous: { target_step_id: StepType; target_question_id?: string };
}

const BackButton = (props: Props) => {
  return (
    // TODO(marcia): Switch to using IconButton instead: https://chakra-ui.com/docs/form/icon-button
    <ArrowBackIcon
      width="28px"
      height="28px"
      color="text.primary"
      _active={{ color: "text.primary" }}
      onClick={() => {
        props.onBack(props.previous);
      }}
    />
  );
};

export default BackButton;
