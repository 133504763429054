import React from "react";

import { WarningIcon } from "@chakra-ui/icons";
import { Box, Center, Code, Link, Text } from "@chakra-ui/react";

import { URLParamError } from "../types";

import ScreenContainer from "../components/ScreenContainer";

import { redirectToExitPage } from "../utils/api";
import { MAX_HEIGHT } from "../utils/constants";
import { apiUrl, isProduction } from "../utils/utils";

// TODO(marcia): Could consider syntax highlighting

const SAMPLE_SNIPPET = `// For all users:

response = POST "${apiUrl()}/v1/exp/initialize_tax_filing&..."
// - user_identifier:
//     unique id from host system
// - See docs for additional parameters

OPEN response.user_url
// in web view or iframe.

`.replace(/\//g, "/\u200B");
// NOTE: \u200B is a zero-width space, allowing for clean line breaks in the code snippet
//       when URLs are too long

function DevErrorScreen({ error }: { error: null | string }) {
  if (isProduction()) {
    // Go to the exit screen if there was an error or the token was expired on load
    redirectToExitPage();
    return <></>;
  }

  let message;
  switch (error) {
    case URLParamError.MISSING_PARAMS:
      message = (
        <Text>
          Missing <Code>params</Code> url parameter
        </Text>
      );
      break;
    case URLParamError.FAILED_TO_BASE64_DECODE:
      message = <Text>Incorrect base64 encoding.</Text>;
      break;
    case URLParamError.MISSING_TOKEN:
      message = (
        <Text>
          Missing <Code>token</Code> in params
        </Text>
      );
      break;
    case URLParamError.INVALID_JSON:
      message = (
        <Text>
          <Code>params</Code> is not valid json
        </Text>
      );
      break;
    case URLParamError.INVALID_USER_TOKEN:
      message = (
        <Text>
          Invalid <Code>user_token</Code>
        </Text>
      );
      break;
    default:
      message = <Text>{error}</Text>;
  }
  return (
    // This is copied from SDK.tsx
    <Box height="100%" borderRadius="md">
      <Box
        minWidth="xs"
        maxWidth="sm"
        height="full"
        maxHeight={MAX_HEIGHT}
        margin="auto"
      >
        <ScreenContainer renderClose={true}>
          <Box>
            <Center>
              <WarningIcon w={12} h={12} my="5" color="#b60b13" />
            </Center>
            <Center textAlign="center" fontWeight="bold">
              Invalid Column Tax URL
            </Center>
            <Center data-testid={error}>{message}</Center>
            <Center my="5">
              <Code fontSize="xs" display="block" whiteSpace="pre-wrap" p="5">
                {SAMPLE_SNIPPET}
              </Code>
              {/* TODO(marcia): Add links to API docs in all the relevant languages */}
            </Center>
            <Center>
              <Text textAlign="center">
                For more information, check the
                <br />
                <Link fontWeight="bold" href="https://docs.columntax.com/">
                  Column Tax Documentation
                </Link>
              </Text>
            </Center>
          </Box>
        </ScreenContainer>
      </Box>
    </Box>
  );
}

export default DevErrorScreen;
