import React from "react";

function ReviewFileIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.7988 2.7988C5.31026 2.28734 6.00396 2 6.72727 2H14C14.2411 2 14.4723 2.09578 14.6428 2.26627L20.0974 7.72081C20.2679 7.8913 20.3636 8.12253 20.3636 8.36364V19.2727C20.3636 19.996 20.0763 20.6897 19.5648 21.2012C19.0534 21.7127 18.3597 22 17.6364 22H6.72727C6.00395 22 5.31026 21.7127 4.7988 21.2012C4.28734 20.6897 4 19.996 4 19.2727V4.72727C4 4.00396 4.28734 3.31026 4.7988 2.7988ZM6.72727 3.81818C6.48617 3.81818 6.25494 3.91396 6.08445 4.08445C5.91396 4.25494 5.81818 4.48617 5.81818 4.72727V19.2727C5.81818 19.5138 5.91396 19.7451 6.08445 19.9156C6.25494 20.086 6.48617 20.1818 6.72727 20.1818H17.6364C17.8775 20.1818 18.1087 20.086 18.2792 19.9156C18.4497 19.7451 18.5455 19.5138 18.5455 19.2727V8.74019L13.6234 3.81818H6.72727Z"
        fill="#293056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2C14.5021 2 14.9091 2.40701 14.9091 2.90909V7.45455H19.4546C19.9567 7.45455 20.3637 7.86156 20.3637 8.36364C20.3637 8.86571 19.9567 9.27273 19.4546 9.27273H14C13.498 9.27273 13.0909 8.86571 13.0909 8.36364V2.90909C13.0909 2.40701 13.498 2 14 2Z"
        fill="#293056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63635 12.9091C7.63635 12.407 8.04337 12 8.54544 12H15.8182C16.3202 12 16.7273 12.407 16.7273 12.9091C16.7273 13.4112 16.3202 13.8182 15.8182 13.8182H8.54544C8.04337 13.8182 7.63635 13.4112 7.63635 12.9091Z"
        fill="#293056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63635 16.5456C7.63635 16.0435 8.04337 15.6365 8.54544 15.6365H15.8182C16.3202 15.6365 16.7273 16.0435 16.7273 16.5456C16.7273 17.0476 16.3202 17.4547 15.8182 17.4547H8.54544C8.04337 17.4547 7.63635 17.0476 7.63635 16.5456Z"
        fill="#293056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63635 9.27262C7.63635 8.77054 8.04337 8.36353 8.54544 8.36353H10.3636C10.8657 8.36353 11.2727 8.77054 11.2727 9.27262C11.2727 9.77469 10.8657 10.1817 10.3636 10.1817H8.54544C8.04337 10.1817 7.63635 9.77469 7.63635 9.27262Z"
        fill="#293056"
      />
    </svg>
  );
}

export default ReviewFileIcon;
