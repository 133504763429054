import React from "react";
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import { Box, Collapse, FormControl, Select, Text } from "@chakra-ui/react";

import {
  BusinessCategory,
  BusinessField,
  BusinessSubcategory,
  ReactHookFormError,
} from "../types";

import {
  categorizeActivity,
  getActivities,
  getSubcategories,
} from "../helpers/BusinessCategories";
import { ErrorMessages, isInvalid, resolveVariant } from "../helpers/Errors";
import SelectOption from "./SelectOption";

interface Props {
  field: BusinessField;
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  error: ReactHookFormError;
  isLoading: boolean;
}

const BusinessLabel: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Box>
      <Text size="lg" fontWeight="semibold" mb="1" color="text.primary">
        {label}
      </Text>
    </Box>
  );
};

const BusinessDropdown: React.FC<Props> = (props: Props) => {
  const categories = props.field.categories;
  const categoryFieldId = props.field.id + "_category";
  const subcategoryFieldId = props.field.id + "_subcategory";

  let categoryName = "";
  let subcategoryName = "";

  const activityCategories = categorizeActivity(props.field);
  if (activityCategories != undefined) {
    categoryName = activityCategories.category;
    subcategoryName = activityCategories.subcategory;
  }

  const onChange = (
    data: unknown,
    options: { name?: string; type?: string },
  ) => {
    // If the user changes the subcategory, reset the business activity.
    // Likewise, if the user changes the category, reset both the subcategory
    // and the business activity.
    if (options.type != "change") {
      return;
    }

    if (options.name == subcategoryFieldId) {
      props.setValue(props.field.id, "");
    } else if (options.name == categoryFieldId) {
      props.setValue(subcategoryFieldId, "");
      props.setValue(props.field.id, "");
    }
  };

  // props.watch here has two different behaviors below - for more info, see
  // https://react-hook-form.com/api/useform/watch

  // watchCategory and watchSubcategory subscribe to the category and
  // subcategory fields and will cause components below to re-render when they
  // receive new values
  const watchCategory = props.watch(categoryFieldId, categoryName);
  const watchSubcategory = props.watch(subcategoryFieldId, subcategoryName);

  // props.watch(onChange) subscribes to the entire form; the callback is
  // invoked on a change to any field.
  props.watch(onChange);

  const categoryOptions = (
    elements: BusinessCategory[] | BusinessSubcategory[],
  ) => {
    return elements.map(
      (element: BusinessCategory | BusinessSubcategory, index) => (
        <SelectOption key={index} label={element.name} value={element.name} />
      ),
    );
  };

  const activityOptions = (activities: string[]) => {
    return activities.map((activity: string, index) => (
      <SelectOption key={index} label={activity} value={activity} />
    ));
  };

  return (
    // Add mb since "Select" doesn't have the same spacing as "Input"
    <Box>
      <FormControl
        isInvalid={isInvalid(props.error)}
        isDisabled={props.isLoading}
      >
        <BusinessLabel label={"Business Category"} />

        <Select
          variant={resolveVariant(props.error)}
          placeholder="Select a Business Category"
          marginBottom="8"
          {...props.register(categoryFieldId)}
        >
          {categoryOptions(categories)}
        </Select>
        <Collapse
          in={getSubcategories(categories, watchCategory).length > 0}
          startingHeight={1}
        >
          <BusinessLabel label={"Business Subcategory"} />
          <Select
            data-testid="subcategory"
            placeholder="Select a Subcategory"
            marginBottom="8"
            {...props.register(subcategoryFieldId)}
          >
            {categoryOptions(getSubcategories(categories, watchCategory))}
          </Select>
        </Collapse>
        <Collapse
          startingHeight={1}
          in={
            getActivities(categories, watchCategory, watchSubcategory).length >
            0
          }
        >
          <BusinessLabel label={"Business Activity"} />
          <Select
            data-testid="activity"
            placeholder="Select a Business Activity"
            {...props.register(props.field.id)}
          >
            {activityOptions(
              getActivities(categories, watchCategory, watchSubcategory),
            )}
          </Select>
        </Collapse>
        <ErrorMessages error={props.error} />
      </FormControl>
    </Box>
  );
};

export default BusinessDropdown;
