import React from "react";

import { Box, Flex } from "@chakra-ui/react";

import { Block, Screen, ScreenId } from "../types";

import BlockRenderer from "./BlockRenderer";

function ScrollAreaBlock({
  contents,
  onSubmit,
  screen,
}: {
  contents: Block[];
  onSubmit: (screenId: ScreenId) => void;
  screen: Screen;
}) {
  return (
    <Box
      overflow="hidden"
      borderWidth="1px"
      borderColor="border.medium"
      borderRadius="12"
    >
      <Flex
        overflowY="auto"
        h="154px"
        borderWidth="0"
        px="4"
        borderStyle="none"
        gap="4"
        flexDirection="column"
      >
        <BlockRenderer blocks={contents} onSubmit={onSubmit} screen={screen} />
      </Flex>
    </Box>
  );
}

export default ScrollAreaBlock;
