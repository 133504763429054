import React, { useEffect } from "react";
import { FieldValues, UseFormRegister, useFormContext } from "react-hook-form";

import { Box, FormControl, Input } from "@chakra-ui/react";

import { DeviceIdField } from "../types";

import { getOrComputeDeviceId } from "../../getDeviceId";

interface Props {
  field: DeviceIdField;
  register: UseFormRegister<FieldValues>;
  isLoading: boolean;
}
const DeviceIdFieldInput: React.FC<Props> = ({
  field,
  register,
  isLoading,
}) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    getOrComputeDeviceId().then((result) => setValue(field.id, result));
  }, []);

  return (
    <Box hidden={true}>
      <FormControl isDisabled={isLoading}>
        <Input {...register(field.id)} />
      </FormControl>
    </Box>
  );
};

export default DeviceIdFieldInput;
