import React from "react";

function MoreIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9999 14.0833C13.5982 14.0833 14.0833 13.5983 14.0833 13C14.0833 12.4017 13.5982 11.9166 12.9999 11.9166C12.4016 11.9166 11.9166 12.4017 11.9166 13C11.9166 13.5983 12.4016 14.0833 12.9999 14.0833Z"
        stroke="#293056"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5833 14.0833C21.1816 14.0833 21.6666 13.5983 21.6666 13C21.6666 12.4017 21.1816 11.9166 20.5833 11.9166C19.9849 11.9166 19.4999 12.4017 19.4999 13C19.4999 13.5983 19.9849 14.0833 20.5833 14.0833Z"
        stroke="#293056"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.41659 14.0833C6.01489 14.0833 6.49992 13.5983 6.49992 13C6.49992 12.4017 6.01489 11.9166 5.41659 11.9166C4.81828 11.9166 4.33325 12.4017 4.33325 13C4.33325 13.5983 4.81828 14.0833 5.41659 14.0833Z"
        stroke="#293056"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MoreIcon;
