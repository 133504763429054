import React from "react";

import { Box, Button, Flex, Text } from "@chakra-ui/react";

import { FixItBlock, FixItSeverity, Resource, ScreenId } from "../types";

import Alert from "../icons/AlertCircleRed";
import Warning from "../icons/WarningOrange";

interface Props {
  block: FixItBlock;
  onSubmit: (screenId: ScreenId, resource?: Resource) => void;
}

const FixIt: React.FC<Props> = ({ block, onSubmit }) => {
  const handleClick = () => {
    if (block.screenId) {
      onSubmit(block.screenId, block.resource);
    }
  };

  const isError = block.severity == FixItSeverity.ERROR;
  return (
    <Box
      key={block.id}
      borderRadius="12"
      borderColor={isError ? "error.medium" : "warning.medium"}
      borderWidth="1px"
      color="text.primary"
      onClick={handleClick}
      cursor="pointer"
      backgroundColor="white"
    >
      <Flex
        bg={isError ? "error.light" : "warning.light"}
        alignItems="flex-start"
        alignContent="top"
        borderTopRadius="12"
        p="4"
      >
        <Box minW="6" minH="6">
          {isError ? <Alert /> : <Warning />}
        </Box>
        <Text ml="2" fontSize="md" fontWeight="semibold">
          {block.title}
        </Text>
      </Flex>
      <Box p="4">
        <Text mb="4">{block.message}</Text>

        {block.screenId && (
          <Button
            mt="4"
            key={block.id}
            width="full"
            variant={isError ? "error" : "warning"}
          >
            {block.buttonText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default FixIt;
