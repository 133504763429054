export function delay(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function unwrapOr<T>(value: T | undefined, alternate: T): T {
  if (value) {
    return value;
  }
  return alternate;
}

export function unwrapOrElse<T>(value: T | undefined, fn: () => T): T {
  if (value) {
    return value;
  }
  return fn();
}
