import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import NumberFormat from "react-number-format";

import { Box, FormControl } from "@chakra-ui/react";

import { Field, ReactHookFormError } from "../types";

import { formatPhoneNumber } from "../../utils/formatter";
import { ErrorMessages, isInvalid, resolveVariant } from "../helpers/Errors";
import InputFieldLabel from "./InputFieldLabel";
import StyledInput from "./StyledInput";

interface Props {
  field: Field;
  register: UseFormRegister<FieldValues>;
  error: ReactHookFormError;
  control: Control;
  isLoading: boolean;
}

const PhoneFieldInput: React.FC<Props> = ({
  field,
  error,
  control,
  isLoading,
}) => {
  return (
    <Box>
      <FormControl isInvalid={isInvalid(error)} isDisabled={isLoading}>
        <InputFieldLabel field={field} />

        <Controller
          render={({ field: { onChange, value, onBlur } }) => (
            <NumberFormat
              variant={resolveVariant(error)}
              allowNegative={false}
              onValueChange={(v) => onChange(v.value)}
              value={value}
              format={formatPhoneNumber}
              placeholder={field.placeholder}
              customInput={StyledInput}
              onBlur={onBlur}
              name={field.id}
            />
          )}
          name={field.id}
          control={control}
          defaultValue={field.answer}
        />
        <ErrorMessages error={error} />
      </FormControl>
    </Box>
  );
};

export default PhoneFieldInput;
