import React from "react";
import { useState } from "react";

import { Box, Center, Circle, Image, SimpleGrid, Text } from "@chakra-ui/react";

import HandoffColumntaxLogo from "../icons/columntax_logo_no_border.svg";
import DefaultBankLogo from "../icons/default_bank_logo.svg";
import HandoffArrows from "../icons/handoff_arrows.svg";
import HostBankLogo, { INNER_LOGO_HEIGHT, LOGO_HEIGHT } from "./HostBankLogo";

interface Props {
  hostBankLogoFilename: string;
  hostBankName?: string;
  fullSizeLogo?: boolean;
}

const HandoffIconBlock: React.FC<Props> = ({
  hostBankLogoFilename,
  hostBankName,
  fullSizeLogo,
}) => {
  /*
  Displays a handoff icon for the Splash Screen. It has three parts -- the bank 
  logo  (either a specified logo or default icon), arrows, and the column tax logo. 
  */

  const [imageLoadError, setImageLoadError] = useState(false);
  return (
    <Center flexDirection="column">
      <SimpleGrid columns={3}>
        {/* We use the host bank logo if provided, or fall back to a default */}
        {hostBankLogoFilename && !imageLoadError ? (
          <HostBankLogo
            hostBankLogoFilename={hostBankLogoFilename}
            fullSizeLogo={fullSizeLogo}
            onError={() => setImageLoadError(true)}
          />
        ) : (
          <img src={DefaultBankLogo} style={{ height: LOGO_HEIGHT }} />
        )}
        <img
          src={HandoffArrows}
          style={{ height: LOGO_HEIGHT, width: "100%" }}
        />
        <Circle
          size={LOGO_HEIGHT}
          borderWidth={1}
          borderColor="border.medium"
          borderStyle="solid"
          backgroundColor="white"
        >
          <Image
            onError={() => setImageLoadError(true)}
            src={HandoffColumntaxLogo}
            boxSize={INNER_LOGO_HEIGHT}
            htmlHeight={INNER_LOGO_HEIGHT}
          />
        </Circle>
      </SimpleGrid>
      {hostBankName && (
        // Max width as the width on the design
        <Box mt={4}>
          <Text fontWeight="semibold" align="center">
            {hostBankName} partners with <br />
            <b>Column Tax</b> to help you file your taxes{" "}
          </Text>
        </Box>
      )}
    </Center>
  );
};

export default HandoffIconBlock;
