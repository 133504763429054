import React from "react";

function QuestionIcon({ fillColor }: { fillColor?: string }) {
  const fill = fillColor || "#293056";
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13" cy="13" r="13" fill={fill} />
      <path
        d="M10.09 10C10.3251 9.33167 10.7892 8.76811 11.4 8.40913C12.0108 8.05016 12.7289 7.91894 13.4272 8.03871C14.1255 8.15849 14.7588 8.52152 15.2151 9.06353C15.6713 9.60553 15.9211 10.2915 15.92 11C15.92 13 12.92 14 12.92 14M13 18H13.01M23 13C23 18.5228 18.5228 23 13 23C7.47715 23 3 18.5228 3 13C3 7.47715 7.47715 3 13 3C18.5228 3 23 7.47715 23 13Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default QuestionIcon;
