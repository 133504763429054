import sha1 from "crypto-js/sha1";

import rollbar from "./rollbar-utils";
import { Fingerprint2 } from "./utils/fingerprint/IRS_fingerprint2_2_1_5";

interface Component {
  value: unknown;
  index: number;
  array: unknown[];
}

// Fingerprint2 is loaded asynchronously via HTML, so we need to wait for it to be available
// Returns true if Fingerprint2 is loaded, false otherwise
function waitForFingerprintJsToLoad(
  timeout = 5000,
  interval = 100,
): Promise<boolean> {
  return new Promise((resolve) => {
    const startTime = Date.now();
    (function checkFingerprint2() {
      if (typeof Fingerprint2 !== "undefined") {
        resolve(true);
      } else if (Date.now() - startTime >= timeout) {
        // resolve, but log, so the user is not blocked
        rollbar.error("Fingerprint2 failed to load after timeout.");
        resolve(false);
      } else {
        // Fingerprint2 not loaded yet, waiting...
        setTimeout(checkFingerprint2, interval);
      }
    })();
  });
}
// Create module-scoped variable to cache the device ID promise
// This will immediately resolve if the promise has already been computed, or return the pending promise
let cachedDeviceIdPromise: Promise<string | null> | null = null;

// getOrComputeDeviceId creates a unique 40-character identifier for the given user
// The function provided by the IRS resolves in about 50ms, so we handle
// this asynchronously and return a promise.
async function getOrComputeDeviceId(): Promise<string | null> {
  // Return the cached deviceId if it exists
  if (cachedDeviceIdPromise) {
    return cachedDeviceIdPromise;
  }

  // Wrap the asynchronous logic in an immediately invoked async function
  cachedDeviceIdPromise = (async (): Promise<string | null> => {
    const isFingerprintJsLoaded = await waitForFingerprintJsToLoad();

    // Return null early if Fingerprint2 didn't load
    if (!isFingerprintJsLoaded) {
      return null;
    }

    return new Promise((resolve) => {
      Fingerprint2.get((components) => {
        const stringtohash = components
          .map((pair: Component): unknown => pair.value)
          .join("###");

        // This must pass IRS Schema validations which specify that this is uppercase
        const deviceId = sha1(stringtohash).toString().toUpperCase();
        resolve(deviceId);
      });
    });
  })();

  return cachedDeviceIdPromise;
}

export { getOrComputeDeviceId };
