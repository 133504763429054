import React, { useEffect, useState } from "react";

import { Box, Flex, Text } from "@chakra-ui/react";

import { ImageId } from "../types";

import ImageBlock from "./ImageBlock";

interface Props {
  screenId: string;
  children: React.ReactNode;
}

const EXCLUDED_SCREENS = [
  "FixErrorsBeforeSummaryScreen",
  "FixWarningsBeforeSummaryScreen",
  "SummaryScreen",
  "ReviewScreen",
  "SubmissionRetryableScreen",
];

const ExpertAssistEntrypointCard: React.FC<Props> = ({
  screenId,
  children,
}) => {
  const [extraPadding, setExtraPadding] = useState(false);
  useEffect(() => {
    setExtraPadding(
      !EXCLUDED_SCREENS.some((screen) => screenId.includes(screen)),
    );
  }, [screenId]);

  return (
    <Box background="white" rounded="card" boxShadow="card" width="100%">
      <Flex
        alignItems="center"
        background="brand.light"
        px={{ base: 4, navmenu: extraPadding ? 8 : 4 }}
        py={{ base: 3, navmenu: 3 }}
        width="100%"
        gap={4}
        roundedTop="card"
      >
        <ImageBlock imageId={ImageId.EXPERT_ASSIST_PROFILES} />
        <Text fontWeight="semibold">You’re enrolled in Expert Assist</Text>
      </Flex>
      <Flex
        px={{ base: 4, navmenu: extraPadding ? 8 : 4 }}
        py={{ base: 3, navmenu: 4 }}
        roundedBottom="card"
        flexDir={{ base: "column", navmenu: "row" }}
        width="100%"
        gap={3}
        justifyContent={{ base: "center", navmenu: "space-between" }}
        alignItems={{ base: "flex-start", navmenu: "center" }}
      >
        {children}
      </Flex>
    </Box>
  );
};

export default ExpertAssistEntrypointCard;
