import React from "react";

interface ExpertIconProps {
  width?: number;
  height?: number;
}

const ExpertIcon: React.FC<ExpertIconProps> = ({ width = 48, height = 48 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="48.2598"
        width="47"
        height="47"
        rx="23.5"
        transform="rotate(-90 0.5 48.2598)"
        fill="white"
      />
      <rect
        x="0.5"
        y="48.2598"
        width="47"
        height="47"
        rx="23.5"
        transform="rotate(-90 0.5 48.2598)"
        stroke="#F2F4F7"
      />
      <path
        d="M30 33.7598V31.7598C30 30.6989 29.5786 29.6815 28.8284 28.9313C28.0783 28.1812 27.0609 27.7598 26 27.7598H18C16.9391 27.7598 15.9217 28.1812 15.1716 28.9313C14.4214 29.6815 14 30.6989 14 31.7598V33.7598"
        stroke="#1E263A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 23.7598C24.2091 23.7598 26 21.9689 26 19.7598C26 17.5506 24.2091 15.7598 22 15.7598C19.7909 15.7598 18 17.5506 18 19.7598C18 21.9689 19.7909 23.7598 22 23.7598Z"
        stroke="#1E263A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="30.5" cy="28.2598" r="5.5" fill="#D4AF37" />
      <path
        d="M28 28.0455L29.6667 29.7598L33 26.7598"
        stroke="#1E263A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExpertIcon;
