import React from "react";

import { Box, Flex, Text } from "@chakra-ui/react";

import TickIcon from "../../diy/icons/TickIcon";
import theme from "../../theme";

interface Props {
  label: string;
  subLabel?: string;
}

const listTheme = theme.components.CheckmarkListItem.sizes.md;

const CheckmarkListItemBlock: React.FC<Props> = ({ label, subLabel }) => {
  return (
    <Box my={listTheme.my} mx="auto" maxWidth={listTheme.maxWidth}>
      <Flex alignItems="center">
        <TickIcon pixelSize={24} mr="3" />
        <Box>
          <Text as="span" fontWeight={listTheme.fontWeight}>
            {label}
          </Text>
          {subLabel && <Text as="span"> {subLabel}</Text>}
        </Box>
      </Flex>
    </Box>
  );
};

export default CheckmarkListItemBlock;
