import React from "react";

import { Flex } from "@chakra-ui/react";

import { Screen } from "../types";
import { TextBlockTypes } from "../types";

import ExpertAssistLandingBlock from "./ExpertAssistLandingBlock";
import NewTextBlock from "./NewTextBlock";

interface Props {
  screen: Screen;
  screenId: string;
  name: string;
  phone: string;
}

const ExpertAssistModal: React.FC<Props> = ({
  screen,
  screenId,
  name,
  phone,
}) => {
  return (
    <Flex gap={4} flexDir="column" mt={4}>
      <NewTextBlock
        text="Expert Assist"
        variant={TextBlockTypes.H2}
        fontAlign="left"
      />

      <ExpertAssistLandingBlock
        screen={screen}
        screenId={screenId}
        name={name}
        phone={phone}
        fallbackMode={true}
      />
    </Flex>
  );
};

export default ExpertAssistModal;
