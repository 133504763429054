import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";

import {
  handleDeleteBinaryAttachment,
  handleGetBinaryAttachmentStatus,
  handleUploadBinaryAttachment,
} from "../../api";
import {
  BinaryAttachment,
  ImageId,
  TextBlockTypes,
  UploadRequestStatus,
} from "../../types";

import { authenticateUrl } from "../../../shared/api";

import { openLink } from "../../../fileDownloadUtils";
import { convertToExternalBrowserUrl } from "../../../utils/nav-utils";
import FilePlusIcon from "../../icons/FilePlusIcon";
import TrashCan from "../../icons/TrashCan";
import ImageBlock from "../ImageBlock";
import NewTextBlock from "../NewTextBlock";

type RequestState =
  | {
      type: UploadRequestStatus.INITIAL;
    }
  | { type: UploadRequestStatus.UPLOADING }
  | {
      type: UploadRequestStatus.SUCCESS;
      binaryAttachments: BinaryAttachment[];
    }
  | { type: UploadRequestStatus.ERROR };

function UploadButton({
  setRequestState,
  requestState,
  attachmentType,
}: {
  // handleUpload takes input onChange event
  setRequestState: (requestState: RequestState) => void;
  requestState: RequestState;
  attachmentType: string;
}) {
  const fileInputRef = useRef<null | HTMLInputElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState<null | File>(null);

  const toast = useToast();

  const successToast = (successMessage: string) => {
    toast({
      title: successMessage,
      status: "success",
      position: "top",
      duration: 3000,
      variant: "success",
      isClosable: true,
    });
  };

  const handleUpload = async (event: any) => {
    const target = event.target as HTMLInputElement;
    if (!target) {
      return;
    }

    const files = target.files;
    if (files && files.length > 0) {
      try {
        setRequestState({
          type: UploadRequestStatus.UPLOADING,
        });
        setFile(files[0]);

        // Upload the file
        const response = await handleUploadBinaryAttachment({
          file: files[0],
          attachmentType: attachmentType,
        });

        if (response.success) {
          setRequestState({
            type: UploadRequestStatus.SUCCESS,
            binaryAttachments: response.binaryAttachments,
          });
          successToast("Your PDF upload was successful.");
        } else {
          setRequestState({
            type: UploadRequestStatus.ERROR,
          });
        }
      } catch {
        setRequestState({
          type: UploadRequestStatus.ERROR,
        });
      }

      // Clear the file input
      target.value = "";
    }
  };

  return (
    <Box
      w={{ base: "full", md: "auto" }}
      // A button should take up half container width minus half of row grid gap
      minW={{ md: "calc(50% - 6px)" }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Button
        variant="outline"
        width="full"
        leftIcon={<FilePlusIcon />}
        isLoading={requestState.type === UploadRequestStatus.UPLOADING}
        loadingText="Uploading..."
        hidden={
          requestState.type === UploadRequestStatus.SUCCESS &&
          requestState.binaryAttachments.length >= 6
        }
        onClick={() => {
          // We cannot naturally put the Button inside of a FormLabel because
          // it will hog the click event. So, forgo the FormLabel and programmatically
          // click the input ref like this
          fileInputRef.current?.click();
        }}
        maxW={{ md: "300px" }}
      >
        <Text fontSize="lg" fontWeight="bold">
          {requestState.type === UploadRequestStatus.SUCCESS &&
          requestState.binaryAttachments.length > 0
            ? "Upload Another PDF"
            : "Upload A PDF"}
        </Text>
      </Button>

      <input
        ref={fileInputRef}
        onChange={handleUpload}
        type="file"
        accept=".pdf"
        hidden
      ></input>
    </Box>
  );
}

interface Props {
  attachmentType: string;
}

interface DeletePDFModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileName: string;
  onDelete: () => void;
}

const DeletePDFModal = ({
  isOpen,
  onClose,
  fileName,
  onDelete,
}: DeletePDFModalProps) => {
  // This is styled similar to ManageDeleteModal, but since it has different props, content and functionality is a little different, it
  // made sense to keep it as an isolated component. At some point it may make sense to make reusable if more similar modals are needed.

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    setIsLoading(true);
    onDelete();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="small">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pt={{ base: 14, md: 0 }} px={{ base: 6, md: 0 }}>
          <Heading size="lg">Delete {fileName}?</Heading>
        </ModalHeader>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalBody px={{ base: 6, md: 0 }}>
          <Text>
            Deleting will remove this uploaded PDF from your tax return.
          </Text>
        </ModalBody>
        <ModalFooter px={{ base: 6, md: 0 }} pb={0}>
          <Button
            variant="error"
            width="full"
            px={4}
            py={6}
            onClick={handleDelete}
            isLoading={isLoading}
            data-manage-delete-confirm
            mb={{ base: 12, md: 0 }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const SavedAttachmentCard: React.FC<{
  attachment: BinaryAttachment;
  refreshStatus: () => void;
}> = ({ attachment, refreshStatus }) => {
  // This is styled similarly to a ResourceItem, but should be a standalone component since ResourceItem functionality and style
  // are bundled together, and this component operates differently (e.g., onEdit and onDelete hit custom endpoints, "details" view actually opens a PDF, etc).

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const deleteThisPdf = async () => {
    await handleDeleteBinaryAttachment({ uuid: attachment.uuid });
    await refreshStatus();
    setShowDeleteModal(false);
  };

  return (
    <>
      <Card variant="outline">
        <Box padding={5}>
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontSize="2xl" fontWeight="bold">
              {attachment.originalFilename}
            </Text>
            <Tag
              variant="subtle"
              backgroundColor="success.light"
              rounded="full"
            >
              Uploaded
            </Tag>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mt={5}>
            <Button
              aria-label={"View"}
              title={"View PDF"}
              variant="gray"
              width="75%"
              height={10}
              onClick={() => {
                openLink(
                  convertToExternalBrowserUrl(authenticateUrl(attachment.url)),
                );
              }}
            >
              View
            </Button>
            <Button
              data-manage-delete
              variant="gray"
              pl={4}
              pr={4}
              height={10}
              borderRadius={"full"}
              onClick={openDeleteModal}
              aria-label={"Delete"}
              title={"Delete"}
            >
              <TrashCan />
            </Button>
          </Flex>
        </Box>
      </Card>

      <DeletePDFModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        fileName={attachment.originalFilename}
        onDelete={deleteThisPdf}
      />
    </>
  );
};

const PdfUploadErrorModal: React.FC<{
  isOpenModal: boolean;
  onCloseModal: () => void;
}> = ({ isOpenModal, onCloseModal }) => {
  return (
    <Modal isOpen={isOpenModal} onClose={onCloseModal}>
      <ModalOverlay />
      <ModalContent
        height="auto"
        maxHeight="full"
        margin="0"
        borderRadius="0"
        bottom="0px"
        borderTopRadius="card"
        borderBottomRadius={{ base: 0, md: "card" }}
        // Use safe-area-inset-top to account for iphone notch
        mt="max(20px, env(safe-area-inset-top))"
        containerProps={{
          height: "full",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: { base: "flex-end", md: "center" },
          borderTopRadius: "card",
        }}
      >
        <ModalCloseButton size="lg" />
        <ModalBody
          px={{ base: 8, md: 0 }}
          pt={{ base: 14, md: 0 }}
          pb={{ base: 12, md: 0 }}
          display="flex"
          flexDirection="column"
        >
          <Flex direction="column" height="auto" gap="8">
            <Flex flexDirection="column" gap={4}>
              <ImageBlock imageId={ImageId.ERROR_FILE} />
              <NewTextBlock
                text="Hmm, Something went wrong"
                variant={TextBlockTypes.H2}
                fontAlign="center"
                marginBottom="2"
              />

              <NewTextBlock text="We weren’t able to upload the file you selected. This could be because the file is larger than 60MB, password protected, or not a valid PDF." />
            </Flex>

            <Flex
              // A button should take up half container width minus half of row grid gap
              minW={{ md: "calc(50% - 6px)" }}
              flexDirection={{ base: "column", md: "row" }}
              gap="4"
              flexGrow={1}
              justifyContent="center"
            >
              <Button
                width="full"
                mt={undefined}
                onClick={onCloseModal}
                backgroundColor="brand.medium"
                color="white"
                maxW={{ md: "300px" }}
              >
                Try Again
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const BinaryAttachmentUploadBlock: React.FC<Props> = (props) => {
  const [requestState, setRequestState] = useState<RequestState>({
    type: UploadRequestStatus.INITIAL,
  });

  const refreshStatus = async () => {
    const res = await handleGetBinaryAttachmentStatus({
      attachment_type: props.attachmentType,
    });
    if (res.success) {
      setRequestState({
        type: UploadRequestStatus.SUCCESS,
        binaryAttachments: res.binaryAttachments,
      });
    } else {
      setRequestState({
        type: UploadRequestStatus.ERROR,
      });
    }
  };

  useEffect(() => {
    refreshStatus();
  }, []);

  const existingAttachments =
    requestState.type === UploadRequestStatus.SUCCESS &&
    requestState.binaryAttachments.map((attachment) => {
      return (
        <SavedAttachmentCard
          key={`saved-attachment-card-${attachment.uuid}`}
          attachment={attachment}
          refreshStatus={refreshStatus}
        />
      );
    });

  return (
    <>
      {existingAttachments}
      <UploadButton
        requestState={requestState}
        setRequestState={setRequestState}
        attachmentType={props.attachmentType}
      />

      <PdfUploadErrorModal
        isOpenModal={requestState.type === UploadRequestStatus.ERROR}
        onCloseModal={refreshStatus}
      />
    </>
  );
};

export default BinaryAttachmentUploadBlock;
