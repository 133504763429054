export const formatPhoneNumber = (value: string) => {
  if (!value) return "";
  let formattedValue = "";
  for (let i = 0; i < value.length; i++) {
    if (i > 9) {
      break;
    }

    // Add phone number helper characters
    if (i === 0) {
      formattedValue += "(";
    } else if (i === 3) {
      formattedValue += ") ";
    } else if (i === 6) {
      formattedValue += "-";
    }

    formattedValue += value[i];
  }
  return formattedValue;
};
