import React from "react";

import { Box, BoxProps, UnorderedList } from "@chakra-ui/react";

import { CheckmarkItem } from "../types";

import theme from "../../theme";
import CheckmarkListItem from "./CheckmarkListItem";

type Props = {
  children: CheckmarkItem[];
  mx?: BoxProps["mx"];
  maxWidth?: string;
  flexDir?: "row" | "column";
  variant?: "icon" | "bullet";
};

const listTheme = theme.components.CheckmarkListItem.sizes.md;

const CheckmarkList: React.FC<Props> = ({
  children,
  mx = "auto",
  maxWidth = listTheme.maxWidth,
  flexDir = "row",
  variant = "icon",
}: Props) => {
  if (variant === "bullet") {
    return (
      <Box
        mx={mx}
        maxWidth={maxWidth}
        display="flex"
        flexDirection="column"
        gridRowGap={listTheme.gap}
      >
        <UnorderedList spacing={8} stylePosition="outside">
          {children.map((checkMarkItem: CheckmarkItem, idx: number) => {
            return (
              <CheckmarkListItem
                key={idx}
                index={idx}
                label={checkMarkItem.label}
                subLabel={checkMarkItem.subLabel}
                iconType={checkMarkItem.iconType}
                flexDir={flexDir}
                variant={variant}
              />
            );
          })}
        </UnorderedList>
      </Box>
    );
  }
  return (
    <Box
      mx={mx}
      maxWidth={maxWidth}
      display="flex"
      flexDirection="column"
      gridRowGap={listTheme.gap}
    >
      {children.map((checkMarkItem: CheckmarkItem, idx: number) => {
        return (
          <CheckmarkListItem
            key={idx}
            index={idx}
            label={checkMarkItem.label}
            subLabel={checkMarkItem.subLabel}
            iconType={checkMarkItem.iconType}
            flexDir={flexDir}
            variant={variant}
          />
        );
      })}
    </Box>
  );
};

export default CheckmarkList;
