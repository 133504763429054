import React from "react";

import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { Action, TableRow } from "../types";

import { textFromArray } from "../helpers/TextFormatters";

interface Props {
  header: string;
  rows: TableRow[];
  onClick?: () => void;
  onEdit?: (action: Action) => void;
}

const SummaryTableBlock: React.FC<Props> = ({ header, rows, onClick }) => {
  return (
    <Box>
      <TableContainer>
        <Table variant="infoSummary">
          <Thead>
            <Tr>
              <Th colSpan={1}>{header}</Th>
              <Th colSpan={1} textAlign="right">
                <Button
                  color="brand.medium"
                  variant="link"
                  onClick={onClick}
                  width="100%"
                  justifyContent="flex-end"
                >
                  Edit
                </Button>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {rows.map((row, i) => (
              <Tr key={`row-${i}-${row.label}`}>
                <Td>{row.label}</Td>
                <Td whiteSpace="normal">
                  {textFromArray(row.value as string)}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SummaryTableBlock;
