import React, { useState } from "react";

import { Box, Button } from "@chakra-ui/react";

import usePDFPolling from "../hooks/usePDFPolling";

interface Props {
  label: string;
  pollUrl: string;
  finalUrl: string;
  intervalMs: number;
  maxTries: number;
}

const PollAndLinkBlock: React.FC<Props> = ({
  label,
  pollUrl,
  finalUrl,
  intervalMs,
  maxTries,
}: Props) => {
  const [progressLabel, setProgressLabel] = useState(label);
  const [pdfUrl, setPdfUrl] = useState<null | string>(null);
  const originalLabel = label;

  const [disabled, setDisabled] = useState(false);

  const { startPolling } = usePDFPolling({
    pollUrl,
    finalUrl,
    intervalMs,
    maxTries,
    onProgress: (progressLabel: string) => {
      setProgressLabel(progressLabel);
    },
    onComplete: (newUrl) => {
      setPdfUrl(newUrl);
      // reset label and allow button to be clicked again
      setDisabled(false);
      setProgressLabel(originalLabel);
    },
  });

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      // A button should take up half container width minus half of row grid gap
      minW={{ md: "calc(50% - 6px)" }}
      flexGrow={1}
      width={{ base: "full", md: "300px" }}
    >
      {pdfUrl ? (
        <Button
          as="a"
          variant="outline"
          width="full"
          href={pdfUrl}
          target="_blank"
          isDisabled={disabled}
          maxW={{ md: "300px" }}
        >
          Open PDF
        </Button>
      ) : (
        <Button
          variant="outline"
          width="full"
          onClick={() => {
            setDisabled(true);
            startPolling();
          }}
          isDisabled={disabled}
          maxW={{ md: "300px" }}
        >
          {progressLabel}
        </Button>
      )}
    </Box>
  );
};

export default PollAndLinkBlock;
