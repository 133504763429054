import { BASE_HEADERS, FORM_DATA, FORM_DATA_HEADERS } from "../utils/headers";
import {
  getUserTokenIfExists,
  parseJwtForHostBankIdentifier,
} from "../utils/params-utils";
import { apiUrl } from "../utils/utils";

/* TODO(sean/marcia): Can we make the payloadType clearer?
    See https://github.com/column-tax/column/pull/360#discussion_r834841429 */
export async function authenticatedFetch(
  url: string,
  options?: { method?: "PUT" | "POST" | "DELETE"; body: string | FormData },
  payloadType?: string,
) {
  // we'll either have a token, or we expect to use cookies
  const token = getUserTokenIfExists();

  let headers: Record<string, string>;
  if (payloadType === FORM_DATA) {
    headers = FORM_DATA_HEADERS;
  } else {
    headers = BASE_HEADERS;
  }

  // if we have a token, include it in headers
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;

    try {
      const hostBankUserIdentifier = parseJwtForHostBankIdentifier(token);
      if (hostBankUserIdentifier) {
        // add a hashed host bank user identifier if available to the request path
        // https://github.com/column-tax/column-be/pull/17934
        // only doing active_time even though backend can handle nav as well, this seemed like least risky, if we need more data
        // we can add nav or other endpoints
        if (url.endsWith("/internal/diy/active_time")) {
          const hashed = await crypto.subtle.digest(
            "SHA-256",
            new TextEncoder().encode(hostBankUserIdentifier),
          );
          const hashArray = Array.from(new Uint8Array(hashed));
          const hashHex = hashArray
            .map((b) => b.toString(16).padStart(2, "0"))
            .join("");

          url = url + "/" + hashHex;
        }
      }
    } catch (error) {
      // ignored
    }
  }

  const response = await fetch(url, {
    headers: headers,
    ...options,
    // include cookies (if they're there)
    credentials: "include",
  });

  if (response.status === 401) {
    throw new NotAuthorizedError();
  }

  if (response.status === 429) {
    throw new RateLimitExceededError();
  }

  if (!response.ok) {
    throw new Error(url);
  }

  return await response.json();
}

export class NotAuthorizedError extends Error {
  constructor(message?: string) {
    super(message);

    this.name = "NotAuthorizedError";
  }
}

export class RateLimitExceededError extends Error {
  constructor(message?: string) {
    super(message);

    this.name = "RateLimitExceededError";
  }
}

export function authenticateUrl(url: string) {
  // token may be null (e.g., if we are using cookies)
  const userToken = getUserTokenIfExists();
  const fullUrl = `${apiUrl()}/${url}`;
  const urlObject = new URL(fullUrl);

  // add urlToken to params only when token is not null
  if (userToken) {
    const urlToken = btoa(JSON.stringify({ token: userToken }));
    urlObject.searchParams.set("params", urlToken);
  }
  return urlObject.toString();
}
