import { ApiUrl } from "./utils";

import { authenticatedFetch } from "../shared/api";

export type SAMLPayload =
  | {
      status: "pending";
    }
  | SAMLPayloadSuccess;
export type SAMLPayloadSuccess = {
  status: "success";
  samlResponse: string;
  acsUrl: string;
};

export async function samlAuthenticate(path: string): Promise<SAMLPayload> {
  const url = `${ApiUrl.apiUrl}${path}`;

  return await authenticatedFetch(url);
}
