import React, { useEffect, useState } from "react";

import { Block, FormObject, Screen, ScreenId } from "../types";

import BlockRenderer from "./BlockRenderer";

interface Props {
  progressiveBlocks: Block[];
  fieldName: string;
  fieldValue: string | number | boolean;
  screen: Screen;
  onSubmit: (screenId: ScreenId) => void;
  formObject?: FormObject;
}

// Note for future engineers - as you keep using this block, you will most likely need to modify field components
// to have data-field-name and data-field-value
const ProgressiveDisclosureBlock: React.FC<Props> = ({
  fieldName,
  fieldValue,
  progressiveBlocks,
  onSubmit,
  screen,
  formObject,
}) => {
  const [inputVal, setInputVal] = useState("");

  useEffect(() => {
    const elementToListenTo = document?.querySelector(
      `[data-field-name="${fieldName}"]`,
    );

    setInputVal(elementToListenTo?.getAttribute("data-field-value") || "");
    if (elementToListenTo) {
      const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.type === "attributes") {
            setInputVal(
              elementToListenTo?.getAttribute("data-field-value") || "",
            );
          }
        });
      });

      observer.observe(elementToListenTo, {
        attributes: true, //configure it to listen to attribute changes
      });
    }
  }, []);

  if (inputVal === fieldValue) {
    return (
      <BlockRenderer
        blocks={progressiveBlocks}
        onSubmit={onSubmit}
        screen={screen}
        formObject={formObject as FormObject}
      />
    );
  }

  return null;
};

export default ProgressiveDisclosureBlock;
