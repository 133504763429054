import React from "react";

import {
  Button,
  Modal as ChakraModal,
  Flex,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { MAX_HEIGHT } from "../utils/constants";

// TODO(marcia): After launch, clean up so that we have one Modal component
// and then can perhaps pass the openText component -- either the flex+icon
// for the help/w4 modals or this underlined text for the privacy policy/tos.
function LinkModal({
  openText,
  body,
}: {
  openText: string;
  body: JSX.Element;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Chakra Modals scroll down to the first tabbable element, so we override that behavior
  // by specifying initialRef (which gets set below to ModalContent)
  // see -- https://v1.chakra-ui.com/docs/components/overlay/modal#focus-on-specific-element
  const initialRef = React.useRef(null);

  return (
    <>
      <Text
        as="span"
        textDecoration="underline"
        fontSize="sm"
        color="text.secondary"
        onClick={onOpen}
        style={{ cursor: "pointer" }}
      >
        {openText}
      </Text>
      <ChakraModal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          height="md"
          containerProps={{
            height: "full",
            maxHeight: MAX_HEIGHT,
            alignItems: "flex-end",
          }}
          marginBottom="0"
          roundedBottom="0"
          ref={initialRef}
        >
          <ModalBody py="0">
            <Flex
              direction="column"
              height="full"
              justifyContent="space-between"
            >
              {body}
              <Button onClick={onClose} width="full" mb="5">
                Back
              </Button>
              {/* TODO(marcia): Figure out why the mb="5" collapses on modals with
              scrolling content, and remove the &nbsp hack below. */}
              <>&nbsp;</>
            </Flex>
          </ModalBody>
        </ModalContent>
      </ChakraModal>
    </>
  );
}

export default LinkModal;
