import React, { useEffect, useRef } from "react";

import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";

import { handleSubmit } from "../../api";
import {
  ActionType,
  NavigationChoiceType,
  ResourceType,
  Screen,
} from "../../types";

import { MIN_CARD_INNER_HEIGHT } from "../../../utils/constants";
import { delay } from "../../../utils/general";
import { useNavManager } from "../../navigationContext";

interface Props {
  screen: Screen;
  // We call setScreen to replace the current screen when we're done polling
  // (either because the backend returned a new screen, or because we timed out).
  // TODO: fix typing in BlockRenderer, etc. so this can't be undefined
  setScreen?: (screen: Screen) => void;

  timeoutMilliseconds: number;
  timeoutScreen: string;
  timeoutMessage?: string;
  label?: string;
}

function AsyncWaitBlock({
  screen,
  setScreen,
  timeoutScreen,
  timeoutMilliseconds,
  timeoutMessage,
  label,
}: Props) {
  const toast = useToast();
  const timedOut = useRef<boolean>(false);
  const { disableNavigation } = useNavManager();

  // Poll for the next screen by making a request for the current screen;
  // the backend is responsible for returning a non-wait screen eventually.
  async function pollForNextScreen() {
    if (timedOut.current) {
      // If we timed out, load the timeout screen specified in the props and show an error toast.
      const action = {
        type: ActionType.NAVIGATE,
        navigationChoice: timeoutScreen,
        navigationChoiceType: NavigationChoiceType.Screen,
        resource: screen.resource,
        resourceType: ResourceType.Source, // because we're navigating *from* the current screen's resource
      };
      const screenResponse = await handleSubmit({ screen, action });
      if (setScreen !== undefined) {
        setScreen(screenResponse.screen);
        toast({
          title:
            timeoutMessage ||
            "There was an error completing your request. Please try again later.",
          status: "error",
          variant: "error",
          position: "top",
          duration: 10000,
          isClosable: true,
        });
      }
    } else {
      const action = {
        type: ActionType.NAVIGATE,
        navigationChoice: screen.id,
        navigationChoiceType: NavigationChoiceType.Screen,
        resource: screen.resource,
        resourceType: ResourceType.Target,
      };
      // Use handleSubmit directly here so we don't unnecessarily swap out the screen, update the nav, etc.
      const screenResponse = await handleSubmit({ screen, action });
      if (screenResponse.screen.id !== screen.id && setScreen !== undefined) {
        setScreen(screenResponse.screen);
      }
    }
  }

  const updateTimedOut = async () => {
    await delay(timeoutMilliseconds);

    if (timedOut) {
      timedOut.current = true;
    }
  };

  useEffect(() => {
    disableNavigation();
    updateTimedOut();

    const interval = setInterval(() => {
      pollForNextScreen();
    }, 5 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Flex
      direction="column"
      justifyContent="space-around"
      minHeight={MIN_CARD_INNER_HEIGHT}
    >
      {/*eslint-disable-next-line @typescript-eslint/no-empty-function*/}
      <Modal isOpen={true} closeOnOverlayClick={false} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent margin="0">
          <ModalBody>
            <Flex direction="column" gridGap="16px">
              <Heading size="h4">{label || "Preparing your return…"}</Heading>
            </Flex>
            <Box mt={4}>
              <Box textAlign="center" my="8">
                <Spinner size="xl" color="brand.medium" />
                <Text color="text.secondary" fontSize="md" mt={4}>
                  We’re crunching some numbers to get your return ready to file.
                </Text>
                <Text mt={4} color="text.secondary" fontSize="md">
                  Please stay on this screen—things might take a minute.
                </Text>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default AsyncWaitBlock;
