import React, { useState } from "react";
import { Mail } from "react-feather";

import { CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Circle,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

import { requestExpertReview, sendEventButtonClick } from "../api";
import { ButtonClickEventNames } from "../types";

import ExpertReviewImage from "../images/expert_assist_expert_review.png";

type Props = {
  screenId: string;
  userEmail: string;
};

export const ExpertReviewModalBody: React.FC<Props> = ({
  screenId,
  userEmail,
}) => {
  const [requestedExpertReview, setRequestedExpertReview] = useState(false);

  return (
    <Flex pt={2} justify="center" align="center" flexDir="column">
      <Image src={ExpertReviewImage} maxH="110px" width="auto" />
      <Heading size="lg" textAlign="center" mb={5} mt={8}>
        Get an Expert Review
      </Heading>
      <Flex flexDir="column" gap={5}>
        <Text size="md">
          Expert Reviews include recommendations for this year, plus tips for
          next year too.
        </Text>
        <Text size="md">
          Please allow our experts up to 1 business day to send a thorough
          review of your return.
        </Text>
        <Text>
          <b>Your email:</b> {userEmail}
        </Text>
        {requestedExpertReview && (
          <Center mb={8}>
            <HStack spacing={2}>
              <Circle size={6} bg="success.medium" color="white">
                <CheckIcon boxSize={3} />
              </Circle>
              <Text color="success.medium">
                Your review will arrive within 1 business day
              </Text>
            </HStack>
          </Center>
        )}
        {!requestedExpertReview && (
          <Button
            onClick={() => {
              setRequestedExpertReview(true);
              sendEventButtonClick({
                buttonName: ButtonClickEventNames.REQUEST_EXPERT_REVIEW,
                screenId: screenId,
              });

              requestExpertReview();
            }}
            width="full"
            variant="outline"
            mb="6"
            leftIcon={<Mail />}
          >
            Get an Expert Review
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default ExpertReviewModalBody;
