import React from "react";

function LockIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.72727 12.0001C5.2252 12.0001 4.81818 12.4071 4.81818 12.9092V19.2728C4.81818 19.7749 5.2252 20.1819 5.72727 20.1819H18.4545C18.9566 20.1819 19.3636 19.7749 19.3636 19.2728V12.9092C19.3636 12.4071 18.9566 12.0001 18.4545 12.0001H5.72727ZM3 12.9092C3 11.4029 4.22104 10.1819 5.72727 10.1819H18.4545C19.9608 10.1819 21.1818 11.4029 21.1818 12.9092V19.2728C21.1818 20.779 19.9608 22.0001 18.4545 22.0001H5.72727C4.22104 22.0001 3 20.779 3 19.2728V12.9092Z"
        fill="#293056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.091 3.81818C11.1266 3.81818 10.2017 4.2013 9.51972 4.88325C8.83777 5.5652 8.45466 6.49012 8.45466 7.45455V11.0909C8.45466 11.593 8.04764 12 7.54557 12C7.04349 12 6.63647 11.593 6.63647 11.0909V7.45455C6.63647 6.00791 7.21115 4.62053 8.23407 3.5976C9.257 2.57467 10.6444 2 12.091 2C13.5377 2 14.925 2.57467 15.948 3.5976C16.9709 4.62053 17.5456 6.00791 17.5456 7.45455V11.0909C17.5456 11.593 17.1386 12 16.6365 12C16.1344 12 15.7274 11.593 15.7274 11.0909V7.45455C15.7274 6.49012 15.3443 5.5652 14.6623 4.88325C13.9804 4.2013 13.0554 3.81818 12.091 3.81818Z"
        fill="#293056"
      />
    </svg>
  );
}

export default LockIcon;
