import React from "react";

function SparkleFillIcon({ color }: { color: string }) {
  return (
    <div>
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icons / edit-3">
          <path
            id="Vector (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.16675 17.4267C9.16675 16.9665 9.53984 16.5934 10.0001 16.5934H17.5001C17.9603 16.5934 18.3334 16.9665 18.3334 17.4267C18.3334 17.887 17.9603 18.2601 17.5001 18.2601H10.0001C9.53984 18.2601 9.16675 17.887 9.16675 17.4267Z"
            fill={color}
          />
          <path
            id="Rectangle 1260 (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.61575 12.5617L5.53236 12.9676C6.11119 13.224 6.57121 13.6905 6.81954 14.2728L7.18583 15.1317L7.60333 14.2162C7.85325 13.6682 8.29263 13.2288 8.84066 12.9789L9.75613 12.5614L8.89718 12.1951C8.31487 11.9468 7.84846 11.4868 7.59208 10.908L7.18607 9.99134L6.82954 10.8521C6.57581 11.4647 6.08912 11.9514 5.47653 12.2051L4.61575 12.5617ZM7.92232 7.5382C7.62558 6.86827 6.67087 6.87986 6.39048 7.5568L5.28974 10.2143C5.20516 10.4185 5.04293 10.5807 4.83873 10.6653L2.18118 11.7661C1.50424 12.0465 1.49266 13.0012 2.1626 13.2979L4.8574 14.4915C5.05034 14.577 5.20368 14.7325 5.28646 14.9266L6.39372 17.5231C6.67782 18.1893 7.61797 18.2009 7.91848 17.5419L9.11975 14.9078C9.20306 14.7251 9.34952 14.5786 9.5322 14.4953L12.1663 13.2941C12.8253 12.9936 12.8137 12.0534 12.1475 11.7693L9.55098 10.662C9.35688 10.5793 9.20141 10.4259 9.11595 10.233L7.92232 7.5382Z"
            fill={color}
          />
          <path
            id="Rectangle 1261 (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.92247 6.765C8.38652 6.52762 8.39578 5.76385 8.93733 5.53953L11.0454 4.66634C11.2088 4.59868 11.3386 4.46889 11.4062 4.30554L12.2794 2.19747C12.5037 1.65592 13.2675 1.64664 13.5049 2.18259L14.4518 4.32048C14.5202 4.47483 14.6446 4.59749 14.7999 4.66371L16.8596 5.54213C17.3926 5.76942 17.4019 6.52152 16.8747 6.76193L16.1965 7.0712L14.7848 7.71498C14.6387 7.78162 14.5215 7.89879 14.4549 8.04493L13.8111 9.45661L13.5018 10.1348C13.2614 10.662 12.5093 10.6527 12.282 10.1197L11.9896 9.43411L11.4036 8.05997C11.3374 7.90468 11.2147 7.7803 11.0604 7.71193L8.92247 6.765ZM11.7305 6.18593L11.7353 6.18805C12.2672 6.42364 12.6916 6.84928 12.9258 7.38108L12.9384 7.35339C13.1717 6.84189 13.5818 6.43181 14.0933 6.19855L14.121 6.18592C13.5892 5.95174 13.1636 5.52735 12.928 4.99546L12.9258 4.99065C12.6908 5.52443 12.2643 5.95093 11.7305 6.18593Z"
            fill={color}
          />
          <rect
            id="Rectangle 1262"
            x="13.3333"
            y="13.26"
            width="5"
            height="1.66667"
            rx="0.833333"
            fill={color}
          />
        </g>
      </svg>
    </div>
  );
}

export default SparkleFillIcon;
