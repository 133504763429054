import React from "react";

import { Link, Text } from "@chakra-ui/react";

import { convertToExternalBrowserUrl } from "../../utils/nav-utils";

interface Props {
  text: string;
  url: string;
  isPrintLink?: boolean;
  display?: string;
}

const LinkBlock: React.FC<Props> = ({ text, url, isPrintLink }) => {
  return isPrintLink ? (
    <Text w="100%" textAlign="right">
      <Link
        color="text.secondary"
        href={convertToExternalBrowserUrl(url)}
        rel="noreferrer"
        target="_blank"
      >
        {text}
      </Link>
    </Text>
  ) : (
    <Link
      color="brand.medium"
      href={convertToExternalBrowserUrl(url)}
      rel="noreferrer"
      target="_blank"
    >
      {text}
    </Link>
  );
};

export default LinkBlock;
