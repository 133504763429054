import React from "react";

import { Button, Flex } from "@chakra-ui/react";

import { PreviousBlock } from "../types";

import BackIcon from "../icons/BackIcon";

interface Props {
  onBack: () => Promise<void>;
  previousBlock?: PreviousBlock;
  disabled?: boolean;
}

const BackButton = (props: Props) => {
  return (
    <Flex
      height={12}
      onClick={() => {
        if (!props.disabled) {
          props.onBack();
        }
      }}
      // Used for integration tests
      aria-label="back-flex"
      data-cypress="back-button"
    >
      {!props.disabled && (
        <Button
          w={12}
          h={12}
          leftIcon={<BackIcon />}
          variant="link"
          color="text.primary"
          // Note that this aria-label is used for integration tests
          aria-label="Back"
          fontWeight="bold"
          fontSize="0"
          justifyContent="flex-start"
        ></Button>
      )}
    </Flex>
  );
};

export default BackButton;
