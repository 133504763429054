import { BusinessCategory, BusinessField, BusinessSubcategory } from "../types";

/*
 * As a reminder, BusinessCategory and BusinessSubcategory are typed as follows:
 *
 *  type BusinessSubcategory = {
 *    name: string;
 *    activities: Array<string>;
 *  };
 *
 *  type BusinessCategory = {
 *    name: string;
 *    activities: Array<string>;
 *    subcategories: Array<BusinessSubcategory>;
 *  };
 *
 * In practice, a BusinessCategory could have either zero subcategories or
 * zero activities.
 *
 * If a BusinessCategory has activities, these are listed under a dynamically
 * created "Other" subcategory that we defined.
 *
 * As an example are the following three categories:
 * "Information", "Other Services", and "Utilities":

  - name: "Information"
    activities:
    - "Publishing industries (except Internet)"
    subcategories:
    - name: "Broadcasting (except Internet) & Telecommunications"
      activities:
      - "Broadcasting (except Internet)"
      - "Telecommunications & Internet service providers"
    - name: "Data Processing Services"
      activities:
      - "Data processing, hosting, & related services"
      - "Other information services (including news syndicates & libraries, Internet publishing & broadcasting)"
    - name: "Motion Picture & Sound Recording"
      activities:
      - "Motion picture & video industries (except video rental)"
      - "Sound recording industries"

  - name: "Other Services"
    activities: []
    subcategories:
    - name: "Personal & Laundry Services"
      activities:
      - "Barber shops"
      - "Beauty salons"
      - "Cemeteries & crematories"
      - "Coin-operated laundries & drycleaners"
      - "Drycleaning & laundry services (except coin-operated) (including laundry & drycleaning drop-off & pickup sites)"
      - "Funeral homes & funeral services"
      - "Linen & uniform supply"
      - "Nail salons"
      - "Parking lots & garages"
      - "Pet care (except veterinary) services"
      - "Photofinishing"
      - "Other personal care services (including diet & weight reducing centers)"
      - "All other personal services"
    - name: "Repair & Maintenance"
      activities:
      - "Automotive body, paint, interior, & glass repair"
      - "Automotive mechanical & electrical repair & maintenance"
      - "Other automotive repair & maintenance (including oil change & lubrication shops & car washes)"
      - "Commercial & industrial machinery & equipment (except automotive & electronic) repair & maintenance"
      - "Electronic & precision equipment repair & maintenance"
      - "Footwear & leather goods repair"
      - "Home & garden equipment & appliance repair & maintenance"
      - "Reupholstery & furniture repair"
      - "Other personal & household goods repair & maintenance"

  - name: "Utilities"
    activities:
    - "Utilities"
    subcategories: []

  */

interface ActivityCategories {
  category: string;
  subcategory: string;
}

const OTHER_SUBCATEGORY_LABEL = "Other";

export function categorizeActivity(
  field: BusinessField,
): ActivityCategories | undefined {
  let activityCategories = undefined;

  field.categories.forEach((category) => {
    // First search through top-level activities, which
    // appear to the taxpayer under the "Other" subcategory
    category.activities.forEach((activityName) => {
      if (field.answer == activityName) {
        activityCategories = {
          category: category.name,
          subcategory: OTHER_SUBCATEGORY_LABEL,
        };
      }
    });

    // Then search through activities nested under subcategories
    category.subcategories.forEach((subcategory) => {
      subcategory.activities.forEach((activityName) => {
        if (field.answer == activityName) {
          activityCategories = {
            category: category.name,
            subcategory: subcategory.name,
          };
        }
      });
    });
  });

  return activityCategories;
}

export function getCategory(
  categories: BusinessCategory[],
  categoryName: string,
): BusinessCategory | undefined {
  return categories.find(({ name }) => name === categoryName);
}

export function getSubcategories(
  categories: BusinessCategory[],
  categoryName: string,
): BusinessSubcategory[] {
  const category = getCategory(categories, categoryName);
  if (category == undefined) {
    return [];
  } else {
    const otherSubcategory =
      category.activities.length > 0
        ? [{ name: OTHER_SUBCATEGORY_LABEL, activities: category.activities }]
        : [];

    return category.subcategories.concat(otherSubcategory);
  }
}

export function getActivities(
  categories: BusinessCategory[],
  categoryName: string,
  subcategoryName: string,
): string[] {
  const category = getCategory(categories, categoryName);
  const subcategories = getSubcategories(categories, categoryName);
  let activities: string[] = [];

  if (category === undefined || subcategories === undefined) {
    activities = [];
  } else {
    // return activities from subcategory if found
    const subcategory = subcategories.find(
      ({ name }) => name === subcategoryName,
    );
    if (subcategory === undefined) {
      activities = [];
    } else {
      activities = subcategory.activities;
    }
  }
  return activities;
}
