import React from "react";

interface Props {
  width?: number | string;
  height?: number | string;
  color?: string;
}

const HelpIcon: React.FC<Props> = ({
  width = 24,
  height = 24,
  color = "#0047FF",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons / Help Circle">
        <g id="Group 1307">
          <path
            id="Vector (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3.81818C7.48131 3.81818 3.81818 7.48131 3.81818 12C3.81818 16.5187 7.48131 20.1818 12 20.1818C16.5187 20.1818 20.1818 16.5187 20.1818 12C20.1818 7.48131 16.5187 3.81818 12 3.81818ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
            fill={color}
          />
          <path
            id="Vector (Stroke)_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2348 8.38538C11.8116 8.31279 11.3763 8.39231 11.0061 8.60987C10.636 8.82743 10.3547 9.16899 10.2122 9.57404C10.0456 10.0477 9.5266 10.2965 9.05298 10.1299C8.57935 9.96332 8.33046 9.44431 8.49707 8.97068C8.78205 8.16058 9.34453 7.47748 10.0849 7.04236C10.8253 6.60724 11.6957 6.44818 12.5421 6.59336C13.3885 6.73854 14.1562 7.17859 14.7093 7.83557C15.2622 8.49241 15.5649 9.3237 15.5637 10.1823C15.5633 11.5735 14.5319 12.4923 13.7953 12.9833C13.3993 13.2473 13.0097 13.4415 12.7227 13.569C12.578 13.6334 12.456 13.6822 12.3682 13.7156C12.3242 13.7324 12.2885 13.7454 12.2626 13.7546L12.231 13.7657L12.2209 13.7691L12.2174 13.7703L12.216 13.7708C12.2157 13.7709 12.2149 13.7712 11.9274 12.9087L12.2149 13.7712C11.7385 13.9299 11.2237 13.6725 11.0649 13.1962C10.9063 12.7202 11.1632 12.2057 11.6389 12.0466L11.6378 12.047C11.6379 12.047 11.638 12.0469 11.6389 12.0466L11.6535 12.0415C11.6674 12.0365 11.6904 12.0282 11.7209 12.0166C11.7822 11.9932 11.8734 11.9568 11.9843 11.9075C12.2087 11.8078 12.501 11.661 12.7867 11.4705C13.4137 11.0526 13.7456 10.6079 13.7456 10.1815L13.7456 10.1801C13.7462 9.75072 13.5949 9.33496 13.3183 9.00648C13.0418 8.67799 12.658 8.45797 12.2348 8.38538Z"
            fill={color}
          />
          <path
            id="Vector (Stroke)_3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0908 16.5458C11.0908 16.0437 11.4978 15.6367 11.9999 15.6367H12.009C12.5111 15.6367 12.9181 16.0437 12.9181 16.5458C12.9181 17.0479 12.5111 17.4549 12.009 17.4549H11.9999C11.4978 17.4549 11.0908 17.0479 11.0908 16.5458Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default HelpIcon;
