import { HistoryItem } from "./types";

import { readFromLocalStorage, saveToLocalStorage } from "../../utils/utils";

export const LOCAL_STORAGE_ADMIN_HISTORY_KEY =
  "local-storage-admin-history-key";

function get(): Array<HistoryItem> {
  return readFromLocalStorage(LOCAL_STORAGE_ADMIN_HISTORY_KEY) || [];
}

function save(history: Array<HistoryItem>) {
  saveToLocalStorage(LOCAL_STORAGE_ADMIN_HISTORY_KEY, JSON.stringify(history));
}

function saveNewItem(historyItem: HistoryItem) {
  // Read existing history
  const history = get();

  // Insert new history item to beginning of array
  history.unshift(historyItem);

  // Keep most recent 20 items in history
  save(history.slice(0, 20));
}

const HistoryUtils = {
  get,
  saveNewItem,
};

export default HistoryUtils;
