import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";

import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FormControl,
  ListItem,
  Square,
  Text,
  UnorderedList,
  useCheckbox,
} from "@chakra-ui/react";

import { CheckboxField, ReactHookFormError } from "../types";

import { ErrorMessages, isInvalid } from "../helpers/Errors";
import { renderWithHtml } from "../helpers/TextFormatters";

export interface CheckboxFieldProps {
  field: CheckboxField;
  register: UseFormRegister<FieldValues>;
  error: ReactHookFormError;
  control: Control;
  isLoading: boolean;
}

// TODO(marcia): There will be a copy pass that removes all
// bullet point content from checkboxes. After that, we can
// remove this Points component.
function Points({ points }: { points: Array<string> }) {
  return (
    <UnorderedList>
      {points.map((point, index) => {
        return <ListItem key={index}>{point}</ListItem>;
      })}
    </UnorderedList>
  );
}

const CheckboxFieldInput: React.FC<CheckboxFieldProps> = (
  props: CheckboxFieldProps,
) => {
  return (
    <Flex>
      <FormControl
        isInvalid={isInvalid(props.error)}
        isDisabled={props.isLoading}
      >
        <Controller
          render={({ field: { onChange, value } }) => {
            const { getInputProps } = useCheckbox({
              onChange,

              // Wow we need to pass *both* isChecked and value in
              // Unfortunately the documentation does not provide
              // a sample shape!
              isChecked: value,
              value,
            });

            const input = getInputProps();

            // Wow close one -- for the radio button, we want to inspect
            // input.checked. For checkboxes, we want to inspect input.value.
            // This ensures that when the form loads with value sets to true,
            // the checkbox does indeed appear checked.
            // The chakra ui documentation is lacking -- so investigate further
            // if this is the right way or there's another approach that is less
            // error-prone.
            // TODO(marcia): Need tests for this behavior to prevent regression
            // @ts-ignore
            const isChecked: boolean = input.value;

            // STOP(marcia): Figure out why loading a checked checkbox
            // requires two taps to uncheck. The first tap sets dirty to true
            // even though value stays true->true
            return (
              <Flex
                // This must be a label so that clicks toggle the value of the checkbox
                as="label"
                width="100%"
                cursor="pointer"
                py="4"
                // TODO(marcia): These styles were copied from Radios.tsx.
                // Clean up and bring to theme/index.tsx
                style={{ WebkitTapHighlightColor: "transparent" }}
                border="1px"
                borderColor={isChecked ? "brand.medium" : "border.medium"}
                bgColor={isChecked ? "brand.light" : "white"}
                rounded="xl"
                justifyContent="space-between"
                data-field-name={props.field.id}
                data-field-value={value}
              >
                <input {...input} hidden name={props.field.id} />
                <Flex px="5" alignItems="center">
                  <Box>
                    <Text
                      fontWeight="semibold"
                      color="text.primary"
                      fontSize="lg"
                      lineHeight="133%"
                    >
                      {props.field.label}
                    </Text>
                    {props.field.subLabelWithHtml ? (
                      <Text color="text.secondary" lineHeight="138%">
                        {renderWithHtml(props.field.subLabelWithHtml)}
                      </Text>
                    ) : (
                      props.field.subLabel && (
                        <Text color="text.secondary" lineHeight="138%">
                          {props.field.subLabel}
                        </Text>
                      )
                    )}

                    {props.field.points && (
                      <Points points={props.field.points} />
                    )}
                  </Box>
                </Flex>

                <Flex pr="4">
                  <Square
                    size={7}
                    border="2px"
                    borderRadius="5px"
                    borderColor={isChecked ? "brand.medium" : "border.medium"}
                    bgColor={isChecked ? "brand.medium" : "white"}
                  >
                    {isChecked && <CheckIcon color="white" />}
                  </Square>
                </Flex>
              </Flex>
            );
          }}
          defaultValue={!!props.field.answer}
          name={props.field.id}
          control={props.control}
        />
        <ErrorMessages error={props.error} />
      </FormControl>
    </Flex>
  );
};

export default CheckboxFieldInput;
