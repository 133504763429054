import React, { KeyboardEvent, useEffect } from "react";
import { FormProvider } from "react-hook-form";

import {
  Box,
  Button,
  Flex,
  Heading,
  Hide,
  Progress,
  Show,
  useBreakpointValue,
} from "@chakra-ui/react";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

import { ActionType, CTA, QuestionScreenProps } from "../types";

import BannerRenderer from "../components/BannerRenderer";
import Breadcrumbs from "../components/Breadcrumbs";
import ContentContainerWrapper from "../components/ContentContainerWrapper";
import HelpButton from "../components/HelpButton";
import NavHeader from "../components/NavHeader";
import ResponsiveContentWrapper from "../components/ResponsiveContentWrapper";
import SubTitle from "../components/SubTitle";

// TODO(marcia): Where does this extension go?
dayjs.extend(utc);
dayjs.extend(customParseFormat);

interface QuestionScreenHOCProps {
  questionScreenProps: QuestionScreenProps;
  children: React.ReactNode;
  save: any;
  formMethods: any;
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  loadingButton: string;
  setLoadingButton: React.Dispatch<React.SetStateAction<string>>;
  timer: React.MutableRefObject<NodeJS.Timeout | null>;
  ref: React.RefObject<HTMLDivElement>;
}

const QuestionScreenHOC = React.forwardRef(
  (
    props: QuestionScreenHOCProps,
    ref: React.LegacyRef<HTMLDivElement> | undefined,
  ) => {
    const {
      questionScreenProps,
      children,
      save,
      formMethods,
      timer,
      isDisabled,
      setIsDisabled,
      loadingButton,
      setLoadingButton,
    } = props;
    const { screen, onSubmit, onBack, supportEnabled } = questionScreenProps;

    const handleKeyboardEvent = (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleSubmit(save, save)();
      }
    };

    const handleBackCta = () => {
      setIsDisabled(true);
      timer.current = setTimeout(() => {
        setLoadingButton(CTA.BACK);
      }, 250);
      onBack();
    };

    const handleSecondaryNext = () => {
      setIsDisabled(true);
      timer.current = setTimeout(() => {
        setLoadingButton(CTA.SECONDARY);
      }, 250);
      screen.secondaryCtaAction &&
        onSubmit({ type: screen.secondaryCtaAction });
    };

    const handleRemindMeLaterLink = () => {
      setIsDisabled(true);
      timer.current = setTimeout(() => {
        setLoadingButton(CTA.REMIND_LATER);
      }, 250);
      screen.remindMeLaterLinkAction &&
        onSubmit({ type: screen.remindMeLaterLinkAction });
    };

    const handleHelp = () => {
      // onSubmit({ navigationChoice: screen.helpScreenId })
      // TODO (chris): may need to standardize the different ways we pass
      // actions to onSubmit, onBack api calls
      onSubmit({ type: ActionType.HELP });
    };

    const { handleSubmit } = formMethods;

    useEffect(() => {
      (document.activeElement as HTMLElement).blur();
    }, [screen.id]);

    useEffect(() => {
      window.addEventListener("keydown", handleKeyboardEvent);

      return () => {
        window.removeEventListener("keydown", handleKeyboardEvent);
      };
    });

    const showBackCTA: boolean =
      screen.renderBack && screen.backCtaText ? true : false;

    const flexValue = useBreakpointValue({ base: 1, md: 0 });

    return (
      <Box w="full" flexDirection="column" zIndex={1}>
        <NavHeader
          onBack={onBack}
          onSubmit={onSubmit}
          bannerItems={screen.bannerItems}
          renderBack={screen.renderBack}
          tempScreenId={screen.id}
          supportEnabled={supportEnabled}
          showDesktopTest={screen.desktopIcon}
          screen={screen}
        />
        <ResponsiveContentWrapper scrollTargetRef={ref}>
          <Flex
            minHeight={{ md: "550px" }}
            direction="column"
            flex={{ base: 1, md: 0 }}
            gap={4}
          >
            <Show above="md">
              <Breadcrumbs screen={screen} onSubmit={onSubmit} />
            </Show>
            {/* The FormProvider gives access to useFormContext within child components. This gives access to all of the formMethods here */}
            <FormProvider {...formMethods}>
              <form
                id={screen.id}
                onSubmit={(event) => event.preventDefault()}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: flexValue,
                }}
              >
                <ContentContainerWrapper>
                  {screen.progressBarValue !== null && (
                    <Progress
                      mb={6}
                      borderRadius="full"
                      value={screen.progressBarValue}
                    />
                  )}

                  <Flex
                    direction="column"
                    justifyContent="space-between"
                    gap={10}
                    flex={{ base: 1, md: 0 }}
                  >
                    <Flex flexDirection="column" gap={8}>
                      {/* this wrapper is needed for the button to stick to the bottom on mobile correctly */}

                      <Flex flexDirection="column" gridRowGap={4}>
                        {/* this is the header wrapper */}
                        <Hide above="md">
                          <Breadcrumbs screen={screen} onSubmit={onSubmit} />
                        </Hide>
                        <Heading color="text.primary" my={0}>
                          {screen.title}
                        </Heading>
                        {screen.subTitle && (
                          <SubTitle
                            text={screen.subTitle}
                            color="text.primary"
                          />
                        )}
                      </Flex>

                      {children}

                      {screen.helpActionBlock && (
                        <HelpButton
                          label={screen.helpActionBlock.label}
                          onClick={handleHelp}
                        />
                      )}
                      {screen.errors && (
                        <BannerRenderer bannerItems={screen.errors} />
                      )}
                    </Flex>
                    <Box display="flex" flexDirection="column">
                      <Flex flexDirection="column" alignItems="center">
                        {showBackCTA && screen.secondaryCtaAction && (
                          <Button
                            w={"full"}
                            mt={4}
                            px={8}
                            form={screen.id}
                            variant="outline"
                            onClick={handleSecondaryNext}
                            isLoading={loadingButton === CTA.SECONDARY}
                            isDisabled={isDisabled}
                            loadingText={screen.secondaryCtaText}
                            maxWidth={{ md: "300px" }}
                          >
                            {screen.secondaryCtaText}
                          </Button>
                        )}
                        <Flex
                          w="full"
                          gridColumnGap={3}
                          gridRowGap={4}
                          justifyContent="center"
                          flexDirection={{
                            base: showBackCTA ? "row" : "column",
                            md: "row",
                          }}
                        >
                          {!showBackCTA && screen.secondaryCtaAction && (
                            <Button
                              w={"full"}
                              mt={{ base: 0, md: 0 }}
                              px={8}
                              form={screen.id}
                              variant="outline"
                              onClick={handleSecondaryNext}
                              isLoading={loadingButton === CTA.SECONDARY}
                              isDisabled={isDisabled}
                              loadingText={screen.secondaryCtaText}
                              maxWidth={{ md: "300px" }}
                            >
                              {screen.secondaryCtaText}
                            </Button>
                          )}
                          {showBackCTA && (
                            <Button
                              mt={{ base: 0, md: 0 }}
                              mr={undefined}
                              px={8}
                              form={screen.id}
                              variant="gray"
                              onClick={handleBackCta}
                              isLoading={loadingButton === CTA.BACK}
                              isDisabled={isDisabled}
                              loadingText={screen.backCtaText}
                              aria-label="Back CTA"
                              maxW="160px"
                            >
                              {screen.backCtaText}
                            </Button>
                          )}
                          <Button
                            variant="primary"
                            w={"full"}
                            px={8}
                            size={"md"}
                            width={!screen.backCtaText ? "full" : undefined}
                            form={screen.id}
                            onClick={handleSubmit(save, save)}
                            isLoading={loadingButton === CTA.CONTINUE}
                            isDisabled={isDisabled}
                            loadingText={screen.callToAction}
                            maxW={{ md: "300px" }}
                            mt={{ base: 0, md: 0 }}
                          >
                            {screen.callToAction}
                          </Button>
                        </Flex>
                        {screen.remindMeLaterLinkText && (
                          <Button
                            width="full"
                            w={"full"}
                            mt={4}
                            px={8}
                            form={screen.id}
                            variant="link"
                            onClick={handleRemindMeLaterLink}
                            isLoading={loadingButton === CTA.REMIND_LATER}
                            isDisabled={isDisabled}
                            loadingText={screen.remindMeLaterLinkText}
                            maxWidth={{ md: "300px" }}
                            minHeight={14}
                          >
                            {screen.remindMeLaterLinkText}
                          </Button>
                        )}
                      </Flex>
                    </Box>
                  </Flex>
                </ContentContainerWrapper>
              </form>
            </FormProvider>
          </Flex>
        </ResponsiveContentWrapper>
      </Box>
    );
  },
);

export default QuestionScreenHOC;
