import React from "react";

import { Box, Button } from "@chakra-ui/react";

interface Props {
  checkoutUrl: string;
}

const CheckoutBlock: React.FC<Props> = ({ checkoutUrl }) => {
  return (
    <Box mb={5}>
      <Button
        onClick={() => {
          window.location.href = checkoutUrl;
        }}
        width="full"
        variant="primary"
      >
        Continue to Pay
      </Button>
    </Box>
  );
};

export default CheckoutBlock;
