import React from "react";

function RadioCheckedIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" fill="currentColor" />
      <path
        d="M8.53555 11.6325L5.85627 8.95318L5.78556 8.88247L5.71485 8.95318L4.92929 9.73873L4.85858 9.80944L4.92929 9.88015L8.46484 13.4157L8.53556 13.4864L8.60627 13.4157L14.499 7.52293L14.5698 7.45219L14.499 7.38148L13.7129 6.59593L13.6422 6.52526L13.5715 6.59596L8.53555 11.6325Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
    </svg>
  );
}

export default RadioCheckedIcon;
