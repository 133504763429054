/* eslint-disable no-fallthrough */
import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import NumberFormat from "react-number-format";

import { Box, FormControl } from "@chakra-ui/react";

import dayjs from "dayjs";

import { DateField, ReactHookFormError } from "../types";

import { classNameToRedactInput } from "../../utils/constants";
import { ErrorMessages, isInvalid, resolveVariant } from "../helpers/Errors";
import InputBlockLabel from "./InputBlockLabel";
import StyledInput from "./StyledInput";

export interface DateFieldProps {
  field: DateField;
  register: UseFormRegister<FieldValues>;
  error: ReactHookFormError;
  control: Control;
  isLoading: boolean;
}

const DateFieldInput: React.FC<DateFieldProps> = ({
  field,
  error,
  control,
  isLoading,
}) => {
  const formatInput = (value: string) => {
    let formattedValue = "";
    let coreValue = "";

    for (let i = 0; i < value.length; i++) {
      if (i > 7) {
        break;
      }

      switch (i) {
        case 0:
          coreValue += value[0];
          formattedValue = coreValue + "m/dd/yyyy";
          break;
        case 1:
          coreValue += value[1];
          formattedValue = coreValue + "/dd/yyyy";
          break;
        case 2:
          coreValue += "/" + value[2];
          formattedValue = coreValue + "d/yyyy";
          break;
        case 3:
          coreValue += value[3];
          formattedValue = coreValue + "/yyyy";
          break;
        case 4:
          coreValue += "/" + value[4];
          formattedValue = coreValue + "yyy";
          break;
        case 5:
          coreValue += value[5];
          formattedValue = coreValue + "yy";
          break;
        case 6:
          coreValue += value[6];
          formattedValue = coreValue + "y";
          break;
        case 7:
          coreValue += value[7];
          formattedValue = coreValue;
      }
    }

    return formattedValue;
  };

  const subLabel = (field: DateField) => {
    switch (field.subLabelType) {
      case undefined:
      case "text":
        return field.subLabel;
      case "current_date":
        return dayjs().format("MM/DD/YYYY");
    }
  };

  // In order to redact a field, we need to apply a classname on the input itself
  // and not a higher level containing element.
  // See the definition of classNameToRedactInput for more
  const className = field.redact ? { className: classNameToRedactInput } : {};
  return (
    <Box>
      <FormControl isInvalid={isInvalid(error)} isDisabled={isLoading}>
        <InputBlockLabel label={field.label} subLabel={subLabel(field)} />
        <Controller
          render={({ field: { onChange, value, onBlur } }) => (
            <NumberFormat
              name={field.id}
              variant={resolveVariant(error)}
              isReadOnly={field.useCurrentDate}
              onValueChange={(v) => onChange(v.value)}
              value={value}
              placeholder={field.placeholder}
              customInput={StyledInput}
              format={formatInput}
              disabled={field.disabled}
              hidden={field.hidden}
              onBlur={onBlur}
              {...className}
            />
          )}
          name={field.id}
          control={control}
        />
        <ErrorMessages error={error} />
      </FormControl>
    </Box>
  );
};

export default DateFieldInput;
