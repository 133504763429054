import React from "react";

import { Text } from "@chakra-ui/react";

import {
  LinkBlock,
  NewTextBlock,
  TextCompositionBlock,
  UnorderedListBlock,
} from "../types";

import { renderWithHtml } from "../helpers/TextFormatters";
import CreditIneligibleReasonsBlock from "./CreditIneligibleReasonsBlock";
import HelpContentBlock from "./HelpContentBlock";

function CreditSummaryIneligibleBlock({
  screenId,
  content,
  helpLabel,
  ineligibleReasonsList,
  ineligibleReasonsHeader,
  helpContentBlocks,
}: {
  screenId: string;
  content: Array<string>;
  helpLabel: string;
  ineligibleReasonsList?: Array<string>;
  ineligibleReasonsHeader?: string;
  helpContentBlocks?: Array<
    NewTextBlock | LinkBlock | TextCompositionBlock | UnorderedListBlock
  >;
}) {
  let helpContentElement = <></>;

  if (helpLabel && helpContentBlocks) {
    helpContentElement = (
      <HelpContentBlock
        screenId={screenId}
        label={helpLabel}
        helpContentBlocks={helpContentBlocks || []}
      />
    );
  }

  return (
    <>
      {content.map((element, index) => (
        <Text key={index}>{renderWithHtml(element)}</Text>
      ))}

      {/* Add a CreditIneligibleReasonsBlock if reasonsList is defined */}
      {ineligibleReasonsList && ineligibleReasonsList.length > 0 && (
        <CreditIneligibleReasonsBlock
          reasonsList={ineligibleReasonsList}
          ineligibleReasonsHeader={ineligibleReasonsHeader}
        />
      )}

      {helpContentElement}
    </>
  );
}

export default CreditSummaryIneligibleBlock;
