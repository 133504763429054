import { getDevelopmentRequestSecret, getInternalRequestSecret } from "./utils";

export const FORM_DATA = "form_data";

export const BASE_HEADERS = {
  "Content-Type": "application/json",
  "Key-Inflection": "camel",
  ...(getInternalRequestSecret() && {
    ["Column-Tax-Internal-Request-Shared-Secret"]: getInternalRequestSecret(),
  }),
  ...(getDevelopmentRequestSecret() && {
    ["X-Github-Token"]: getDevelopmentRequestSecret(),
  }),
};

export const FORM_DATA_HEADERS = {
  "Key-Inflection": "camel",
  ...(getInternalRequestSecret() && {
    ["Column-Tax-Internal-Request-Shared-Secret"]: getInternalRequestSecret(),
  }),
};
