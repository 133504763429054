import {
  Control,
  FieldError,
  FieldErrors,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  MultipleFieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import { ResponsiveValue } from "@chakra-ui/react";

// TODO(marcia): Consider making screen.id an enum
type BaseScreen = {
  userEmail: string;
  id: string;
  resource: Resource;
  screenType: ScreenType;
  bannerItems?: Array<BannerBlock>;
  hostBankName: string;
  fullSizeLogo?: boolean;
  renderBack: boolean;
  allowBrowserBack?: boolean;
  enableEditQuestionnaire?: boolean;
  errors?: Array<BannerBlock>;
  events: Array<UserEvent>;
  showLauncher: boolean;
  desktopIcon?: boolean;
  nav?: NavObject;
  sectionName?: string;
  forceRenderingOnCard?: boolean;
  customMenuItems: Array<NavigationChoiceBlock>;
  enrolledInExpertAssist?: boolean;
  accessToExpertAssistLiveHelp?: boolean;
  accessToExpertAssistExpertReview?: boolean;
  shouldColumnTaxOfferExpertAssistOnSupportScreen?: boolean;
  expertAssistConfiguration?: string;
  showMobileNavMenu?: boolean;
  fullWidthContainer?: boolean;
  expertAssistScreenId: string;
};

export type TQuestionScreen = BaseScreen & {
  // TODO(sean): Make mandatory once all screens have titles
  title?: string;
  subTitle?: string;
  screenType: ScreenType.QUESTION;
  blocks: Array<Block>;
  helpScreenId?: string;
  helpActionBlock?: HelpActionBlock;
  callToAction: string;
  backCtaText?: string;
  secondaryCtaText?: string;
  secondaryCtaAction?: string;
  remindMeLaterLinkText?: string;
  remindMeLaterLinkAction?: string;
  centerContent: boolean;
  progressBarValue?: number;
};

export type TMiniManageScreen = TQuestionScreen & {
  miniManagedBlocks?: Array<Array<Block>>;
  resources?: Array<Resource>;
  addAnotherLabel?: string;
};

export type TManageScreen = BaseScreen & {
  // TODO(chris): Make mandatory once all screens have titles
  title?: string;
  subTitle?: string;
  labels: Record<string, string>;
  screenType: ScreenType.MANAGE;
  addButtonEnabled?: boolean;
  deleteButtonEnabled: boolean;
  continueButtonEnabled?: boolean;
  resources: Array<Record<string, string>>;
  helpContentBlock: HelpContentBlock | undefined;
  optionalBlocks?: Array<Block>;
  backCtaText?: string;
};

export enum Status {
  NOT_STARTED = "not started",
  SUFFICIENT = "done",
  STARTED = "started",
  PRIMARY_START = "primary start",
  OUTLINE_START = "outline start",
  GREY_EDIT = "grey edit",
  ORANGE_IN_PROGRESS = "orange in progress",
  CUSTOM_EXPERT_ASSIST = "custom expert assist",
}

// Needs to be kept in sync with BE Computation::Catalogue::Jurisdiction
export enum Jurisdiction {
  US = "us",
  AZ = "az",
  CA = "ca",
  GA = "ga",
  IL = "il",
  MI = "mi",
  MO = "mo",
  NC = "nc",
  NJ = "nj",
  NY = "ny",
  PA = "pa",
  VA = "va",
  WA = "wa",
}

// Must be kept in sync with backend/lib/source.rb
export enum Source {
  IMPORTED = "imported",
  PRIOR_YEAR = "prior_year",
  PARTNER_BANK = "partner_bank",
  UPLOADED = "uploaded",
}

export type SectionStatusResponse = {
  label: string;
  color: string;
  backgroundColor: string;
  status: Status;
};

export type NavigationChoiceBlockDetailsItem = {
  label?: string;
  value: string | number | Currency;
  isCurrency?: boolean;
};

type NavigationChoiceBlockBase = BaseBlock & {
  screenId: ScreenId;
  sectionName?: string;
  label: string;
  subLabel?: string;
  iconId: IconId;
  status: SectionStatusResponse;
  isEnabled: boolean;
  info: NavigationChoiceInfo[];
  details?: NavigationChoiceBlockDetailsItem[];
  resource?: Resource | null;
  recommended: boolean;
  iconBackgroundColor?: ResponsiveValue<string>;
};

export type NavigationChoiceBlock = NavigationChoiceBlockBase & {
  type: BlockType.NAVIGATION_CHOICE;
};

export type NavigationChoiceInfo = {
  key: string;
  value: string;
};

export type PreviousBlock = BaseBlock & {
  label: string;
};

export type TNavigationScreen = BaseScreen & {
  // TODO(chris): Make mandatory once all screens have titles
  title?: string;
  subTitle?: string;
  screenType: ScreenType.NAVIGATION;
  blocks: Array<Block>;
  previousBlock?: PreviousBlock;
  estimatorBlock?: EstimatorBlock;
  delay: number;
  illustrationId?: string;
};

export type TInterludeScreen = BaseScreen & {
  title?: string;
  subTitle?: string;
  screenType: ScreenType.INTERLUDE;
  delay: number;
  nextScreen: string;
  icon: InterludeIcons;
};

export type TAuthScreen = BaseScreen & {
  title?: string;
  nextScreen: string;
  maskedVerifiedPhoneNumber: string | null;
  prefilledPhoneNumber: string | null;
  screenType: ScreenType.AUTH;
  targetNavigateAction: NavigationAction;
  allowDeviceVerification: boolean;
};

export type Screen =
  | TManageScreen
  | TNavigationScreen
  | TMiniManageScreen
  | TQuestionScreen
  | TInterludeScreen
  | TAuthScreen;

export type BlockScreen = TNavigationScreen | TQuestionScreen | TManageScreen;
export type BannerBlock = IconBlock | AlertBlock;

export interface ScreenProps {
  onAnswer: (screen: Screen) => void;
  updateAnswer: (fieldId: string, answer: FieldAnswer) => void;
  screen: Screen;
  jump?: () => void;
}

export interface QuestionScreenProps {
  onBack: () => Promise<void>;
  onSubmit: (action: Action) => Promise<void>;
  updateAnswer: (fieldId: string, answer: FieldAnswer) => void;
  screen: TQuestionScreen;
  supportEnabled: boolean;
  setScreen: (screen: Screen) => void;
}

export type ResourceAction = {
  type: string;
  resource?: Resource | string;
};

export type UploadW2Action = {
  type: ActionType.UPLOAD_W2;
  file: File;
  resource: Resource;
  uploadType: UploadType;
};

export enum ResourceType {
  Source = "source",
  Target = "target",
}

export enum NavigationChoiceType {
  Screen = "screen",
  Section = "section",
}

export type NavigationAction = {
  navigationChoice: string;
  navigationChoiceType: NavigationChoiceType;
  type: ActionType.NAVIGATE;
  resource?: Resource | string;
  resourceType?: ResourceType;
};

export type Action = ResourceAction | NavigationAction | UploadW2Action;

export interface ManageScreenProps {
  onBack: () => Promise<void>;
  onSubmit: (action: Action) => Promise<void>;
  screen: TManageScreen;
  supportEnabled: boolean;
  setScreen: (screen: Screen) => void;
}

export interface NavigationScreenProps {
  onBack: () => Promise<void>;
  // TODO(marcia): Switch this back to NavigationAction, and
  // as part of that probably update ResourceAction.type to be
  // an exhaustive enum rather than just string
  onSubmit: (action: Action, requestScreen?: Screen) => Promise<void>;
  screen: TNavigationScreen;
  supportEnabled: boolean;
  setScreen: (screen: Screen) => void;
}

export interface InterludeScreenProps {
  onSubmit: (action: Action, requestScreen?: Screen) => Promise<void>;
  screen: TInterludeScreen;
  supportEnabled: boolean;
  setScreen: (screen: Screen) => void;
}

export interface AuthScreenProps {
  onBack: () => Promise<void>;
  onSubmit: (action: Action, requestScreen?: Screen) => Promise<void>;
  screen: TAuthScreen;
  supportEnabled: boolean;
  setScreen: (screen: Screen) => void;
}

export type FieldAnswer = string | number | boolean | null | undefined;

export enum ValidationType {
  REQUIRED = "required",
  REGEX = "regex",
}

type BaseValidation = {
  id: string;
  type: ValidationType;
};

type RequiredValidation = BaseValidation & {
  type: ValidationType.REQUIRED;
};

type RegexValidation = BaseValidation & {
  type: ValidationType.REGEX;
  payload: {
    pattern: string;
  };
};

export type Validation = RequiredValidation | RegexValidation;

type BaseField = BaseBlock & {
  // TODO(marcia): Consider renaming select to enum for consistency with TRU?
  // TODO(marcia): Consider how to support both radio (single select)
  // and checkbox fields (multi select)
  // TODO(marcia): Along the lines of TRU's evolution, likely TBD what currency
  // is (integer number of cents? formatted string?). And sometimes we only
  // want the user to input whole dollars, or we might want decimal precision.
  fieldType: FieldType;
  type: BlockType.FIELD;
  label: string;
  subLabel?: string;
  placeholder: string;
  optional: boolean;
  answer?: FieldAnswer;
  validations?: Array<Validation>;
  errors?: Array<string>;
  helpLabel?: string;
  helpContentBlocks?: Array<
    NewTextBlock | LinkBlock | TextCompositionBlock | UnorderedListBlock
  >;
  redact?: boolean;
  hidden?: boolean;
};

export type Option = {
  label: string;
  value: string;
  descriptors?: string[];
};

export type BusinessSubcategory = {
  name: string;
  activities: Array<string>;
};

export type BusinessCategory = {
  name: string;
  activities: Array<string>;
  subcategories: Array<BusinessSubcategory>;
};

export type SelectField = BaseField & {
  fieldType: FieldType.SELECT;
  options: Array<Option>;
};

export type DropdownField = BaseField & {
  fieldType: FieldType.DROPDOWN;
  options: Array<Option>;
};

export type BusinessField = BaseField & {
  fieldType: FieldType.BUSINESS;
  categories: Array<BusinessCategory>;
};

export type StringField = BaseField & {
  fieldType: FieldType.STRING;

  // Whether the string is string-like but only contains digits (like a zip
  // code, routing number, or pin)
  numeric?: boolean;
  maxLength?: number;
  inputRegex?: string;
  disabled?: boolean;
};

export type IntegerField = BaseField & {
  fieldType: FieldType.INTEGER;
  min?: number;
  max?: number;
  suffix?: string;
};

export type CurrencyField = BaseField & {
  fieldType: FieldType.CURRENCY;
  allowNegative?: boolean;
  allowCents?: boolean;
  disabled?: boolean;
  minCents?: number;
  maxCents?: number;
};

export type BooleanField = BaseField & {
  fieldType: FieldType.BOOLEAN;
  horizontal: boolean;
  trueLabel?: string;
  falseLabel?: string;
};

export type SeparatorField = BaseField & {
  fieldType: FieldType.SEPARATOR;
};

export type DateField = BaseField & {
  fieldType: FieldType.DATE;
  useCurrentDate: boolean;
  subLabelType?: "text" | "current_date";
  disabled?: boolean;
};

export type DecimalField = BaseField & {
  fieldType: FieldType.DECIMAL;
  min?: number;
  max?: number;
};

export type LabelField = BaseField & {
  fieldType: FieldType.LABEL;
};

export type TextField = BaseField & {
  fieldType: FieldType.TEXT;
  text: string;
};

export type SSNField = BaseField & {
  fieldType: FieldType.SSN;
  readOnly: boolean;
};

export type EINField = BaseField & {
  fieldType: FieldType.EIN;
};

export type CheckboxField = BaseField & {
  fieldType: FieldType.CHECKBOX;
  inverted?: boolean;
  points?: undefined | Array<string>;
  subLabelWithHtml: undefined | string;
};

export type PhoneField = BaseField & {
  fieldType: FieldType.PHONE;
};

export type DeviceIdField = BaseField & {
  fieldType: FieldType.DEVICE_ID;
};

export type Field =
  | BooleanField
  | BusinessField
  | CheckboxField
  | CurrencyField
  | DateField
  | DecimalField
  | DeviceIdField
  | DropdownField
  | EINField
  | IntegerField
  | LabelField
  | PhoneField
  | SSNField
  | SelectField
  | SeparatorField
  | StringField
  | TextField;

type BaseBlock = {
  id: string;
  type: BlockType;
  label?: string;
};

export type ContentListType = Array<
  | NewTextBlock
  | LinkBlock
  | TextCompositionBlock
  | UnorderedListBlock
  | ImageBlock
>;

export type CollapsibleSectionBlock = BaseBlock & {
  type: BlockType.COLLAPSIBLE_SECTION_BLOCK;
  open: boolean;
  label: string;
  collapseContentBlocks: ContentListType;
};

export type ContinueBlock = BaseBlock & {
  type: BlockType.CONTINUE_BLOCK;
  label: string;
  screen: Screen;
  screenId: ScreenId;
  isEnabled: boolean;
  style: string;
  url?: string;
  renderBackCta: boolean;
  resource?: Resource;
  secondaryLinkBlock?: {
    label: string;
    screenId: ScreenId;
    resource?: Resource;
  };
};

export type SplashScreenContinueBlock = BaseBlock & {
  type: BlockType.SPLASH_SCREEN_CONTINUE_BLOCK;
  label: string;
  screen: Screen;
  screenId: ScreenId;
  isEnabled: boolean;
  url?: string;
  filingFeeContent: Array<Block>;
  legalNoteContent: Array<LinkBlock | TemporaryLinkModalBlock | NewTextBlock>;
  renderDesktopButton: boolean;
};

export type HelpActionBlock = BaseBlock & {
  type: BlockType.HELP_ACTION_BLOCK;
  label: string;
};

export type SeparatorBlock = BaseBlock & {
  type: BlockType.SEPARATOR;
};

export type GroupSeparatorBlock = BaseBlock & {
  type: BlockType.GROUP_SEPARATOR;
  label?: string;
};

export type ExpertAssistLandingBlock = BaseBlock & {
  type: BlockType.EXPERT_ASSIST_LANDING_BLOCK;
  name: string;
  phone: string;
  shouldColumnTaxOfferExpertAssistOnSupportScreen: boolean;
};

export type ExpertAssistEntrypointBlock = BaseBlock & {
  type: BlockType.EXPERT_ASSIST_ENTRYPOINT_BLOCK;
  expertAssistScreenId: string;
  description?: string;
  buttonLabel?: string;
};

export type ExpertReviewBlock = BaseBlock & {
  type: BlockType.EXPERT_REVIEW_BLOCK;
  description?: string;
  buttonLabel?: string;
};

export type ExpertTipBlock = BaseBlock & {
  type: BlockType.EXPERT_TIP_BLOCK;
  caption: string;
  content: TextElement[];
};

export type OffSeasonInfoBlock = BaseBlock & {
  type: BlockType.OFF_SEASON_INFO_BLOCK;
  blockTitle: string;
  caption: string;
  filingOpenDate: string;
  checkmarkListItems?: CheckmarkItem[];
};

export type OffSeasonSubmittedSummary = {
  type: "submitted_summary";
  viewReturnButton: NavigationChoiceBlock;
  pdfLink: string;
};

export type OffSeasonNotSubmittedSummary = {
  type: "not_submitted_summary";
  text: string;
};

export type OffSeasonSubmissionSummary =
  | OffSeasonSubmittedSummary
  | OffSeasonNotSubmittedSummary;

export type OffSeasonTaxSummaryBlock = BaseBlock & {
  type: BlockType.OFF_SEASON_TAX_SUMMARY_BLOCK;
  blockTitle: string;
  summary: OffSeasonSubmissionSummary;
};

export type ReturnToFilingBlock = BaseBlock & {
  type: BlockType.RETURN_TO_FILING_BLOCK;
  blockTitle: string;
  continueFilingButton: NavigationChoiceBlock;
};

export type HandoffIconBlock = BaseBlock & {
  type: BlockType.HANDOFF_ICON;
  hostBankLogoFilename: string;
};

export type IconBlock = BaseBlock & {
  type: BlockType.ICON;
  iconId: string;
};

export type ImageBlock = BaseBlock & {
  type: BlockType.IMAGE;
  imageId: string;
};

export type NotApplicableBlock = BaseBlock & {
  type: BlockType.NOT_APPLICABLE;
  label: string;
  subLabel: string;
};

export type LinkBlock = BaseBlock & {
  type: BlockType.LINK;
  text: string;
  url: string;
  isPrintLink: boolean;
};

export enum ListItemIcon {
  CORNER_DOWN_RIGHT = "CORNER_DOWN_RIGHT",
  GREEN_FILLED_CHECK = "GREEN_FILLED_CHECK",
}

export type ListItemWithIconBlock = BaseBlock & {
  type: BlockType.LIST_ITEM_WITH_ICON_BLOCK;
  icon: ListItemIcon;
  blockItem: NewTextBlock;
};

export type TemporaryLinkModalBlock = BaseBlock & {
  type: BlockType.TEMPORARY_LINK_MODAL;
  openText: string;
  bodyComponent: string;
  screenId: string;
};

export enum TextBlockVariant {
  HEADLINE_CENTERED = "headline-centered",
  TITLE = "title",
  H2_TITLE = "h2-title",
}

export enum TextBlockTypes {
  DEFAULT = "default",
  TEXT_SM = "text-sm",
  TEXT_LG = "text-lg",
  TEXT_XL = "text-xl",
  H3 = "h3",
  H2 = "h2",
  H1 = "h1",
  H4 = "h4",
  CAPTION = "caption",
}

// TODO(marcia): Audit button variants and add all the ones that
// we expect the BE to be able to send to the FE
export enum ButtonVariant {
  LIGHT_BLUE = "light-blue",
  GRAY = "gray",
}

export type FontAlignType = "left" | "center";
export type FontWeightType = "normal" | "semibold" | "bold" | "extrabold";

export type NewTextBlock = BaseBlock & {
  type: BlockType.NEW_TEXT_BLOCK;
  text: string;
  variant: TextBlockTypes;
  isSecondaryColor?: boolean;
  fontWeight?: FontWeightType;
  fontAlign?: FontAlignType;
  lineHeight?: string;
  marginBottom?: string;
  verticalAlign?: string;
};

export type ExpandBlock = BaseBlock & {
  type: BlockType.EXPAND_BLOCK;
  contents: Block[];
  label: string;
};

export type HelpContentBlock = BaseBlock & {
  type: BlockType.HELP_CONTENT_BLOCK;
  label: string;
  helpContentBlocks?: Array<
    NewTextBlock | LinkBlock | TextCompositionBlock | UnorderedListBlock
  >;
};
export type FallbackBlock = BaseBlock & {
  type: BlockType.FALLBACK;
  errorScreenId: ScreenId;
  samlUrl: string;
  timeoutMilleseconds: number;
  shouldIframe: boolean;
};

export type CreditSummaryEligibleBlock = BaseBlock & {
  type: BlockType.CREDIT_SUMMARY_ELIGIBLE_BLOCK;
  creditName: string;
  content: Array<string>;
  amountLabel: string;
  amount?: number;
  amounts?: Array<[string, number]>;
  helpLabel: string;
  helpContentBlocks?: Array<
    NewTextBlock | LinkBlock | TextCompositionBlock | UnorderedListBlock
  >;
};

export type CreditSummaryIneligibleBlock = BaseBlock & {
  type: BlockType.CREDIT_SUMMARY_INELIGIBLE_BLOCK;
  content: Array<string>;
  helpLabel: string;
  ineligibleReasonsList?: Array<string>;
  ineligibleReasonsHeader?: string;
  helpContentBlocks?: Array<
    NewTextBlock | LinkBlock | TextCompositionBlock | UnorderedListBlock
  >;
};

export type DesktopHandoffBlock = BaseBlock & {
  type: BlockType.DESKTOP_HANDOFF;
  screenId: string;
};

export type DesktopHandoffForPdfBlock = BaseBlock & {
  type: BlockType.DESKTOP_HANDOFF_FOR_PDF;
  screenId: string;
};

export type InputEditBlock = BaseBlock & {
  type: BlockType.INPUT_BUTTON;
  screenId: ScreenId;
  text: string;
  label: string;
};

export type TextElement = LinkBlock | TemporaryLinkModalBlock | string;

export type UnorderedListBlock = BaseBlock & {
  type: BlockType.UNORDERED_LIST;
  content: Array<NewTextBlock>;
};

export type AlertBlock = BaseBlock & {
  type: BlockType.ALERT;
  title: string;
  text: string;
  status: AlertTypes;
};

export type FixItBlock = BaseBlock & {
  type: BlockType.FIX_IT;
  title: string;
  message: string;
  screenId: ScreenId;
  severity: FixItSeverity;
  resource?: Resource;
  buttonText: string;
};

export type CallbackFormBlock = BaseBlock & {
  type: BlockType.CALLBACK_FORM_BLOCK;
  name: string;
  phone: string;
  screenId: string;
};

export type ScrollAreaBlock = BaseBlock & {
  type: BlockType.SCROLL_AREA;
  contents: Block[];
};

export type ReturnSummaryBlock = BaseBlock & {
  type: BlockType.RETURN_SUMMARY;
  totalIncome: number;
  adjustments: number;
  deductions: number;
  taxableIncome: number;
  effectiveTaxRate: number;
  totalTax: number;
  otherTaxes: number;
  credits: number;
  payments: number;
  netRefundAmount: number;
};

export type SummaryAmountBlock = BaseBlock & {
  type: BlockType.SUMMARY_AMOUNT;
  text: string;
  amount: number;
};

export type SummaryDetailBlock = BaseBlock & {
  type: BlockType.SUMMARY_DETAIL;
  summaryText: string;
  amount: number;
  detailText: string;
  lineItems: Array<SummaryAmountBlock>;
};

export type SummaryHeaderBlock = BaseBlock & {
  type: BlockType.SUMMARY_HEADER;
  amount: number;
  federal?: number;
  state?: number;
  stateCode?: string;
  showOverallAmount?: boolean;
};

export type PdfBlock = BaseBlock & {
  type: BlockType.PDF;
  url: string;
  authenticate: boolean;
};

export type PollAndLinkBlock = BaseBlock & {
  type: BlockType.POLL_AND_LINK;
  label: string;
  pollUrl: string;
  finalUrl: string;
  intervalMs: number;
  maxTries: number;
};

export type SaveAsPdfBlock = BaseBlock & {
  type: BlockType.SAVE_AS_PDF;
  url: string;
  authenticate: boolean;
};

// The enums must be kept in sync with the BE's
// EstimatorStatus serialized values
export enum EstimatorStatus {
  EMPTY = "empty",
  CALCULATING = "calculating",
  LOADING = "loading",
  REFUND = "refund",
  TAXES_OWED = "taxes_owed",
}

export type EstimatorBlock = BaseBlock & {
  type: BlockType.ESTIMATOR;
  status: EstimatorStatus;
  amount: number;
  stateStatus?: EstimatorStatus;
  stateAmount?: number;
};

export type EstimatorWaitBlock = BaseBlock & {
  type: BlockType.ESTIMATOR_WAIT;
  timeoutMilliseconds: number;
};

export type CheckoutBlock = BaseBlock & {
  type: BlockType.CHECKOUT_BLOCK;
  checkoutUrl: string;
};

export enum UploadRequestStatus {
  INITIAL,
  UPLOADING,
  SUCCESS,
  ERROR,
}

export type BinaryAttachment = {
  url: string;
  uuid: string;
  originalFilename: string;
};

export type BinaryAttachmentUploadBlock = BaseBlock & {
  type: BlockType.BINARY_ATTACHMENT_UPLOAD;
  attachmentType: string;
};

// Must be kept in sync with UploadType enum in backend
export enum UploadType {
  IRS1040 = "irs1040",
  W2 = "w2",
}

// Must be kept in sync with UploadStatus enum in backend
export enum UploadStatus {
  ERRORED = "errored",
  INVALIDATED = "invalidated",
  STARTED = "started",
  SUCCEEDED = "succeeded",
}

export type PrefillUploadBlock = BaseBlock & {
  type: BlockType.PREFILL_UPLOAD | BlockType.W2_UPLOAD;
  uploadType: UploadType;
  optimisticPayloadOnUpload: {
    screen: Screen;
  };
};

export type PrefillWaitBlock = BaseBlock & {
  type: BlockType.PREFILL_WAIT | BlockType.W2_WAIT;
  uploadType: UploadType;
  timeoutMilliseconds: number;
};

export type AsyncWaitBlock = BaseBlock & {
  type: BlockType.ASYNC_WAIT;
  timeoutMilliseconds: number;
  timeoutScreen: string;
  timeoutMessage?: string;
};

export type ModalBlock = BaseBlock & {
  type: BlockType.MODAL_BLOCK;
  contents: Block[];
  dismissModalText: string;
  dismissModalTextVariant: ButtonVariant;
};

export type ExitBlock = BaseBlock & {
  type: BlockType.EXIT_BLOCK;
  hostBankName: string;
  renderCloseConfirmationModal: boolean;
  includeTopPadding?: boolean;
};

export type CheckmarkItem = {
  label: React.ReactNode | string;
  subLabel?: string;
  iconType?: IconId;
  flexDir?: "row" | "column";
};

export type CheckmarkItemBlock = BaseBlock & {
  type: BlockType.CHECKMARK_LIST_ITEM;
  label: string;
  subLabel: string;
};

export type CheckmarkListBlock = BaseBlock & {
  type: BlockType.CHECKMARK_LIST_BLOCK;
  items: CheckmarkItem[];
  offSeasonScreen?: boolean;
  flexDir?: "row" | "column";
  variant?: "icon" | "bullet";
};

export type BankingInformationBlock = BaseBlock & {
  type: BlockType.BANKING_INFORMATION;
  routingNumber: string;
  accountNumber: string;
  accountType: string;
  editScreenId?: ScreenId;
};

export type SubmissionStatusSummaryBlock = BaseBlock & {
  type: BlockType.SUBMISSION_STATUS_SUMMARY;
  title: string;
  submissionState: SubmissionState;
  refundOrAmountOwed: number;
  refundOrAmountOwedLabel: string;
  refundOrPaymentMethod: string | null;
  refundOrPaymentMethodLabel: string | null;
  moneyMoveDate: string;
  moneyMoveDateLabelEnum: MoneyMoveDateLabel;
  datetime: string | null;
  datetimeLabel: string | null;
  estProcessingTime: string | null;
  failureTitles: string[] | null;
  refundUrl?: string;
  paymentUrl?: string;
  submissionId?: string;
};

export type PriorYearReturnSummaryBlock = BaseBlock & {
  type: BlockType.PRIOR_YEAR_RETURN_SUMMARY;
  title: string;
  submissionStatus: string;
  refundOrAmountOwed: number;
  refundOrAmountOwedLabel: string;
  datetime: string | null;
  datetimeLabel: string | null;
  adjustedGrossIncome: number;
};

export type CustomerSupportBlock = BaseBlock & {
  type: BlockType.CUSTOMER_SUPPORT;
};

export type TableRow = {
  label: string;
  value: string | number;
};

export type TableRowWithLinks = {
  label: string;
  value: string | number | LinkBlock;
};

export type TableVariant = "simple" | "striped" | "unstyled";

export type TableBlock = BaseBlock & {
  type: BlockType.TABLE;
  header: string;
  rows: TableRow[];
  variant?: TableVariant;
  formatAsDollar: boolean;
  width?: ResponsiveValue<string>;
};

export type SectionTableBlock = BaseBlock & {
  type: BlockType.SECTION_TABLE;
  header: string;
  sections: TableRow[][];
};

export type SummaryTableBlock = BaseBlock & {
  type: BlockType.SUMMARY_TABLE;
  header: string;
  rows: TableRow[];
  screenId: ScreenId;
};

export type PromptUserRefileBlock = BaseBlock & {
  type: BlockType.PROMPT_USER_REFILE;
  screenId: ScreenId;
  hasErrors: boolean;
  isState: boolean;
  onResubmitPage: boolean;
  stateAbbreviation: string;
};

export type PyagiBlock = BaseBlock & {
  type: BlockType.PYAGI_BLOCK;
  tpPriorYearAgi: number;
  priorYear: number;
  screen: string;
};

export type ReviewAndFileBlock = NavigationChoiceBlockBase & {
  type: BlockType.REVIEW_AND_FILE;
  readyToRefile: boolean;
  totalFeesLabel: null | string;
  resubmissionLabel: null | string;
  alreadyPaidLabel: null | string;
  payingForExpertAssistOnly: boolean;
};

export type ScheduleCOverviewBlock = BaseBlock & {
  type: BlockType.SCHEDULE_C_OVERVIEW;
  businesses: {
    id: number;
    totalBusinessExpenses: number;
    totalNumBusinessExpenses: number;
    totalVehicleExpenses: number;
    totalVehicleMiles: number;
    totalVehicles: number;
    importedSourceLabel: string;
  }[];
  itemHeader: string;
};

export type W2OverviewBlock = BaseBlock & {
  type: BlockType.W2_OVERVIEW;
  w2s: {
    id: number;
    employerName: string;
    totalWages: number;
    totalIncomeTax: number;
    importedSourceLabel: string;
  }[];
  itemHeader: string;
};

export type PrefillNoticeItemBlockType = BaseBlock & {
  type: BlockType.PREFILL_NOTICE_ITEM;
  title: string;
  descriptionList: string[];
  source: Source;
  importedSourceLabel: string;
};

export type TextCompositionBlock = BaseBlock & {
  type: BlockType.TEXT_COMPOSITION_BLOCK;
  content: Array<LinkBlock | NewTextBlock | IconBlock>;
  justifyContent?: string;
};

export type MilestoneListBlock = BaseBlock & {
  type: BlockType.MILESTONE_LIST_BLOCK;
  steps?: Array<MilestoneStep>;
};

export type ProgressiveDisclosureBlock = BaseBlock & {
  type: BlockType.PROGRESSIVE_DISCLOSURE_BLOCK;
  fieldName: string;
  fieldValue: string | number | boolean;
  progressiveBlocks: Block[];
};

export type ModalStep = {
  blocks: Block[];
  onStepComplete?: () => Promise<boolean>;
  onClose?: () => void;
  buttonLabel: string;
  hideBackButton?: boolean;
};

export type NavigationModalBlock = BaseBlock & {
  type: BlockType.NAVIGATION_MODAL_BLOCK;
  steps: ModalStep[];
  isOpen: boolean;
  onClose: () => void;
  screen: Screen;
};

export type ExpertAssistLandingOptInBlock = BaseBlock & {
  type: BlockType.EXPERT_ASSIST_OPT_IN_BLOCK;
  screen: Screen;
  expertAssistFee: number;
  taxFilingFee: number;
  columnCollectsPayment: boolean;
  hostBankName: string;
};

export type Block =
  | AlertBlock
  | AsyncWaitBlock
  | BankingInformationBlock
  | BinaryAttachmentUploadBlock
  | CheckmarkItemBlock
  | CheckmarkListBlock
  | CheckoutBlock
  | CollapsibleSectionBlock
  | ContinueBlock
  | CreditSummaryEligibleBlock
  | CreditSummaryIneligibleBlock
  | CustomerSupportBlock
  | DesktopHandoffBlock
  | DesktopHandoffForPdfBlock
  | EstimatorBlock
  | ExitBlock
  | ExpandBlock
  | ExpertAssistLandingBlock
  | ExpertAssistEntrypointBlock
  | ExpertReviewBlock
  | ExpertTipBlock
  | FallbackBlock
  | Field
  | FixItBlock
  | GroupSeparatorBlock
  | HandoffIconBlock
  | HelpActionBlock
  | HelpContentBlock
  | IconBlock
  | ImageBlock
  | InputEditBlock
  | LabelField
  | LinkBlock
  | ListItemWithIconBlock
  | MilestoneListBlock
  | ModalBlock
  | NavigationChoiceBlock
  | NewTextBlock
  | NotApplicableBlock
  | PdfBlock
  | PromptUserRefileBlock
  | PyagiBlock
  | ProgressiveDisclosureBlock
  | ReturnSummaryBlock
  | ReturnToFilingBlock
  | ReviewAndFileBlock
  | OffSeasonInfoBlock
  | OffSeasonTaxSummaryBlock
  | PollAndLinkBlock
  | PrefillNoticeItemBlockType
  | PrefillUploadBlock
  | PrefillWaitBlock
  | PriorYearReturnSummaryBlock
  | SaveAsPdfBlock
  | ScheduleCOverviewBlock
  | ScrollAreaBlock
  | SectionTableBlock
  | SeparatorBlock
  | SplashScreenContinueBlock
  | SubmissionStatusSummaryBlock
  | SummaryAmountBlock
  | SummaryDetailBlock
  | SummaryHeaderBlock
  | SummaryTableBlock
  | TableBlock
  | TextCompositionBlock
  | W2OverviewBlock
  | UnorderedListBlock
  | CallbackFormBlock
  | ExpertAssistLandingOptInBlock
  | NavigationModalBlock;

// NOTE(nihar): could move this to the top of the file since it's referenced above
export enum BlockType {
  ALERT = "alert_block",
  ASYNC_WAIT = "async_wait_block",
  BANKING_INFORMATION = "banking_information_block",
  BINARY_ATTACHMENT_UPLOAD = "binary_attachment_upload_block",
  CALLBACK_FORM_BLOCK = "callback_form_block",
  CHECKMARK_LIST_BLOCK = "checkmark_list_block",
  CHECKMARK_LIST_ITEM = "checkmark_list_item_block",
  CHECKOUT_BLOCK = "checkout_block",
  COLLAPSIBLE_SECTION_BLOCK = "collapsible_section_block",
  CONTINUE_BLOCK = "continue_block",
  CREDIT_SUMMARY_ELIGIBLE_BLOCK = "credit_summary_eligible_block",
  CREDIT_SUMMARY_INELIGIBLE_BLOCK = "credit_summary_ineligible_block",
  CUSTOMER_SUPPORT = "customer_support_block",
  DESKTOP_HANDOFF = "desktop_handoff_block",
  DESKTOP_HANDOFF_FOR_PDF = "desktop_handoff_for_pdf_block",
  ESTIMATOR = "estimator_block",
  ESTIMATOR_WAIT = "estimator_wait_block",
  EXIT_BLOCK = "exit_block",
  EXPAND_BLOCK = "expand_block",
  EXPERT_ASSIST_LANDING_BLOCK = "expert_assist_landing_block",
  EXPERT_ASSIST_ENTRYPOINT_BLOCK = "expert_assist_entrypoint_block",
  EXPERT_ASSIST_OPT_IN_BLOCK = "expert_assist_opt_in_block",
  EXPERT_REVIEW_BLOCK = "expert_review_block",
  EXPERT_TIP_BLOCK = "expert_tip_block",
  FALLBACK = "fallback_block",
  FIELD = "field",
  FIX_IT = "fix_it_block",
  GROUP_SEPARATOR = "group_separator",
  HANDOFF_ICON = "handoff_icon_block",
  HELP_ACTION_BLOCK = "help_action_block",
  HELP_CONTENT_BLOCK = "help_content_block",
  ICON = "icon_block",
  IMAGE = "image_block",
  INPUT_BUTTON = "input_button_block",
  LABEL = "label",
  LINK = "link_block",
  LIST_ITEM_WITH_ICON_BLOCK = "list_item_with_icon_block",
  MILESTONE_LIST_BLOCK = "milestone_list_block",
  MODAL_BLOCK = "modal_block",
  NAVIGATION_MODAL_BLOCK = "navigation_modal_block",
  NAVIGATION_CHOICE = "navigation_choice",
  NEW_TEXT_BLOCK = "new_text_block",
  NOT_APPLICABLE = "not_applicable_block",
  OFF_SEASON_INFO_BLOCK = "off_season_info_block",
  OFF_SEASON_TAX_SUMMARY_BLOCK = "off_season_tax_summary_block",
  PDF = "pdf_block",
  POLL_AND_LINK = "poll_and_link_block",
  PREFILL_NOTICE_ITEM = "prefill_notice_item_block",
  PREFILL_UPLOAD = "prefill_upload_block",
  PREFILL_WAIT = "prefill_wait_block",
  PROGRESSIVE_DISCLOSURE_BLOCK = "progressive_disclosure_block",
  PROMPT_USER_REFILE = "prompt_user_refile_block",
  PRIOR_YEAR_RETURN_SUMMARY = "prior_year_return_summary_block",
  PYAGI_BLOCK = "pyagi_block",
  RETURN_SUMMARY = "return_summary_block",
  RETURN_TO_FILING_BLOCK = "return_to_filing_block",
  REVIEW_AND_FILE = "review_and_file_block",
  SAVE_AS_PDF = "save_as_pdf_block",
  SCHEDULE_C_OVERVIEW = "schedule_c_overview_block",
  SCROLL_AREA = "scroll_area_block",
  SECTION_TABLE = "section_table_block",
  SEPARATOR = "separator",
  SPLASH_SCREEN_CONTINUE_BLOCK = "splash_screen_continue_block",
  SUBMISSION_STATUS_SUMMARY = "submission_status_summary_block",
  SUMMARY_AMOUNT = "summary_amount_block",
  SUMMARY_DETAIL = "summary_detail_block",
  SUMMARY_HEADER = "summary_header_block",
  SUMMARY_TABLE = "summary_table_block",
  TABLE = "table_block",
  TEMPORARY_LINK_MODAL = "temporary_link_modal_block",
  TEXT_COMPOSITION_BLOCK = "text_composition_block",
  // STOPLAUNCH(marcia): Once the BE starts sending the
  // PREFILL_UPLOAD/PREFILL_WAIT values, then we can remove these enums
  W2_UPLOAD = "w2_upload_block",
  W2_WAIT = "w2_wait_block",
  W2_OVERVIEW = "w2_overview_block",
  UNORDERED_LIST = "unordered_list_block",
}

// These must match the field types sent down by the BE

export enum FieldType {
  BOOLEAN = "boolean",
  BUSINESS = "business",
  CHECKBOX = "checkbox",
  CURRENCY = "currency",
  DATE = "date",
  DECIMAL = "decimal",
  DEVICE_ID = "device_id",
  DROPDOWN = "dropdown",
  EIN = "ein",
  INTEGER = "integer",
  LABEL = "label",
  PHONE = "phone",
  SELECT = "select",
  SEPARATOR = "separator",
  SSN = "ssn",
  STRING = "string",
  TEXT = "text",
}

export enum FixItSeverity {
  ERROR = "error",
  WARNING = "warning",
}

// TODO(marcia): We have many more screens than this enumeration,
// and things still seem to work. Can we delete this then or
// otherwise update to be more accurate?
export enum ScreenId {
  ERROR = "ERROR",
  F1040_ADD_W2 = "ADD_W2_SCREEN",
  F1040_EDIT_W2 = "EDIT_W2_SCREEN",
  F1040_FILING_STATUS = "FILING_STATUS_SCREEN",
  F1040_MANAGE_W2 = "MANAGE_W2_SCREEN",
  F1040_SPOUSE_INFO = "SPOUSE_INFO",
  F1040_TAXPAYER_INFO = "TAXPAYER_INFO_SCREEN",
  F1040_W2 = "W2_SCREEN",
  HOME_SCREEN = "HOME_SCREEN",
  INITIAL_LOADING = "INITIAL_LOADING",
  INITIAL_SPLASH = "SPLASH_SCREEN",
  UNUSED = "UNUSED",
}

export enum ScreenType {
  MANAGE = "manage",
  NAVIGATION = "navigation",
  QUESTION = "question",
  UPLOAD = "upload",
  INTERLUDE = "interlude",
  AUTH = "auth",
}

// Note that these enums must match the serialized values of the
// DiyNavigation::ActionType enum
export enum ActionType {
  ADD = "add",
  ANSWER = "answer",
  CONTINUE = "continue",
  DELETE = "delete",
  EDIT = "edit",
  HELP = "help",
  NAVIGATE = "navigate",
  PREVIOUS = "previous",
  // STOPLAUNCH(marcia): Rename this to generic action type. We
  // will rely on the action's uploadType to tell us if we are
  // uploading a w2 or irs1040 document
  UPLOAD_W2 = "upload_w2",
}

export type HandleSubmitType = (
  action: Action,
  requestScreen?: Screen,
) => Promise<void>;

export enum IconId {
  ARCHIVE = "ARCHIVE",
  CALENDAR = "CALENDAR",
  CHECK = "CHECK",
  CREDIT_CARD = "CREDIT_CARD",
  COINS = "COINS",
  DISQUALIFYING = "DISQUALIFYING",
  DOLLAR_SIGN = "DOLLAR_SIGN",
  EA_EXPERT = "EA_EXPERT",
  EA_LIVE = "EA_LIVE",
  EA_REVIEW = "EA_REVIEW",
  EDIT = "EDIT",
  HOME = "HOME",
  LOCATION = "LOCATION",
  LOCK_ICON = "LOCK_ICON",
  MESSAGE_SQUARE = "MESSAGE_SQUARE",
  PERSON = "PERSON",
  PERSON_PLUS = "PERSON_PLUS",
  PHONE = "PHONE",
  REVIEW = "REVIEW",
  REVIEW_AND_FILE = "REVIEW_AND_FILE",
  SPLASH = "SPLASH",
  WARNING = "WARNING",
  EXIT_ARROW = "EXIT_ARROW",
  GET_STARTED = "GET_STARTED",
  TICK = "TICK",
}

export enum ImageId {
  ERROR_FILE = "ERROR_FILE",
  PY_AGI = "PY_AGI",
  PY_AGI_FROM_2022 = "PY_AGI_FROM_2022",
  REVIEW = "Review",
  SPARKLE_FILE = "SPARKLE_FILE",
  SUCCESS_CHECKMARK = "SUCCESS_CHECKMARK",
  MILESTONE_INITIAL = "MILESTONE_INITIAL",
  MILESTONE_BEFORE_INCOME = "MILESTONE_BEFORE_INCOME",
  MILESTONE_BEFORE_CREDITS = "MILESTONE_BEFORE_CREDITS",
  MILESTONE_BEFORE_STATE = "MILESTONE_BEFORE_STATE",
  MILESTONE_BEFORE_FILING = "MILESTONE_BEFORE_FILING",
  IMPORT_PRIOR_RETURN = "IMPORT_PRIOR_RETURN",
  PARTNER_PREFILL_NOTICE = "PARTNER_PREFILL_NOTICE",
  EXPERT_ASSIST_PROFILES = "EXPERT_ASSIST_PROFILES",
  EXPERT_ASSIST_HERO = "EXPERT_ASSIST_HERO",
  EXPERT_ASSIST_CONFIRM = "EXPERT_ASSIST_CONFIRM",
  EXPERT_ASSIST_HELP_CHART_89 = "EXPERT_ASSIST_HELP_CHART_89",
  EXPERT_ASSIST_HELP_CHART_129 = "EXPERT_ASSIST_HELP_CHART_129",
  EXPERT_ASSIST_SUCCESS = "EXPERT_ASSIST_SUCCESS",
  EXPERT_ASSIST_CALLBACK = "EXPERT_ASSIST_CALLBACK",
}

export enum PillStatus {
  START_HERE = "start here",
  DONE = "done",
}

export type ReactHookFormError =
  | {
      type: string;
      message?: string;
      types: MultipleFieldErrors;
    }
  | FieldError
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | Merge<FieldError, FieldErrorsImpl<any>>
  | undefined;

export type FormObject = {
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  control: Control;
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors: FieldErrors<FieldValues>;
};

export enum Events {
  APP_CLOSE = "App Close",
  BUTTON_CLICK = "Button Click",
  BUTTON_CLICK_COMPLETED = "button_click_completed",
  DRAKE_HANDOFF = "Drake Handoff",
  OPEN_HELP_CONTENT = "Open Help Content",
  REDIRECTION = "Redirection",
  ZENDESK_WIDGET_OPENED = "zendesk_widget_opened",
}

// these are used for tracking/analytics, so don't change without checking
export enum ButtonClickEventNames {
  UNTRACKED = "untracked",
  // callback modal
  CONFIRM_ZENDESK_CALLBACK_REQUEST = "confirm_zendesk_callback_request",
  EXPERT_ASSIST_CALL_NOW = "ExpertAssistCallNow",
  EXPERT_ASSIST_SCHEDULE = "ExpertAssistSchedule",
  EXPERT_ASSIST_CHAT = "ExpertAssistChat",
  EXPERT_ASSIST_LEARN_MORE = "ExpertAssistLearnMore", // When a user clicks the "Learn More" button on the Expert Assist modal
  EXPERT_ASSIST_JOIN = "ExpertAssistJoin", // When a user clicks the "Join" button on the Expert Assist modal
  EXPERT_ASSIST_CONFIRM_VIA_OPT_IN = "ExpertAssistConfirmViaOptIn",

  // nav header buttons
  NAV_BAR_QUESTION_MARK = "NavBarQuestionMark",
  NAV_HEADER_MORE_BUTTON = "nav_header_more_options_menu",
  NAV_HEADER_RETURN_TO_TAX_HOME = "nav_header_return_to_tax_home",
  NAV_HEADER_EDIT_QUESTIONNAIRE = "nav_header_edit_questionnaire",
  NAV_HEADER_EDIT_BANKING_INFO = "nav_header_edit_banking_info",
  NAV_HEADER_DELETE_CALIFORNIA_RETURN_OPEN_MODAL = "nav_header_delete_california_return_open_modal",
  NAV_HEADER_DELETE_CALIFORNIA_RETURN = "nav_header_delete_california_return",
  // mobile-desktop handoff button
  SEND_MOBILE_DESKTOP_HANDOFF_MAGIC_LINK = "send_mobile_desktop_handoff_magic_link",
  REQUEST_EXPERT_REVIEW = "request_expert_review",
  OPT_IN_TO_EXPERT_ASSIST = "opt_in_to_expert_assist",
  FORM_1040_UPLOAD_INITIATED = "form_1040_upload_initiated",
}

export enum OpenHelpTypes {
  BLOCK = "block",
  FIELD = "field",
  MANAGE_SCREEN = "manage-screen",
}

export enum InterludeIcons {
  HAND_WITH_PHONE = "hand_with_phone",
  WOMAN_WITH_LAPTOP = "woman_with_laptop",
  ANIMATED_CT_ICON = "animated_ct_icon",
}

export enum AlertTypes {
  // ERROR is non-dismissible and blocks user progress
  ERROR = "error",
  // WARNING is dismissible and only alerts the user to potentially fix an issue but allows them to proceed with filing
  WARNING = "warning",
  // INFO is just an informational box with no impact on user progress
  INFO = "info",
  // SUBTLE_INFO is a more subdued, subtle version of the above info
  SUBTLE_INFO = "subtle-info",
  // PREFILL is used to let people know about prefilled data
  PREFILL = "prefill",
  // HELP is used for help content
  HELP = "help",
}

export type UserDetails = {
  bankNavIcon: string;
  bankNavTitle: string;
  bankNavSubTitle: string;
  bankSlug: string | undefined;
  bankColor: string | undefined;
  userId: string;
  chatEnabled: boolean;
  email: string;
  name: string | undefined;
  talkEnabled: boolean;
  supportEnabled: boolean;
};

export type Resource = {
  uuid: string;
  parentUuid?: string;
};

export type Currency = {
  cents: number;
  currencyIso: string;
};

export type ResourceDetail = {
  label?: string;
  value: string | number | Currency;
  isCurrency?: boolean;
};

export enum CTA {
  // used for the primary action a user is taking, like submitting a form or proceeding to the next screen
  CONTINUE = "continue",
  // a convenience back button that takes the user to the previous screen (same action as the nav bar back button)
  BACK = "back",
  // used for a secondary forward action a user might take, like skipping ahead to the next screen
  SECONDARY = "secondary",
  // used for a link-type reminder button underneath the main CTA
  REMIND_LATER = "remind_later",
  // The Add button in Managed patterns
  MANAGE_ADD = "manage_add",
}

export type ReviewApps = {
  url: string;
  branch: string;
  title?: string;
  prNumber?: number;
  status: string;
  minutesSinceDeploy: number;
}[];

// Navigation menu (left sidebar)

export type MenuSubItem = {
  enabled: boolean;
  label: string;
  link?: ScreenId;
  resource: Resource;
  sectionName?: string;
  navStatus: NavMenuStatus;
};

export enum NavMenuStatus {
  HIDDEN = "hidden",
  DISABLED = "disabled",
  ENABLED = "enabled",
}

export type MenuItem = {
  icon: string;
  enabled: boolean;
  label: string;
  link: ScreenId;
  resource: Resource;
  subItems?: [MenuSubItem];
  spaced?: boolean;
  sectionName?: string;
  otherScreens?: string[];
  dataCypress?: string;
  isStateItem?: boolean;
};

export type NavObject = {
  title: string;
  navItems: [MenuItem];
  returnItems: [MenuItem];
  navigationMenuDisabledScreens: string[];
  navigationDisabled: boolean;
  subTitle?: string;
  icon?: string;
};

export type MilestoneStep = {
  complete: boolean;
  next: boolean;
  label: string;
  description: string;
};

export interface UserEvent {
  // Note that the enum of user events are currently defined on the
  // backend
  name: string;
  metadata: Record<string, unknown>;
}

export enum SubmissionState {
  NOT_REVIEWABLE = "not_reviewable",
  NOT_SUBMITTED = "not_submitted",
  IN_REVIEW = "in_review",
  PENDING_SUBMISSION = "pending_submission",
  IN_SUBMISSION = "in_submission",
  SUBMITTED = "submitted",
  EXCEPTION = "exception",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  RETRYABLE = "retryable",
  REJECTED_NON_RETRYABLE = "rejected_non_retryable",
  SUBMISSION_NOT_NEEDED = "submission_not_needed",
}

export enum MoneyMoveDateLabel {
  ESTIMATED_REFUND_DATE = "estimated_refund_date",
  PAYMENT_ON_OR_AFTER_DATE = "payment_on_or_after_date",
  PAYMENT_DUE = "payment_due",
}
