import React, { useState } from "react";

import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Button, Text } from "@chakra-ui/react";

interface ManageDeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  resourceName: string;
  onDelete: () => void;
}

const ManageDeleteModal = ({
  isOpen,
  onClose,
  resourceName,
  onDelete,
}: ManageDeleteModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    setIsLoading(true);
    onDelete();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="small">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pt={{ base: 14, md: 0 }} px={{ base: 6, md: 0 }}>
          <Heading size="lg">Delete {resourceName}?</Heading>
        </ModalHeader>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalBody px={{ base: 6, md: 0 }}>
          <Text>
            This will also delete any information you’ve added for this{" "}
            {resourceName}.
          </Text>
        </ModalBody>
        <ModalFooter px={{ base: 6, md: 0 }} pb={0}>
          <Button
            variant="error"
            width="full"
            px={4}
            py={6}
            onClick={handleDelete}
            isLoading={isLoading}
            data-manage-delete-confirm
            mb={{ base: 12, md: 0 }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ManageDeleteModal;
