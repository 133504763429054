import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Center,
  Circle,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

import TaxFilingContainer from "./diy/TaxFilingContainer";
import LoadingBlock from "./diy/components/LoadingBlock";
import ResponsiveContentWrapper from "./diy/components/ResponsiveContentWrapper";
import TickIconSVG from "./diy/icons/tick_icon.svg";
import { BASE_HEADERS } from "./utils/headers";
import { apiUrl } from "./utils/utils";

const MagicLinkLogin = () => {
  const [tokenIsExpired, setTokenIsExpired] = useState<boolean | null>(null);
  const [errorWithSendingEmail, setErrorWithSendingEmail] =
    useState<boolean>(false);
  const paramsRef = useRef<string>("");

  const [sentEmail, setSentEmail] = useState(false);

  const magicLinkAndRedirect = async () => {
    // Extract the 'params' URL parameter
    const url = new URL(window.location.href);
    paramsRef.current = url.searchParams.get("params") || "";

    // If there are no params, show token-expired state
    // TODO(marcia): How would a user get here? Would they edit the URL
    // to remove the params URL parameter on purpose? What should we show
    // in that case?
    if (!paramsRef.current) {
      setTokenIsExpired(true);
    }

    try {
      const response = await fetch(
        `${apiUrl()}/internal/diy/login_via_magic_link?params=${
          paramsRef.current
        }`,
        {
          headers: {
            ...BASE_HEADERS,
          },
          credentials: "include",
        },
      );

      const json = await response.json();

      // Check if response is ok and isAuthenticated is true
      if (response.ok && json.isAuthenticated) {
        setTokenIsExpired(false);

        // NOTE(nihar): I tried to use react's history hooks here, but the browser would hang
        window.location.href = "/tax-filing";
      } else {
        // If status is anything other than OK, assume unauthenticated
        setTokenIsExpired(true);
      }
    } catch {
      // If user is unauthenticated, show error and 'send new email' button
      setTokenIsExpired(true);
    }
  };

  useEffect(() => {
    magicLinkAndRedirect();
  }, []);

  const emailNewMagicLink = async () => {
    try {
      await fetch(
        `${apiUrl()}/internal/diy/send_email_with_renewed_magic_link`,
        {
          headers: {
            ...BASE_HEADERS,
          },
          method: "POST",
          body: JSON.stringify({
            expiredParams: paramsRef.current,
          }),
        },
      );
      setSentEmail(true);
    } catch {
      setErrorWithSendingEmail(true);
    }
  };

  let component = <LoadingBlock />;

  if (tokenIsExpired) {
    component = (
      <>
        <Heading data-cypress="title" m="0">
          Your link has expired
        </Heading>
        <Text textAlign="center">
          We’ll email you a new link so you
          <br /> can continue filing your taxes.
        </Text>
        {sentEmail ? (
          <Text color="success.medium">
            <Flex alignItems="center">
              <Center display="inline-block" verticalAlign="middle" mr="2">
                <Circle bg="success.medium" size="20px">
                  <Image src={TickIconSVG} width="14px" />
                </Circle>
              </Center>
              <Text> We’ve sent a new link to your email</Text>
            </Flex>
          </Text>
        ) : (
          <>
            <Button
              onClick={() => {
                setErrorWithSendingEmail(false);
                emailNewMagicLink();
              }}
            >
              Get A New Link
            </Button>
            {errorWithSendingEmail && (
              <Text textAlign="center">
                There was an unexpected error. Please try again.
              </Text>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <TaxFilingContainer screenId="magic-link-login">
      {/* TODO(marcia): Undo this following copy/paste from NavigationScreen and consider switching
  to some reusable card/screen container?*/}
      <Box w="full" flexDirection="column">
        <ResponsiveContentWrapper size="md">
          <Flex
            flexDirection="column"
            gridGap="30px"
            alignItems="center"
            height="100%"
            mt={tokenIsExpired ? "20%" : ""}
          >
            {/* TODO(marcia): Figure out more correct way of vertical alignment instead of conditionally
            applying a hard-coded 20% margin top. */}
            {component}
          </Flex>
        </ResponsiveContentWrapper>
      </Box>
    </TaxFilingContainer>
  );
};

export default MagicLinkLogin;
