import React from "react";

import { Box, Divider, HStack, Text, VStack } from "@chakra-ui/react";

import { dollarsToUsd } from "../helpers/CurrencyFormatter";

function SummaryDetailBlock({
  summaryText,
  amount,
  detailText,
  lineItems,
}: {
  summaryText: string;
  amount: number;
  detailText: string;
  lineItems: Array<{ text: string; amount: number }>;
}) {
  const formattedAmount = amount ? dollarsToUsd(amount) : dollarsToUsd(0);
  const Items = lineItems.map((lineItem, key) => {
    return (
      <Box
        key={key}
        mt="4"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <HStack spacing={3}>
          <VStack alignItems="left" spacing={0}>
            <Text alignItems="left" width="100%" fontSize="md">
              {lineItem.text}
            </Text>
          </VStack>
        </HStack>

        <HStack>
          <Text alignItems="left" width="100%" fontSize="md">
            {dollarsToUsd(lineItem.amount)}
          </Text>
        </HStack>
      </Box>
    );
  });

  return (
    <>
      <Box
        mt="4"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <HStack spacing={3}>
          <VStack alignItems="left" spacing={0}>
            <Text alignItems="left" width="100%" fontSize="xl">
              {summaryText}
            </Text>
          </VStack>
        </HStack>

        <HStack>
          <Text alignItems="left" width="100%" fontSize="xl">
            {formattedAmount}
          </Text>
        </HStack>
      </Box>
      <Box
        mt="4"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <HStack spacing={3}>
          <VStack alignItems="left" spacing={0}>
            <Text
              color="text.secondary"
              alignItems="left"
              width="100%"
              fontSize="md"
            >
              {detailText}
            </Text>
          </VStack>
        </HStack>
      </Box>
      {Items}
      <Divider color="border.medium" />
    </>
  );
}

export default SummaryDetailBlock;
