import React from "react";

import { Box } from "@chakra-ui/react";

// TODO(marcia): Figure out how to easily convert to css module
import "./AnimatedTypewriter.scss";

function AnimatedTypewriter() {
  return (
    // Scale down the whole typewriter from 120px to 100px in this
    // fashion instead of fiddling with each of the styles
    <Box style={{ transform: "scale(0.83)" }}>
      <div className="typewriter">
        <div className="slide">
          <i></i>
        </div>
        <div className="paper"></div>
        <div className="keyboard"></div>
      </div>
    </Box>
  );
}

export default AnimatedTypewriter;
