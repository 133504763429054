import React, { ReactNode } from "react";

import { Box } from "@chakra-ui/react";

interface ContentContainerWrapperProps {
  children: ReactNode;
  padding?: number;
}

const ContentContainerWrapper: React.FC<ContentContainerWrapperProps> = ({
  children,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    flex={{ base: 1, md: 0 }}
    p={{ base: 0, md: 12 }}
    pb={{ base: 0, md: 12 }}
    marginBottom={{ base: 0, md: 6 }}
    _notLast={{ marginTop: 6, marginBottom: 6 }}
    _first={{ marginTop: 0, marginBottom: 6 }}
    rounded={{ base: undefined, md: "card" }}
    boxShadow={{ base: undefined, md: "card" }}
    backgroundColor={{ base: undefined, md: "white" }}
  >
    {children}
  </Box>
);

export default ContentContainerWrapper;
