import React, { useContext, useEffect, useRef, useState } from "react";

import {
  Box,
  Circle,
  Flex,
  Heading,
  Image,
  Skeleton,
  Spinner,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";

import {
  Action,
  ActionType,
  MenuItem,
  MenuSubItem,
  NavMenuStatus,
  NavigationChoiceType,
  Resource,
  ResourceType,
  Screen,
  UserDetails,
} from "../types";

import {
  NavigationMenuContext,
  NavigationMenuState,
} from "../helpers/navigationMenuContext";
import NavSubmenuArrowClosed from "../icons/NavSubmenuArrowClosed";
import NavSubmenuArrowOpen from "../icons/NavSubmenuArrowOpen";
import DefaultBankLogo from "../icons/default_bank_logo.svg";
import { useNavManager } from "../navigationContext";
import CloseButton from "./CloseButton";
import { hostBankLogoFilenameToSrc } from "./HostBankLogo";
import ResourceIcon from "./ResourceIcon";

const LOGO_HEIGHT = 10;
const INNER_LOGO_HEIGHT = "21px";

const activeColor = "background.light";

type HandleSubmitType = (
  action: Action,
  requestScreen?: Screen,
) => Promise<void>;

type NavMenuProps = {
  userDetails: UserDetails;
  screen: Screen;
  onSubmit: HandleSubmitType;
};

type NavMenuSubItemProps = {
  subItem: MenuSubItem;
  screenId: string;
  screenSectionName?: string;
  // TODO(sean/roland): Reevaluate if these are the right parameters here if we add more items to the nav bar
  handleClick: (
    screenId: string,
    sectionName?: string,
    resource?: Resource,
  ) => void;
  navigationDisabled?: boolean;
  loadingId: string | undefined;
  navigationMenuState: NavigationMenuState;
};

type NavMenuItemProps = {
  item: MenuItem;
  screenId: string;
  screenSectionName?: string;
  // TODO(sean/roland): Reevaluate if these are the right parameters here if we add more items to the nav bar
  handleClick: (
    screenId: string,
    sectionName?: string,
    resource?: Resource,
  ) => void;
  navigationDisabled?: boolean;
  loadingId?: string | undefined;
  isStateItem?: boolean;
  navigationMenuState: NavigationMenuState;
};

const Loader = () => (
  <Box w={6} h={6} display="flex" justifyContent="center" alignItems="center">
    <Spinner size="xs" />
  </Box>
);

const NavMenuSubItem = ({
  subItem,
  screenId,
  screenSectionName,
  handleClick,
  navigationDisabled,
  loadingId,
}: NavMenuSubItemProps) => {
  const active =
    subItem.link === screenId || subItem.sectionName == screenSectionName;
  const enabled = subItem.navStatus === NavMenuStatus.ENABLED || active;
  const clickable = enabled && !navigationDisabled && !active && handleClick;
  const loading = loadingId && subItem.link === loadingId;

  if (subItem.navStatus === NavMenuStatus.HIDDEN && !active) {
    return null;
  }

  const link = subItem.link;

  return (
    <Box
      pl={loading ? 6 : 12}
      py="3"
      pr="6"
      display="flex"
      flexDirection="row"
      alignItems="center"
      cursor={clickable ? "pointer" : "initial"}
      opacity={enabled ? (clickable || active ? 1 : 0.7) : 0.5}
      borderLeft="solid 4px"
      borderColor={active ? "brand.medium" : "transparent"}
      backgroundColor={active ? activeColor : undefined}
      _hover={{
        backgroundColor: clickable ? activeColor : undefined,
      }}
      onClick={
        clickable && link
          ? () => handleClick(link, subItem.sectionName, subItem.resource)
          : undefined
      }
    >
      {loading && <Loader />}
      <Box flexDirection="row" alignItems="center" ml={2}>
        {subItem.label}
      </Box>
    </Box>
  );
};

const NavMenuItem = ({
  item,
  screenId,
  screenSectionName,
  handleClick,
  navigationDisabled,
  loadingId,
  navigationMenuState,
}: NavMenuItemProps) => {
  if (!item) return null;

  const otherScreens = item.otherScreens;
  const subItems = item.subItems;
  const hasMatchingSubsItems =
    subItems &&
    subItems.some(
      (e: MenuSubItem) =>
        e.link == screenId || e.sectionName == screenSectionName,
    );

  const hasMatchingScreens =
    otherScreens && otherScreens.some((e: string) => e === screenId);

  const active =
    (item.link && item.link === screenId) ||
    (!hasMatchingSubsItems && hasMatchingScreens) ||
    (item.sectionName == screenSectionName && !hasMatchingSubsItems) ||
    (item.isStateItem && screenSectionName === "state");

  const enabled = item.enabled;
  const clickable = enabled && !navigationDisabled && !active && handleClick;
  const loading = loadingId && item.link && item.link === loadingId;

  return (
    <>
      <Box
        display="flex"
        mt={item.spaced ? 4 : 0}
        pl={5}
        py={3}
        borderLeft="solid 4px"
        backgroundColor={active ? activeColor : undefined}
        _hover={{
          backgroundColor: enabled && !active ? activeColor : undefined,
        }}
        borderColor={active ? "brand.medium" : "transparent"}
        onClick={
          clickable
            ? () => handleClick(item.link, item.sectionName, item.resource)
            : undefined
        }
        cursor={clickable ? "pointer" : "initial"}
        opacity={enabled ? (clickable || active ? 1 : 0.7) : 0.5}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box>
            {loading ? (
              <Loader />
            ) : enabled ? (
              <ResourceIcon iconId={item.icon} isEnabled={enabled} />
            ) : (
              <ResourceIcon iconId={"LOCK_ICON"} />
            )}
          </Box>
          <Flex flexDirection="row" alignItems="center" ml={3}>
            <Text size="md" fontWeight="semibold">
              {item.label}
            </Text>
            <Box ml={3}>
              {item.subItems &&
                ((enabled && active) ||
                item.subItems.some(
                  (e: MenuSubItem) =>
                    e.link === screenId || e.sectionName == screenSectionName,
                ) ? (
                  <NavSubmenuArrowOpen />
                ) : (
                  <NavSubmenuArrowClosed />
                ))}
            </Box>
          </Flex>
        </Box>
      </Box>
      {item.enabled &&
        item.subItems &&
        (active ||
          item.subItems.some(
            // This is a bit confusing... there's reloading the whole nav object
            // BUT it's separate from actually clicking on a nav menu item and
            // loading into a new screen
            (e: MenuSubItem) =>
              e.link == screenId || e.sectionName == screenSectionName,
          )) &&
        item.subItems.map((subItem: MenuSubItem, subIndex: number) => (
          <NavMenuSubItem
            key={subIndex}
            subItem={subItem}
            screenId={screenId}
            screenSectionName={screenSectionName}
            handleClick={handleClick}
            navigationDisabled={navigationDisabled}
            loadingId={loadingId}
            navigationMenuState={navigationMenuState}
          />
        ))}
    </>
  );
};

const NavMenu: React.FC<NavMenuProps> = (props: NavMenuProps) => {
  // navMenu is the left hand nav
  const { nav, navigationMenuState, navMenuVisible, setNavMenuVisible } =
    useContext(NavigationMenuContext);
  const { screen, onSubmit, userDetails } = props;
  const hostBankLogoFilename = userDetails.bankNavIcon;
  const [imageLoadError, setImageLoadError] = useState(false);
  const [loadingId, setLoadingId] = useState<string | undefined>(undefined);
  const navMenuRef = useRef<HTMLDivElement | null>(null); // Ref to detect visibility

  // Get the "navmenu" breakpoint from the Chakra theme
  const theme = useTheme();
  const navMenuBreakpoint = theme.breakpoints.navmenu;

  const getNavMenuBreakpointVisibility = () => {
    const mediaQuery = window.matchMedia(`(min-width: ${navMenuBreakpoint})`);
    return mediaQuery.matches;
  };

  // Update global state directly
  useEffect(() => {
    const handleResize = () => {
      const visibility = getNavMenuBreakpointVisibility();
      if (navMenuVisible !== visibility) {
        setNavMenuVisible(visibility);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, [navMenuVisible, setNavMenuVisible, navMenuBreakpoint]);

  const { navigationDisabled, disableNavigation, enableNavigation } =
    useNavManager();

  const handleClick = (
    screenId: string,
    sectionName?: string,
    resource?: Resource,
  ) => {
    disableNavigation();
    setLoadingId(screenId);
    onSubmit({
      navigationChoice: sectionName,
      navigationChoiceType: NavigationChoiceType.Section,
      type: ActionType.NAVIGATE,
      resource,
      resourceType: ResourceType.Target,
    });
  };

  useEffect(() => {
    setLoadingId(undefined);
    enableNavigation();
  }, [screen]);

  // TODO(marcia): Rename this to showNav, since we are re-purposing the same flag.
  if (screen.showMobileNavMenu === false) {
    return null;
  }

  if (!nav) {
    return (
      <Box
        ref={navMenuRef}
        backgroundColor="background.dark"
        h="100vh"
        maxH="100vh"
        minW="280px"
        zIndex={2}
        padding={4}
        display={navMenuVisible ? "block" : "none"}
      >
        <Stack>
          <Box
            h="auto"
            pt={8}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            px={6}
            flexShrink={0}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box h={LOGO_HEIGHT} w={LOGO_HEIGHT}>
                {hostBankLogoFilename && !imageLoadError ? (
                  <Circle
                    size={LOGO_HEIGHT}
                    borderWidth={1}
                    borderColor="border.medium"
                    borderStyle="solid"
                  >
                    <Image
                      onError={() => setImageLoadError(true)}
                      src={hostBankLogoFilenameToSrc(hostBankLogoFilename)}
                      boxSize={
                        screen.fullSizeLogo ? undefined : INNER_LOGO_HEIGHT
                      }
                      htmlHeight={
                        screen.fullSizeLogo ? undefined : INNER_LOGO_HEIGHT
                      }
                    />
                  </Circle>
                ) : (
                  <Image
                    src={DefaultBankLogo}
                    boxSize={LOGO_HEIGHT}
                    htmlHeight={LOGO_HEIGHT}
                  />
                )}
              </Box>
              <Box ml={4}>
                <Text size="md" fontWeight="bold">
                  {userDetails.bankNavTitle}
                </Text>
                <Text size="xs">
                  Powered by <b>Column Tax</b>
                </Text>
              </Box>
            </Box>
            <Box
              pt={12}
              pb={2}
              borderBottom="1px dashed"
              borderBottomColor="border.medium"
              mb={4}
            >
              <Heading size="caption">{userDetails.bankNavSubTitle}</Heading>
            </Box>
            <Skeleton height="30px" mb="4" />
            <Skeleton height="30px" mb="4" />
            <Skeleton height="30px" mb="4" />
            <Skeleton height="30px" mb="4" />
            <Skeleton height="30px" mb="4" />
          </Box>
        </Stack>
      </Box>
    );
  }

  const showNavItems =
    !nav.navigationMenuDisabledScreens?.includes(screen.id) &&
    !nav.navigationDisabled;

  return (
    <Box
      ref={navMenuRef}
      backgroundColor="background.dark"
      h="100vh"
      maxH="100vh"
      minWidth="300px"
      maxWidth="300px"
      zIndex={2}
      display={navMenuVisible ? "block" : "none"}
    >
      <Box
        display="flex"
        flexDirection="column"
        h="100vh"
        maxH="100vh"
        justifyContent={showNavItems ? "space-between" : ""}
      >
        <Box
          h="auto"
          pt={8}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          px={6}
          flexShrink={0}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box h={LOGO_HEIGHT} w={LOGO_HEIGHT}>
              {hostBankLogoFilename && !imageLoadError ? (
                <Circle
                  size={LOGO_HEIGHT}
                  borderWidth={1}
                  borderColor="border.medium"
                  borderStyle="solid"
                >
                  <Image
                    onError={() => setImageLoadError(true)}
                    src={hostBankLogoFilenameToSrc(hostBankLogoFilename)}
                    boxSize={
                      screen.fullSizeLogo ? undefined : INNER_LOGO_HEIGHT
                    }
                    htmlHeight={
                      screen.fullSizeLogo ? undefined : INNER_LOGO_HEIGHT
                    }
                  />
                </Circle>
              ) : (
                <Image
                  src={DefaultBankLogo}
                  boxSize={LOGO_HEIGHT}
                  htmlHeight={LOGO_HEIGHT}
                />
              )}
            </Box>
            <Box ml={4}>
              <Text size="md" fontWeight="bold">
                {userDetails.bankNavTitle}
              </Text>
              <Text size="xs">
                Powered by <b>Column Tax</b>
              </Text>
            </Box>
          </Box>
          <Box
            pt={showNavItems ? 12 : 6}
            pb={showNavItems ? 2 : 0}
            borderBottom="1px dashed"
            borderBottomColor="border.medium"
          >
            {showNavItems && (
              <Heading size="caption">{userDetails.bankNavSubTitle}</Heading>
            )}
          </Box>
        </Box>
        {showNavItems && (
          <Box
            display="flex"
            flexDirection="column"
            mt={4}
            overflowY="auto"
            height="full"
          >
            {nav.navItems.map((item: MenuItem, index: number) => (
              <NavMenuItem
                key={"menuItem" + index}
                item={item}
                screenId={screen.id}
                screenSectionName={screen.sectionName}
                handleClick={handleClick}
                navigationDisabled={navigationDisabled}
                loadingId={loadingId}
                navigationMenuState={navigationMenuState}
              />
            ))}
          </Box>
        )}
        <Box
          h="auto"
          mx={0}
          pt={0}
          pb={8}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flexShrink={0}
        >
          <Box
            display="flex"
            marginTop={0}
            mx={6}
            px={6}
            paddingTop={4}
            height={0}
            borderTop={showNavItems ? "1px dashed" : "none"}
            borderTopColor="border.medium"
          />
          <Box
            display="flex"
            flexDirection="column"
            mt={showNavItems ? 4 : 0}
            overflowY="auto"
            height="full"
          >
            {nav.returnItems.map((item: MenuItem, index: number) => (
              <NavMenuItem
                key={"menuItem" + index}
                item={item}
                screenId={screen.id}
                screenSectionName={screen.sectionName}
                handleClick={handleClick}
                navigationDisabled={navigationDisabled}
                loadingId={loadingId}
                navigationMenuState={navigationMenuState}
              />
            ))}
          </Box>
          <CloseButton
            small
            renderCloseConfirmationModal={true}
            data-testid="close-button-diy"
            component={
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                cursor="pointer"
                py={3}
                px={6}
                mx={0}
                _hover={{
                  backgroundColor: activeColor,
                }}
              >
                {/* Rotates the icon 180degrees since we need an arrow left Exit icon,
                  and only an arrow right is available in Feather */}
                <Box transform="rotate(180deg)" color="primary" p={"2px"}>
                  <ResourceIcon iconId={"EXIT_ARROW"} />
                </Box>
                <Flex flexDirection="row" alignItems="center" ml={3}>
                  <Text size="md" fontWeight="semibold">
                    Back to {userDetails.bankNavTitle}
                  </Text>
                </Flex>
              </Box>
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NavMenu;
