import React from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";

import { Box, FormControl, Input } from "@chakra-ui/react";

import { ReactHookFormError, StringField } from "../types";

import { ErrorMessages, isInvalid, resolveVariant } from "../helpers/Errors";
import InputFieldLabel from "./InputFieldLabel";

export interface StringFieldProps {
  field: StringField;
  register: UseFormRegister<FieldValues>;
  error: ReactHookFormError;
  isLoading: boolean;
}
const StringFieldInput: React.FC<StringFieldProps> = ({
  field,
  register,
  error,
  isLoading,
}) => {
  let patternProps = {};

  let re: RegExp;
  if (field.inputRegex) {
    re = new RegExp(field.inputRegex);
  }

  // If the string is numeric, then only allow digits
  // We do not switch to type="number" because that ignores
  // the maxLength value
  // see: https://stackoverflow.com/questions/18510845/maxlength-ignored-for-input-type-number-in-chrome
  if (field.numeric) {
    patternProps = { pattern: "\\d*" };
    if (!field.inputRegex) {
      re = new RegExp("^\\d*$");
    }
  }
  return (
    <Box>
      <FormControl isInvalid={isInvalid(error)} isDisabled={isLoading}>
        <InputFieldLabel field={field} />
        <Input
          variant={resolveVariant(error)}
          placeholder={field.placeholder}
          maxLength={field.maxLength}
          isDisabled={field.disabled}
          onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
            // Check to see if the input character matches the regex
            // provided by the backend.
            if (re && e.data && !re.test(e.data)) {
              e.preventDefault();
            }
          }}
          {...patternProps}
          {...register(field.id)}
        />

        <ErrorMessages error={error} />
      </FormControl>
    </Box>
  );
};

export default StringFieldInput;
