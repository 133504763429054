import { ReactNode } from "react";

import { Box } from "@chakra-ui/react";

interface Props {
  children: ReactNode;
  backgroundColor?: string;
}

const Card = ({ children, backgroundColor = "white" }: Props) => (
  <Box display="flex" flexDirection="column" justifyContent="space-between">
    <Box rounded="card" boxShadow="card" backgroundColor={backgroundColor}>
      {children}
    </Box>
  </Box>
);

export default Card;
