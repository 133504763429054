import React from "react";

import { Block, BlockType, FormObject, Screen, UploadType } from "../types";

import HandoffIconBlock from "../components/HandoffIconBlock";
import IconBlock from "../components/IconBlock";
import ImageBlock from "../components/ImageBlock";
import NavigationChoice from "../components/NavigationChoice";
import PdfBlock from "../components/PdfBlock";
import PyagiBlock from "../components/PyagiBlock";
import SeparatorBlock from "../components/SeparatorBlock";
import SummaryAmountBlock from "../components/SummaryAmountBlock";
import SummaryDetailBlock from "../components/SummaryDetailBlock";
import SummaryHeaderBlock from "../components/SummaryHeaderBlock";

import rollbar from "../../rollbar-utils";
import AlertBlock from "./AlertBlock";
import BankingInformationBlock from "./BankingInformationBlock";
import CheckmarkList from "./CheckmarkList";
import CheckmarkListItemBlock from "./CheckmarkListItemBlock";
import CheckoutBlock from "./CheckoutBlock";
import CollapsibleSectionBlock from "./CollapsibleSectionBlock";
import ContinueButtonBlock from "./ContinueButtonBlock";
import CreditSummaryEligibleBlock from "./CreditSummaryEligibleBlock";
import CreditSummaryIneligibleBlock from "./CreditSummaryIneligibleBlock";
import CustomerSupportBlock from "./CustomerSupportBlock";
import DesktopHandoffBlock from "./DesktopHandoffBlock";
import DesktopHandoffForPdfBlock from "./DesktopHandoffForPdfBlock";
import EstimatorBlock from "./EstimatorBlock";
import ExitBlock from "./ExitBlock";
import ExpandBlock from "./ExpandBlock";
import ExpertAssistEntrypointBlock from "./ExpertAssistEntrypointBlock";
import ExpertAssistLandingBlock from "./ExpertAssistLandingBlock";
import ExpertReviewBlock from "./ExpertReviewBlock";
import ExpertTipBlock from "./ExpertTipBlock";
import FallbackBlock from "./FallbackBlock";
import FieldRenderer from "./FieldRenderer";
import FixIt from "./FixIt";
import GroupSeparatorBlock from "./GroupSeparatorBlock";
import HelpContentBlock from "./HelpContentBlock";
import InputButtonBlock from "./InputButtonBlock";
import LinkBlock from "./LinkBlock";
import MilestoneListBlock from "./MilestoneListBlock";
import NewTextBlock from "./NewTextBlock";
import NotApplicableBlock from "./NotApplicableBlock";
import OffSeasonInfoBlock from "./OffSeasonInfoBlock";
import OffSeasonTaxSummaryBlock from "./OffSeasonTaxSummaryBlock";
import PollAndLinkBlock from "./PollAndLinkBlock";
import PrefillNoticeItemBlock from "./PrefillNoticeItemBlock";
import PriorYearReturnSummaryBlock from "./PriorYearReturnSummaryBlock";
import ProgressiveDisclosureBlock from "./ProgressiveDisclosureBlock";
import PromptUserRefile from "./PromptUserRefile";
import ReturnSummaryBlock from "./ReturnSummaryBlock";
import ReturnToFilingBlock from "./ReturnToFilingBlock";
import ReviewAndFile from "./ReviewAndFile";
import SaveAsPdfBlock from "./SaveAsPdfBlock";
import ScheduleCOverviewBlock from "./ScheduleCOverviewBlock";
import ScrollAreaBlock from "./ScrollAreaBlock";
import SectionTableBlock from "./SectionTableBlock";
import SplashScreenContinueButtonBlock from "./SplashScreenContinueButtonBlock";
import SubmissionStatusSummaryBlock from "./SubmissionStatusSummaryBlock";
import SummaryTableBlock from "./SummaryTableBlock";
import TableBlock from "./TableBlock";
import TextCompositionBlock from "./TextCompositionBlock";
import UnorderedListBlock from "./UnorderedListBlock";
import W2OverviewBlock from "./W2OverviewBlock";
import AsyncWaitBlock from "./blocks/AsyncWaitBlock";
import BinaryAttachmentUploadBlock from "./blocks/BinaryAttachmentUploadBlock";
import CallbackFormBlock from "./blocks/CallbackFormBlock";
import ExpertAssistLandingOptInBlock from "./blocks/ExpertAssistOptInBlock";
import ModalBlock from "./blocks/ModalBlock";
import NavigationModalBlock from "./blocks/NavigationModalBlock";
import PrefillUploadBlock from "./blocks/PrefillUploadBlock";
import PrefillWaitBlock from "./blocks/PrefillWaitBlock";

interface BlockRendererProps {
  // TODO(marcia): Fix types... depending on the context -- navigation or
  // question screen, this onSubmit has a different interface.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit?: any;
  onBack?: () => Promise<void>;
  screen: Screen;
  blocks: Block[];
  formObject?: FormObject;
  navigationDisabled?: boolean;
  navigationDisableSetter?: (state: boolean) => void;
  setScreen?: (screen: Screen) => void;
  isLoading?: boolean;
  onFileUpload?: ({
    file,
    uploadType,
  }: {
    file: File;
    uploadType: UploadType;
  }) => void;
}

const BlockRenderer: React.FC<BlockRendererProps> = ({
  onSubmit,
  onBack,
  blocks,
  screen,
  formObject,
  navigationDisabled,
  navigationDisableSetter,
  setScreen,
  isLoading = false,
  onFileUpload,
}) => {
  const Blocks = blocks.map((block) => {
    switch (block.type) {
      case BlockType.ALERT:
        return (
          <AlertBlock
            key={block.id}
            title={block.title}
            text={block.text}
            status={block.status}
          />
        );
      case BlockType.NEW_TEXT_BLOCK:
        return <NewTextBlock key={block.id} {...block} />;
      case BlockType.TEXT_COMPOSITION_BLOCK:
        return (
          <TextCompositionBlock
            key={block.id}
            content={block.content}
            justifyContent={block.justifyContent}
          />
        );
      case BlockType.SCHEDULE_C_OVERVIEW:
        return <ScheduleCOverviewBlock {...block} key={block.id} />;
      case BlockType.W2_OVERVIEW:
        return <W2OverviewBlock {...block} key={block.id} />;
      case BlockType.PREFILL_NOTICE_ITEM:
        return <PrefillNoticeItemBlock key={block.id} {...block} />;
      case BlockType.PROMPT_USER_REFILE:
        return (
          <PromptUserRefile
            key={block.id}
            hasErrors={block.hasErrors}
            onClick={() => onSubmit(block.screenId)}
            isState={block.isState}
            onResubmitPage={block.onResubmitPage}
            stateAbbreviation={block.stateAbbreviation}
          />
        );
      case BlockType.NAVIGATION_CHOICE:
        return (
          <NavigationChoice
            key={block.id}
            choice={block}
            onClick={() => onSubmit(block.screenId, block.resource)}
            navigationDisabled={navigationDisabled}
            navigationDisableSetter={navigationDisableSetter}
          />
        );
      case BlockType.REVIEW_AND_FILE:
        return (
          <ReviewAndFile
            key={block.id}
            choice={block}
            onClick={() =>
              onSubmit(
                block.sectionName ? "" : block.screenId,
                undefined,
                block.sectionName,
              )
            }
            navigationDisabled={navigationDisabled}
            navigationDisableSetter={navigationDisableSetter}
            totalFeesLabel={block.totalFeesLabel}
            resubmissionLabel={block.resubmissionLabel}
            alreadyPaidLabel={block.alreadyPaidLabel}
            payingForExpertAssistOnly={block.payingForExpertAssistOnly}
          />
        );
      case BlockType.EXPERT_TIP_BLOCK:
        return (
          <ExpertTipBlock
            key={block.id}
            caption={block.caption}
            content={block.content}
          />
        );
      case BlockType.EXPERT_ASSIST_LANDING_BLOCK:
        return (
          <ExpertAssistLandingBlock
            screen={screen}
            screenId={screen.id}
            name={block.name}
            phone={block.phone}
          />
        );
      case BlockType.EXPERT_ASSIST_ENTRYPOINT_BLOCK:
        return (
          <ExpertAssistEntrypointBlock
            onSubmit={onSubmit}
            screenId={screen.id}
            description={block.description}
            buttonLabel={block.buttonLabel}
            expertAssistScreenId={block.expertAssistScreenId}
          />
        );
      case BlockType.EXPERT_ASSIST_OPT_IN_BLOCK:
        return (
          <ExpertAssistLandingOptInBlock
            id={block.id}
            type={block.type}
            key={block.id}
            expertAssistFee={block.expertAssistFee}
            taxFilingFee={block.taxFilingFee}
            columnCollectsPayment={block.columnCollectsPayment}
            hostBankName={block.hostBankName}
            screen={screen}
          />
        );
      case BlockType.EXPERT_REVIEW_BLOCK:
        return (
          <ExpertReviewBlock
            screenId={screen.id}
            description={block.description}
            buttonLabel={block.buttonLabel}
            userEmail={screen.userEmail}
          />
        );
      case BlockType.RETURN_TO_FILING_BLOCK:
        return (
          <ReturnToFilingBlock
            key={block.id}
            blockTitle={block.blockTitle}
            continueFilingButton={block.continueFilingButton}
            onSubmit={onSubmit}
          />
        );

      case BlockType.OFF_SEASON_INFO_BLOCK:
        return (
          <OffSeasonInfoBlock
            key={block.id}
            blockTitle={block.blockTitle}
            caption={block.caption}
            filingOpenDate={block.filingOpenDate}
            checkmarkListItems={block.checkmarkListItems}
          />
        );

      case BlockType.OFF_SEASON_TAX_SUMMARY_BLOCK:
        return (
          <OffSeasonTaxSummaryBlock
            key={block.id}
            blockTitle={block.blockTitle}
            summary={block.summary}
            onSubmit={onSubmit}
          />
        );

      case BlockType.ICON:
        return <IconBlock key={block.id} iconId={block.iconId} />;
      case BlockType.UNORDERED_LIST:
        return <UnorderedListBlock key={block.id} content={block.content} />;

      case BlockType.INPUT_BUTTON:
        return (
          <InputButtonBlock
            text={block.text}
            label={block.label}
            key={block.id}
            onClick={() => onSubmit(block.screenId)}
          />
        );

      case BlockType.HANDOFF_ICON:
        return (
          <HandoffIconBlock
            key={block.id}
            hostBankLogoFilename={block.hostBankLogoFilename}
            hostBankName={!screen.title ? screen.hostBankName : undefined}
            fullSizeLogo={screen.fullSizeLogo || false}
          />
        );

      case BlockType.IMAGE:
        return <ImageBlock key={block.id} imageId={block.imageId} />;

      case BlockType.NOT_APPLICABLE:
        return (
          <NotApplicableBlock
            key={block.id}
            label={block.label}
            subLabel={block.subLabel}
          />
        );

      case BlockType.LINK:
        return (
          <LinkBlock
            key={block.id}
            text={block.text}
            url={block.url}
            isPrintLink={block.isPrintLink}
          />
        );

      case BlockType.TABLE:
        return (
          <TableBlock
            key={block.id}
            header={block.header}
            rows={block.rows}
            variant={block.variant}
            formatAsDollar={block.formatAsDollar}
            width={block.width}
          />
        );

      case BlockType.SECTION_TABLE:
        return (
          <SectionTableBlock
            key={block.id}
            header={block.header}
            sections={block.sections}
          />
        );

      case BlockType.SCROLL_AREA:
        return (
          <ScrollAreaBlock
            key={block.id}
            onSubmit={onSubmit}
            contents={block.contents}
            screen={screen}
          />
        );

      case BlockType.SUMMARY_AMOUNT:
        return (
          <SummaryAmountBlock
            key={block.id}
            text={block.text}
            amount={block.amount}
          />
        );

      case BlockType.CREDIT_SUMMARY_ELIGIBLE_BLOCK:
        return (
          <CreditSummaryEligibleBlock
            key={block.id}
            screenId={screen.id}
            creditName={block.creditName}
            content={block.content}
            amountLabel={block.amountLabel}
            amount={block.amount}
            amounts={block.amounts}
            helpLabel={block.helpLabel}
            helpContentBlocks={block.helpContentBlocks}
          />
        );

      case BlockType.CREDIT_SUMMARY_INELIGIBLE_BLOCK:
        return (
          <CreditSummaryIneligibleBlock
            key={block.id}
            screenId={screen.id}
            content={block.content}
            helpLabel={block.helpLabel}
            helpContentBlocks={block.helpContentBlocks}
            ineligibleReasonsList={block.ineligibleReasonsList}
            ineligibleReasonsHeader={block.ineligibleReasonsHeader}
          />
        );

      case BlockType.SUMMARY_DETAIL:
        return (
          <SummaryDetailBlock
            key={block.id}
            summaryText={block.summaryText}
            amount={block.amount}
            detailText={block.detailText}
            lineItems={block.lineItems}
          />
        );

      case BlockType.SUMMARY_HEADER:
        return (
          <SummaryHeaderBlock
            key={block.id}
            amount={block.amount}
            federal={block.federal}
            state={block.state}
            stateCode={block.stateCode}
            showOverallAmount={block.showOverallAmount}
          ></SummaryHeaderBlock>
        );

      case BlockType.SUMMARY_TABLE:
        return (
          <SummaryTableBlock
            key={block.id}
            header={block.header}
            rows={block.rows}
            onClick={() => onSubmit(block.screenId)}
          />
        );

      case BlockType.RETURN_SUMMARY:
        return (
          <ReturnSummaryBlock
            key={block.id}
            totalIncome={block.totalIncome}
            adjustments={block.adjustments}
            deductions={block.deductions}
            taxableIncome={block.taxableIncome}
            effectiveTaxRate={block.effectiveTaxRate}
            totalTax={block.totalTax}
            otherTaxes={block.otherTaxes}
            credits={block.credits}
            payments={block.payments}
            netRefundAmount={block.netRefundAmount}
          />
        );

      case BlockType.SEPARATOR:
        return <SeparatorBlock key={block.id} />;

      case BlockType.GROUP_SEPARATOR:
        return <GroupSeparatorBlock key={block.id} label={block.label} />;

      case BlockType.PDF:
        return (
          <PdfBlock
            key={block.id}
            url={block.url}
            authenticate={block.authenticate}
          />
        );

      case BlockType.FIELD:
        return (
          <FieldRenderer
            key={block.id}
            field={block}
            formObject={formObject as FormObject}
            screenId={screen.id}
            isLoading={isLoading}
          />
        );

      case BlockType.FALLBACK:
        return (
          <FallbackBlock
            key={block.id}
            timeoutMilleseconds={block.timeoutMilleseconds}
            errorScreenId={block.errorScreenId}
            samlUrl={block.samlUrl}
            onSubmit={onSubmit}
            shouldIframe={block.shouldIframe}
          />
        );

      case BlockType.CUSTOMER_SUPPORT:
        return <CustomerSupportBlock key={block.id} screenId={screen.id} />;

      case BlockType.CONTINUE_BLOCK:
        return (
          <ContinueButtonBlock
            key={block.id}
            block={block}
            screen={screen}
            onSubmit={onSubmit}
            onBack={onBack}
            navigationDisabled={navigationDisabled}
            secondaryLinkBlock={block.secondaryLinkBlock}
          />
        );

      case BlockType.SPLASH_SCREEN_CONTINUE_BLOCK:
        return (
          <SplashScreenContinueButtonBlock
            key={block.id}
            block={block}
            screen={screen}
            onSubmit={onSubmit}
            navigationDisabled={navigationDisabled}
            userEmail={screen.userEmail}
          />
        );

      case BlockType.PROGRESSIVE_DISCLOSURE_BLOCK:
        return (
          <ProgressiveDisclosureBlock
            onSubmit={onSubmit}
            screen={screen}
            key={block.id}
            formObject={formObject}
            {...block}
          />
        );
      case BlockType.EXPAND_BLOCK:
        return (
          <ExpandBlock
            contents={block.contents}
            label={block.label}
            onSubmit={onSubmit}
            screen={screen}
            key={block.id}
          />
        );
      case BlockType.HELP_CONTENT_BLOCK:
        return (
          <HelpContentBlock
            screenId={screen.id}
            key={block.id}
            label={block.label}
            helpContentBlocks={block.helpContentBlocks || []}
          />
        );
      case BlockType.CHECKMARK_LIST_ITEM:
        return (
          <CheckmarkListItemBlock
            label={block.label}
            key={block.id}
            subLabel={block.subLabel}
          />
        );
      case BlockType.CHECKMARK_LIST_BLOCK: {
        return (
          <CheckmarkList
            key={block.id}
            flexDir={block.flexDir ?? undefined}
            variant={block.variant}
          >
            {block.items}
          </CheckmarkList>
        );
      }

      case BlockType.ESTIMATOR:
        return (
          <EstimatorBlock
            key={block.id}
            fedAmount={block.amount}
            fedStatus={block.status}
            stateAmount={block.stateAmount}
          />
        );
      case BlockType.EXIT_BLOCK:
        return (
          <ExitBlock
            key={block.id}
            hostBankName={block.hostBankName}
            renderCloseConfirmationModal={block.renderCloseConfirmationModal}
            includeTopPadding={block.includeTopPadding}
          />
        );
      case BlockType.POLL_AND_LINK:
        return <PollAndLinkBlock key={block.id} {...block} />;
      case BlockType.SAVE_AS_PDF:
        return <SaveAsPdfBlock key={block.id} {...block} />;
      case BlockType.BANKING_INFORMATION:
        return (
          <BankingInformationBlock
            key={block.id}
            routingNumber={block.routingNumber}
            accountNumber={block.accountNumber}
            accountType={block.accountType}
            editScreenId={block.editScreenId}
            onSubmit={onSubmit}
          />
        );
      case BlockType.SUBMISSION_STATUS_SUMMARY:
        return <SubmissionStatusSummaryBlock key={block.id} {...block} />;
      case BlockType.PRIOR_YEAR_RETURN_SUMMARY:
        return <PriorYearReturnSummaryBlock key={block.id} {...block} />;
      case BlockType.FIX_IT:
        return <FixIt key={block.id} block={block} onSubmit={onSubmit} />;
      case BlockType.DESKTOP_HANDOFF:
        return (
          <DesktopHandoffBlock
            key={block.id}
            screenId={screen.id}
            userEmail={screen.userEmail}
          />
        );
      case BlockType.DESKTOP_HANDOFF_FOR_PDF:
        return (
          <DesktopHandoffForPdfBlock
            key={block.id}
            screenId={screen.id}
            userEmail={screen.userEmail}
          />
        );
      case BlockType.CHECKOUT_BLOCK:
        return <CheckoutBlock key={block.id} checkoutUrl={block.checkoutUrl} />;
      case BlockType.BINARY_ATTACHMENT_UPLOAD:
        return (
          <BinaryAttachmentUploadBlock
            key={block.id}
            attachmentType={block.attachmentType}
          />
        );
      case BlockType.PREFILL_UPLOAD:
      case BlockType.W2_UPLOAD:
        // BlockRenderer is in a number of contexts (different screens and
        // different blocks such as ExpandBlock, ProgressiveDisclosureBlock,
        // etc). We expect only the QuestionScreen and NavigationScreen to
        // allow the taxpayer to upload a file -- so only those two in turn
        // will pass an onFileUpload prop callback.
        if (onFileUpload) {
          return (
            <PrefillUploadBlock
              key={block.id}
              onFileUpload={onFileUpload}
              setScreen={setScreen}
              label={block.label}
              uploadType={block.uploadType}
              optimisticPayloadOnUpload={block.optimisticPayloadOnUpload}
              screenId={screen.id}
            />
          );
        } else {
          return <></>;
        }
      case BlockType.PREFILL_WAIT:
      case BlockType.W2_WAIT:
        return (
          <PrefillWaitBlock
            key={block.id}
            uploadType={block.uploadType}
            screen={screen}
            setScreen={setScreen}
            onSubmit={onSubmit}
            timeoutMilliseconds={block.timeoutMilliseconds}
          />
        );
      case BlockType.ASYNC_WAIT:
        return (
          <AsyncWaitBlock
            key={block.id}
            screen={screen}
            setScreen={setScreen}
            label={block.label}
            timeoutScreen={block.timeoutScreen}
            timeoutMilliseconds={block.timeoutMilliseconds}
            timeoutMessage={block.timeoutMessage}
          />
        );
      case BlockType.MODAL_BLOCK:
        return (
          <ModalBlock
            key={block.id}
            screen={screen}
            onSubmit={onSubmit}
            contents={block.contents}
            dismissModalText={block.dismissModalText}
            dismissModalTextVariant={block.dismissModalTextVariant}
          />
        );
      case BlockType.PYAGI_BLOCK:
        return (
          <PyagiBlock
            key={block.id}
            tpPriorYearAgi={block.tpPriorYearAgi}
            priorYear={block.priorYear}
            onSubmit={onSubmit}
            screen={block.screen}
          />
        );
      case BlockType.MILESTONE_LIST_BLOCK:
        return <MilestoneListBlock key={block.id} steps={block.steps} />;

      case BlockType.COLLAPSIBLE_SECTION_BLOCK:
        return <CollapsibleSectionBlock blockProps={block} />;
      case BlockType.CALLBACK_FORM_BLOCK:
        return <CallbackFormBlock key={block.id} {...block} />;
      case BlockType.NAVIGATION_MODAL_BLOCK:
        return <NavigationModalBlock key={block.id} {...block} />;
      default:
        // (nihar) should this be an error?
        rollbar.warn(`No block type matched! For ${block.type}.`);
    }
  });

  return <>{Blocks}</>;
};

export default BlockRenderer;
