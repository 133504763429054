import React from "react";

import { Icon } from "@chakra-ui/icons";

interface Props {
  boxSize: string | number;
  fill: string;
  stroke: string;
  color: string;
  borderWidth: number;
  strokeWidth: number;
}

/**
 * The SVG colors don't respect the chakra theme color names, but we can still
 * use "currentColor". So - to fill the circle with a theme color, pass in
 * "theme.whatever" to `color` and "currentColor" for `fill.`
 */
function DollarSignCircle({
  boxSize,
  fill,
  stroke,
  color,
  borderWidth,
  strokeWidth,
}: Props) {
  return (
    <Icon
      boxSize={boxSize}
      viewBox="0 0 20 20"
      fill="none"
      color={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="9"
        fill={fill}
        stroke={stroke}
        strokeWidth={borderWidth}
      />
      <path
        d="M11.8847 8.125C11.8847 8.125 12.193 6.25 10.0097 6.25C8.13406 6.25 8.13367 7.74643 8.13406 8.125C8.13659 10.5539 11.8838 9.375 11.8838 11.875C11.8838 13.125 11.2591 13.75 10.0091 13.75C7.74918 13.75 8.13532 11.875 8.13532 11.875"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 4.5v1.5 M10 14v1.5"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

DollarSignCircle.defaultProps = {
  stroke: "currentColor",
  fill: "none",
  boxSize: "20px",
  color: "",
  strokeWidth: 2,
  borderWidth: 2,
};

export default DollarSignCircle;
