import React from "react";

function NavSubmenuArrowOpen() {
  return (
    <svg
      width="6"
      height="4"
      viewBox="0 0 6 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.77701 3.41882L0.45062 0.833941C0.276869 0.640885 0.413878 0.333252 0.673609 0.333252H5.32639C5.58612 0.333252 5.72313 0.640885 5.54938 0.833941L3.22299 3.41882C3.10382 3.55123 2.89618 3.55123 2.77701 3.41882Z"
        fill="#293056"
      />
    </svg>
  );
}

export default NavSubmenuArrowOpen;
