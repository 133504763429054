import React from "react";

import { Box, Heading, Text } from "@chakra-ui/react";

import { TextElement } from "../types";

import { renderWithHtml } from "../helpers/TextFormatters";
import expertImage from "../images/tax-expert.jpg";

interface Props {
  caption?: string;
  content: TextElement[];
}

const ExpertTipBlock: React.FC<Props> = ({ caption, content }) => {
  return (
    <Box>
      <Box
        mt="6"
        border="2px"
        borderRadius="card"
        position="relative"
        p={4}
        backgroundColor="background.dark"
        boxShadow="md"
      >
        <Box
          borderRadius="expertTipPhoto"
          border="2px"
          borderColor="text.primary"
          w={12}
          h={12}
          overflow="hidden"
          right="20px"
          top={-6}
          position="absolute"
        >
          <img width="full" src={expertImage} alt="Tax Expert Photo" />
        </Box>
        <Heading data-cypress="caption" color="text.primary" size="caption">
          {caption}
        </Heading>
        <Box mt={2}>
          {content.map((element, index) => {
            if (typeof element === "string") {
              return (
                <Text key={index} alignItems="left" display="inline">
                  {renderWithHtml(element)}
                </Text>
              );
            }
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default ExpertTipBlock;
