import React from "react";

import { Text } from "@chakra-ui/react";

// TODO(marcia): Maybe it makes more sense to use props.children
// for passing in the text instead of props.text
function QuestionText({ text }: { text: string | JSX.Element }) {
  return (
    <Text
      fontSize="2xl"
      lineHeight="1.8rem"
      fontWeight="bold"
      textAlign="center"
    >
      {text}
    </Text>
  );
}

export default QuestionText;
