import React from "react";

import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import dayjs from "dayjs";

import rollbar from "../../rollbar-utils";
import { dollarsToUsd } from "../helpers/CurrencyFormatter";

// Format dates as follows: Dec 6, 2022
const DATE_FORMAT_STRING = "MMM D, YYYY";

interface Props {
  id: string;
  type: string;
  title: string;
  submissionStatus: string;
  refundOrAmountOwed: number;
  refundOrAmountOwedLabel: string;
  datetime?: string | null;
  datetimeLabel?: string | null;
  adjustedGrossIncome: number;
}

function labelFromSubmissionState(submissionStatus: string): {
  text: string;
  color: string;
} | void {
  // Must be kept in sync with ReturnTaxYear::SubmissionState
  switch (submissionStatus) {
    case "not_reviewable":
    case "retryable":
    case "rejected_non_retryable":
    case "rejected":
    case "exception":
      return {
        color: "warning.dark",
        text: "Rejected",
      };
    case "accepted":
      return {
        color: "brand.medium",
        text: "Accepted",
      };
    case "not_submitted":
    case "pending_submission":
    case "in_submission":
      return {
        color: "warning.dark",
        text: "Not submitted",
      };
    case "submitted":
      return {
        color: "warning.dark",
        text: "Pending",
      };
    default:
      rollbar.error(`Unexpected submission state ${submissionStatus}`);
  }
}

const PriorYearReturnSummaryBlock: React.FC<Props> = ({
  title,
  submissionStatus,
  datetime,
  datetimeLabel,
  refundOrAmountOwed,
  refundOrAmountOwedLabel,
  adjustedGrossIncome,
}) => {
  const label = labelFromSubmissionState(submissionStatus);
  return (
    <TableContainer pb="6">
      <Table variant="status">
        <Thead>
          <Tr>
            <Th colSpan={2}>{title}</Th>
          </Tr>
        </Thead>
        {(label?.color || label?.text) && (
          <Tbody>
            <Tr>
              <Td>E-file status</Td>
              <Td color={label.color}>{label.text}</Td>
            </Tr>
          </Tbody>
        )}
        {datetime && (
          <Tbody>
            <Tr>
              <Td>{datetimeLabel}</Td>
              <Td>{dayjs(datetime).format(DATE_FORMAT_STRING)}</Td>
            </Tr>
          </Tbody>
        )}

        <Tbody>
          <Tr>
            <Td>{refundOrAmountOwedLabel}</Td>
            <Td>{dollarsToUsd(refundOrAmountOwed)}</Td>
          </Tr>
        </Tbody>

        {adjustedGrossIncome && (
          <Tbody>
            <Tr>
              <Td>Adjusted Gross Income</Td>
              <Td>{dollarsToUsd(adjustedGrossIncome)}</Td>
            </Tr>
          </Tbody>
        )}
      </Table>
    </TableContainer>
  );
};

export default PriorYearReturnSummaryBlock;
