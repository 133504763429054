import React from "react";

import { Center, Circle, Image } from "@chakra-ui/react";

import TickIconSVG from "../icons/tick_icon.svg";
import ResourceIcon from "./ResourceIcon";

interface Props {
  iconId: string;
}

// Must match icon ids in screen.rb
enum InlineIconIds {
  CHECK_CIRCLE_ICON = "CHECK_CIRCLE_ICON",
}
const IconBlock: React.FC<Props> = ({ iconId }) => {
  if (iconId === InlineIconIds.CHECK_CIRCLE_ICON) {
    return (
      <Center display="inline-block" verticalAlign="middle" mr="2">
        <Circle bg="success.dark" size="20px">
          <Image src={TickIconSVG} width="14px" />
        </Circle>
      </Center>
    );
  } else {
    return (
      <Center mt="5">
        <ResourceIcon iconId={iconId} />
      </Center>
    );
  }
};

export default IconBlock;
