import React, { ReactElement } from "react";

import { Box } from "@chakra-ui/react";

function NavigationChoiceHOC({
  isEnabled,
  setIsDisabled,
  navigationDisabled,
  navigationDisableSetter,
  setIsLoading,
  onClick,
  item,
}: {
  isEnabled: boolean;
  setIsDisabled: (state: boolean) => void;
  setIsLoading: (state: boolean) => void;
  navigationDisabled?: boolean;
  navigationDisableSetter?: (state: boolean) => void;
  onClick: () => void;
  item: ReactElement;
}) {
  return (
    <Box
      onClick={() => {
        if (isEnabled && !navigationDisabled) {
          setIsDisabled(true);
          navigationDisableSetter && navigationDisableSetter(true);
          setTimeout(() => {
            setIsLoading(true);
          }, 250);
          onClick();
        }
      }}
      rounded="card"
      boxShadow="card"
      cursor={!navigationDisabled && isEnabled ? "pointer" : "default"}
      _hover={
        isEnabled && !navigationDisabled
          ? {
              boxShadow: "0 1px 15px 0 #ccc",
            }
          : {}
      }
    >
      {item}
    </Box>
  );
}

export default NavigationChoiceHOC;
