import { ReactNode } from "react";

import { Tag, TagLabel } from "@chakra-ui/react";

interface Props {
  label: string;
  icon: ReactNode;
  backgroundColor?: string;
  contentColor?: string;
}
export default function IconChip({
  label,
  icon,
  backgroundColor = "brand.light",
  contentColor = "brand.dark",
}: Props) {
  return (
    <Tag
      variant="subtle"
      bgColor={backgroundColor}
      gap={0.5}
      borderRadius="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={2}
      py={1}
      size={"md"}
    >
      {icon}
      <TagLabel fontWeight="semibold" color={contentColor}>
        {label}
      </TagLabel>
    </Tag>
  );
}
