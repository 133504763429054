import React from "react";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { TableRow, TableRowWithLinks } from "../types";

import { convertToExternalBrowserUrl } from "../../utils/nav-utils";

interface Props {
  header: string;
  sections: TableRow[][] | TableRowWithLinks[][];
}

const TableSection = ({
  rows,
  isLastSection,
}: {
  rows: TableRow[] | TableRowWithLinks[];
  isLastSection: boolean;
}) => {
  const renderValue = (
    value: string | number | { text: string; url: string },
  ) => {
    if (typeof value === "string" || typeof value === "number") {
      return value;
    } else {
      return (
        <Link
          color="brand.medium"
          href={convertToExternalBrowserUrl(value.url)}
          rel="noreferrer"
          target="_blank"
          textDecoration={"underline"}
        >
          {value.text}
          <ExternalLinkIcon ml="4px" />
        </Link>
      );
    }
  };

  // The CSS override here is because in GENERAL we want the last child to have a bottom border
  // but since the table overall has a border, the very last section shouldn't have a bottom border
  // because it will cause 2 bottom borders to be rendered
  const sectionRows = rows.map((row, i) => (
    <Tr key={`row-${i}-${row.label}`}>
      <Td
        style={
          i === rows.length - 1 && isLastSection ? { borderBottom: "none" } : {}
        }
      >
        {row.label}
      </Td>
      <Td
        style={
          i === rows.length - 1 && isLastSection ? { borderBottom: "none" } : {}
        }
      >
        {renderValue(row.value)}
      </Td>
    </Tr>
  ));
  return <Tbody>{sectionRows}</Tbody>;
};

const SectionTableBlock: React.FC<Props> = ({ header, sections }) => {
  const tableSections = sections.map((section, i) => (
    <TableSection
      isLastSection={i === sections.length - 1}
      rows={section}
      key={`section-${i}-${section[0].label}`}
    />
  ));
  return (
    <Box>
      <TableContainer>
        <Table variant="summary">
          <Thead>
            <Tr>
              <Th colSpan={2}>{header}</Th>
            </Tr>
          </Thead>
          {tableSections}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SectionTableBlock;
