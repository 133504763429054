import React from "react";

function Home() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.649 2.1915C12.3208 1.93617 11.8611 1.93617 11.5328 2.1915L3.35096 8.55513C3.12952 8.72737 3 8.99219 3 9.27273V19.2727C3 19.996 3.28734 20.6897 3.7988 21.2012C4.31026 21.7127 5.00395 22 5.72727 22H9.36364H14.8182H18.4545C19.1779 22 19.8716 21.7127 20.383 21.2012C20.8945 20.6897 21.1818 19.996 21.1818 19.2727V9.27273C21.1818 8.99219 21.0523 8.72737 20.8309 8.55513L12.649 2.1915ZM15.7273 20.1818H18.4545C18.6956 20.1818 18.9269 20.086 19.0974 19.9156C19.2679 19.7451 19.3636 19.5138 19.3636 19.2727V9.71735L12.0909 4.06078L4.81818 9.71735V19.2727C4.81818 19.5138 4.91396 19.7451 5.08445 19.9156C5.25494 20.086 5.48617 20.1818 5.72727 20.1818H8.45455V12C8.45455 11.4979 8.86156 11.0909 9.36364 11.0909H14.8182C15.3203 11.0909 15.7273 11.4979 15.7273 12V20.1818ZM10.2727 20.1818V12.9091H13.9091V20.1818H10.2727Z"
        fill="#293056"
      />
    </svg>
  );
}

export default Home;
