import { ZendeskAPI } from "react-zendesk";

import { NotAuthorizedError } from "./shared/api";

import { getZendeskToken } from "./diy/api";
import rollbar from "./rollbar-utils";
import { redirectToExitPage } from "./utils/api";

// This is the production Zendesk key which helps us open up the correct Zendesk
// widget, and you will find instructions for it here:
// https://columntax.zendesk.com/admin/channels/classic/web-widget/9501218518541/edit/installation
export const ZENDESK_KEY = "0b5cf703-0f2d-437e-a417-d54aa2dccd44";

// This is the sandbox Zendesk key, see
// https://columntax1712869465.zendesk.com/admin/channels/messaging_and_social/channels_list/web_widget/25826213847565/edit
export const ZENDESK_SANDBOX_KEY = "139c9516-9b20-4084-be2e-ec87ca077616";

export const ZENDESK_BASE_SETTINGS = {
  webWidget: {
    launcher: {
      mobile: {
        labelVisible: true,
      },
      talkLabel: {
        "*": "Help",
      },
    },
    contactOptions: {
      enabled: true,
      contactButton: { "*": "Get in touch" },
    },
  },
  helpCenter: {
    originalArticleButton: false,
  },
  chat: {},
  talk: {},
  contactForm: {},
  authenticate: {
    chat: {
      jwtFn: (callback: (jwt: string) => Promise<void>) => {
        getZendeskToken()
          .then(function (response: { token: string }) {
            callback(response.token);
          })
          .catch((error) => {
            if (error instanceof NotAuthorizedError) {
              redirectToExitPage();
            }
          });
      },
    },
  },
};

export const openZendesk = async (successCallback?: () => void) => {
  try {
    // This appUserId session storage variable is sent during authentication with Zendesk, and leaving it causes
    // the zendesk /login call to send contradictory IDs for multi-hostbank users, where an external ID does not match the
    // appUserId. This has been known to cause issues. See COL-27275.
    Object.keys(sessionStorage).forEach((key) => {
      if (key.includes("appUserId")) {
        sessionStorage.removeItem(key);
      }
    });

    const response = await getZendeskToken();
    ZendeskAPI("messenger", "loginUser", function (callback: any) {
      callback(response.token);
      ZendeskAPI("messenger", "open");
      successCallback?.();
    });
  } catch (error) {
    rollbar.warn(`Error in opening Zendesk: ${error}`);
  }
};

export const showLauncher = (useZendeskProductionClassic: boolean) => {
  if (useZendeskProductionClassic) {
    ZendeskAPI("webWidget", "show");
  }
};
