import { setDeviceId } from "../api";

import { getOrComputeDeviceId } from "../../getDeviceId";

// Helper to compute the device ID (or load a cached version), and report to backend
// This is async -- it should run on app startup but not block any other calls (and it should work if the user takes
// other actions or switches screens before the update finishes)
export const reportDeviceId = async () => {
  const deviceId = await getOrComputeDeviceId();
  if (!deviceId) {
    return;
  }
  return setDeviceId(deviceId).catch(console.error);
};
