import { Resource, Screen, UserDetails } from "./diy/types";

export interface GoogleOauthClient {
  requestCode: () => void;
}

export interface GoogleOauthClientParams {
  client_id: string;
  scope: string;
  callback: (params: { code: string }) => void;
}

declare global {
  interface Window {
    zE: (
      namespace: string,
      operation: string,
      params: Record<string, unknown>,
    ) => null;
    google: {
      accounts: {
        oauth2: {
          initCodeClient: (
            params: GoogleOauthClientParams,
          ) => GoogleOauthClient;
        };
      };
    };
    ReactNativeWebView: {
      postMessage(msg: string): void;
    };
    webkit: {
      messageHandlers: {
        [key in SDKEvent]: {
          postMessage(msg: string): void;
        };
      };
    };
    Android: {
      postMessage(msg: string): void;
    };
    LogRocket: {
      identify(userId: string): void;
    };
    newrelic: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setCustomAttribute(attribute: string, value: any): void;
    };
  }
}

export type BusinessRuleResult = {
  // We keep the snake case here because we download
  // these results to be saved and run in the BE repo's
  // test suite
  rule_number: string;
  passes: boolean;
  alert_message: string;
};

export type SubmissionRuleResult = {
  rule_number: string;
  rule_text: string;
  passes: boolean;
};

export type UserHistoryResult = {
  inputHistory: Array<UserHistoryElement>;
};

export type UserHistoryElement = {
  id: number;
  createdAt: string;
  before: any;
  after: any;
  diff: any;
  beforeXml: string;
  afterXml: string;
  diffXml: string;
};

export type NamespacePathElement = {
  internalId: string;
  index: number;
};

export type Entry = {
  internalId: string;
};

export type PathToEntry = {
  entry: Entry;
  namespacePathElements: Array<NamespacePathElement>;
};

export type Disqualification = {
  methodName: string;
  internalId: string;
  isDisqualified: boolean;
  pathToEntry: PathToEntry;
};

export type RequiredField = {
  message: string;
  internalId: string;
  pathToEntry: PathToEntry;
};

export type ContentRestriction = {
  errors: RestrictionError[];
  pathToEntry: PathToEntry;
};

export type RestrictionError = {
  errorType: string;
  message: string;
};

export type QuestionWithEmployerNameAndYear = BaseQuestion & {
  id: "any_other_income" | "estimated_w2_income" | "verify_zero_w2_jobs";
  previous?: { target_step_id: StepType; target_question_id?: string };
  answer: boolean | number | null;
};

export type QuestionWithMFJ = BaseQuestion & {
  id: "estimated_other_income";
  previous?: { target_step_id: StepType; target_question_id?: string };
  answer: number | null;
};

export type UnifiedSubmissionElement = {
  id: string;
  timestamp: string;
  status: string;
  submissionType: string;
  submissionRuleFailures: string[];
  acknowledgementFailures: string[];
};

export interface StepForDataLoadingVerify {
  id: StepType.DATA_LOADING;
  question: IQuestion;
  details: {
    taskType: "verify";
    hostBankName?: string;
  };
}

export interface StepForDataLoadingWithhold {
  id: StepType.DATA_LOADING;
  question: IQuestion;
  details: {
    taskType: "withhold";
    automatedOnlyConfiguration: boolean;
    hostBankName?: string;
  };
}

export interface StepForQuestionWithEmployerDetails {
  id: StepType.QUESTION;
  previous?: { target_step_id: StepType; target_question_id?: string };
  question: QuestionWithEmployerNameAndYear;
  details: {
    employerName: string;
    year: number;
  };
}

export interface StepForQuestionWithMFJDetails {
  id: StepType.QUESTION;
  previous?: { target_step_id: StepType; target_question_id?: string };
  question: QuestionWithMFJ;
  details: {
    isMarriedFilingJointly: boolean;
  };
}

export interface StepForQuestionWithNumberOfW2Jobs {
  id: StepType.QUESTION;
  previous?: { target_step_id: StepType; target_question_id?: string };
  question: NumberOfW2JobsQuestion;
}

export type QuestionStep =
  | {
      id: StepType.QUESTION;
      previous?: { target_step_id: StepType; target_question_id?: string };
      question: IQuestion;
    }
  | StepForQuestionWithEmployerDetails
  | StepForQuestionWithMFJDetails
  | StepForQuestionWithNumberOfW2Jobs;

export enum SDKEvent {
  // TODO(marcia): Note that this is copied over to our
  // public column-tax.js. If we change this event name,
  // we'd have to update all apps and our own js.
  COLUMN_ON_CLOSE = "column-on-close",
  COLUMN_ON_USER_EVENT = "column-on-user-event",
}

export type FeatureFlags = Record<string, unknown>;

export type UserTokenCheckAndPayload =
  | {
      isValid: true;
      payload:
        | Step
        | { screen: Screen; userData: UserDetails; featureFlags: FeatureFlags };
    }
  | {
      isValid: false;
      errorMessage: string;
    };

export type UnvalidatedUserParams = {
  token: string;
  screen: InitialScreenRequest | undefined;
};

export type InitialScreenRequest = {
  id: string;
  resource?: Resource;
  payload?: Record<string, unknown>;
};

export type ValidUserParams = {
  isValid: true;
  token: string;
};

export type InvalidUserParams = {
  isValid: false;
  errorMessage: string;
};

export type ValidatedUserParams = ValidUserParams | InvalidUserParams;

export enum URLParamError {
  MISSING_PARAMS = "MISSING_PARAMS",
  FAILED_TO_BASE64_DECODE = "FAILED_TO_BASE64_DECODE",
  MISSING_TOKEN = "MISSING_TOKEN",
  INVALID_JSON = "INVALID_JSON",
  INVALID_USER_TOKEN = "INVALID_USER_TOKEN",
}

export enum StepType {
  INITIAL_LOADING = "INITIAL_LOADING",
  INITIAL_SPLASH = "INITIAL_SPLASH",
  QUESTION = "QUESTION",
  PAYROLL_CHOICE = "PAYROLL_CHOICE",
  PAYROLL_PAY_CYCLE = "PAYROLL_PAY_CYCLE",
  PAYROLL_GROSS_AMOUNT = "PAYROLL_GROSS_AMOUNT",
  PAYROLL_FEDERAL_INCOME_TAX = "PAYROLL_FEDERAL_INCOME_TAX",
  PAYROLL_NET_PAY = "PAYROLL_NET_PAY",
  PAYROLL_CONNECT = "PAYROLL_CONNECT",
  PAYROLL_CONNECT_PENDING = "PAYROLL_CONNECT_PENDING",
  DATA_LOADING = "DATA_LOADING",
  INCOME_VERIFICATION = "INCOME_VERIFICATION",
  SUMMARY = "SUMMARY",
  FINDINGS_AUTOMATED = "FINDINGS_AUTOMATED",
  FINDINGS_MANUAL = "FINDINGS_MANUAL",
  SIGNATURE = "SIGNATURE",
  EMPLOYER_INFORMATION = "EMPLOYER_INFORMATION",
  UPDATE_OWN_W4_INSTRUCTIONS = "UPDATE_OWN_W4_INSTRUCTIONS",
  OUTCOME = "OUTCOME",
  ERROR = "ERROR",
}

export type Step =
  | {
      id: StepType.INITIAL_LOADING;
    }
  | {
      id: StepType.INITIAL_SPLASH;
    }
  | {
      id: StepType.PAYROLL_CHOICE;
      question: IQuestion;
      previous?: { target_step_id: StepType; target_question_id?: string };
    }
  | {
      id: StepType.PAYROLL_PAY_CYCLE;
      question: EnumQuestion;
    }
  | {
      id: StepType.PAYROLL_GROSS_AMOUNT;
      question: IQuestion;
    }
  | {
      id: StepType.PAYROLL_FEDERAL_INCOME_TAX;
      question: IQuestion;
      details: {
        payrollGrossAmount: string;
      };
    }
  | {
      id: StepType.PAYROLL_NET_PAY;
      question: IQuestion;
      details: {
        payrollGrossAmount: string;
        federalIncomeTax: string;
      };
    }
  | {
      id: StepType.PAYROLL_CONNECT;
      details: {
        atomicPublicToken: string;
        shouldSwitchDirectDeposit: boolean;
        hostBankName: string;
      };
      question: IQuestion;
    }
  | {
      id: StepType.PAYROLL_CONNECT_PENDING;
      question: {
        answer: string | null;
      };
    }
  | QuestionStep
  | StepForDataLoadingVerify
  | StepForDataLoadingWithhold
  | {
      id: StepType.INCOME_VERIFICATION;
      question: IQuestion;
      details: {
        income: number;
        employerName: string;
      };
    }
  | {
      id: StepType.SUMMARY;
      question: IQuestion;
      summary: { fields: SummaryEntry[] };
    }
  | {
      id: StepType.FINDINGS_AUTOMATED;
      details: {
        automatedOnlyConfiguration: boolean;
        unlockedByColumn: number;
        federalTaxes: number;
        takeHome: number;
        atomicPublicToken: string;
        atomicLinkedAccountId: string;
        filingStatus: string;
        multipleJobsOrSpouseWorks: boolean;
        dependentAmount: number;
        otherIncomeNotFromJobsAmount: number;
        deductionsAmount: number;
        extraWithholdingPerPayPeriodAmount: number;
        nonResidentAlien: boolean;
        exempt: boolean;
        dependentTaxCreditEligible: number;
        dependentsUnder17: number;
        dependentsUnder17Amount: number;
        otherDependents: number;
        otherDependentsAmount: number;
      };
      question: IQuestion;
    }
  | {
      id: StepType.FINDINGS_MANUAL;
      details: {
        unlockedByColumn: number;
        federalTaxes: number;
        takeHome: number;
      };
      question: BooleanQuestion;
    }
  | {
      id: StepType.SIGNATURE;
      question: IQuestion;
      details: {
        w4Entries: Array<{ step: string; value: string }>;
      };
    }
  | {
      id: StepType.UPDATE_OWN_W4_INSTRUCTIONS;
      question: IQuestion;
      details: {
        w4Entries: Array<{ step: string; value: string }>;
      };
    }
  | {
      id: StepType.EMPLOYER_INFORMATION;
      question: EmployerInformationQuestion;
    }
  | {
      id: StepType.OUTCOME;
      details: {
        hostBankName: string;
        isQualified: boolean;
        automatedW4Available: boolean;
        automatedW4Success: boolean;
        shouldSwitchDirectDeposit: boolean;
        successfulDirectDepositSwitch: boolean;
        unlockedByColumn: number;
        knowsPayrollProvider: boolean;
        knowsHrEmail: boolean;
        automatedOnlyConfiguration: boolean;
      };
    }
  | { id: StepType.ERROR; error?: Error };

export interface AppState {
  currentStep: Step;
}

export enum ActionType {
  NEXT_STEP,
}

export interface AppAction {
  type: ActionType.NEXT_STEP;
  nextStep: Step;
}

type BaseQuestion = {
  id: string;
  type: string;
  text: string;
  preamble?: string;
  explanation?: string;
};

export type EmployerInformationQuestion = BaseQuestion & {
  answer: {
    employerCompanyName: string;
    employerHrEmail: string;
  } | null;
};

export type NumberOfW2JobsQuestion = BaseQuestion & {
  answer: {
    numberOfSalariedW2Jobs: number;
    numberOfHourlyW2Jobs: number;
    numberOf1099Jobs: number;
  };
  maximum: number;
};

// These must match the question types sent down by the BE
export enum QuestionType {
  CURRENCY = "currency",
  INTEGER = "int",
  BOOLEAN = "bool",
  ENUM = "enum",
}

export type CurrencyQuestion = BaseQuestion & {
  type: QuestionType.CURRENCY;
  answer: number | null;
  maximum: number;
};

export type IntegerQuestion = BaseQuestion & {
  type: QuestionType.INTEGER;
  maximum: number;
  answer: number | null;
};

export type BooleanQuestion = BaseQuestion & {
  type: QuestionType.BOOLEAN;
  answer: boolean | null;
  options: { value: boolean; label: string }[];
};

export type EnumQuestion = BaseQuestion & {
  type: QuestionType.ENUM;
  answer: string | null;
  options: { value: string; label: string }[];
};

export type IQuestion =
  | CurrencyQuestion
  | IntegerQuestion
  | BooleanQuestion
  | EnumQuestion;

export type SummaryEntry = {
  key: string;
  label: string;
  answer: {
    label: string;
  };
};

export enum Errors {
  UNAUTHORIZED = "Admin Tool Unauthorized",
}
