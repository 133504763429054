import React from "react";
import { Calendar, Phone } from "react-feather";

import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";

import { sendEventButtonClick } from "../../api";
import {
  BlockType,
  ButtonClickEventNames,
  ExpertAssistLandingOptInBlock as ExpertAssistLandingOptInBlockType,
  ModalStep,
} from "../../types";

import { ExpertAssistConfirmStep } from "../modal-step/ExpertAssistConfirmStep";
import { ExpertAssistDetailStep } from "../modal-step/ExpertAssistDetailStep";
import { ExpertAssistSuccessStep } from "../modal-step/ExpertAssistSuccessStep";
import NavigationModalBlock from "./NavigationModalBlock";

const ExpertAssistLandingOptInBlock: React.FC<
  ExpertAssistLandingOptInBlockType
> = ({
  screen,
  expertAssistFee,
  taxFilingFee,
  columnCollectsPayment,
  hostBankName,
}) => {
  const theme = useTheme();
  const {
    isOpen: isOptInModalOpen,
    onOpen: onOptInModalOpen,
    onClose: onOptInModalClose,
  } = useDisclosure();

  const steps: ModalStep[] = [
    ExpertAssistDetailStep({ screenId: screen.id, expertAssistFee }),
    ExpertAssistConfirmStep({
      screenId: screen.id,
      expertAssistFee,
      taxFilingFee,
    }),
    ExpertAssistSuccessStep({
      columnCollectsPayment,
      hostBankName,
    }),
  ];

  return (
    <Box position="relative">
      <Flex
        flexDir="column"
        position="relative"
        borderWidth="1px"
        borderColor={theme.colors.expertAssist.gold}
        borderRadius="xl"
        px={{ base: 7, md: 8 }}
        pt={{ base: 8, md: 6 }}
        pb={{ base: 7, md: 6 }}
        bg="white"
        gap={6}
      >
        <Flex direction="column" gap={1}>
          <Flex align="center" gap={1.5}>
            <Phone size={20} />
            <Text
              variant="h4"
              fontWeight="bold"
              fontSize={{ base: "16px", md: "18px" }}
            >
              Talk to an expert
            </Text>
          </Flex>
          <Text color={theme.colors.text.secondary}>
            We’re available to talk Monday — Friday, 10am — 5pm ET.
          </Text>
        </Flex>
        <Flex direction="column" gap={1}>
          <Flex align="center" gap={1.5}>
            <Calendar size={20} />
            <Text
              variant="h4"
              fontWeight="bold"
              fontSize={{ base: "16px", md: "18px" }}
            >
              Schedule a call for later
            </Text>
          </Flex>
          <Text color={theme.colors.text.secondary}>
            Schedule a call with one of our experts at a time that works best
            for you.
          </Text>
        </Flex>
        <Flex justify="center">
          <Button
            onClick={() => {
              sendEventButtonClick({
                buttonName: ButtonClickEventNames.EXPERT_ASSIST_LEARN_MORE,
                screenId: screen.id,
              });
              onOptInModalOpen();
            }}
            variant="outline"
            width={{ base: "100%", md: "461px" }}
            size="lg"
          >
            Learn More
          </Button>
        </Flex>
        <NavigationModalBlock
          type={BlockType.NAVIGATION_MODAL_BLOCK}
          id="callback-modal"
          isOpen={isOptInModalOpen}
          onClose={onOptInModalClose}
          steps={steps}
          screen={screen}
        />
      </Flex>
      <Flex
        position="absolute"
        top="-3"
        left="50%"
        transform="translateX(-50%)"
        border={`1px solid ${theme.colors.expertAssist.gold}`}
        px="9px"
        py={0.25}
        borderRadius={10}
        bg="white"
      >
        <Text
          fontSize="14px"
          lineHeight="20px"
          fontWeight={600}
          variant="caption"
          color={theme.colors.text.secondary}
          whiteSpace="nowrap"
        >
          Included with Expert Assist
        </Text>
      </Flex>
    </Box>
  );
};

export default ExpertAssistLandingOptInBlock;
