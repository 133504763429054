import { FeatureFlags } from "../types";
import {
  Action,
  ActionType,
  BinaryAttachment,
  Block,
  BlockType,
  EstimatorBlock as EstimatorBlockType,
  EstimatorWaitBlock as EstimatorWaitBlockType,
  Events,
  FieldAnswer,
  FieldType,
  NavObject,
  OpenHelpTypes,
  Screen,
  ScreenType,
  UploadStatus,
  UploadType,
  UploadW2Action,
} from "./types";

import { authenticatedFetch } from "../shared/api";
import { ProductIdentifier } from "../shared/types";

import { onExit } from "../utils/api";
import { apiUrl, getApiUrlByProduct } from "../utils/utils";

function mapBlockForPayload(
  blocks: Block[],
): { id: string; type: string; fieldType: FieldType; answer: FieldAnswer }[] {
  return blocks.flatMap((block) => {
    if (block.type == BlockType.PROGRESSIVE_DISCLOSURE_BLOCK) {
      return mapBlockForPayload(block.progressiveBlocks);
    }

    if (block.type !== BlockType.FIELD) {
      return [];
    }
    return [
      {
        id: block.id,
        type: block.type,
        fieldType: block.fieldType,
        answer: block.answer,
      },
    ];
  });
}

// TODO(marcia): Get ready to use user token in fetch request -- will
// involve separating out the reusable bits from src/api.ts and moving to
// something like src/shared/utils.ts
function getPayloadByScreen(
  screen: Screen,
  action: Action,
): {
  payload: string | FormData;
  payloadType: string | undefined;
} {
  let payload;
  let payloadType;
  switch (screen.screenType) {
    case ScreenType.QUESTION:
      payload = JSON.stringify({
        screen: {
          id: screen.id,
          blocks: mapBlockForPayload(screen.blocks),
          resource: screen.resource,
          screenType: screen.screenType,
          action: action,
        },
      });
      break;
    case ScreenType.MANAGE:
      payload = JSON.stringify({
        screen: {
          id: screen.id,
          resource: screen.resource,
          screenType: screen.screenType,
          action,
        },
      });
      break;
    case ScreenType.NAVIGATION:
      payload = JSON.stringify({
        screen: {
          id: screen.id,
          resource: screen.resource,
          screenType: screen.screenType,
          action,
        },
      });
      break;
    case ScreenType.INTERLUDE:
    default:
      payload = JSON.stringify({
        screen: {
          id: screen.id,
          resource: screen.resource,
          screenType: screen.screenType,
          action,
        },
      });
  }

  return { payload, payloadType };
}

// STOPLAUNCH(marcia): Rename to handleUploadForProcessing or something
// equivalently generic that handles both W-2 and IRS1040 OCR
export async function handleUploadW2({
  action,
  featureFlags,
}: {
  action: UploadW2Action;
  featureFlags: FeatureFlags | null;
}): Promise<{
  screen: Screen;
}> {
  // Construct FormData to send file and additional fields
  const payload = new FormData();
  payload.append("file", action.file);
  payload.append("upload_type", action.uploadType);

  // For the more typical answer endpoint, we send the nested screen, complete
  // with a resource object that contains both uuid and parent uuid. When uploading
  // a file, we use FormData which requires flat key/value pairs where each value
  // is either a string or file-blob.
  // https://developer.mozilla.org/en-US/docs/Web/API/FormData
  //
  // W-2s do not have a parent uuid, but just send an empty parent uuid in case
  // we end up re-purposing this code later for something that does have a more
  // nested relationship (like the 1099-NEC of a Schedule C)
  payload.append("resource_uuid", action.resource?.uuid || "");
  payload.append("resource_parent_uuid", action.resource?.parentUuid || "");

  return await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.DIY_UPLOAD_W2, featureFlags),
    {
      method: "PUT",
      body: payload,
    },
    "form_data",
  );
}

export async function handleUploadBinaryAttachment({
  file,
  attachmentType,
}: {
  file: File;
  attachmentType: string;
}): Promise<{
  success: boolean;
  binaryAttachments: BinaryAttachment[];
}> {
  // Construct FormData to send file and additional fields
  const payload = new FormData();
  payload.append("file", file);
  payload.append("attachment_type", attachmentType);

  return await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.DIY_UPLOAD_BINARY_ATTACHMENT),
    {
      method: "PUT",
      body: payload,
    },
    "form_data",
  );
}

export async function handleDeleteBinaryAttachment({
  uuid,
}: {
  uuid: string;
}): Promise<{
  success: boolean;
}> {
  return await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.DIY_DELETE_BINARY_ATTACHMENT),
    {
      method: "DELETE",
      body: JSON.stringify({ uuid }),
    },
  );
}

export async function handleGetBinaryAttachmentStatus({
  attachment_type,
}: {
  attachment_type: string;
}): Promise<{
  success: boolean;
  binaryAttachments: BinaryAttachment[];
}> {
  return await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.DIY_GET_BINARY_ATTACHMENT_STATUS),
    {
      method: "POST",
      body: JSON.stringify({ attachment_type }),
    },
  );
}

// user-facing: emails magic link to currently logged-in user
export async function emailMagicLink(): Promise<void> {
  await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.EMAIL_DIY_MAGIC_LINK),
    {
      method: "POST",
      body: JSON.stringify({}),
    },
  );
}

export async function requestExpertReview(): Promise<void> {
  await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.REQUEST_EXPERT_REVIEW),
    {
      method: "POST",
      body: JSON.stringify({}),
    },
  );
}

export async function optInToExpertAssist(): Promise<void> {
  await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.OPT_IN_TO_EXPERT_ASSIST),
    {
      method: "POST",
      body: JSON.stringify({}),
    },
  );
}

/**
 * Sends a request to the backend to create a Zendesk ticket for a callback request.
 * This ticket will include details like name, phone, and reason for callback so that
 * support can call the user back.
 */
export async function requestCallbackFromExpert({
  name,
  phone,
  callbackReason,
}: {
  name: string;
  phone: string;
  callbackReason: string;
}): Promise<{ success: boolean }> {
  try {
    await authenticatedFetch(
      getApiUrlByProduct(ProductIdentifier.SEND_CALLBACK_DETAILS),
      {
        method: "POST",
        body: JSON.stringify({ name, phone, callbackReason }),
      },
    );
    return { success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getUploadW2Status({
  resourceUuid,
  timedOut,
  uploadType,
}: {
  resourceUuid: string;
  timedOut: boolean;
  uploadType: UploadType;
}): Promise<{
  screen: Screen;
  status: UploadStatus;
  originalFilename: string;
}> {
  const url = new URL(getApiUrlByProduct(ProductIdentifier.DIY_UPLOAD_W2));
  url.searchParams.set("uploadType", uploadType);
  url.searchParams.set("resourceUuid", resourceUuid);
  if (timedOut) {
    url.searchParams.set("timedOut", "1");
  }

  return await authenticatedFetch(url.toString());
}

export async function getNav(): Promise<NavObject | null> {
  return await authenticatedFetch(getApiUrlByProduct(ProductIdentifier.NAV));
}

export async function getEstimator(): Promise<
  EstimatorBlockType | EstimatorWaitBlockType | null
> {
  return await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.ESTIMATOR),
  );
}

export async function handleSubmit({
  screen,
  action,
}: {
  screen: Screen;
  action: Action;
}): Promise<{
  screen: Screen;
}> {
  const { payload, payloadType } = getPayloadByScreen(screen, action);
  return await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.DIY),
    {
      method: "PUT",
      body: payload,
    },
    payloadType,
  );
}

export async function handleBack({ screen }: { screen: Screen }): Promise<{
  screen: Screen;
}> {
  const payload = {
    screen: {
      ...screen,
      action: { type: ActionType.PREVIOUS },
    },
  };
  return await authenticatedFetch(getApiUrlByProduct(ProductIdentifier.DIY), {
    method: "PUT",
    body: JSON.stringify(payload),
  });
}

export async function updateTotalActiveTime(
  activeTimeElapsedMilleseconds: number,
): Promise<void> {
  const payload = {
    activeTimeElapsedMilleseconds,
  };

  await authenticatedFetch(getApiUrlByProduct(ProductIdentifier.ACTIVE_TIME), {
    method: "PUT",
    body: JSON.stringify(payload),
  });
}

type SendPhoneVerificationCodeResponse =
  | {
      type: "success";
    }
  | {
      type: "error";
      errors: string[];
    };

export async function sendPhoneVerificationCode(
  phoneNumber: string,
): Promise<SendPhoneVerificationCodeResponse> {
  return await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.SEND_PHONE_VERIFICATION_CODE),
    {
      method: "POST",
      body: JSON.stringify({
        phoneNumber,
      }),
    },
  );
}

type VerificationResponse =
  | {
      type: "url";
      url: string;
      success: boolean;
    }
  | {
      type: "cookie";
      success: boolean;
    };

// TODO(Billy): handle 401 errors and 429 errors in the case that MFA fails
export async function verifyPhone(
  phoneNumber: string,
  code: string,
): Promise<VerificationResponse> {
  return await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.VERIFY_PHONE_CODE),
    {
      method: "POST",
      body: JSON.stringify({
        phoneNumber,
        code,
      }),
    },
  );
}

// Checks if we recognize this device-id
export async function checkForDeviceIdAuthentication(
  deviceId: string,
): Promise<VerificationResponse> {
  return await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.AUTHENTICATE_VIA_DEVICE_ID),
    {
      method: "POST",
      body: JSON.stringify({ deviceId }),
    },
  );
}
// report the Device ID to the backend
export async function setDeviceId(deviceId: string): Promise<void> {
  await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.DEVICE_ID_REPORTER),
    {
      method: "PUT",
      body: JSON.stringify({ deviceId }),
    },
  );
}

async function sendEvent({
  event,
  callback,
  properties,
}: {
  event: Events;
  callback?: () => void;
  properties?: { [key: string]: string };
}): Promise<void> {
  properties = properties || {};

  try {
    await authenticatedFetch(getApiUrlByProduct(ProductIdentifier.EVENTS), {
      method: "PUT",
      body: JSON.stringify({
        event: event,
        properties: {
          ...properties,
        },
      }),
    });
  } catch (err) {
    console.error(`failed to send "${event}" analytics event: ${err}`);
  } finally {
    if (callback) {
      callback();
    }
  }
}

export function exitDiy(): void {
  sendEvent({
    event: Events.APP_CLOSE,
    callback: onExit,
  });
}

export function redirect(url: string): void {
  sendEvent({
    event: Events.REDIRECTION,
    properties: {
      url: url,
    },
    callback: () => {
      window.open(url, "_blank");
    },
  });
}

export function sendEventDrakeHandoff(properties: {
  pollDurationSeconds: number;
  callback: () => void;
}) {
  sendEvent({
    event: Events.DRAKE_HANDOFF,
    properties: {
      toDrake: "true",
      pollDurationSeconds: properties.pollDurationSeconds.toString(),
    },
    callback: properties.callback,
  });
}

export function sendEventOpenHelpModal(properties: {
  openKey: string;
  openType: OpenHelpTypes;
}): void {
  sendEvent({
    event: Events.OPEN_HELP_CONTENT,
    properties,
  });
}

type SendEventButtonClickProps = {
  buttonName: string;
  screenId: string;
  callback?: () => void;
  // additional properties to send with the event, we need to include () => void because of the optional callback prop
  [key: string]: string | number | boolean | null | undefined | (() => void);
};

// sends events for user button clicks -- generic event
export function sendEventButtonClick({
  buttonName,
  screenId,
  callback,
  ...additionalProps
}: SendEventButtonClickProps): void {
  sendEvent({
    event: Events.BUTTON_CLICK,
    properties: {
      buttonName: buttonName,
      screenId: screenId,
      ...additionalProps,
    },
    callback,
  });
}

export enum ButtonClickCompletedResult {
  SUCCESS = "success",
  FAILURE = "failure",
}

export enum ButtonClickCompletedAction {
  SEND_ZENDESK_CALLBACK_REQUEST = "sendZendeskCallbackRequest",
  NAVIGATED_TO_LANDING_SCREEN = "navigatedToLandingScreen",
  OPENED_EXPERT_ASSIST_MODAL = "openedExpertAssistModal",
  OPENED_ZENDESK_WIDGET = "openedZendeskWidget",
}

type SendEventButtonClickCompletedProps = {
  buttonName: string;
  callback?: () => void;
  result: ButtonClickCompletedResult;
  action?: ButtonClickCompletedAction;
  screenId: string;
};

// sends events for user button click completes -- generic event
export function sendEventButtonClickCompleted({
  buttonName,
  result,
  callback,
  action,
  screenId,
}: SendEventButtonClickCompletedProps): void {
  sendEvent({
    event: Events.BUTTON_CLICK_COMPLETED,
    properties: {
      buttonName,
      result,
      screenId,
      ...(action && { action }),
    },
    callback,
  });
}

export enum ZendeskWidgetOpenedStatus {
  ATTEMPTED = "attempted",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

type sendZendeskWidgetOpenedProps = {
  callback?: () => void;
  status: ZendeskWidgetOpenedStatus;
};

// sends events for user button click completes -- generic event
export function sendZendeskWidgetOpened({
  status,
  callback,
}: sendZendeskWidgetOpenedProps): void {
  sendEvent({
    event: Events.ZENDESK_WIDGET_OPENED,
    properties: {
      status,
    },
    callback,
  });
}

export async function getScreen(): Promise<{ screen: Screen }> {
  return await authenticatedFetch(getApiUrlByProduct(ProductIdentifier.DIY));
}

export async function submitSupportRequest(
  message: string,
  screenId: string,
): Promise<void> {
  return await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.SUPPORT),
    {
      method: "POST",
      body: JSON.stringify({
        message: message,
        screenId: screenId,
      }),
    },
  );
}

export async function getZendeskToken(): Promise<{ token: string }> {
  return await authenticatedFetch(
    getApiUrlByProduct(ProductIdentifier.ZENDESK),
  );
}

// not authenticated -- called from unauthed context
export async function checkIfUserOwesFees(userUuid: string): Promise<boolean> {
  // Function to check with API if user owes fees
  const response = await fetch(
    `${apiUrl()}/internal/payments/user-owes-fees/${userUuid}`,
    {
      method: "GET",
      headers: { "Key-Inflection": "camel" },
    },
  );
  const data = await response.json();
  return data.owesFees;
}

export async function createStripeCheckoutSession(
  userUuid: string,
  successUrl: string,
): Promise<string> {
  // Function to check with API if user owes a filing fee
  const response = await fetch(
    `${apiUrl()}/internal/payments/create-stripe-checkout-session`,
    {
      method: "POST",
      headers: {
        "Key-Inflection": "camel",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userUuid: userUuid,
        successUrl: successUrl,
      }),
    },
  );
  const data = await response.json();
  return data.stripeClientSecret;
}
