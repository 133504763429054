import React from "react";

interface ReviewIconProps {
  width?: number;
  height?: number;
}

const ReviewIcon: React.FC<ReviewIconProps> = ({ width = 48, height = 48 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="48.2598"
        width="47"
        height="47"
        rx="23.5"
        transform="rotate(-90 0.5 48.2598)"
        fill="white"
      />
      <rect
        x="0.5"
        y="48.2598"
        width="47"
        height="47"
        rx="23.5"
        transform="rotate(-90 0.5 48.2598)"
        stroke="#F2F4F7"
      />
      <rect
        x="15"
        y="15.7598"
        width="18"
        height="18"
        rx="2"
        stroke="#1E263A"
        stroke-width="2"
      />
      <path
        d="M22.2929 25.0527L21.5858 25.7598L23 27.174L23.7071 26.4669L22.2929 25.0527ZM31.7929 15.5527L22.2929 25.0527L23.7071 26.4669L33.2071 16.967L31.7929 15.5527Z"
        fill="#1E263A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 20.7598C18 20.2075 18.2713 19.7598 18.6061 19.7598L22.3939 19.7598C22.7287 19.7598 23 20.2075 23 20.7598C23 21.3121 22.7287 21.7598 22.3939 21.7598L18.6061 21.7598C18.2713 21.7598 18 21.312 18 20.7598Z"
        fill="#1E263A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25 28.1167C25 27.6828 25.2558 27.3311 25.5714 27.3311L29.1427 27.3311C29.4583 27.3311 29.7141 27.6828 29.7141 28.1167C29.7141 28.5507 29.4583 28.9024 29.1427 28.9024L25.5714 28.9024C25.2558 28.9024 25 28.5507 25 28.1167Z"
        fill="#1E263A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.3569 25.7598C27.7908 25.7598 28.1426 26.0156 28.1426 26.3312L28.1426 29.9025C28.1426 30.2181 27.7908 30.4739 27.3569 30.4739C26.923 30.4739 26.5712 30.2181 26.5712 29.9025L26.5712 26.3312C26.5712 26.0156 26.923 25.7598 27.3569 25.7598Z"
        fill="#1E263A"
      />
      <circle cx="17.5" cy="31.2598" r="5.5" fill="#D4AF37" />
      <path
        d="M15 31.0455L16.6667 32.7598L20 29.7598"
        stroke="#1E263A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ReviewIcon;
