import React from "react";

import {
  Flex,
  ListItem,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from "@chakra-ui/react";

function CreditIneligibleReasonsBlock({
  reasonsList,
  ineligibleReasonsHeader,
}: {
  reasonsList: Array<string>;
  ineligibleReasonsHeader?: string;
}) {
  const defaultHeader = "Why aren’t you eligible?";

  return (
    <Flex>
      <Table variant="eligibility_explanation">
        <Thead>
          {/* set the header to ineligibleReasonsHeader or "What you'll need" */}
          <Tr>
            <Th>{ineligibleReasonsHeader || defaultHeader}</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <UnorderedList>
                {reasonsList.map((reason, index) => {
                  return (
                    <ListItem
                      key={index}
                      whiteSpace="pre-wrap"
                      // add spacing between bullets
                      _notLast={{ marginBottom: 4 }}
                    >
                      {reason}
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Flex>
  );
}

export default CreditIneligibleReasonsBlock;
