import React from "react";
import { Check } from "react-feather";

import {
  Box,
  Flex,
  RadioProps,
  Stack,
  Text,
  useRadio,
  useRadioGroup,
  useTheme,
} from "@chakra-ui/react";

import { Option } from "../types";

import RadioCheckedIcon from "../icons/RadioCheckedIcon";
import RadioUnCheckedIcon from "../icons/RadioUnCheckedIcon";

function Radio(
  props: RadioProps & {
    background?: string;
    horizontal: boolean;
    showCheckbox?: boolean;
    descriptors?: string[];
  },
) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  // TOOD(marcia): Figure out types
  // @ts-ignore
  const isChecked: boolean = input.checked;
  const theme = useTheme();
  const brandMedium = theme.colors.brand.medium;

  return (
    <Box
      width="100%"
      as="label"
      border="1px"
      color={isChecked ? "brand.medium" : ""}
      borderColor={isChecked ? "brand.medium" : "border.medium"}
      backgroundColor={isChecked ? "brand.light" : "white"}
      borderRadius="xl"
      userSelect="none"
      sx={
        props.horizontal
          ? {
              my: 0,
              ":first-of-type": {
                ml: 0,
              },
              ":last-child": {
                mr: 0,
              },
            }
          : {
              mx: 0,
              ":first-of-type": {
                mt: 0,
              },
              ":last-child": {
                mb: 0,
              },
            }
      }
    >
      <input {...input} />
      {/* Set webkit tap highlight color to transparent because the
      default light blue highlight is distracting. It seems to only appear
      in chrome and not in the ios simulator
      */}
      <Stack
        {...checkbox}
        cursor="pointer"
        alignItems="center"
        justifyContent={props.showCheckbox ? "start" : "center"}
        p="3"
        pl={props.showCheckbox ? 5 : 3}
        style={{ WebkitTapHighlightColor: "transparent" }}
      >
        <Flex
          width={props.showCheckbox ? "100%" : undefined}
          alignItems="center"
          gap={3}
        >
          {props.showCheckbox && (
            <Box>
              {isChecked ? <RadioCheckedIcon /> : <RadioUnCheckedIcon />}
            </Box>
          )}
          <Box>
            <Text fontWeight="semibold" color={theme.colors.text.primary}>
              {props.children}
            </Text>
          </Box>
        </Flex>
        {props.descriptors &&
          props.descriptors.map((descriptor, index) => (
            <Flex
              justifyContent="start"
              alignItems="center"
              gap="12px"
              key={index}
              width="100%"
            >
              <Check
                width={16}
                height={16}
                color={brandMedium}
                style={{ flexShrink: 0 }}
              />
              <Text>{descriptor}</Text>
            </Flex>
          ))}
      </Stack>
    </Box>
  );
}

interface Props {
  options: Option[];
  name: string;
  value: string;
  onChange: (value: string) => void;
  horizontal?: boolean;
  variant: string;
  showCheckboxes?: boolean;
}

const Radios: React.FC<Props> = ({
  name,
  value,
  onChange,
  options,
  horizontal = false,
  variant,
  showCheckboxes,
}) => {
  const {
    getRootProps,
    getRadioProps,
    value: radioGroupValue,
  } = useRadioGroup({
    name,
    value,
    onChange,
  });

  const group = getRootProps();
  return (
    <Flex
      // Include this data attribute to make it easier for integration
      // tests to find and click the right option
      data-radio-id={name}
      data-field-name={name}
      data-field-value={radioGroupValue}
      direction={horizontal ? "row" : "column"}
      gridGap={3}
      {...group}
    >
      {options.map(({ value, label, descriptors }) => {
        const radio = getRadioProps({ value: value.toString() });
        return (
          <Radio
            key={value.toString()}
            variant={variant}
            {...radio}
            horizontal={horizontal}
            showCheckbox={showCheckboxes}
            descriptors={descriptors}
          >
            {label}
          </Radio>
        );
      })}
    </Flex>
  );
};
export default Radios;
