// Utility functions for donwloading files from our API
export function download(filename: string, data: string) {
  const downloadLink = document.createElement("a");
  downloadLink.href = `data:text/plain,${encodeURIComponent(data)}`;
  downloadLink.download = filename;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export function downloadBinaryFile(filename: string, data: Blob) {
  const downloadLink = document.createElement("a");
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.download = filename;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

// TODO(marcia): Move to more common utils, since this
// is used for ExpertAssistLandingBlock
export function openLink(url: string) {
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.target = "_blank";

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
