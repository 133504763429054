// Must be passed a complete valid URL or a Column Tax app path
// Our webview is inspecting URLs and checking if they contain
// "columntax-external-link=true". If so, they will open in the
// external browser.
import { authenticateUrl } from "../shared/api";

import { openLink } from "../fileDownloadUtils";

export function convertToExternalBrowserUrl(url: string): string {
  let urlObject;
  try {
    // Try to construct the URL object assuming we've been passed a
    // valid, complete url
    urlObject = new URL(url);
  } catch {
    // If that failed, then we have a Column Tax path that we still want to open
    // in an external browser
    urlObject = new URL(
      `${window.location.origin}${import.meta.env.BASE_URL}${url}`,
    );
  }
  urlObject.searchParams.set("columntax-external-link", "true");
  return urlObject.toString();
}

export function authenticateAndOpenPDF(url: string): void {
  openLink(convertToExternalBrowserUrl(authenticateUrl(url)));
}
