import { useEffect, useLayoutEffect, useState } from "react";

import { useMediaQuery } from "@chakra-ui/media-query";

import { FeatureFlags, NamespacePathElement } from "../types";
import {
  API_PATHS_BY_PRODUCT,
  DEFAULT_API_URL,
  DEFAULT_TAX_YEAR_CONFIG,
  NAV_HEADER_HEIGHT,
} from "./constants";

import { ProductIdentifier } from "../shared/types";

import { Role } from "../diy/internal/types";
import rollbar from "../rollbar-utils";
import { ApiError } from "./errors";

const ADMIN_AUTH_HEADERS = ["access-token", "client", "uid"];

// Matches the values for the REACT_APP_ENVIRONMENT variables set at
// https://vercel.com/column-tax/column/settings/environment-variables
export enum Environment {
  PRODUCTION = "production",
  SANDBOX = "sandbox",
  INTERNAL_TA = "internal-ta",
  NEXT = "next",
  INTERNAL_TA_NEXT = "internal-ta-next",
  PREVIEW = "preview",
  QA = "qa",

  // This is set in .env for local development
  DEVELOPMENT = "development",
}

export function setHref(href: string) {
  // This function only exists here to allow us to spy on its
  // having been called with KLOVER_DEEP_LINK_URL in App.test.tsx
  window.location.href = href;
}

export function setTitle(title: string) {
  let env: string | undefined;
  if (isUsingCodespaceBackend()) {
    env = import.meta.env.REACT_APP_CODESPACE_NAME;
  } else {
    env = import.meta.env.REACT_APP_ENVIRONMENT;
  }
  if (isProduction() || env === undefined) {
    document.title = title;
  } else {
    document.title = `${title} (${env})`;
  }
}

export function isProduction() {
  // production -- https://app.columnapi.com/
  return import.meta.env.REACT_APP_ENVIRONMENT === Environment.PRODUCTION;
}

export function isSandbox() {
  // sandbox, for external customers -- https://app-sandbox.columnapi.com/
  return (
    import.meta.env.REACT_APP_ENVIRONMENT === Environment.SANDBOX ||
    import.meta.env.REACT_APP_ENVIRONMENT === Environment.QA
  );
}

export function isInternalTA() {
  // internal-ta, for internal TA team -- https://app-internal-ta.columnapi.com/
  return import.meta.env.REACT_APP_ENVIRONMENT === Environment.INTERNAL_TA;
}

export function isInternalTANext() {
  // internal-ta-next, for internal TA team -- https://app-internal-ta-next.columnapi.com/
  return import.meta.env.REACT_APP_ENVIRONMENT === Environment.INTERNAL_TA_NEXT;
}

export function isDevelopment() {
  // local development, set with .env
  return import.meta.env.REACT_APP_ENVIRONMENT === Environment.DEVELOPMENT;
}

export function isInternalUse() {
  return isInternalTA() || isDevelopment() || isInternalTANext();
}

export function isHostedInternal() {
  return isInternalTA() || isInternalTANext();
}

export function isPullRequestBackend(apiUrl: string) {
  return apiUrl.indexOf("column-be-review-pr-") !== -1;
}

export function isCodespaceBackend(apiUrl: string) {
  return (
    !!import.meta.env.REACT_APP_CODESPACE_NAME &&
    apiUrl.startsWith(DEFAULT_API_URL)
  );
}

export function isUsingPullRequestBackend() {
  return isPullRequestBackend(ApiUrl.apiUrl);
}

export function isUsingCodespaceBackend() {
  return isCodespaceBackend(ApiUrl.apiUrl);
}

export function isCatalogEditable() {
  // Catalog Editor and ScreenBuilder write to the backend's filesystem. This is only allowed when the backend is a
  // PR environment, or during local development when the local backend is selected.
  return (
    isUsingPullRequestBackend() ||
    isUsingCodespaceBackend() ||
    (isDevelopment() && ApiUrl.branchName != "main")
  );
}

export function getScreenSize() {
  // iphone 6/7/8 plus, iphone x etc
  const isTallPhone = useMediaQuery([
    "(min-width : 375px)",
    "(min-height : 730px)",
  ]).every((val) => val);

  // iphone 6/7/8
  const isMediumPhone = useMediaQuery([
    "(min-width : 375px)",
    "(min-height : 660px)",
  ]).every((val) => val);

  return {
    isTallPhone,
    isMediumPhone: isMediumPhone && !isTallPhone,
  };
}

export function getCookieValue(key: string) {
  const cookies = document.cookie.split(";");
  const cookiesByKey = {} as { [key: string]: string };

  cookies.forEach((cookie) => {
    const split = cookie.trim().split("=");
    cookiesByKey[split[0]] = split[1];
  });

  return cookiesByKey[key];
}

export function getAdminAuth() {
  const headers = {} as { [key: string]: string };

  ADMIN_AUTH_HEADERS.forEach((header) => {
    const item = readFromLocalStorage(header);

    if (item) {
      const now = new Date();
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        removeFromLocalStorage(header);
      }

      headers[header] = item.value;
    }
  });

  return headers;
}

export function saveAdminAuth(headers: Headers) {
  if (ADMIN_AUTH_HEADERS.every((authHeader) => headers.has(authHeader))) {
    // Save admin auth headers if they are all present
    ADMIN_AUTH_HEADERS.forEach((header) => {
      const value = headers.get(header);
      if (value) {
        // devise_token_auth will not send a new value for the access-token
        // if this request is considered to be one of a "batch" of requests.
        // In that case, we don't want to overwrite our value
        // See https://devise-token-auth.gitbook.io/devise-token-auth/conceptual#about-batch-requests
        const now = new Date();
        const item = {
          value: value,
          // Expire in 24 hours, consistent with devise_token_auth's expiry
          expiry: now.getTime() + 24 * 60 * 60 * 1000,
        };

        saveToLocalStorage(header, JSON.stringify(item));
      }
    });
  }
}

export function clearAdminAuth() {
  ADMIN_AUTH_HEADERS.forEach((header) => {
    removeFromLocalStorage(header);
  });
}

export function readFromLocalStorage(key: string) {
  try {
    const value = localStorage.getItem(key) || "null";
    return JSON.parse(value);
  } catch {
    // TODO(marcia): Error handling
  }
}

// TODO: consider JSON.stringify-ing the value before saving to be symmetric
// with readFromLocalStorage
export function saveToLocalStorage(key: string, value: string) {
  try {
    localStorage.setItem(key, value);
  } catch {
    rollbar.warn(`Couldn't add item to local storage ${key}: ${value}`);
  }
}

// Same TODOs for local storage
export function readFromSessionStorage(key: string, suppressError = false) {
  try {
    const value = sessionStorage.getItem(key) || "null";
    return JSON.parse(value);
  } catch {
    if (!suppressError) {
      rollbar.warn(`Couldn't retrieve item from session storage ${key}`);
    }
  }
}

export function saveToSessionStorage(key: string, value: string) {
  try {
    sessionStorage.setItem(key, value);
  } catch {
    rollbar.warn(`Couldn't add item to session storage ${key}: ${value}`);
  }
}

function removeFromLocalStorage(key: string) {
  try {
    localStorage.removeItem(key);
  } catch {
    rollbar.warn(`Couldn't remove item from local storage ${key}`);
  }
}

export function clearLocalStorage() {
  try {
    localStorage.clear();
  } catch {
    rollbar.warn(`Couldn't clear local storage`);
  }
}

export function renderNamespacePathElement(
  namespacePathElement: NamespacePathElement,
) {
  let index = "";
  if (namespacePathElement.index != null) {
    index = `[${namespacePathElement.index}]`;
  }
  return `${namespacePathElement.internalId}${index}`;
}

export function getErrorMessage(error: unknown): string {
  if (error instanceof Error) return error.message;
  return String(error);
}

export function getErrorBacktrace(error: unknown): string[] | undefined {
  if (error instanceof ApiError) return error.backtrace;
  return undefined;
}

export function isInternalUser(role: Role) {
  return role === Role.INTERNAL;
}

export function getInternalRequestSecret() {
  return import.meta.env.REACT_APP_INTERNAL_REQUEST_SHARED_SECRET || "null";
}

export function getDevelopmentRequestSecret() {
  return import.meta.env.REACT_APP_GITHUB_TOKEN;
}

// TODO: unite this with useTaxYear in catalog/utils.ts
export function getCurrentSessionOrDefaultTaxYear() {
  const taxYear = readFromSessionStorage("taxYear", true);

  if (taxYear && taxYear != "null") {
    return taxYear;
  }

  return DEFAULT_TAX_YEAR_CONFIG;
}

// TODO(marcia): Rename to represent both api url and branch name concepts
export const ApiUrl = {
  apiUrl:
    new URLSearchParams(window.location.search).get("apiUrl") ||
    DEFAULT_API_URL,
  supportApiUrl:
    import.meta.env.REACT_APP_API_SUPPORT_URL ||
    new URLSearchParams(window.location.search).get("apiUrl") ||
    DEFAULT_API_URL,

  branchName: "main",
};

export function apiUrl() {
  return commonApiUrl(ApiUrl.apiUrl);
}

export function supportApiUrl() {
  return commonApiUrl(ApiUrl.supportApiUrl);
}

export function saveLocalBranchName(branchName: string) {
  ApiUrl.branchName = branchName;
}

export function commonApiUrl(url: string) {
  const taxYear = getCurrentSessionOrDefaultTaxYear();

  const apiUrl = new URL(url);

  // If we do not specify a tax year, then we are defaulting to using the current tax year
  // However, when we are developing, we want to be able to specify other tax years
  // The api_url can either be /TYxx OR /api/TYxx. If the api_url is /api/TYXX, it is
  // for codespaces.
  if (taxYear == DEFAULT_TAX_YEAR_CONFIG || apiUrl.pathname.includes(taxYear)) {
    return url;
  }

  return `${url}/${taxYear}`;
}

export function setApiUrl(apiUrl: string) {
  // strip trailing slash if it exists
  ApiUrl.apiUrl = apiUrl.replace(/\/$/, "");

  // store the url as a param so TAs can save a link to it and not have to re-pick
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set("apiUrl", ApiUrl.apiUrl);
  window.location.search = searchParams.toString();
}

export function getApiUrlByProduct(
  key: ProductIdentifier,
  featureFlags?: FeatureFlags | null,
) {
  if (
    key === ProductIdentifier.DIY_UPLOAD_W2 &&
    featureFlags?.useSlowBackendForW2Uploads
  ) {
    return `${supportApiUrl()}${API_PATHS_BY_PRODUCT[key]}`;
  }

  return `${apiUrl()}${API_PATHS_BY_PRODUCT[key]}`;
}

export function useAppHeight() {
  const [appHeight, setAppHeight] = useState(window.innerHeight);

  const measure = () => {
    const doc = document.documentElement;
    doc.style.setProperty(
      "--chakra-sizes-appHeight",
      `${window.innerHeight - NAV_HEADER_HEIGHT}px`,
    );
    // (Tomasz) we need inner size as well for Manage Pattern Screen that uses it to position CTA buttons.
    doc.style.setProperty(
      "--chakra-sizes-appInnerHeight",
      `${window.innerHeight - NAV_HEADER_HEIGHT * 2}px`,
    );
    setAppHeight(window.innerHeight);
  };

  useEffect(() => {
    measure();
  }, []);

  useLayoutEffect(() => {
    if (window) {
      window.addEventListener("resize", measure);
      return () => {
        window.removeEventListener("resize", measure);
      };
    }
  }, [window.innerHeight]);

  return appHeight;
}
