import React from "react";

import { Box, Center, Divider, Flex, Text, useTheme } from "@chakra-ui/react";

import { ScheduleCOverviewBlock as ScheduleCBlockType } from "../types";

import { centsToUsdNoCents } from "../helpers/CurrencyFormatter";
import SparkleFillIcon from "../icons/SparkleFillIcon";
import CarIcon from "../icons/car.svg";
import IconChip from "./Chip";

function ScheduleCOverviewBlock({
  businesses,
  itemHeader,
}: ScheduleCBlockType) {
  const theme = useTheme();
  const brandDarkColor = theme.colors.brand.dark;

  return (
    <>
      {businesses.map((business) => (
        <Box
          rounded="card"
          boxShadow="card"
          backgroundColor="white"
          key={business.id}
          padding={{ base: 5, sm: 6, md: 8 }}
          gap={{ base: 3, sm: 4 }}
          display="flex"
          flexDirection="column"
        >
          {/* 'row-reverse' is utilized for larger screens to ensure proper horizontal content placement. 
      When designing or debugging, consider the placement logic from a 'reversed' perspective. */}
          <Flex
            flexDirection={{ base: "column", sm: "row-reverse" }}
            justifyContent={{ base: "flex-start", sm: "space-between" }}
            alignItems={{ base: "flex-start", sm: "center" }}
            gap={{ base: 2, sm: 4 }}
          >
            <IconChip
              icon={<SparkleFillIcon color={brandDarkColor} />}
              label={business.importedSourceLabel}
              backgroundColor="brand.light"
              contentColor={brandDarkColor}
            />
            <Box>
              <Text fontSize="xl" fontWeight="bold">
                {itemHeader}
              </Text>
            </Box>
          </Flex>
          <Flex gap={1} flexDir="column">
            <Flex justifyContent="space-between">
              <Text>Business expenses</Text>
              <Text fontWeight="semibold">
                {centsToUsdNoCents(business.totalBusinessExpenses)}
              </Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text>Vehicle expenses</Text>
              <Text fontWeight="semibold">
                {centsToUsdNoCents(business.totalVehicleExpenses)}
              </Text>
            </Flex>
          </Flex>
          <Divider
            borderColor="border.medium"
            borderStyle="dotted"
            mb={0}
            mt={0}
          />
          <Flex justifyContent="space-between">
            <Text fontWeight="semibold">Total expenses</Text>
            <Text fontWeight="semibold">
              {centsToUsdNoCents(
                business.totalVehicleExpenses + business.totalBusinessExpenses,
              )}
            </Text>
          </Flex>
          <Flex>
            <Box
              p="1"
              pr="2"
              mr="2"
              backgroundColor="background.light"
              borderRadius="full"
              fontSize="sm"
              fontWeight="medium"
            >
              <Flex alignItems="center">
                <Center
                  width="6"
                  height="6"
                  mr="2"
                  borderRadius="full"
                  backgroundColor="background.dark"
                >
                  {business.totalNumBusinessExpenses}
                </Center>
                <Text>Expense categories</Text>
              </Flex>
            </Box>
            {business.totalVehicles > 0 && (
              <Box
                p="1"
                pr="2"
                backgroundColor="background.light"
                borderRadius="full"
                fontSize="sm"
                fontWeight="medium"
              >
                <Flex alignItems="center">
                  <Center
                    height="6"
                    px="1"
                    mr="2"
                    borderRadius="full"
                    backgroundColor="background.dark"
                  >
                    <img src={CarIcon} />
                    {business.totalVehicles > 1 && (
                      <Text ml="1">{`x${business.totalVehicles}`}</Text>
                    )}
                  </Center>
                  <Text>
                    {new Intl.NumberFormat("en-US", {
                      notation: "compact",
                      compactDisplay: "short",
                    }).format(business.totalVehicleMiles)}{" "}
                    miles
                  </Text>
                </Flex>
              </Box>
            )}
          </Flex>
        </Box>
      ))}
    </>
  );
}

export default ScheduleCOverviewBlock;
