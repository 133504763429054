import React from "react";

function Archive() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.74999 6.80469C4.13352 6.80469 4.44443 7.1156 4.44443 7.49913L4.44443 15.8325L15.5555 15.8325L15.5555 7.49913C15.5555 7.1156 15.8665 6.80469 16.25 6.80469C16.6335 6.80469 16.9444 7.1156 16.9444 7.49913V16.5269C16.9444 16.9104 16.6335 17.2214 16.25 17.2214H3.74999C3.36646 17.2214 3.05554 16.9104 3.05554 16.5269L3.05554 7.49913C3.05554 7.1156 3.36646 6.80469 3.74999 6.80469Z"
        fill="#293056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66669 4.02843C1.66669 3.6449 1.9776 3.33398 2.36113 3.33398L17.6389 3.33398C18.0224 3.33398 18.3334 3.6449 18.3334 4.02843V7.50065C18.3334 7.88418 18.0224 8.1951 17.6389 8.1951L2.36113 8.1951C1.9776 8.1951 1.66669 7.88418 1.66669 7.50065L1.66669 4.02843ZM3.05558 4.72287L3.05558 6.80621L16.9445 6.80621V4.72287L3.05558 4.72287Z"
        fill="#293056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.91669 10.2784C7.91669 9.8949 8.2276 9.58398 8.61113 9.58398H11.3889C11.7724 9.58398 12.0834 9.8949 12.0834 10.2784C12.0834 10.662 11.7724 10.9729 11.3889 10.9729H8.61113C8.2276 10.9729 7.91669 10.662 7.91669 10.2784Z"
        fill="#293056"
      />
    </svg>
  );
}

export default Archive;
