import React from "react";

interface QuestionIconOutlinedProps {
  className?: string;
  width?: number | string;
  height?: number | string;
}

const QuestionIconOutlined: React.FC<QuestionIconOutlinedProps> = ({
  className = "",
  width = 30,
  height = 30,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Icons / Help Circle">
        <g id="Group 1307">
          <path
            id="Vector (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 4.87364C9.53993 4.87364 5.11366 9.29991 5.11366 14.76C5.11366 20.2201 9.53993 24.6464 15 24.6464C20.4601 24.6464 24.8864 20.2201 24.8864 14.76C24.8864 9.29991 20.4601 4.87364 15 4.87364ZM2.91669 14.76C2.91669 8.08656 8.32658 2.67667 15 2.67667C21.6735 2.67667 27.0834 8.08656 27.0834 14.76C27.0834 21.4334 21.6735 26.8433 15 26.8433C8.32658 26.8433 2.91669 21.4334 2.91669 14.76Z"
            fill="#293056"
          />
          <path
            id="Vector (Stroke)_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2836 10.3928C14.7722 10.3051 14.2463 10.4012 13.799 10.6641C13.3517 10.9269 13.0118 11.3396 12.8397 11.8291C12.6384 12.4014 12.0112 12.7021 11.4389 12.5008C10.8666 12.2995 10.5659 11.6723 10.7672 11.1C11.1115 10.1212 11.7912 9.29575 12.6858 8.76998C13.5804 8.24421 14.6322 8.05201 15.655 8.22744C16.6777 8.40287 17.6054 8.93459 18.2736 9.72843C18.9417 10.5221 19.3075 11.5266 19.3061 12.564C19.3056 14.2451 18.0592 15.3553 17.1692 15.9486C16.6907 16.2677 16.22 16.5022 15.8732 16.6563C15.6983 16.7341 15.551 16.7931 15.4448 16.8335C15.3916 16.8538 15.3485 16.8695 15.3172 16.8806L15.279 16.894L15.2669 16.8981L15.2626 16.8996L15.2609 16.9002C15.2606 16.9003 15.2595 16.9006 14.9121 15.8585L15.2595 16.9006C14.684 17.0925 14.0619 16.7814 13.87 16.2059C13.6783 15.6307 13.9888 15.0091 14.5636 14.8168L14.5622 14.8172C14.5623 14.8172 14.5624 14.8172 14.5636 14.8168L14.5812 14.8106C14.5981 14.8046 14.6257 14.7946 14.6627 14.7805C14.7368 14.7523 14.8469 14.7083 14.9809 14.6487C15.2521 14.5282 15.6052 14.3509 15.9505 14.1206C16.7081 13.6156 17.1091 13.0784 17.1091 12.563L17.1091 12.5614C17.1099 12.0426 16.927 11.5402 16.5929 11.1433C16.2588 10.7464 15.7949 10.4805 15.2836 10.3928Z"
            fill="#293056"
          />
          <path
            id="Vector (Stroke)_3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.9015 20.2524C13.9015 19.6457 14.3933 19.1539 15 19.1539H15.011C15.6176 19.1539 16.1094 19.6457 16.1094 20.2524C16.1094 20.8591 15.6176 21.3509 15.011 21.3509H15C14.3933 21.3509 13.9015 20.8591 13.9015 20.2524Z"
            fill="#293056"
          />
        </g>
      </g>
    </svg>
  );
};

export default QuestionIconOutlined;
