import React from "react";

import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";

import { onExit as onExitSDK } from "../utils/api";
import QuestionText from "./QuestionText";

/**
 * Note that this is TRU only close button and there is
 * a more recent diy/components/CloseButton!
 */
function CloseButton({
  renderCloseConfirmationModal,
}: {
  renderCloseConfirmationModal: boolean;
}) {
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  return (
    <>
      {/* Close icon in the upper right of most screens.
       In the common case, clicking the close icon will show a confirmation modal.
       Sometimes, eg on the error screen where they cannot do anything anyway (for now?),
       we will skip the confirmation modal and immediately exit the SDK.
      */}
      <Box
        p="2"
        m="-2"
        data-testid="close-button"
        onClick={renderCloseConfirmationModal ? onOpenModal : onExitSDK}
      >
        {/* TODO(marcia): Switch to using IconButton instead: https://chakra-ui.com/docs/form/icon-button
        And, probably after that, we could use a more semantic way of getting this
        element and not using a data-testid
        */}
        <CloseIcon color="text.primary" _active={{ color: "text.primary" }} />
      </Box>

      {/* Modal that asks the user to confirm they want to exit */}
      <Modal
        isOpen={isOpenModal}
        onClose={onCloseModal}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          height="full"
          maxHeight="full"
          margin="0"
          borderRadius="0"
        >
          {/* Use safe-area-inset-top to account for iphone notch */}
          <ModalBody paddingTop="max(20px, env(safe-area-inset-top))">
            <Flex direction="column" height="full" py="10">
              <QuestionText text="Are you sure you want to exit?" />
              <Spacer />
              <Button onClick={onExitSDK} width="full">
                Yes, exit
              </Button>
              <Button
                onClick={onCloseModal}
                variant="outline"
                width="full"
                mt="5"
              >
                No, continue
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CloseButton;
