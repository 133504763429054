import React from "react";

import { Box } from "@chakra-ui/react";

// TODO(marcia): Figure out how to easily convert to css module
import "./AnimatedProgressBar.scss";

function AnimatedProgressBar() {
  return (
    <Box
      _after={{ background: "brand.medium" }}
      className="animated-progress-bar"
      width="100%"
    ></Box>
  );
}

export default AnimatedProgressBar;
