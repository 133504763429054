import React from "react";

import { Box, Button } from "@chakra-ui/react";

import { authenticateUrl } from "../../shared/api";

import { openLink } from "../../fileDownloadUtils";
import { convertToExternalBrowserUrl } from "../../utils/nav-utils";

interface Props {
  label?: string;
  url: string;
  authenticate: boolean;
}

const SaveAsPdfBlock: React.FC<Props> = ({
  label = "Save Return as PDF",
  url,
  authenticate,
}) => {
  const src = authenticate ? authenticateUrl(url) : url;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      // A button should take up half container width minus half of row grid gap
      minW={{ md: "calc(50% - 6px)" }}
      mt={{ base: 4, md: 0 }}
      flexGrow={1}
      width={{ base: "80%", md: "300px" }}
    >
      <Button
        mt={{ base: 4, md: 0 }}
        variant="outline"
        width="full"
        onClick={() => {
          openLink(convertToExternalBrowserUrl(src));
        }}
        maxW={{ md: "300px" }}
      >
        {label}
      </Button>
    </Box>
  );
};

export default SaveAsPdfBlock;
