import React from "react";
import { FiCornerDownRight } from "react-icons/fi";

import { Box, Circle, Flex, List, ListIcon, ListItem } from "@chakra-ui/react";

import {
  BlockType,
  ListItemIcon,
  ListItemWithIconBlock,
  NewTextBlock as NewTextBlockType,
  TextCompositionBlock as TextCompositionBlockType,
} from "../types";

import GreenFilledCheck from "../../icons/GreenFilledCheck";
import rollbar from "../../rollbar-utils";
import NewTextBlock from "./NewTextBlock";
import TextCompositionBlock from "./TextCompositionBlock";

interface Props {
  justifyContent?: string;
  content: Array<
    NewTextBlockType | ListItemWithIconBlock | TextCompositionBlockType
  >;
}

const iconColor = "text.secondary";

const UnorderedListBlock: React.FC<Props> = ({ content }) => {
  function CircleIcon() {
    return <Circle bg="black" size="5px" display="inline-block" />;
  }

  return (
    <List spacing={4}>
      {content.map((item, index) => {
        switch (item.type) {
          case BlockType.TEXT_COMPOSITION_BLOCK:
            return (
              <ListItem key={index} mb={4}>
                <Flex gap={2} alignItems="center">
                  <ListIcon color={iconColor} key={index} as={CircleIcon} />
                  <TextCompositionBlock
                    {...item}
                    key={index}
                    justifyContent="center"
                  />
                </Flex>
              </ListItem>
            );
          case BlockType.NEW_TEXT_BLOCK:
            return (
              <ListItem key={index} mb={4}>
                <Flex gap={2} alignItems="center">
                  <ListIcon color={iconColor} key={index} as={CircleIcon} />
                  <NewTextBlock {...item} marginBottom="0" display="inline" />
                </Flex>
              </ListItem>
            );
          case BlockType.LIST_ITEM_WITH_ICON_BLOCK: {
            const { icon } = item;

            let iconComponent = undefined;
            switch (icon) {
              case ListItemIcon.CORNER_DOWN_RIGHT:
                iconComponent = FiCornerDownRight;
                break;
              case ListItemIcon.GREEN_FILLED_CHECK:
                iconComponent = GreenFilledCheck;
                break;
              default:
                iconComponent = CircleIcon;
                break;
            }

            return (
              <ListItem>
                <Flex
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDir="row"
                  gap={2}
                >
                  <Box width="20px" height="20px">
                    <ListIcon
                      as={iconComponent}
                      color={iconColor}
                      width="100%"
                      height="100%"
                    />
                  </Box>
                  <Box>
                    <NewTextBlock {...item.blockItem} display="inline" />
                  </Box>
                </Flex>
              </ListItem>
            );
          }

          default:
            rollbar.error("Unexpected block type for UnorderedListBlock");
        }
      })}
    </List>
  );
};

export default UnorderedListBlock;
