import React from "react";

interface Props {
  isEnabled?: boolean;
}

function PersonPlusCircle({ isEnabled }: Props) {
  const color = isEnabled ? "#0070FF" : "#C4C4C4";

  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="19" r="18.5" fill="white" stroke="#E0E0E1" />
      <circle cx="18" cy="13.499" r="3" stroke={color} strokeWidth="2" />
      <path
        d="M20.5 27.4985L11.1828 27.499C10.5641 27.499 10.0939 26.9428 10.1969 26.3327V26.3327C10.7655 22.9646 13.6824 20.499 17.0981 20.499C17.2401 20.499 17.3746 20.499 17.5 20.499C19.5 20.499 20.5 20.9985 20.5 20.9985"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 20.501C23.4477 20.501 23 20.9487 23 21.501V23.5H21C20.4477 23.5 20 23.9477 20 24.5C20 25.0523 20.4477 25.5 21 25.5H23V27.501C23 28.0533 23.4477 28.501 24 28.501C24.5523 28.501 25 28.0533 25 27.501V25.5H27C27.5523 25.5 28 25.0523 28 24.5C28 23.9477 27.5523 23.5 27 23.5H25V21.501C25 20.9487 24.5523 20.501 24 20.501Z"
        fill={color}
      />
    </svg>
  );
}

export default PersonPlusCircle;
