import React, { useContext, useEffect, useRef, useState } from "react";
import Zendesk from "react-zendesk";

import { handleBack, handleSubmit, handleUploadW2 } from "./api";
import {
  Action,
  ActionType,
  AlertTypes,
  AuthScreenProps,
  BlockType,
  FieldAnswer,
  InterludeScreenProps,
  ManageScreenProps,
  NavigationScreenProps,
  QuestionScreenProps,
  Screen,
  ScreenType,
  UploadW2Action,
  UserDetails,
} from "./types";

import { NotAuthorizedError } from "../shared/api";

import NavMenu from "./components/NavMenu";

import { FeatureFlagContext } from "../App";
import useActiveTimeTracker from "../useActiveTimeTracker";
import { onNavigate, onUserEvent } from "../utils/api";
import { redirectToExitPage } from "../utils/api";
import { delay } from "../utils/general";
import {
  clearScreenFromUrlParams,
  loadScreenFromUrl,
  saveScreenToUrlParams,
} from "../utils/params-utils";
import {
  isDevelopment,
  isHostedInternal,
  isProduction,
  isSandbox,
} from "../utils/utils";
import {
  ZENDESK_BASE_SETTINGS,
  ZENDESK_KEY,
  ZENDESK_SANDBOX_KEY,
} from "../zendesk";
import TaxFilingContainer from "./TaxFilingContainer";
import { NavigationMenuContext } from "./helpers/navigationMenuContext";
import { NavigationContextProvider } from "./navigationContext";
import AuthScreen from "./screens/AuthScreen";
import InterludeScreen from "./screens/InterludeScreen";
import ManageScreen from "./screens/ManageScreen";
import NavigationScreen from "./screens/NavigationScreen";
import QuestionScreen from "./screens/QuestionScreen";
import { UserContextProvider } from "./userContext";

function TaxFilingSDK({
  initialScreen,
  userDetails,
  updateNav,
}: {
  initialScreen: Screen | null;
  userDetails: UserDetails | null;

  // IFF screen is set, use that to update the Nav
  updateNav: (screen: Screen | null) => Promise<void>;
}) {
  const { nav } = useContext(NavigationMenuContext);
  const [screen, setScreen] = useState<Screen | null>(null);

  const conditionallyUpdateNav = (
    currentScreen: Screen,
    nextScreen: Screen,
  ) => {
    if (
      // If the new screen response included nav data, update the nav with it
      nextScreen.nav ||
      // Otherwise, update if we're transitioning to or from a manage or navigation screen
      (currentScreen &&
        ([ScreenType.MANAGE, ScreenType.NAVIGATION].includes(
          currentScreen.screenType,
        ) ||
          [ScreenType.MANAGE, ScreenType.NAVIGATION].includes(
            nextScreen.screenType,
          ))) ||
      // always refresh if previously disabled because we might be able to un-DQ ourselves depending on the response on the question screen
      nav?.navigationDisabled
    ) {
      updateNav(nextScreen);
    }
  };

  const featureFlags = useContext(FeatureFlagContext);
  const allowBrowserBack = useRef(true);

  // Start tracking time user is actively tax filing

  if (!isDevelopment()) {
    useActiveTimeTracker();
  }

  useEffect(() => {
    window.scrollTo({ top: 0 });
    onNavigate(window.location.href);
  }, [screen?.id]);

  useEffect(() => {
    // While we are switching to always returning a true/false value
    // here -- we will only disallow navigating back via the browser
    // if allowBrowserBack is explicitly false. A null value will
    // be interpreted as "yes, allow!"
    allowBrowserBack.current = !(screen?.allowBrowserBack === false);
  }, [screen]);

  useEffect(() => {
    // Each time our screen changes, update the URL to reflect the new screen
    // via window.history.pushState. When the user clicks the browser back
    // and forward buttons, this will trigger a popState event, which we
    // listen for below.
    if (screen) {
      saveScreenToUrlParams(screen);
    }
  }, [featureFlags, screen?.id]);

  useEffect(() => {
    // Listen for popstate events which correspond to each
    // window.history.pushState call we'd made with each
    // screen transition and saveScreenToUrlParams.
    const onPopstate = async () => {
      // Use reference because otherwise we will trap via closure a
      // stale value of the screen state.
      // See https://stackoverflow.com/questions/60540985/react-usestate-doesnt-update-in-window-events
      if (!allowBrowserBack.current) {
        // Imagine the following flow:
        //   Summary Screen -> R&F Screens -> Submit Return -> Submission Pending Screen
        // The taxpayer will have transitioned through many many on their way to the
        // final Submission Pending Screen, which disallows browser back navigation.
        // When the user clicks the browser back button, a popstate event will be triggered
        // and we will:
        // 1. Clear the screen info from the URL params. The current URL will point to one of
        //    of the prior screens (Summary, Review & File, Submit Return). We clear that screen
        //    info so that a copy/paste or reload of the URL will show whatever the BE designates
        //    as the correct screen -- in this case, the SubmissionPendingScrene.
        // 2. We return early. This prevents us from re-fetching any screen content. We really just
        //    want to keep the user on this screen when they navigate back.
        clearScreenFromUrlParams();
        return;
      }
      // Load the screen represented in the url, as identified by
      // screen id and resource.
      const result = await loadScreenFromUrl();

      if (result.isValid) {
        const { screen } = result.payload as {
          screen: Screen;
        };

        setScreen(screen);
      } else {
        // TODO(marcia): We probably want to render a more specific exit
        // page about re-sending a magic link. This is the same redirect that
        // happens if a user tries to answer/submit a screen but gets an
        // unauthorized error.
        redirectToExitPage();
      }
    };
    window.addEventListener("popstate", onPopstate);

    return () => {
      window.removeEventListener("popstate", onPopstate);
    };
  }, []);

  useEffect(() => {
    if (screen?.errors && screen.errors.length !== 0) {
      // Delay so that it scrolls only after all error messages are rendered
      delay(10).then(() =>
        window.scrollTo({ top: document.body.scrollHeight }),
      );
    }
  }, [screen?.errors]);

  // setup FullView
  useEffect(() => {
    if (!userDetails) return;
    if (!featureFlags?.enableFullview) return;

    window.$fvIdentity = {
      // userID is a UUID, which is what we want to send
      id: userDetails.userId,
      name: userDetails.name ?? "",
      email: userDetails.email,
      // disableReplaysForUser will opt-the user out of having recordings/replays in FullView. This is false for all users for now (https://support.fullview.io/en/articles/6122361-how-to-install-fullview)
      disableReplaysForUser: false,
      // env corresponds to FullView's environment segmentation (everything goes to the same FullView instance, but users get tagged by env)
      env: isProduction() ? "production" : "sandbox",
    };
  }, [userDetails, featureFlags]);

  useEffect(() => {
    if (initialScreen !== null) {
      setScreen(initialScreen);
      updateNav(initialScreen);
    }
  }, [initialScreen]);

  const onSubmit = async (action: Action) => {
    if (!screen) {
      return;
    }
    try {
      let response;
      if (action.type === ActionType.UPLOAD_W2) {
        response = await handleUploadW2({
          action: action as UploadW2Action,
          featureFlags,
        });
      } else {
        response = await handleSubmit({ screen, action });
      }
      const nextScreen: Screen = response.screen;
      const currentScreen = screen;
      setScreen(nextScreen);

      conditionallyUpdateNav(currentScreen, nextScreen);

      // Fire any events associated with the response from the BE
      nextScreen.events.forEach((event) => {
        // Catch any errors in case partner code fails
        try {
          onUserEvent(event);
        } catch (err) {
          console.error(err);
        }
      });
    } catch (error) {
      if (error instanceof NotAuthorizedError) {
        redirectToExitPage();
      }

      screen.errors = [
        {
          id: "error",
          type: BlockType.ALERT,
          title: "Something went wrong",
          text: "We're not quite sure what happened. Check to make sure all your information is entered correctly, and try again.",
          status: AlertTypes.ERROR,
        },
      ];
      setScreen({ ...screen });
    }
  };

  const onBack = async () => {
    if (!screen) {
      return;
    }
    try {
      const response = await handleBack({ screen });
      const nextScreen: Screen = response.screen;
      const currentScreen = screen;
      setScreen(nextScreen);
      conditionallyUpdateNav(currentScreen, nextScreen);
    } catch (error) {
      if (error instanceof NotAuthorizedError) {
        redirectToExitPage();
      }
    }
  };

  let Component;
  if (!screen || !userDetails) {
    return <></>;
  }

  const zendeskSettings = {
    ...ZENDESK_BASE_SETTINGS,
    chat: {
      suppress: !userDetails.chatEnabled,
    },
    talk: {
      suppress: !userDetails.talkEnabled,
    },
  };

  const useZendeskSandbox = !!(featureFlags && featureFlags.useZendeskSandbox);

  const updateAnswer = (blockId: string, answer: FieldAnswer) => {
    if (screen.screenType === ScreenType.QUESTION) {
      const updatedBlocks = screen.blocks.map((block) => {
        return block.id === blockId ? { ...block, answer } : block;
      });

      setScreen({
        userEmail: screen.userEmail,
        id: screen.id,
        title: screen.title,
        screenType: ScreenType.QUESTION,
        resource: screen.resource,
        renderBack: screen.renderBack,
        blocks: updatedBlocks,
        progressBarValue: screen.progressBarValue,
        callToAction: screen.callToAction,
        hostBankName: screen.hostBankName,
        events: screen.events,
        centerContent: screen.centerContent,
        showLauncher: screen.showLauncher,
        customMenuItems: [],
        expertAssistScreenId: screen.expertAssistScreenId,
      });
    }
  };

  const supportEnabled = userDetails.supportEnabled;

  switch (screen.screenType) {
    case ScreenType.QUESTION: {
      const props: QuestionScreenProps = {
        screen,
        onSubmit,
        onBack,
        updateAnswer,
        supportEnabled,
        setScreen,
      };
      Component = <QuestionScreen {...props} />;
      break;
    }
    case ScreenType.MANAGE: {
      const props: ManageScreenProps = {
        screen,
        onBack,
        onSubmit,
        supportEnabled,
        setScreen,
      };
      Component = <ManageScreen {...props} />;
      break;
    }
    case ScreenType.NAVIGATION: {
      const props: NavigationScreenProps = {
        screen,
        onSubmit,
        onBack,
        supportEnabled,
        setScreen,
      };
      Component = <NavigationScreen {...props} />;
      break;
    }
    case ScreenType.INTERLUDE: {
      const props: InterludeScreenProps = {
        screen,
        onSubmit,
        supportEnabled,
        setScreen,
      };
      Component = <InterludeScreen {...props} />;
      break;
    }

    case ScreenType.AUTH: {
      const props: AuthScreenProps = {
        screen,
        onSubmit,
        onBack,
        supportEnabled,
        setScreen,
      };

      Component = <AuthScreen {...props} />;
      break;
    }
  }

  const zendeskKey = useZendeskSandbox ? ZENDESK_SANDBOX_KEY : ZENDESK_KEY;

  return (
    <UserContextProvider userDetails={userDetails}>
      <NavigationContextProvider>
        <TaxFilingContainer screenId={screen.id}>
          <>
            <NavMenu
              screen={screen}
              onSubmit={onSubmit}
              userDetails={userDetails}
            />
            {Component}
            {(isProduction() || isHostedInternal() || isSandbox()) &&
              userDetails.supportEnabled && (
                <Zendesk defer zendeskKey={zendeskKey} {...zendeskSettings} />
              )}
          </>
        </TaxFilingContainer>
      </NavigationContextProvider>
    </UserContextProvider>
  );
}

export default TaxFilingSDK;
