import { Circle, Image } from "@chakra-ui/react";

interface Props {
  hostBankLogoFilename: string;
  onError: () => void;
  fullSizeLogo?: boolean;
}

const HOSTBANK_LOGO_FOLDER = "images/logos/square";

// hardcoded height for every element, to ensure proper display
export const LOGO_HEIGHT = "60px";
export const INNER_LOGO_HEIGHT = "32px";

export function hostBankLogoFilenameToSrc(
  hostBankLogoFilename: string,
): string {
  // By default, newer logos are served straight from a CDN
  if (hostBankLogoFilename.startsWith("https")) {
    return hostBankLogoFilename;
  }

  // The legacy path refers to images in our frontend repository
  return `${HOSTBANK_LOGO_FOLDER}/${hostBankLogoFilename}`;
}

function HostBankLogo({ hostBankLogoFilename, onError, fullSizeLogo }: Props) {
  return (
    <Circle
      size={LOGO_HEIGHT}
      borderWidth={1}
      borderColor="border.medium"
      borderStyle="solid"
      backgroundColor="white"
    >
      <Image
        onError={() => onError()}
        src={hostBankLogoFilenameToSrc(hostBankLogoFilename ?? "")}
        boxSize={fullSizeLogo ? undefined : INNER_LOGO_HEIGHT}
        htmlHeight={fullSizeLogo ? undefined : INNER_LOGO_HEIGHT}
      />
    </Circle>
  );
}

export default HostBankLogo;
