import { useState } from "react";

import { BooleanQuestion, CurrencyQuestion, IntegerQuestion } from "../types";

// The BE validates emails at the model level with the URI::MailTo::EMAIL_REGEXP regex
// See https://github.com/ruby/ruby/blob/13b17cb8fcdc1d37ac9708b344ac69be61c51681/lib/uri/mailto.rb#L55
// We use the same regex here in the FE, with the following modifications:
// - Use ^ and $ plus multiline flag m instead of \A and \z
// - Removed unnecessary escaping \#, \/
const EMAIL_REGEXP =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/m;

export function validateEmail(input: string): {
  isValid: boolean;
  errorMessage?: string;
} {
  if (input === "") {
    return { isValid: false };
  }
  if (EMAIL_REGEXP.test(input)) {
    return { isValid: true };
  } else {
    return {
      isValid: false,
      errorMessage: "Please enter a valid email address.",
    };
  }
}

export function validateCurrency(
  input: string,
  maximum = 1000000,
  errorMessage = "Please enter an amount under $1 million.",
): {
  isValid: boolean;
  errorMessage?: string;
} {
  if (input === "") {
    return { isValid: false };
  }

  const inputAsFloat = parseFloat(input);
  if (inputAsFloat < 0 || inputAsFloat > maximum) {
    return {
      isValid: false,
      errorMessage: errorMessage,
    };
  }

  return { isValid: true };
}

// Given a string representing a whole number, return a string
// for that same number with thousands separator commas inserted.
// eg: insertCommasForDisplay("1234") --> "1,234"
export function insertCommasForDisplay(value: string) {
  const parsed = parseInt(value);
  if (isNaN(parsed)) {
    return "";
  } else {
    return parsed.toLocaleString();
  }
}

function numCommas(answer: string) {
  const localeString = insertCommasForDisplay(answer);
  return (localeString.match(/,/g) || []).length;
}

export function formatCurrencyValue(value: number) {
  const numFractionDigits = value % 1 === 0 ? 0 : 2;
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    // we need to specify both minimum and maximum fraction digits to support old browsers
    // for 'currency', minimumFractionDigits is defaulted to 2 and the constructor in old
    // browsers had an invariant that maximumFractionDigits >= minimumFractionDigits
    minimumFractionDigits: numFractionDigits,
    maximumFractionDigits: numFractionDigits,
  });
}

function restoreCursor(
  event: React.ChangeEvent<HTMLInputElement>,
  answer: string,
  newAnswer: string,
) {
  // Restore cursor position as we possibly add/remove commas.
  // Otherwise, the cursor will jump to the end
  // See https://stackoverflow.com/a/49648061
  const initialCursor = event.target.selectionStart;

  if (initialCursor) {
    const diff = numCommas(newAnswer) - numCommas(answer);
    const updatedCursor = initialCursor + diff;

    const element = event.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = updatedCursor;
      element.selectionEnd = updatedCursor;
    });
  }
}

export function useBooleanState(question: BooleanQuestion): {
  answer: string;
  setAnswer: React.Dispatch<React.SetStateAction<string>>;
  transformAnswer: (answer: string) => boolean;
} {
  // On the FE, we convert the boolean answers to strings because
  // that's what the chakra ui radio buttons will expect/convert to
  const [answer, setAnswer] = useState(
    question.answer === null ? "" : question.answer.toString(),
  );

  return {
    answer,
    setAnswer,
    transformAnswer: transformAnswerToBoolean,
  };
}

export function useIntegerState(question: IntegerQuestion | CurrencyQuestion): {
  answer: string;
  setAnswer: React.Dispatch<React.SetStateAction<string>>;
  transformAnswer: (answer: string) => number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} {
  // On the FE, we convert the integer answers to strings that
  // makes it simpler when working with the inputs
  const [answer, setAnswer] = useState(
    question.answer === null ? "" : question.answer.toString(),
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    let newAnswer = event.target.value;

    // For integers, we'll look at just the digits (and strip out
    // any thousands separators or errant keystrokes)
    newAnswer = newAnswer.replace(/\D/g, "");

    restoreCursor(event, answer, newAnswer);

    setAnswer(newAnswer);
  }

  return {
    answer,
    setAnswer,
    transformAnswer: transformAnswerToInteger,
    handleChange,
  };
}

export function useCurrencyState(question: CurrencyQuestion): {
  answer: string;
  setAnswer: React.Dispatch<React.SetStateAction<string>>;
  transformAnswer: (answer: string) => number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} {
  // On the FE, we convert the currency answers to strings that
  // makes it simpler when working with the inputs
  const [answer, setAnswer] = useState(
    question.answer === null ? "" : question.answer.toString(),
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    let newAnswer = event.target.value;

    // For currency, we'll keep just the digits and a decimal.
    // We'll strip out the thousands separators and anything else
    newAnswer = newAnswer.replace(/[^0-9.]/g, "");

    // If the user types more than one decimal point, remove all
    // the ones after the first decimal point
    const indexOfFirstDecimal = newAnswer.indexOf(".");
    if (indexOfFirstDecimal !== -1) {
      newAnswer =
        newAnswer.substring(0, indexOfFirstDecimal) +
        "." +
        // Take the whole string after the first decimal,
        // remove any extra decimals, and *then* take the first two digits
        newAnswer
          .substring(indexOfFirstDecimal + 1)
          .replace(".", "")
          .substr(0, 2);
    }

    restoreCursor(event, answer, newAnswer);

    setAnswer(newAnswer);
  }

  return {
    answer,
    setAnswer,
    transformAnswer: transformAnswerToFloat,
    handleChange,
  };
}

// Convert back from string to boolean for sending to BE
function transformAnswerToBoolean(answer: string): boolean {
  return answer === "true";
}

// Convert back from string to integer for sending to BE
function transformAnswerToInteger(answer: string): number {
  return parseInt(answer) || 0;
}

// Convert back from string to float for sending to BE
function transformAnswerToFloat(answer: string): number {
  return parseFloat(answer) || 0;
}
