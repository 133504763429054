import React from "react";

import { Box, Text } from "@chakra-ui/react";

import { renderWithHtml } from "../helpers/TextFormatters";

const InputBlockLabel: React.FC<{
  label: string;
  subLabel?: string;
}> = ({ label, subLabel }) => {
  return (
    <Box mb="2">
      <Label label={label} />
      {subLabel && <SubLabelText subLabel={subLabel} />}
    </Box>
  );
};

export default InputBlockLabel;

export const Label: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Box>
      <LabelText label={label} />
    </Box>
  );
};

const LabelText: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Text size="lg" fontWeight="semibold" color="text.primary">
      {label}
    </Text>
  );
};

const SubLabelText: React.FC<{ subLabel: string }> = ({ subLabel }) => {
  return (
    <>
      {subLabel.split("\n").map((paragraph, idx) => (
        <Text
          fontSize="md"
          lineHeight="1.375rem"
          textAlign="left"
          color="text.secondary"
          mb={3}
          _last={{ marginBottom: 3 }}
          key={"par" + idx}
        >
          {renderWithHtml(paragraph)}
        </Text>
      ))}
    </>
  );
};
