import { authenticateUrl } from "../../shared/api";

import { BASE_HEADERS } from "../../utils/headers";
import { convertToExternalBrowserUrl } from "../../utils/nav-utils";
import { getUserTokenIfExists } from "../../utils/params-utils";

interface PDFPollHook {
  pollUrl: string;
  finalUrl: string;
  intervalMs: number;
  maxTries: number;
  onProgress: (progressLabel: string) => void;
  onComplete: (pdfUrl: string) => void;
}

/*
 * Hook that can be used to poll and open a PDF on completion
 *
 * We will hit *pollUrl* every *intervalMs*. Each time we hit the URL, we'll
 * get the current status of the job and call *onProgress*.
 *
 * When the job completes, we'll call the *onComplete* callback
 *
 * If we run out of tries, we'll stop polling. We could also consider adding
 * some sort of callback to handle this case!
 */
const usePDFPolling = ({
  pollUrl,
  finalUrl,
  intervalMs,
  maxTries,
  onProgress,
  onComplete,
}: PDFPollHook): {
  startPolling: () => void;
} => {
  const token = getUserTokenIfExists();

  const poll = async (
    pollUrl: string,
    finalUrl: string,
    remainingTries: number,
  ) => {
    if (remainingTries <= 0) {
      return;
    }
    // Create headers object (token may be null)
    const headers: Record<string, string> = {
      ...BASE_HEADERS,
      "Key-Inflection": "camel",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await fetch(authenticateUrl(pollUrl), {
      headers: headers,
      // include cookies
      credentials: "include",
    });

    if (response.status === 200) {
      console.log("got response", response);
      const jobStatus = await response.json();
      if (jobStatus.progressLabel) {
        onProgress(jobStatus.progressLabel);
      }
      if (jobStatus.completed) {
        onComplete(convertToExternalBrowserUrl(authenticateUrl(finalUrl)));
      } else {
        setTimeout(
          () => poll(pollUrl, finalUrl, remainingTries - 1),
          intervalMs,
        );
      }
    }
  };

  return {
    startPolling: () => {
      poll(pollUrl, finalUrl, maxTries);
    },
  };
};

export default usePDFPolling;
