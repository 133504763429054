import React, { ReactNode } from "react";

import { Box, Flex, FlexProps } from "@chakra-ui/react";

import { StepType } from "../types";

import BackButton from "../components/BackButton";
import CloseButton from "../components/CloseButton";

import { getScreenSize } from "../utils/utils";

interface Props extends FlexProps {
  children: ReactNode;
  renderClose: boolean;
  renderCloseConfirmationModal?: boolean;
  onBack?: (targetStep: {
    target_step_id: string;
    target_question_id?: string;
  }) => Promise<void>;
  previous?: { target_step_id: StepType; target_question_id?: string };
}

function ScreenContainer({
  children,
  renderClose,
  renderCloseConfirmationModal = true,
  onBack,
  previous,
  ...props
}: Props) {
  const { isTallPhone, isMediumPhone } = getScreenSize();

  let styles: FlexProps;

  if (isTallPhone) {
    styles = {
      padding: "8",
      paddingTop: "24",
      paddingBottom: "16",
    };
  } else if (isMediumPhone) {
    styles = {
      padding: "8",
      paddingY: "16",
    };
  } else {
    styles = {
      padding: "6",
    };
  }
  return (
    <Flex
      {...styles}
      {...props}
      overflow="auto"
      height="full"
      direction="column"
      borderRadius="md"
      position="relative"
    >
      {/* Use safe-area-inset-right and top to adjust for the notch
          See https://webkit.org/blog/7929/designing-websites-for-iphone-x/ */}
      <Box>
        <Box
          left="max(20px, env(safe-area-inset-left))"
          top="max(20px, env(safe-area-inset-top))"
          position="absolute"
        >
          {previous && onBack && (
            <BackButton onBack={onBack} previous={previous} />
          )}
        </Box>
        <Box
          position="absolute"
          right="max(20px, env(safe-area-inset-right))"
          top="max(20px, env(safe-area-inset-top))"
        >
          {renderClose && (
            <CloseButton
              renderCloseConfirmationModal={renderCloseConfirmationModal}
            />
          )}
        </Box>
      </Box>

      {children}
    </Flex>
  );
}

export default ScreenContainer;
