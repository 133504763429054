import React from "react";

function RadioUnCheckedIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="18" height="18" rx="9" fill="white" />
      <rect
        x="1"
        y="1"
        width="18"
        height="18"
        rx="9"
        stroke="#D4D4D4"
        strokeWidth="2"
      />
    </svg>
  );
}

export default RadioUnCheckedIcon;
