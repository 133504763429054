import React from "react";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Box, Show } from "@chakra-ui/react";

import { OpenHelpTypes } from "../types";

import DesktopHandoffForPdfModalBody from "./DesktopHandoffForPdfModalBody";
import Modal from "./Modal";

type Props = {
  screenId: string;
  userEmail: string;
};

const DesktopHandoffForPdfBlock: React.FC<Props> = ({
  screenId,
  userEmail,
}) => {
  const title = "Not sure how to upload a PDF from your phone?";
  // Only show for mobile users
  return (
    <Show below="md">
      <Box>
        <Modal
          openText={title}
          openEventProperties={{
            openKey: "desktop-handoff-pdf",
            openType: OpenHelpTypes.BLOCK,
            screenId,
          }}
          icon={
            <InfoOutlineIcon
              color="brand.medium"
              mr="2"
              fontWeight="medium"
              width="3"
              height="3"
            />
          }
          body={
            <DesktopHandoffForPdfModalBody
              screenId={screenId}
              userEmail={userEmail}
              title={title}
            />
          }
        />
      </Box>
    </Show>
  );
};

export default DesktopHandoffForPdfBlock;
