import React from "react";

import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";

interface Props {
  text: string;
  label: string;
  onClick: () => void;
}

const InputButtonBlock: React.FC<Props> = ({ text, label, onClick }) => {
  return (
    <InputGroup onClick={onClick} size="md" cursor="pointer" marginBottom="5">
      <Input
        readOnly
        style={{ cursor: "pointer", fontSize: "16px", fontWeight: 600 }}
        value={text}
      />
      <InputRightElement
        fontWeight="semibold"
        fontSize="16px"
        color="brand.medium"
        pr="8"
        h="full"
      >
        {label}
      </InputRightElement>
    </InputGroup>
  );
};

export default InputButtonBlock;
