export const catalogTheme = {
  components: {
    Button: {
      variants: {
        "ta-pill": {
          fontWeight: "semibold",
          background: "gray.200",
          color: "#222",
          border: "none",

          // Hover won't come into play on mobile, but
          // chakra's default hover is gray-ish which is
          // confusing in development
          _hover: {
            background: "gray.100",
            color: "#1570EF",

            // Because of some chakra specificity rules, repeat
            // this gray disabled button rule here too
            _disabled: {
              background: "#D7EDFF",
              color: "#1570EF",
            },
          },

          _active: {
            background: "#EFF8FF",
            border: "none",
          },

          _disabled: {
            background: "#D7EDFF",
            // By default, chakra sets the opacity to 0.4 for disabled buttons
            opacity: 0.5,
            color: "#0039CE",
          },

          // (Tomasz) We override button background color on touch vevices not supporting hover with default background color.
          "@media(hover: none)": {
            _hover: {
              background: "#EFF8FF",
            },
          },
        },

        "ta-outline": {
          fontWeight: "semibold",
          background: "white",
          color: "#293056",
          border: "1px solid",

          _disabled: {
            background: "gray.300",
            // By default, chakra sets the opacity to 0.4 for disabled buttons
            opacity: 1,
          },

          _active: {
            background: "#F8F8F8",
          },

          // Hover won't come into play on mobile, but
          // chakra's default hover is gray-ish which is
          // confusing in development
          _hover: {
            background: "gray.100",
            borderColor: "#293056",

            // Because of some chakra specificity rules, repeat
            // this gray disabled button rule here too
            _disabled: {
              background: "gray.300",
              // By default, chakra sets the opacity to 0.4 for disabled buttons
              opacity: 1,
            },
          },

          // (Tomasz) We override button background color on touch vevices not supporting hover with default background color.
          "@media(hover: none)": {
            _hover: {
              background: "white",
            },
          },
        },
      },
    },
  },
};
