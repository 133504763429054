import React, { useRef, useState } from "react";

import { Box, Button, Text } from "@chakra-ui/react";

import { sendEventButtonClick } from "../../api";
import { ButtonClickEventNames, Screen, UploadType } from "../../types";

import FilePlusIcon from "../../icons/FilePlusIcon";

interface Props {
  onFileUpload: ({
    file,
    uploadType,
  }: {
    file: File;
    uploadType: UploadType;
  }) => void;
  setScreen?: (screen: Screen) => void;
  optimisticPayloadOnUpload?: { screen: Screen };
  // TODO(marcia): Update these to be required props after another daily
  // deploy so we're more guaranteed the BE is sending these
  label?: string;
  uploadType?: UploadType;
  screenId: string;
}
const PrefillUploadBlock = ({
  onFileUpload,
  setScreen,
  optimisticPayloadOnUpload,
  label,
  uploadType,
  screenId,
}: Props) => {
  const fileInputRef = useRef<null | HTMLInputElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState<null | File>(null);

  return (
    <Box
      mb={undefined}
      w={{ base: "full", md: "auto" }}
      // A button should take up half container width minus half of row grid gap
      minW={{ md: "calc(50% - 6px)" }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Button
        variant="outline"
        width="full"
        leftIcon={<FilePlusIcon />}
        onClick={() => {
          // We cannot naturally put the Button inside of a FormLabel because
          // it will hog the click event. So, forgo the FormLabel and programmatically
          // click the input ref like this
          sendEventButtonClick({
            buttonName: ButtonClickEventNames.FORM_1040_UPLOAD_INITIATED,
            screenId: screenId,
          });
          fileInputRef.current?.click();
        }}
        maxW={{ md: "300px" }}
      >
        <Text fontSize="lg" fontWeight="bold">
          {/* It's possible the FE could be deployed first, before the
            BE begins sending label. In this case, default to showing
            the usual "Upload your W-2" label. Once the BE always sends
            the label, then we can remove this fallback and fix up the
            types to make label a required prop */}
          {label || "Upload your W-2"}
        </Text>
      </Button>

      <input
        ref={fileInputRef}
        onChange={(event) => {
          const target = event.target as HTMLInputElement;
          if (!target) {
            return;
          }

          const files = target.files;
          if (files && files.length > 0) {
            setFile(files[0]);

            // Initiate uploading the file
            onFileUpload({
              file: files[0],
              uploadType: uploadType || UploadType.W2,
            });

            // Show the waiting screen right away, instead of waiting for the
            // upload to finish. This waiting screen starts polling for status
            if (setScreen && optimisticPayloadOnUpload) {
              setScreen(optimisticPayloadOnUpload.screen);
            }
          }
        }}
        type="file"
        accept="image/*,.pdf"
        hidden
      ></input>
    </Box>
  );
};

export default PrefillUploadBlock;
