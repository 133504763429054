import React from "react";

interface DIWMSVGProps {
  width?: number;
  height?: number;
  className?: string;
}

const FallbackBannerIcon: React.FC<DIWMSVGProps> = ({
  width = 24,
  height = 24,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="DIWM-SVG">
        <path
          id="Vector"
          d="M10.4577 2.88219C11.1454 2.20073 12.2696 2.20073 12.9573 2.88219L14.6773 4.58698C14.9622 4.86939 15.3383 5.04659 15.7417 5.08848L18.1764 5.34134C19.1497 5.44242 19.8506 6.30245 19.7348 7.25329L19.445 9.632C19.3969 10.026 19.4898 10.4242 19.7078 10.7589L21.0238 12.779C21.5498 13.5865 21.2997 14.6589 20.4676 15.1632L18.3862 16.4245C18.0413 16.6335 17.7811 16.9528 17.6496 17.3282L16.8558 19.5944C16.5386 20.5002 15.5256 20.9776 14.6039 20.6554L12.2982 19.8497C11.9162 19.7162 11.4988 19.7162 11.1168 19.8497L8.8111 20.6554C7.88939 20.9776 6.87641 20.5002 6.55914 19.5944L5.76539 17.3282C5.63391 16.9528 5.37365 16.6335 5.02884 16.4245L2.94736 15.1632C2.11532 14.6589 1.86514 13.5865 2.39119 12.779L3.70715 10.7589C3.92515 10.4242 4.01803 10.026 3.97002 9.632L3.68021 7.25329C3.56436 6.30245 4.26535 5.44242 5.23859 5.34134L7.67333 5.08848C8.07664 5.04659 8.45274 4.86939 8.7377 4.58698L10.4577 2.88219Z"
          fill="#D4AF37"
          stroke="white"
          strokeWidth="2"
        />
        <path
          id="Vector_2"
          d="M14.8294 9.66406L10.5366 13.864L8.58545 11.955"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default FallbackBannerIcon;
