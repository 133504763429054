import React from "react";

import { ContentListType, OpenHelpTypes } from "../types";

import HelpModal from "./HelpModal";

interface Props {
  screenId: string;
  label: string;
  helpContentBlocks: ContentListType;
}

const HelpContentBlock: React.FC<Props> = ({
  screenId,
  label,
  helpContentBlocks,
}) => {
  return (
    <HelpModal
      openEventProperties={{
        openKey: screenId,
        openType: OpenHelpTypes.BLOCK,
        screenId: screenId,
      }}
      openText={label}
      helpContentBlocks={helpContentBlocks}
    />
  );
};

export default HelpContentBlock;
