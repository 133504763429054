import React from "react";

function LocationCircle() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.66667C10.2319 3.66667 8.53622 4.36905 7.28597 5.61929C6.03573 6.86953 5.33335 8.56522 5.33335 10.3333C5.33335 12.915 7.01313 15.4202 8.85498 17.3644C9.75886 18.3185 10.6656 19.0996 11.3473 19.6424C11.6009 19.8444 11.8225 20.0127 12 20.1438C12.1775 20.0127 12.3991 19.8444 12.6528 19.6424C13.3345 19.0996 14.2412 18.3185 15.1451 17.3644C16.9869 15.4202 18.6667 12.915 18.6667 10.3333C18.6667 8.56522 17.9643 6.86953 16.7141 5.61929C15.4638 4.36905 13.7681 3.66667 12 3.66667ZM12 21.1667C11.5378 21.86 11.5376 21.8599 11.5373 21.8597L11.5349 21.8582L11.5294 21.8544L11.5103 21.8415C11.4941 21.8305 11.4709 21.8147 11.4413 21.7942C11.3821 21.7532 11.2971 21.6934 11.1898 21.616C10.9755 21.4612 10.672 21.2352 10.309 20.9462C9.58446 20.3692 8.61618 19.5357 7.64506 18.5106C5.73691 16.4965 3.66669 13.585 3.66669 10.3333C3.66669 8.1232 4.54466 6.00358 6.10746 4.44078C7.67027 2.87797 9.78988 2 12 2C14.2102 2 16.3298 2.87797 17.8926 4.44078C19.4554 6.00358 20.3334 8.1232 20.3334 10.3333C20.3334 13.585 18.2631 16.4965 16.355 18.5106C15.3839 19.5357 14.4156 20.3692 13.691 20.9462C13.3281 21.2352 13.0246 21.4612 12.8102 21.616C12.703 21.6934 12.6179 21.7532 12.5587 21.7942C12.5291 21.8147 12.506 21.8305 12.4898 21.8415L12.4707 21.8544L12.4651 21.8582L12.4633 21.8593C12.4631 21.8595 12.4623 21.86 12 21.1667ZM12 21.1667L12.4623 21.86C12.1824 22.0467 11.8172 22.0464 11.5373 21.8597L12 21.1667Z"
        fill="#293056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.66667C11.0795 8.66667 10.3334 9.41286 10.3334 10.3333C10.3334 11.2538 11.0795 12 12 12C12.9205 12 13.6667 11.2538 13.6667 10.3333C13.6667 9.41286 12.9205 8.66667 12 8.66667ZM8.66669 10.3333C8.66669 8.49238 10.1591 7 12 7C13.841 7 15.3334 8.49238 15.3334 10.3333C15.3334 12.1743 13.841 13.6667 12 13.6667C10.1591 13.6667 8.66669 12.1743 8.66669 10.3333Z"
        fill="#293056"
      />
    </svg>
  );
}

export default LocationCircle;
