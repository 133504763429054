import React from "react";

import { BannerBlock, BlockType } from "../types";

import AlertBlock from "../components/AlertBlock";
import IconBlock from "../components/IconBlock";

interface BannerRendererProps {
  bannerItems: Array<BannerBlock>;
}

const BannerRenderer: React.FC<BannerRendererProps> = ({ bannerItems }) => {
  const Blocks = bannerItems.map((block) => {
    switch (block.type) {
      case BlockType.ICON:
        return <IconBlock key={block.id} iconId={block.iconId} />;

      case BlockType.ALERT:
        return (
          <AlertBlock
            key={block.id}
            title={block.title}
            text={block.text}
            status={block.status}
          />
        );
    }
  });

  return <>{Blocks}</>;
};

export default BannerRenderer;
